import React from "react";
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  FormControl,
  Link,
  Chip,
  Divider,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
  InputAdornment,
  MenuItem,
  Select,
} from "@material-ui/core";
import { NumericFormat } from "react-number-format";
import { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
// import {  Che FormControlLabel, TextField } from "@mui/material";
// import Autocomplete from "@mui/material/Autocomplete";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import React, { useState } from 'react';
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css"; // Importing the styles
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useLocation, useHistory } from "react-router-dom";

import Header from "../components/header";
import { useDispatch, useSelector } from "react-redux";
import "../css/expenseReport.css";
import {
  getAllExpenseReportData,
  getEmpDetail,
  getAllExpenseReportDownloadData,
  getAllExpenseReportDownloadData1,
  getExpenseReportData,
  setAllExpenseChips,
} from "../redux/Expense/action";
import { getTimesheetReportData } from "../redux/TimeSheet/actionTs";
import { useMsal, useAccount } from "@azure/msal-react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px",
  },
  root1: {
    padding: "9px",
  },
  formControl1: {
    width: "100%",
    height: "70px",
    marginLeft: "9px",
    "& .MuiFormLabel-root": {
      //marginLeft: "11px",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#515961",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-input": {
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  addExpenseButton: {
    height: "42px",
    width: "187px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  buttonText: {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
  },
  addExpenseButton1: {
    height: "34px",
    width: "119px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  buttonText1: {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: "10px",
    fontWeight: 500,
  },
  customRow: {
    height: "20px", // Adjust this value as needed
  },
  statusStyle: {
    borderRadius: "100px",
    width: " 110px",
    height: "22px",
    flexShrink: 0,
  },
  tablerow: {
    height: 58,
  },
  auditHistory: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "14px",
    color: "#008CE6",
    paddingTop: "20px",
    textDecoration: "underline",
  },
}));
function AllNewExpenseReport() {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const [isLoader, setIsLoader] = useState(true);
  const [value, setValue] = useState([null, null]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedProjId, setSelectedProjId] = useState([]);
  const [selectedMaxAmount, setSelectedMaxAmount] = useState([]);
  const [selectedMinAmount, setSelectedMinAmount] = useState([]);
  const [selectedEmpName, setSelectedEmpName] = useState([]);
  const [selectedExpCategory, setSelectedExpCategory] = useState([]);
  const [selectedExpPurpose, setSelectedExpPurpose] = useState([]);
  const [selectedExpId, setSelectedExpId] = useState([]);
  const [selectedManagerApprovalDate, setSelectedManagerApprovalDate] =
    useState([]);
  const [selectedCreationDate, setSelectedCreationDate] = useState([]);

  const [chips, setChips] = useState({
    creationDateChip: [],
    managerApprovalDateChip: [],
    expIdChip: [],
    expPurposeChip: [],
    expCategoryChip: [],
    empNameChip: [],
    minClaimAmountChip: [],
    maxClaimAmountChip: [],
    statusChip: [],
    projIdChip: [],
  });
  const [tempChips, setTempChips] = useState({
    creationDateChip: [],
    managerApprovalDateChip: [],
    expIdChip: [],
    expPurposeChip: [],
    expCategoryChip: [],
    empNameChip: [],
    minClaimAmountChip: [],
    maxClaimAmountChip: [],
    statusChip: [],
    projIdChip: [],
  });
  const [selectedProjItem, setSelectedProjItem] = useState([]);
  const [selectedDateItem, setSelectedDateItem] = useState([]);
  const [isNewChip, setIsNewChip] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  //   const [startDate, setStartDate] = useState(dayjs());
  //   const [endDate, setEndDate] = useState(dayjs());
  const datePickerRef = useRef(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const dispatch = useDispatch();
  const { instance, accounts, inProgress } = useMsal();
  const [operator, setOperator] = useState(">="); // Default operator
  const [amount, setAmount] = useState("");
  const [expenseReportViewState, setExpenseReportViewState] = useState([]);
  const [uniqueExpenses, setUniqueExpenses] = useState([]);

  const handleOperatorChange = (event) => {
    setOperator(event.target.value);
  };

  const account = useAccount(accounts[0] || {});
  let dateRange1 = "";
  let dateRange2 = "";
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const getAllExpenseReportList = useSelector(
    (state) => state.createExpenseData.getAllExpensesReport
  );
  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );
  const expenseReport = useSelector(
    (state) => state?.createExpenseData?.getAllExpensesReportDropdown
  );
  var allSetChips = useSelector(
    (state) => state?.createExpenseData?.getSetExpenseChips
  );
  const timesheetReportJson = JSON.parse(expenseReport);
  // console.log("first", location?.state);
  const handleConfirm = () => {};
  const handleScroll = () => {
    // Check if the user has scrolled to the bottom

    if (
      window.innerHeight + document.documentElement.scrollTop + 2 >=
        document.documentElement.offsetHeight &&
      currentPage !== 1 &&
      (currentPage - 1) * 10 < expenseReportViewState[0]?.totalRecords
    ) {
      // setCurrentPage(currentPage + 1);
      // Load the next page when scrolling to the bottom

      // setCurrentPage((prevPage) => prevPage + 1);
      handleExpenseReport();
    }
  };
  useEffect(() => {
    dispatch(getEmpDetail(account?.username));
  }, []);
  useEffect(() => {
    if (ssoEmpIdDetails) {
      dispatch(getExpenseReportData());
    }
  }, [dispatch, ssoEmpIdDetails]);
  useEffect(() => {
    // console.log("checking double data 3", allSetChips);

    if (allSetChips) {
      setTempChips({
        creationDateChip: allSetChips.allTempChips.creationDateChip,
        managerApprovalDateChip:
          allSetChips.allTempChips.managerApprovalDateChip,
        expIdChip: allSetChips.allTempChips.expIdChip,
        expPurposeChip: allSetChips.allTempChips.expPurposeChip,
        expCategoryChip: allSetChips.allTempChips.expCategoryChip,
        empNameChip: allSetChips.allTempChips.empNameChip,
        minClaimAmountChip: allSetChips.allTempChips.minClaimAmountChip,
        statusChip: allSetChips.allTempChips.statusChip,
        projIdChip: allSetChips.allTempChips.projIdChip,
      });
      setChips({
        creationDateChip: allSetChips.allTempChips.creationDateChip,
        managerApprovalDateChip:
          allSetChips.allTempChips.managerApprovalDateChip,
        expIdChip: allSetChips.allTempChips.expIdChip,
        expPurposeChip: allSetChips.allTempChips.expPurposeChip,
        expCategoryChip: allSetChips.allTempChips.expCategoryChip,
        empNameChip: allSetChips.allTempChips.empNameChip,
        minClaimAmountChip: allSetChips.allTempChips.minClaimAmountChip,
        statusChip: allSetChips.allTempChips.statusChip,
        projIdChip: allSetChips.allTempChips.projIdChip,
      });

      setSelectedStatus(allSetChips.allTempChips.statusChip);
      // setSelectedProjItem(allSetChips.allTempChips.projectsChip);
      setSelectedEmpName(allSetChips.allTempChips.empNameChip);
      setSelectedCreationDate(allSetChips.allTempChips.creationDateChip);
      setSelectedManagerApprovalDate(
        allSetChips.allTempChips.managerApprovalDateChip
      );
      setSelectedExpId(allSetChips.expIdChip);
      // setSelectedEmpName(allSetChips.allTempChips.statusChip);
      setSelectedExpPurpose(allSetChips.allTempChips.expPurposeChip);
      setSelectedExpCategory(allSetChips.allTempChips.expCategoryChip);
      setSelectedProjId(allSetChips.allTempChips.projIdChip);
      setOperator(allSetChips.operator);
      setAmount(allSetChips.amount);
      //   setSelectedExpCategory(allSetChips.allTempChips.expCategoryChip);
      setCurrentPage(allSetChips.currentPage);
      // setCurrentPage(1);
      setExpenseReportViewState(allSetChips.expenseReportViewState);
      setIsNewChip(false);
    }
  }, [allSetChips]);
  useEffect(() => {
    setExpenseReportViewState((prevState) => {
      const updatedState = [];
      // console.log(updatedState); // This will log the updated state right away
      // Perform any action you need with updatedState here
      return updatedState;
    });
    // setExpenseReportViewState(()=>[]);
    if (expenseReport && ssoEmpIdDetails) {
      if (
        selectedManagerApprovalDate &&
        selectedManagerApprovalDate?.length === 2
      ) {
        const [startDate, endDate] = selectedManagerApprovalDate;
        const formattedStartDate = new Date(startDate).toLocaleDateString(
          "en-GB"
        ); // 'en-GB' for dd/MM/yyyy format
        const formattedEndDate = new Date(endDate).toLocaleDateString("en-GB");
        dateRange1 = `${formattedStartDate} – ${formattedEndDate}`;
      }

      const obj = {
        empId: ssoEmpIdDetails?.empId,
        expCode: allSetChips?.allTempChips?.expIdChip
          ? allSetChips?.allTempChips?.expIdChip
          : [],
        purpose: allSetChips?.allTempChips?.expPurposeChip
          ? allSetChips?.allTempChips?.expPurposeChip
          : [],
        categoryName: allSetChips?.allTempChips?.expCategoryChip
          ? allSetChips?.allTempChips?.expCategoryChip
          : [],
        empName: allSetChips?.allTempChips?.empNameChip
          ? allSetChips?.allTempChips?.empNameChip
          : [],
        createdOn: allSetChips?.dateRange2 ? allSetChips?.dateRange2 : [],
        managerApprovalDate: allSetChips?.dateRange1
          ? allSetChips?.dateRange1
          : [],
        compOperator: allSetChips?.operator ? allSetChips?.operator : "",
        claimAmount: allSetChips?.amount ? allSetChips?.amount : "",
        status: allSetChips?.allTempChips?.statusChip
          ? allSetChips?.allTempChips?.statusChip
          : [],
        projectCode: allSetChips?.allTempChips?.projIdChip
          ? allSetChips?.allTempChips?.projIdChip
          : [],
        // pageNumber: allSetChips?.currentPage ? allSetChips?.currentPage : 1,
        pageNumber: 1,
        pageSize: 50,
      };

      dispatch(getAllExpenseReportData(obj, setIsLoader));
      setCurrentPage(2);
      // setExpenseReportViewState([]);
      // console.log(
      //   "checking double data 1",
      //   expenseReportViewState,
      //   getAllExpenseReportList
      // );
    }
  }, [expenseReport, ssoEmpIdDetails]);

  useEffect(() => {
    if (isNewChip) {
      setChips({
        statusChip: tempChips.statusChip,
        projIdChip: tempChips.projIdChip,
        creationDateChip: tempChips.creationDateChip,
        managerApprovalDateChip: tempChips.managerApprovalDateChip,
        expIdChip: tempChips.expIdChip,
        expPurposeChip: tempChips.expPurposeChip,
        expCategoryChip: tempChips.expCategoryChip,
        empNameChip: tempChips.empNameChip,
        minClaimAmountChip: tempChips.minClaimAmountChip,
        maxClaimAmountChip: tempChips.maxClaimAmountChip,
      });
      setIsNewChip(false);
    }
  }, [isNewChip]);
  useEffect(() => {
    // Add a scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentPage, expenseReportViewState]);
  useEffect(() => {
    if (getAllExpenseReportList?.length > 0) {
      setExpenseReportViewState((prev) => [
        ...prev,
        ...getAllExpenseReportList
      ]);
      // console.log("checking double data 7", expenseReportViewState);
    
    }
  }, [getAllExpenseReportList]);

    useEffect(() => {
      // Create a Set to filter unique expenses based on expId
      const uniqueExpIds = new Set();
  const filteredUniqueExpenses = expenseReportViewState.filter(item => {
            if (!uniqueExpIds.has(item.expId)) {
          uniqueExpIds.add(item.expId);
          return true; // Keep this item
        }
        return false; // Filter out this item
      });

      setUniqueExpenses(filteredUniqueExpenses);
      // console.log("checking double data 9", filteredUniqueExpenses);
    }, [expenseReportViewState]);

 

  const handleApplyFilter = () => {
    setExpenseReportViewState([]);
    // setCurrentPage(1);

    setIsLoader(true);

    if (
      selectedManagerApprovalDate &&
      selectedManagerApprovalDate?.length === 2
    ) {
      const [startDate, endDate] = selectedManagerApprovalDate;
      const formattedStartDate = new Date(startDate).toLocaleDateString(
        "en-GB"
      ); // 'en-GB' for dd/MM/yyyy format
      const formattedEndDate = new Date(endDate).toLocaleDateString("en-GB");
      dateRange1 = `${formattedStartDate} – ${formattedEndDate}`;
    }
    if (selectedCreationDate && selectedCreationDate?.length === 2) {
      const [startDate, endDate] = selectedCreationDate;
      const formattedStartDate = new Date(startDate).toLocaleDateString(
        "en-GB"
      ); // 'en-GB' for dd/MM/yyyy format
      const formattedEndDate = new Date(endDate).toLocaleDateString("en-GB");
      dateRange2 = `${formattedStartDate} – ${formattedEndDate}`;
    }

    const obj = {
      empId: ssoEmpIdDetails?.empId,
      expCode: tempChips.expIdChip ? tempChips.expIdChip : [],
      purpose: tempChips.expPurposeChip ? tempChips.expPurposeChip : [],
      categoryName: tempChips.expCategoryChip ? tempChips.expCategoryChip : [],
      empName: tempChips.empNameChip ? tempChips.empNameChip : [],
      createdOn: dateRange2 ? dateRange2 : [],
      managerApprovalDate: dateRange1 ? dateRange1 : [],
      compOperator: operator ? operator : "",
      claimAmount: amount ? amount : "",
      status: tempChips.statusChip ? tempChips.statusChip : [],
      projectCode: tempChips.projIdChip ? tempChips.projIdChip : [],
      pageNumber: 1,
      pageSize: 50,
    };

    dispatch(getAllExpenseReportData(obj, setIsLoader));
    setCurrentPage(2);
  };
  const handleClearAll = () => {
    setTempChips({
      creationDateChip: [],
      managerApprovalDateChip: [],
      expIdChip: [],
      expPurposeChip: [],
      expCategoryChip: [],
      empNameChip: [],
      minClaimAmountChip: [],
      maxClaimAmountChip: [],
      statusChip: [],
      projIdChip: [],
    });
    setChips({
      creationDateChip: [],
      managerApprovalDateChip: [],
      expIdChip: [],
      expPurposeChip: [],
      expCategoryChip: [],
      empNameChip: [],
      minClaimAmountChip: [],
      maxClaimAmountChip: [],
      statusChip: [],
      projIdChip: [],
    });
    setSelectedCreationDate([]);
    setSelectedManagerApprovalDate([]);
    setSelectedExpId([]);
    setSelectedExpPurpose([]);
    setSelectedExpCategory([]);
    setSelectedEmpName([]);
    setSelectedProjId([]);
    setSelectedStatus([]);
    setAmount("");
    setIsLoader(true);
    const obj = {
      empId: ssoEmpIdDetails?.empId,
      expCode: "",
      purpose: "",
      categoryName: "",
      empName: "",
      createdOn: "",
      managerApprovalDate: "",
      compOperator: "",
      claimAmount: "",
      status: "",
      projectCode: "",
      pageNumber: 1,
      pageSize: 50,
    };

    dispatch(getAllExpenseReportData(obj, setIsLoader));
  };
  const handleReportDownload = () => {
    setIsLoader(true);

    if (
      selectedManagerApprovalDate &&
      selectedManagerApprovalDate?.length === 2
    ) {
      const [startDate, endDate] = selectedManagerApprovalDate;
      const formattedStartDate = new Date(startDate).toLocaleDateString(
        "en-GB"
      ); // 'en-GB' for dd/MM/yyyy format
      const formattedEndDate = new Date(endDate).toLocaleDateString("en-GB");
      dateRange1 = `${formattedStartDate} – ${formattedEndDate}`;
    }
    if (selectedCreationDate && selectedCreationDate?.length === 2) {
      const [startDate, endDate] = selectedCreationDate;
      const formattedStartDate = new Date(startDate).toLocaleDateString(
        "en-GB"
      ); // 'en-GB' for dd/MM/yyyy format
      const formattedEndDate = new Date(endDate).toLocaleDateString("en-GB");
      dateRange2 = `${formattedStartDate} – ${formattedEndDate}`;
    }

    const obj = {
      empId: ssoEmpIdDetails?.empId,
      expCode: tempChips.expIdChip ? tempChips.expIdChip : "",
      purpose: tempChips.expPurposeChip ? tempChips.expPurposeChip : "",
      categoryName: tempChips.expCategoryChip ? tempChips.expCategoryChip : "",
      empName: tempChips.empNameChip ? tempChips.empNameChip : "",
      createdOn: dateRange2 ? dateRange2 : "",
      managerApprovalDate: dateRange1 ? dateRange1 : "",
      compOperator: operator ? operator : "",
      claimAmount: amount ? amount : "",
      status: tempChips.statusChip ? tempChips.statusChip : "",
      projectCode: tempChips.projIdChip ? tempChips.projIdChip : "",
      pageNumber: 1,
      pageSize: 100000,
      reporttype: "getallexpensereportdownload",
    };
    //  console.log("obj",obj)
    dispatch(getAllExpenseReportDownloadData1(obj, setIsLoader));
  };
  const handleExpenseReport = () => {
    setIsLoader(true);
    if (
      selectedManagerApprovalDate &&
      selectedManagerApprovalDate?.length === 2
    ) {
      const [startDate, endDate] = selectedManagerApprovalDate;
      const formattedStartDate = new Date(startDate).toLocaleDateString(
        "en-GB"
      ); // 'en-GB' for dd/MM/yyyy format
      const formattedEndDate = new Date(endDate).toLocaleDateString("en-GB");
      dateRange1 = `${formattedStartDate} – ${formattedEndDate}`;
    }
    if (selectedCreationDate && selectedCreationDate?.length === 2) {
      const [startDate, endDate] = selectedCreationDate;
      const formattedStartDate = new Date(startDate).toLocaleDateString(
        "en-GB"
      ); // 'en-GB' for dd/MM/yyyy format
      const formattedEndDate = new Date(endDate).toLocaleDateString("en-GB");
      dateRange2 = `${formattedStartDate} – ${formattedEndDate}`;
    }

    const obj = {
      empId: ssoEmpIdDetails?.empId,
      expCode: tempChips.expIdChip ? tempChips.expIdChip : "",
      purpose: tempChips.expPurposeChip ? tempChips.expPurposeChip : "",
      categoryName: tempChips.expCategoryChip ? tempChips.expCategoryChip : "",
      empName: tempChips.empNameChip ? tempChips.empNameChip : "",
      createdOn: dateRange2 ? dateRange2 : "",
      managerApprovalDate: dateRange1 ? dateRange1 : "",
      compOperator: operator ? operator : "",
      claimAmount: amount ? amount : "",
      status: tempChips.statusChip ? tempChips.statusChip : "",
      projectCode: tempChips.projIdChip ? tempChips.projIdChip : "",
      pageNumber: currentPage,
      pageSize: 50,
    };

    dispatch(getAllExpenseReportData(obj, setIsLoader));
    setCurrentPage(currentPage + 1);
  };
  const handleChipDelete = (chipType, value) => {
    // const originalValue=selectedProjects.filter(item=>item.replace(/@\$\@/g, "-")!==value)
    // setSelectedProjects(originalValue);
    const index = chips[`${chipType}`].indexOf(value);
    if (index != -1) {
      chips[`${chipType}`].splice(index, 1);
      setIsNewChip(true);
    }
    // chips[`${chipType}`].filter((val) => val !== value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // You can add additional logic here if needed
  };
  const handleAmountChange = (e) => {
    // Allow only numbers and up to 2 decimal places
    const value = e.target.value;
    const regex = /^\d*\.?\d{0,2}$/; // Regex to allow only numbers with up to 2 decimal places

    if (regex.test(value)) {
      setAmount(value);
      setTempChips((prevChips) => ({
        ...prevChips,
        minClaimAmountChip: value ? [value] : [], // Update chip if value is not empty
      }));
      setIsNewChip(true);
    } else {
      setAmount("");
      setTempChips((prevChips) => ({
        ...prevChips,
        minClaimAmountChip: [], // Clear chip if input value is invalid
      }));
      setIsNewChip(false);
    }
  };
  const handleDateChange = (event, values) => {
    if (
      selectedManagerApprovalDate &&
      selectedManagerApprovalDate?.length === 2
    ) {
      const [startDate, endDate] = selectedManagerApprovalDate;
      const formattedStartDate = new Date(startDate).toLocaleDateString(
        "en-GB"
      ); // 'en-GB' for dd/MM/yyyy format
      const formattedEndDate = new Date(endDate).toLocaleDateString("en-GB");
      dateRange1 = `${formattedStartDate} – ${formattedEndDate}`;
    }
    // setSelectedManagerApprovalDate(values);
    setTempChips((prevChips) => ({
      ...prevChips,
      managerApprovalDateChip: dateRange1,
    }));
    // setDateChipValues(originalValues);
    setIsNewChip(true);
  };
  const handleEmployeeName = (event) => {
    setCurrentPage(1);
    const selectedValue = event.target.value.trimStart();
    const isDuplicate = tempChips.employeeChip?.includes(selectedValue);

    const handleEmployeeSubmit = (shouldUpdateState) => {
      //co//sole.log("inside handleemployeesubmit");
      if (shouldUpdateState && !isDuplicate) {
        setSelectedEmpName("");
        setTempChips((prevChips) => ({
          ...prevChips,
          empNameChip: [...prevChips.empNameChip, selectedValue],
        }));
        setIsNewChip(true);
        //co//sole.log("tempchips", tempChips);
      }
    };

    if (
      (event.key === "Enter" && selectedValue?.length > 0) ||
      (event.type === "blur" && selectedValue?.length > 0)
    ) {
      handleEmployeeSubmit(true);
    } else {
      setSelectedEmpName(selectedValue);
    }
    if (event.type === "keydown" && event.key === "Enter") {
      event.preventDefault();
      setSelectedEmpName(""); // Clear the input field after Enter
    }

    if (event.type === "blur") {
      setSelectedEmpName(""); // Clear the input field after blur
    }
  };
  const handleExpId = (event) => {
    setCurrentPage(1);
    const selectedValue = event.target.value.trimStart();
    const isDuplicate = tempChips.employeeChip?.includes(selectedValue);

    const handleEmployeeSubmit = (shouldUpdateState) => {
      //co//sole.log("inside handleemployeesubmit");
      if (shouldUpdateState && !isDuplicate) {
        setSelectedExpId("");
        setTempChips((prevChips) => ({
          ...prevChips,
          expIdChip: [...prevChips.expIdChip, selectedValue],
        }));
        setIsNewChip(true);
        //co//sole.log("tempchips", tempChips);
      }
    };

    if (
      (event.key === "Enter" && selectedValue?.length > 0) ||
      (event.type === "blur" && selectedValue?.length > 0)
    ) {
      handleEmployeeSubmit(true);
    } else {
      setSelectedExpId(selectedValue);
    }
    if (event.type === "keydown" && event.key === "Enter") {
      event.preventDefault();
      setSelectedExpId(""); // Clear the input field after Enter
    }

    if (event.type === "blur") {
      setSelectedExpId(""); // Clear the input field after blur
    }
  };
  const handleProjId = (event) => {
    setCurrentPage(1);
    const selectedValue = event.target.value.trimStart();
    const isDuplicate = tempChips.employeeChip?.includes(selectedValue);

    const handleEmployeeSubmit = (shouldUpdateState) => {
      //co//sole.log("inside handleemployeesubmit");
      if (shouldUpdateState && !isDuplicate) {
        setSelectedProjId("");
        setTempChips((prevChips) => ({
          ...prevChips,
          projIdChip: [...prevChips.projIdChip, selectedValue],
        }));
        setIsNewChip(true);
      }
    };

    if (
      (event.key === "Enter" && selectedValue?.length > 0) ||
      (event.type === "blur" && selectedValue?.length > 0)
    ) {
      handleEmployeeSubmit(true);
    } else {
      setSelectedProjId(selectedValue);
    }
    if (event.type === "keydown" && event.key === "Enter") {
      event.preventDefault();
      setSelectedProjId(""); // Clear the input field after Enter
    }

    if (event.type === "blur") {
      setSelectedProjId(""); // Clear the input field after blur
    }
  };
  const handleExpensePurposeChange = (event, values) => {
    setCurrentPage(1);
    if (values) {
      setSelectedExpPurpose(values);

      setTempChips((prevChips) => ({
        ...prevChips,
        expPurposeChip: values,
      }));
      setIsNewChip(true);
    } else {
      setSelectedExpPurpose([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        expPurposeChip: [],
      }));
    }
  };
  const handleExpenseCategoryChange = (event, values) => {
    setCurrentPage(1);
    if (values) {
      setSelectedExpCategory(values);

      setTempChips((prevChips) => ({
        ...prevChips,
        expCategoryChip: values,
      }));
      setIsNewChip(true);
    } else {
      setSelectedExpCategory([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        expCategoryChip: [],
      }));
    }
  };
  const handleStatusChange = (event, values) => {
    setCurrentPage(1);
    if (values) {
      setSelectedStatus(values);

      setTempChips((prevChips) => ({
        ...prevChips,
        statusChip: values,
      }));
      setIsNewChip(true);
    } else {
      setSelectedStatus([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        statusChip: [],
      }));
    }
  };

  return (
    <div>
      <Header
        heading={"All Expenses Report"}
        ssoEmpId={tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId}
        disableAccOptions={1}
      />
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#e3e8f7",
        }}
      >
        {/* <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity="success"
            sx={{ width: "100%", backgroundColor: "green", color: "white" }} // Set the background color
          >
            Download successful!
          </MuiAlert>
        </Snackbar>{" "} */}
        <Box>
          {" "}
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              history.push("/expense-Management");
              //   window.location.reload();
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Box>

        <Button
          onClick={handleReportDownload}
          className={classes.addExpenseButton}
        >
          <Typography className={classes.buttonText}>Download All</Typography>
        </Button>
      </Box>

      <Divider style={{ marginBottom: "23px" }} />
      <Box
        container
        //spacing={0}
        style={{
          display: "flex",
          marginBottom: "10px",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        {" "}
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.Purpose)
                ? timesheetReportJson?.Purpose
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              // width: "70%",
              width:
                window.screen.availWidth == 1920
                  ? "90%"
                  : window.screen.availWidth == 1536
                  ? "115%"
                  : window.screen.availWidth == 1098
                  ? "57%"
                  : "92%",
              // fontSize: "14px",
              // "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            style={{
              // marginLeft: "105px"
              marginLeft:
                window.screen.availWidth == 1920
                  ? "25px"
                  : window.screen.availWidth == 1536
                  ? "25px"
                  : window.screen.availWidth == 1098
                  ? "41px"
                  : "15px",
            }}
            //options={statusOptions}
            value={selectedExpPurpose}
            onChange={handleExpensePurposeChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedExpPurpose.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px", fontSize: "14px" }}
                label="Expense Purpose"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" }, // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.Category)
                ? timesheetReportJson?.Category
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              // width: "57%",
              width:
                window.screen.availWidth == 1920
                  ? "90%"
                  : window.screen.availWidth == 1536
                  ? "115%"
                  : window.screen.availWidth == 1098
                  ? "57%"
                  : "113%",
              // ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            //options={statusOptions}
            style={{
              //marginLeft: "19px",
              marginLeft:
                window.screen.availWidth == 1920
                  ? "6px"
                  : window.screen.availWidth == 1536
                  ? "86px"
                  : window.screen.availWidth == 1098
                  ? "19px"
                  : "16px",
            }}
            value={selectedExpCategory}
            onChange={handleExpenseCategoryChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedExpCategory.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px" }}
                label="Expense Category"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" }, // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.Status)
                ? timesheetReportJson?.Status
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              //width: "60%",
              width:
                window.screen.availWidth == 1920
                  ? "65%"
                  : window.screen.availWidth == 1536
                  ? "115%"
                  : window.screen.availWidth == 1098
                  ? "57%"
                  : "65%",
              // ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            //options={statusOptions}
            style={{
              //marginLeft: "223px",
              marginLeft:
                window.screen.availWidth == 1920
                  ? "-11px"
                  : window.screen.availWidth == 1536
                  ? "147px"
                  : window.screen.availWidth == 1098
                  ? "136px"
                  : "71px",
            }}
            value={selectedStatus}
            onChange={handleStatusChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedStatus.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px" }}
                label="Status"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" }, // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid
          style={{
            // marginLeft: "-62.87rem"
            marginRight:
              window.screen.availWidth == 1920
                ? "3rem"
                : window.screen.availWidth == 1536
                ? "23px"
                : window.screen.availWidth == 1098
                ? "-62.87rem"
                : "13px",
            marginLeft:
              window.screen.availWidth == 1920
                ? "-8rem"
                : window.screen.availWidth == 1536
                ? "13rem"
                : "0px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                m: 1,
                width:
                  window.screen.availWidth == 1920
                    ? "45ch"
                    : window.screen.availWidth == 1536
                    ? "34ch"
                    : window.screen.availWidth == 1098
                    ? "25ch"
                    : "26.5ch",
              },
            }}
            noValidate
            autoComplete="off"
            // onSubmit={handleFormSubmit}
          >
            <div>
              <TextField
                id="outlined-size-small"
                label="Search by Expense Id"
                size="small"
                InputProps={{
                  inputMode: "text",
                  pattern: "[a-zA-Z0-9,]*",
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ height: "20px", width: "20px" }} />
                    </InputAdornment>
                  ),
                  inputProps: {
                    style: { padding: "8.5px 14px" },
                  },
                }}
                InputLabelProps={{
                  style: { fontSize: "14px" }, // Set the font size here
                }}
                variant="outlined"
                // sx={{
                //   ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                //     fontSize: "14px",
                //   },
                // }}
                value={selectedExpId}
                onChange={handleExpId}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    // e.preventDefault();
                    handleExpId(e); // Your keydown logic
                  }
                }}
                onBlur={handleExpId}
              />
            </div>
          </Box>
        </Grid>
        <Grid
          style={{
            // marginLeft: "-62.87rem"
            marginRight:
              window.screen.availWidth == 1920
                ? "1rem"
                : window.screen.availWidth == 1536
                ? "0.87rem"
                : window.screen.availWidth == 1098
                ? "-62.87rem"
                : "14px",
            marginLeft:
              window.screen.availWidth == 1920
                ? "-1rem"
                : window.screen.availWidth == 1536
                ? "0rem"
                : "-2px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                m: 1,
                width:
                  window.screen.availWidth == 1920
                    ? "45ch"
                    : window.screen.availWidth == 1536
                    ? "34ch"
                    : window.screen.availWidth == 1098
                    ? "25ch"
                    : "34.5ch",
              },
            }}
            noValidate
            autoComplete="off"
            // onSubmit={handleFormSubmit}
          >
            <div>
              <TextField
                id="outlined-size-small"
                label="Search by Employee Name/Id"
                size="small"
                InputProps={{
                  inputMode: "text",
                  pattern: "[a-zA-Z0-9,]*",
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ height: "20px", width: "20px" }} />
                    </InputAdornment>
                  ),
                  inputProps: {
                    style: { padding: "8.5px 14px" },
                  },
                }}
                InputLabelProps={{
                  style: { fontSize: "14px" }, // Set the font size here
                }}
                variant="outlined"
                // sx={{
                //   ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                //     fontSize: "14px",
                //   },
                // }}
                value={selectedEmpName}
                onChange={handleEmployeeName}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    // e.preventDefault();
                    handleEmployeeName(e); // Your keydown logic
                  }
                }}
                onBlur={handleEmployeeName}
              />
            </div>
          </Box>
        </Grid>
      </Box>
      <Grid
        container
        spacing={1}
        style={{
          display: "flex",
          marginBottom: "15px",
          marginTop: "2px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {" "}
        <Grid>
          <div>
            {/* <h3>Select Date Range</h3> */}
            <DateRangePicker
              size="lg"
              format="dd/MM/yyyy"
              character=" - "
              value={selectedCreationDate}
              onChange={(newValue) => setSelectedCreationDate(newValue)}
              placeholder="Creation Date Range"
              error={false}
              onOk={handleConfirm} // Make sure this event is correctly implemented
              style={{
                width:
                  window.screen.availWidth == 1920
                    ? "417px"
                    : window.screen.availWidth == 1536
                    ? "318px"
                    : window.screen.availWidth == 1098
                    ? "25ch"
                    : "255px",
                paddingLeft:
                  window.screen.availWidth == 1920
                    ? "28px"
                    : window.screen.availWidth == 1536
                    ? "27px"
                    : window.screen.availWidth == 1098
                    ? "25ch"
                    : "19px",
              }}
              className="custom-date-range-picker"
            />
          </div>
        </Grid>
        <Grid>
          <div
            style={{
              marginLeft:
                window.screen.availWidth == 1920
                  ? "-56px"
                  : window.screen.availWidth == 1536
                  ? "-39px"
                  : window.screen.availWidth == 1098
                  ? "25ch"
                  : "-22px",
            }}
          >
            {/* <h3>Select Date Range</h3> */}
            <DateRangePicker
              size="lg"
              format="dd/MM/yyyy"
              character=" - "
              value={selectedManagerApprovalDate}
              onChange={(newValue) => {
                setSelectedManagerApprovalDate(newValue);

                // handleDateChange();
              }}
              // onChange={handleDateChange}
              // onChange={handleDateChange}
              placeholder="Manager Approval Date Range"
              error={false}
              onOk={handleConfirm} // Make sure this event is correctly implemented
              style={{
                width:
                  window.screen.availWidth == 1920
                    ? "393px"
                    : window.screen.availWidth == 1536
                    ? "301px"
                    : window.screen.availWidth == 1098
                    ? "25ch"
                    : "296px",
                paddingLeft:
                  window.screen.availWidth == 1920
                    ? ""
                    : window.screen.availWidth == 1536
                    ? "9px"
                    : window.screen.availWidth == 1098
                    ? "25ch"
                    : "2px",
              }} // Optional styling
              // inputStyle={{
              //     fontSize: '14px', // Adjust font size for the placeholder text
              //   }}
              className="custom-date-range-picker"
            />
          </div>
        </Grid>
        <Grid>
          <TextField
            id="outlined-basic"
            label="Claim Amount"
            variant="outlined"
            value={amount}
            onChange={handleAmountChange}
            onBlur={() => {
              if (amount === "") {
                // Clear the input box after losing focus
                // setAmount('');
                setTempChips((prevChips) => ({
                  ...prevChips,
                  minClaimAmountChip: [], // Clear the chip when the input box loses focus
                }));
                setIsNewChip(false);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                if (amount === "") {
                  // setAmount(''); // Clear the input box on Enter key
                  setTempChips((prevChips) => ({
                    ...prevChips,
                    minClaimAmountChip: [], // Clear the chip on Enter key
                  }));
                  setIsNewChip(false);
                }
                setAmount("");
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Select
                    value={operator}
                    onChange={(e) => setOperator(e.target.value)}
                    style={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    <MenuItem value=">=">{">="}</MenuItem>
                    <MenuItem value="<=">{"<="}</MenuItem>
                  </Select>
                </InputAdornment>
              ),
              style: { height: "40px" },
            }}
            //   InputLabelProps={{
            //     shrink: true, // This forces the label to float above the input
            //   }}
            style={{
              width:
                window.screen.availWidth == 1920
                  ? "285px"
                  : window.screen.availWidth == 1536
                  ? "292px"
                  : window.screen.availWidth == 1098
                  ? "25ch"
                  : "167px",
              marginLeft:
                window.screen.availWidth == 1920
                  ? "-52px"
                  : window.screen.availWidth == 1536
                  ? "-32px"
                  : window.screen.availWidth == 1098
                  ? "25ch"
                  : "-19px",
              marginRight:
                window.screen.availWidth == 1920
                  ? "389px"
                  : window.screen.availWidth == 1536
                  ? "-38px"
                  : window.screen.availWidth == 1098
                  ? "25ch"
                  : "-17px",
            }}
          />
        </Grid>
        <Grid
          style={{
            // marginLeft: "-62.87rem"
            marginRight:
              window.screen.availWidth == 1920
                ? "9.4rem"
                : window.screen.availWidth == 1536
                ? "7.3rem"
                : window.screen.availWidth == 1098
                ? "-62.87rem"
                : "139px",
            marginLeft:
              window.screen.availWidth == 1920
                ? "-27rem"
                : window.screen.availWidth == 1536
                ? "0rem"
                : "",
          }}
        >
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                m: 1,
                width:
                  window.screen.availWidth == 1920
                    ? "45ch"
                    : window.screen.availWidth == 1536
                    ? "34ch"
                    : window.screen.availWidth == 1098
                    ? "25ch"
                    : "26.5ch",
              },
            }}
            noValidate
            autoComplete="off"
            // onSubmit={handleFormSubmit}
          >
            <div>
              <TextField
                id="outlined-size-small"
                label="Search by Project Id"
                size="small"
                InputProps={{
                  inputMode: "text",
                  pattern: "[a-zA-Z0-9,]*",
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ height: "20px", width: "20px" }} />
                    </InputAdornment>
                  ),
                  inputProps: {
                    style: { padding: "8.5px 14px" },
                  },
                }}
                InputLabelProps={{
                  style: { fontSize: "14px" }, // Set the font size here
                }}
                variant="outlined"
                // sx={{
                //   ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                //     fontSize: "14px",
                //   },
                // }}
                value={selectedProjId}
                onChange={handleProjId}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    // e.preventDefault();
                    handleProjId(e); // Your keydown logic
                  }
                }}
                onBlur={handleProjId}
              />
            </div>
          </Box>
        </Grid>
        <Grid item xs={1} md={1}>
          <Box style={{ width: "100px" }}>
            <Link
              style={{
                color: "#008CE6",
                //   fromDate && toDate && toDate >= fromDate

                //     : "#808080",
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left",
                cursor: "pointer",
                // cursor:
                //   fromDate && toDate && toDate >= fromDate
                //     ? "pointer"
                //     : "not-allowed",
                // pointerEvents:
                //   fromDate && toDate && toDate >= fromDate ? "" : "none",
                //marginLeft: "-23rem",
                marginLeft:
                  window.screen.availWidth == 1920
                    ? "-6rem"
                    : window.screen.availWidth == 1536
                    ? "-4rem"
                    : window.screen.availWidth == 1098
                    ? "-23rem"
                    : "-6rem",
              }}
              underline="always"
              onClick={(e) => {
                // setCurrentPage(1);
                // setTimesheetReportViewState([]);
                // handleNewApplyFilter();
                handleApplyFilter();
              }}
            >
              Apply Filter
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      {/* {applyFilterClicked && ( */}
      <div style={{ marginLeft: "16px" }}>
        {Object.entries(chips).map(([chipType, chipValue], index) => (
          <React.Fragment key={index}>
            {Array.isArray(chipValue)
              ? chipValue.map((value, valueIndex) => (
                  <Chip
                    key={`${chipType}-${valueIndex}`}
                    label={value}
                    onDelete={() => handleChipDelete(chipType, value)}
                    style={{
                      margin: "10px 5px 10px 5px",
                      backgroundColor: "#fff",
                      color: "#000",
                      fontWeight: "600",
                      border: "1px solid #000",
                      fontSize: "10px",
                      height: "24px",
                    }}
                    deleteIcon={
                      <ClearIcon
                        style={{
                          color: "#000",
                          height: "18px",
                          width: "18px",
                        }}
                      />
                    }
                  />
                ))
              : chipValue && (
                  <Chip
                    key={`${chipType}-${chipValue}`}
                    label={chipValue}
                    onDelete={() => handleChipDelete(chipValue)}
                    style={{
                      margin: "5px",
                      backgroundColor: "#fff",
                      color: "#000",
                      fontWeight: "600",
                      border: "1px solid #000",
                    }}
                    deleteIcon={
                      <ClearIcon
                        style={{
                          color: "#000",
                          height: "18px",
                          width: "18px",
                        }}
                      />
                    }
                  />
                )}
          </React.Fragment>
        ))}
        {Object.values(chips).some((chipValue) =>
          Array.isArray(chipValue) ? chipValue?.length > 0 : chipValue
        ) && (
          <Link
            style={{
              color: "#008CE6",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "24px",
              letterSpacing: "0px",
              textAlign: "left",
              cursor: "pointer",
            }}
            underline="always"
            onClick={handleClearAll}
          >
            Clear All
          </Link>
        )}
      </div>
      {/* )} */}
      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: "#e3e8f7" }}>
            <TableCell
              className={classes.root}
              style={{
                fontWeight: 600,
                fontSize: "14px",
                width: "13%",
                // padding: "0px",
                lineHeight: "1rem",
                paddingLeft: "15px",
              }}
            >
              Expense Id
            </TableCell>

            <TableCell
              className={classes.root}
              style={{
                fontWeight: 600,
                fontSize: "14px",
                width: "12%",
                // padding: "13px 13px 13px 13px",
                lineHeight: "1rem",
              }}
            >
              Expense Category
            </TableCell>
            <TableCell
              className={classes.root}
              style={{
                fontWeight: 600,
                fontSize: "14px",
                width: "13%",
                // padding: "13px 13px 13px 13px",
                lineHeight: "1rem",
              }}
            >
              Expense Purpose{" "}
            </TableCell>

            <TableCell
              className={classes.root}
              style={{
                fontWeight: 600,
                fontSize: "14px",
                width: "13%",
                // paddingLeft: "0px",
                // textAlign: "right",
                // padding: "13px 13px 13px 0px",
                lineHeight: "1rem",
              }}
            >
              Employee Name
            </TableCell>
            <TableCell
              className={classes.root}
              style={{
                fontWeight: 600,
                fontSize: "14px",
                width: "8%",
                // paddingLeft: "45px",
                // padding: "13px 13px 13px 13px",
                lineHeight: "1rem",
              }}
            >
              Creation Date
            </TableCell>
            <TableCell
              className={classes.root}
              style={{
                fontWeight: 600,
                fontSize: "14px",
                width: "11%",
                // paddingLeft: "45px",
                // padding: "13px 13px 13px 13px",
                lineHeight: "1rem",
              }}
            >
              Manager Approval Date
            </TableCell>
            <TableCell
              className={classes.root}
              style={{
                fontWeight: 600,
                fontSize: "14px",
                width: "11%",
                // paddingLeft: "45px",
                // padding: "13px 13px 13px 13px",
                lineHeight: "1rem",
              }}
            >
              Claim Amount
            </TableCell>
            <TableCell
              className={classes.root}
              style={{
                fontWeight: 600,
                fontSize: "14px",
                width: "10%",
                // padding: "13px 13px 13px 14px",
                lineHeight: "1rem",
                // textAlign: "center",
              }}
            >
              Status
            </TableCell>
            <TableCell
              className={classes.root}
              style={{
                fontWeight: 600,
                fontSize: "14px",
                width: "9%",
                // padding: "13px 13px 13px 14px",
                lineHeight: "1rem",
                textAlign: "center",
              }}
            >
              Project Code
            </TableCell>
          </TableHead>

          <TableBody>
            <>
              {uniqueExpenses?.map((row, index) => (
                <TableRow className={classes.customRow} key={index}>
                  {/* {console.log(
                    "checking double data 6",
                    expenseReportViewState,uniqueExpenses
                  )} */}
                  <TableCell
                    className={classes.root1}
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      paddingLeft: "15px",
                    }}
                    onClick={() => {
                      dispatch(
                        setAllExpenseChips({
                          allTempChips: tempChips,
                          dateRange1,
                          dateRange2,
                          operator,
                          amount,
                          currentPage,
                          expenseReportViewState,
                        })
                      );

                      const text = "/viewExpenseDetails/:id";
                      const result = text.replace(":id", row.expId);
                      history.push({
                        pathname: result,
                        state: {
                          response: row,
                          empId: row.empId,
                          compName: "allExpenseTable",
                        },
                      });
                    }}
                  >
                    {row.expCode}
                  </TableCell>
                  <TableCell
                    className={classes.root1}
                    style={{ fontSize: "12px", fontWeight: 500 }}
                    onClick={() => {
                      dispatch(
                        setAllExpenseChips({
                          allTempChips: tempChips,
                          dateRange1,
                          dateRange2,
                          operator,
                          amount,
                          currentPage,
                          expenseReportViewState,
                        })
                      );

                      const text = "/viewExpenseDetails/:id";
                      const result = text.replace(":id", row.expId);
                      history.push({
                        pathname: result,
                        state: {
                          response: row,
                          empId: row.empId,
                          compName: "allExpenseTable",
                        },
                      });
                    }}
                  >
                    {" "}
                    {row.categoryName}
                  </TableCell>

                  <TableCell
                    className={classes.root1}
                    style={{ fontSize: "12px", fontWeight: 500 }}
                    onClick={() => {
                      dispatch(
                        setAllExpenseChips({
                          allTempChips: tempChips,
                          dateRange1,
                          dateRange2,
                          operator,
                          amount,
                          currentPage,
                          expenseReportViewState,
                        })
                      );

                      const text = "/viewExpenseDetails/:id";
                      const result = text.replace(":id", row.expId);
                      history.push({
                        pathname: result,
                        state: {
                          response: row,
                          empId: row.empId,
                          compName: "allExpenseTable",
                        },
                      });
                    }}
                  >
                    {row.purpose}
                  </TableCell>
                  <TableCell
                    className={classes.root1}
                    style={{
                      paddingLeft: "15px",
                      textAlign: "left",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      dispatch(
                        setAllExpenseChips({
                          allTempChips: tempChips,
                          dateRange1,
                          dateRange2,
                          operator,
                          amount,
                          currentPage,
                          expenseReportViewState,
                        })
                      );

                      const text = "/viewExpenseDetails/:id";
                      const result = text.replace(":id", row.expId);
                      history.push({
                        pathname: result,
                        state: {
                          response: row,
                          empId: row.empId,
                          compName: "allExpenseTable",
                        },
                      });
                    }}
                  >
                    {row.empName}
                  </TableCell>
                  <TableCell
                    className={classes.root1}
                    style={{
                      paddingLeft: "15px",
                      textAlign: "left",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      dispatch(
                        setAllExpenseChips({
                          allTempChips: tempChips,
                          dateRange1,
                          dateRange2,
                          operator,
                          amount,
                          currentPage,
                          expenseReportViewState,
                        })
                      );

                      const text = "/viewExpenseDetails/:id";
                      const result = text.replace(":id", row.expId);
                      history.push({
                        pathname: result,
                        state: {
                          response: row,
                          empId: row.empId,
                          compName: "allExpenseTable",
                        },
                      });
                    }}
                  >
                    {row.createdOn}
                  </TableCell>
                  <TableCell
                    className={classes.root1}
                    style={{
                      paddingLeft: "17px",
                      textAlign: "left",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      dispatch(
                        setAllExpenseChips({
                          allTempChips: tempChips,
                          dateRange1,
                          dateRange2,
                          operator,
                          amount,
                          currentPage,
                          expenseReportViewState,
                        })
                      );

                      const text = "/viewExpenseDetails/:id";
                      const result = text.replace(":id", row.expId);
                      history.push({
                        pathname: result,
                        state: {
                          response: row,
                          empId: row.empId,
                          compName: "allExpenseTable",
                        },
                      });
                    }}
                  >
                    {row.managerApprovedOn}
                  </TableCell>
                  <TableCell
                    className={classes.root1}
                    style={{
                      paddingLeft: "15px",
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      dispatch(
                        setAllExpenseChips({
                          allTempChips: tempChips,
                          dateRange1,
                          dateRange2,
                          operator,
                          amount,
                          currentPage,
                          expenseReportViewState,
                        })
                      );

                      const text = "/viewExpenseDetails/:id";
                      const result = text.replace(":id", row.expId);
                      history.push({
                        pathname: result,
                        state: {
                          response: row,
                          empId: row.empId,
                          compName: "allExpenseTable",
                        },
                      });
                    }}
                  >
                    {row.currency} {row.claimedAmount}
                  </TableCell>

                  <TableCell
                    className={classes.root1}
                    style={{ fontSize: "12px", fontWeight: 500 }}
                    onClick={() => {
                      dispatch(
                        setAllExpenseChips({
                          allTempChips: tempChips,
                          dateRange1,
                          dateRange2,
                          operator,
                          amount,
                          currentPage,
                          expenseReportViewState,
                        })
                      );

                      const text = "/viewExpenseDetails/:id";
                      const result = text.replace(":id", row.expId);
                      history.push({
                        pathname: result,
                        state: {
                          response: row,
                          empId: row.empId,
                          compName: "allExpenseTable",
                        },
                      });
                    }}
                  >
                    <Box>
                      <Chip
                        label={row.status}
                        className={classes.statusStyle}
                        style={{
                          background:
                            row.status === "In Progress"
                              ? "#E0B15C"
                              : row.status === "Approved"
                              ? " #349259"
                              : row.status === "Auto Approved"
                              ? " #349259"
                              : row.status === "Rejected"
                              ? "#D40606"
                              : row.status === "Draft"
                              ? "#808080"
                              : row.status === "Not Created"
                              ? "#808080"
                              : row.status === "Paid"
                              ? "#406086"
                              : "",
                          display:
                            row.status === "In Progress" ||
                            row.status === "Approved" ||
                            row.status === "Auto Approved" ||
                            row.status === "Rejected" ||
                            row.status === "Draft" ||
                            row.status === "Not Created" ||
                            row.status === "Paid"
                              ? "inline-flex"
                              : "none",
                          color: "#ffffff",
                          fontSize: "12px",
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell
                    className={classes.root1}
                    style={{
                      paddingLeft: "0px",
                      textAlign: "center",
                      fontSize: "12px",
                      whiteSpace: "pre-line", // Ensures the newline character is respected
                    }}
                    onClick={() => {
                      dispatch(
                        setAllExpenseChips({
                          allTempChips: tempChips,
                          dateRange1,
                          dateRange2,
                          operator,
                          amount,
                          currentPage,
                          expenseReportViewState,
                        })
                      );
                      const text = "/viewExpenseDetails/:id";
                      const result = text.replace(":id", row.expId);
                      // console.log("Redirecting to:", result); // Debugging line
                      // console.log("Row Data:", row); // Log row data for debugging

                      history.push({
                        pathname: result,
                        state: {
                          response: row,
                          //   expId:row.expId,
                          empId: row.empId,
                          compName: "allExpenseTable",
                        },
                      });
                    }}
                  >
                    {row.projectId.split("@")[0]}
                    {"\n"}
                    {row.projectId.split("@")[1]}
                  </TableCell>
                </TableRow>
              ))}
            </>
          </TableBody>
        </Table>
      </TableContainer>
      {getAllExpenseReportList?.length === 0 &&
      expenseReportViewState?.length === 0 ? (
        <Grid>
          <Typography
            style={{
              fontSize: "20px",
              lineHeight: "240px",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            No Expense entry exists!
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
      <div>
        <Backdrop
          sx={{ color: "#092FED", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {/* </Box> */}
    </div>
  );
}

export default AllNewExpenseReport;
