import React from "react";
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  FormControl,
  Link,
  Chip,
  Divider,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
  Paper,
  Dialog,
  Snackbar,
  Menu,
  MenuItem,
} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { NumericFormat } from "react-number-format";
import ClearIcon from "@mui/icons-material/Clear";
import Decrypt from "../Utils/decryption";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  getTimesheetReportData,
  getApprovalListInvoiceData,
  postImportInvoicingDetailsData,
  getApprovalInvoicingReportData,
  getInvoicingAuditDetailsData,
  getInvoiceExportReportData,
  invoicingStatusUpdateData,
  ApprovalListForInvoicing,
  allPMOChipsClearData,
  getApprovalInvoicingApprovalExportData,
} from "../redux/TimeSheet/actionTs";
import { getEmpDetail } from "../redux/Expense/action";
import { setAllPMOChips } from "../redux/TimeSheet/actionTs";
import { useState, useEffect, useRef } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { CheckBox } from "@material-ui/icons";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CommentIcon from "@mui/icons-material/Comment";
import * as XLSX from "xlsx";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import CloseIcon from "@mui/icons-material/Close";
import ImportIcon from "@material-ui/icons/ArrowDownward";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import AuditHistoryDialog from "./auditHistoryInvoicing";
import HistoryIcon from "@mui/icons-material/History";
import Tooltip from "@mui/material/Tooltip";
import Encrypt from "../Utils/encryption";
import ApproveRejectByManager from "./approveRejectByManager";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import OutsideClickHandler from "react-outside-click-handler";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useTheme } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import { styled } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 13,
    top: 31,
    border: `0.125rem solid ${theme.palette.background.paper}`,
    padding: "0 0.25rem",
  },
}));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  addExpenseButton: {
    height: "30px",
    width: "100px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  addExpenseButton1: {
    height: "30px",
    width: "212px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  buttonText: {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: "13px",
    fontWeight: 500,
  },
  checkBoxRoot: {
    background: "#0B1941",
    color: "white",
    "&:checked": {
      background: "#0B1941",
      color: "white",
    },
  },
  snackbarMessage: {
    color: "#333",
    fontFamily: "Montserrat",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "0.2px",
  },
  snackbarMessage1: {
    color: "#252733",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0.2px",
    paddingLeft: "10px",
  },
  anchorStyle: {
    "&.MuiSnackbar-anchorOriginTopRight": {
      top: "51px",
      left: "auto",
      right: "52px",
    },
  },
  tooltip: {
    paddingLeft: "2px",
    color: "#9E9E9E",
    maxWidth: "20px",
    maxHeight: "20px",
  },

  tooltipBox: {
    minWidth: "225px",
    left: "auto!important",
    right: 0,
    marginTop: "-11px",
    // top:'-20px!important'
  },
  arrow: {
    left: "auto!important",

    right: 0,
  },
  rootFooter: {
    flexShrink: 0,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
  backgroundFilter: {
    paddingBottom: "10px",
  },
  dotIcon: {
    color: "#666666",
    padding: "0px",
  },
  backdrop: {
    "& .MuiPaper-root": {
      //   width: "173px",
      //   height: "138px ",
      background: " #FFFFFF",
      boxShadow:
        "0px 4px 5px rgba(0, 0, 0, 0), 0px 1px 10px rgba(0, 0, 0, 0), 0px 2px 4px -1px rgba(0, 0, 0, 0.1)",
      borderRadius: "4px",
      overflowY: "hidden",
    },
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <div className={classes.rootFooter}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        style={{ padding: "3px" }}
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        style={{ padding: "3px" }}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        style={{ padding: "3px" }}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        style={{ padding: "3px" }}
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
export default function ResponsivePMOAutomation() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const target = useRef(null);
  const [isNewChip, setIsNewChip] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedEntityCode, setSelectedEntityCode] = useState([]);
  const [selectedVertical, setSelectedVertical] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedEmpType, setSelectedEmpType] = useState([]);
  const [selectedEmpName, setSelectedEmpName] = useState([]);
  const [selectedProjGroup, setSelectedProjGroup] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);

  const [chips, setChips] = useState({
    dateChip: [],
    verticalChip: [],
    projectsChip: [],
    statusChip: [],
    empEntityChip: [],
    empTypeChip: [],
    employeeChip: [],
    projPurposeChip: [],
    customerNameChip: [],
    statusChip: [],
    projGroupChip: [],
  });
  const [tempChips, setTempChips] = useState({
    dateChip: [],
    verticalChip: [],
    projectsChip: [],
    statusChip: [],
    empEntityChip: [],
    empTypeChip: [],
    employeeChip: [],
    projPurposeChip: [],
    customerNameChip: [],
    statusChip: [],
    projGroupChip: [],
  });
  const [selectedProjItem, setSelectedProjItem] = useState([]);
  const [selectedDateItem, setSelectedDateItem] = useState([]);
  const [applyFilterClicked, setApplyFilterClicked] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [auditHistoryData, setAuditHistoryData] = useState([]);
  const [openAuditHistDialog, setOpenAuditHistDialog] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [approveRejectStatus, setApproveRejectStatus] = useState("");
  const [approveRejectComment, setApproveRejectComment] = useState(null);
  const [showApproveRejectDialog, setShowApproveRejectDialog] = useState(false);
  const [firstAppRej, setFirstAppRej] = useState("");
  const [selected, setSelected] = React.useState([]);
  const [multiDownload, setMultiDownload] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [refreshTableToggle, setRefreshTableToggle] = useState(0);
  const [open, setOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [approveRejectData, setApproveRejectData] = React.useState([]);
  const [dateChipValues, setDateChipValues] = useState([]);
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [openImport, setImportOpen] = useState(false);
  const [parsedData, setParsedData] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [isImportedData, setIsImportedData] = useState(false);
  // const [isLoader, setIsLoader] = useState(false);
  const [importSnackbarOpen, setImportSnackbarOpen] = useState(false);
  const [remarkOpen, setRemarkOpen] = useState(false);
  const [remarkText, setRemarkText] = useState("");
  const [tooltipIndex, setTooltipIndex] = useState(-1);
  const remarkRef = useRef(null);
  const [addRemarkOpen, setAddRemarkOpen] = useState(false);
  const [remark, setRemark] = useState("");
  const [rowData, setRowData] = useState([]);
  const [snackBarData, setSnackBarData] = useState({
    name: "",
    id: "",
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [reportViewState, setReportViewState] = useState([]);
  const [currentPage, setCurrentPage] = useState(2);
  const [toolAction, setToolAction] = React.useState("");
  const [commentData, setCommentData] = React.useState([]);
  const [currentPageSize, setCurrentPageSize] = useState(100);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [errorMessage, setErrorMessage] = useState(0);
  const [currentRow, setCurrentRow] = useState([]); // State to hold current row being edited
  const [showHours, setShowHours] = useState([]);
  const [statusSnackbarOpen, setStatusSnackbarOpen] = useState(false);
  const [countShow, setCountShow] = React.useState(1);
  const [anchorFilter1, setAnchorFilter1] = useState("right");
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [anchorE2, setAnchorE2] = useState(null);
  const [anchorE3, setAnchorE3] = useState(null);

  const [popRow, setPopRow] = useState(null);

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const timesheetReport = useSelector(
    (state) => state?.timesheetData?.getTimesheetReport
  );
  const timesheetReportJson = JSON.parse(timesheetReport);
  // console.log("Brijesh: ", timesheetReportJson,ssoEmpIdDetails);

  const invoicingAuditDetail = useSelector(
    (state) => state?.timesheetData?.getInvoicingAuditDetails
  );
  const auditHistoryList = useSelector(
    (state) => state.timesheetData.getAllTimesheetAudit
  );
  const invoiceUpdateStatus = useSelector(
    (state) => state.timesheetData.isPostInvoicingStatusUpdate
  );
  var approvalListForInvoice = useSelector(
    (state) => state?.timesheetData?.getApprovalListInvoice
  );
  var allSetPMOChips = useSelector(
    (state) => state.timesheetData.getSetPMOChips
  );
  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );
  const maintainStateVar = useSelector(
    (state) => state.timesheetData.maintainStateVariable
  );
  const timesheetAccessFlag = useSelector(
    (state) => state?.timesheetData?.getTimesheetFlag
  );
  // console.log("approvalListForInvoice", approvalListForInvoice);
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  let tempvalcount=allSetPMOChips?.tempvalcount?allSetPMOChips?.tempvalcount:1;
  const handleChangePage = (event, newPage) => {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    setIsLoader(true);
    const encryptedChips = tempChips?.customerNameChip.map((chip) =>
      Encrypt(key, JSON.stringify(chip))
    );
    const joinedEncryptedNames = encryptedChips?.join("@");
    const replacedString = joinedEncryptedNames?.replace(/\+/g, "%2B");

    const encryptedChips1 = tempChips?.projGroupChip.map((chip) =>
      Encrypt(key, JSON.stringify(chip))
    );
    const joinedEncryptedNames1 = encryptedChips1?.join("@");
    const replacedString1 = joinedEncryptedNames1?.replace(/\+/g, "%2B");

    const projFilter = tempChips.projectsChip?.map((item) => {
      const parts = item.split("PROJ");
      const first = parts[0].substring(parts[0].length - 3);
      const third = parts[1].split("-");
      return `${first}PROJ-${third[1]}`;
    });
    const obj = {
      loginBy: Encrypt(
        key,
        JSON.stringify(
          tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId
        )
      ),
      delegateeId:
        ssoEmpIdDetails?.empId === tsDelEmpIdRedux
          ? JSON.stringify(null)
          : Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId)),
      fromDate: tempChips.dateChip ? selectedDateItem : [],
      entity: tempChips.empEntityChip ? tempChips.empEntityChip : [],
      projId: tempChips.projectsChip ? projFilter : [],
      projpurpose: tempChips.projPurposeChip ? tempChips.projPurposeChip : [],
      CustomerName:
        tempChips.customerNameChip?.length > 0 ? replacedString : [],
      empDetails: tempChips.employeeChip ? tempChips.employeeChip : [],
      empType: tempChips.empTypeChip ? tempChips.empTypeChip : [],
      vertical: tempChips.verticalChip ? tempChips.verticalChip : [],
      status: tempChips.statusChip ? tempChips.statusChip : [],
      projGroup: tempChips.projGroupChip?.length > 0 ? replacedString1 : [],
      body: "",
      eCode: ssoEmpIdDetails?.empId,
      pagenumber: newPage + 1,
      pagesize: rowsPerPage,
      reportflag: 0,
    };
    dispatch(
      setAllPMOChips({
        allTempChips: tempChips,
        // currentPage,
        selectedDateItem,
        selectedDate,
        selectedProjItem,
        selectedProjects,
        tempvalcount,
        rowsPerPage:rowsPerPage,
        page:newPage
        // maintainStateVar,
        // applyFilterClicked,
      })
    );
    dispatch(getApprovalListInvoiceData(obj, setIsLoader)).then((response) => {
      setIsLoader(false);
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    setIsLoader(true);
    const encryptedChips = tempChips?.customerNameChip.map((chip) =>
      Encrypt(key, JSON.stringify(chip))
    );
    const joinedEncryptedNames = encryptedChips?.join("@");
    const replacedString = joinedEncryptedNames?.replace(/\+/g, "%2B");

    const encryptedChips1 = tempChips?.projGroupChip.map((chip) =>
      Encrypt(key, JSON.stringify(chip))
    );
    const joinedEncryptedNames1 = encryptedChips1?.join("@");
    const replacedString1 = joinedEncryptedNames1?.replace(/\+/g, "%2B");

    const projFilter = tempChips.projectsChip?.map((item) => {
      const parts = item.split("PROJ");
      const first = parts[0].substring(parts[0].length - 3);
      const third = parts[1].split("-");
      return `${first}PROJ-${third[1]}`;
    });
    const obj = {
      loginBy: Encrypt(
        key,
        JSON.stringify(
          tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId
        )
      ),
      delegateeId:
        ssoEmpIdDetails?.empId === tsDelEmpIdRedux
          ? JSON.stringify(null)
          : Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId)),
      fromDate: tempChips.dateChip ? selectedDateItem : [],
      entity: tempChips.empEntityChip ? tempChips.empEntityChip : [],
      projId: tempChips.projectsChip ? projFilter : [],
      projpurpose: tempChips.projPurposeChip ? tempChips.projPurposeChip : [],
      CustomerName:
        tempChips.customerNameChip?.length > 0 ? replacedString : [],
      empDetails: tempChips.employeeChip ? tempChips.employeeChip : [],
      empType: tempChips.empTypeChip ? tempChips.empTypeChip : [],
      vertical: tempChips.verticalChip ? tempChips.verticalChip : [],
      status: tempChips.statusChip ? tempChips.statusChip : [],
      projGroup: tempChips.projGroupChip?.length > 0 ? replacedString1 : [],
      body: "",
      eCode: ssoEmpIdDetails?.empId,
      pagenumber: 1,
      pagesize: parseInt(event.target.value, 10),
      reportflag: 0,
    };
    dispatch(
      setAllPMOChips({
        allTempChips: tempChips,
        // currentPage,
        selectedDateItem,
        selectedDate,
        selectedProjItem,
        selectedProjects,
        tempvalcount,
        rowsPerPage:parseInt(event.target.value, 10),
    page:0
        // maintainStateVar,
        // applyFilterClicked,
      })
    ); 
    dispatch(getApprovalListInvoiceData(obj, setIsLoader)).then((response) => {
      setIsLoader(false);
    });
    setPage(0);
  };
  //   const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  //   };

  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  //   };

  useEffect(() => {
    dispatch(getEmpDetail(account?.username));
  }, []);

  useEffect(() => {
    if (allSetPMOChips) {
      // console.log("chips chips useffect", allSetPMOChips);

      setTempChips({
        dateChip: allSetPMOChips.allTempChips.dateChip,
        statusChip: allSetPMOChips.allTempChips.statusChip,
        empEntityChip: allSetPMOChips.allTempChips.empEntityChip,
        empTypeChip: allSetPMOChips.allTempChips.empTypeChip,
        verticalChip: allSetPMOChips.allTempChips.verticalChip,
        projectsChip: allSetPMOChips.allTempChips.projectsChip,
        employeeChip: allSetPMOChips.allTempChips.employeeChip,
        projPurposeChip: allSetPMOChips.allTempChips.projPurposeChip,
        customerNameChip: allSetPMOChips.allTempChips.customerNameChip,
        projGroupChip: allSetPMOChips.allTempChips.projGroupChip,
      });
      setChips({
        dateChip: allSetPMOChips.allTempChips.dateChip,
        statusChip: allSetPMOChips.allTempChips.statusChip,
        empEntityChip: allSetPMOChips.allTempChips.empEntityChip,
        empTypeChip: allSetPMOChips.allTempChips.empTypeChip,
        verticalChip: allSetPMOChips.allTempChips.verticalChip,
        projectsChip: allSetPMOChips.allTempChips.projectsChip,
        employeeChip: allSetPMOChips.allTempChips.employeeChip,
        projPurposeChip: allSetPMOChips.allTempChips.projPurposeChip,
        customerNameChip: allSetPMOChips.allTempChips.customerNameChip,
        projGroupChip: allSetPMOChips.allTempChips.projGroupChip,
      });
      // setFromDate(allSetPMOChips.fromDate);
      // setToDate(allSetPMOChips.toDate);
      setSelectedStatus(allSetPMOChips.allTempChips.statusChip);
      setSelectedProjItem(allSetPMOChips.allTempChips.projectsChip);
      setSelectedEmpType(allSetPMOChips.allTempChips.empTypeChip);
      setSelectedEntityCode(allSetPMOChips.allTempChips.empEntityChip);
      setSelectedVertical(allSetPMOChips.allTempChips.verticalChip);
      setSelectedProjects(allSetPMOChips.selectedProjects);
      // setSelectedEmpName(allSetPMOChips.allTempChips.statusChip);
      setSelectedProjGroup(allSetPMOChips.allTempChips.projGroupChip);
      setSelectedDate(allSetPMOChips?.selectedDate);
      setSelectedCustomer(allSetPMOChips.allTempChips.customerNameChip);
      // setCurrentPage(allSetPMOChips.currentPage);
      // setReportViewState(allSetPMOChips.reportViewState);
      setCountShow(allSetPMOChips?.tempvalcount);
      setIsNewChip(false);
      setPage(allSetPMOChips?.page);
      setRowsPerPage(allSetPMOChips?.rowsPerPage);
    }
  }, [allSetPMOChips]);
  // useEffect(() => {

  // if(ssoEmpIdDetails?.empId1!==tsDelEmpIdRedux){

  // }

  // }, [])

  const handleClickAction = (event, row) => {
    setAnchorE2(row?.identifier);

    setAnchorE3(event.currentTarget);
    setPopRow(row);
  };

  const handleClose = () => {
    setAnchorE2(null);
    setAnchorE3(null);
    setPopRow(null);
  };
  const handleBackdropClick = () => {
    setImportOpen(false);
    setSelectedFileName("");
    setParsedData(null);
    setIsImportedData(false);
    const fileInput = document.getElementById("file-input");
    fileInput.value = "";
  };

  const handleImportClickOpen = () => {
    setImportOpen(true);
  };

  const handleImportClose = () => {
    setImportOpen(false);
    setSelectedFileName("");
    setParsedData(null);
    setIsImportedData(false);
    const fileInput = document.getElementById("file-input");
    fileInput.value = "";
  };
  const handleAuditHistoryClick = (row, index) => {
    // setAuditHistoryData([]);
    // console.log("row of audit", row, index);
    const obj = {
      invoicingId: row.invoicingRecId,
    };

    setIsLoader(true);

    // Wait for the dispatch to complete
    dispatch(getInvoicingAuditDetailsData(obj, setIsLoader)).then(() =>
      handleOpenAuditHistDialog()
    );

    // Open the dialog only after data is fetched
  };
  const handleOpenAuditHistDialog = () => {
    setOpenAuditHistDialog(true);
  };
  const handleCloseAuditHistDialog = () => {
    setOpenAuditHistDialog(false);
  };
  var checkLoader = 0;
  const handleApprove = (row, index, bulkVal) => {
    // const newObject = {
    //   recid: row.invoicingRecId,
    //   totalsubmittedhours: row.totalSubmittedHours,
    //   remark:row.remark,
    //   invapprovedbilledhours: row.approvedBilledHours,
    // };
    // setApproveRejectData([...approveRejectData, newObject]);
    setChecked(false);
    setIsLoader(true);
    // checkLoader = 1;
    setApproveRejectStatus("Approved");
    setFirstAppRej("Approved");
    setCurrentIndex(index);
    handleApproveRejectByManager(row, "Approved", null, bulkVal);
    setSelected([]);
    setMultiDownload([]);
  };
  const handleApproveRejectDialog = (index, status) => {
    setCurrentIndex(index);
    setFirstAppRej(status);
    // firstAppRej = status;
    // console.log("firstAppRej", firstAppRej);
    // setApproveRejectStatus(status);
    setShowApproveRejectDialog(true);
  };
  const handleApproveRejectStatus = (stat) => {
    setApproveRejectStatus(stat);
    //   const event = new CustomEvent('changesMadeEvent', {
    //     detail: { changes: true },
    //   });
    //   window.dispatchEvent(event);
  };

  const handleComment = (data) => {
    setApproveRejectComment(data);
  };

  const handleCloseApproveRejectDialog = () => {
    setShowApproveRejectDialog(false);
  };
  const handleApproveRejectByManager = async (
    value,
    status,
    comment,
    bulkVal
  ) => {
    var newObject = [];
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    if (selected?.length > 0) {
      newObject = approveRejectData?.filter((item) =>
        selected.includes(item.identifier)
      );
    }
    if (approveRejectData?.length == 0) {
      newObject = [
        {
          identifier: value.identifier,
          recid: value.invoicingRecId,
          totalsubmittedhours: value.totalSubmittedHours,
          remark: "",
          invapprovedbilledhours: value.approvedBilledHours,
          empId: Encrypt(key, JSON.stringify(value.empId)),
          projId: Encrypt(key, JSON.stringify(value.projId)),
          fromDate: value.fromDate,
          invrecbillablehours: value.recommandedBillabledHours,
        },
      ];
    }
    // setApproveRejectData([...approveRejectData, newObject]);
    setChecked(false);

    let formData = new FormData();

    // console.log("handleApproveRejectByManager", approveRejectData);
    formData.append(
      "EmpId",
      Encrypt(
        key,
        JSON.stringify(
          tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId
        )
      )
    );
    formData.append(
      "DelegateeId",
      ssoEmpIdDetails?.empId === tsDelEmpIdRedux
        ? JSON.stringify(null)
        : Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    formData.append("Status", JSON.stringify(status));
    formData.append("Comment", comment);
    formData.append(
      "InvoiceData",
      approveRejectData?.length > 0 && selected?.length == 0
        ? JSON.stringify(approveRejectData)
        : JSON.stringify(newObject)
    );

    // if (bulkVal) {
    //   formData.append("TmDetails", JSON.stringify(tmdetails1));
    // } else {
    //   formData.append("TmDetails", JSON.stringify(tmdetails));
    // }

    try {
      setShowApproveRejectDialog(false);
      // setIsLoader(true);
      // console.log("loader apply filter1", isLoader);
      // approvalListForInvoice=[];
      dispatch(ApprovalListForInvoicing());
      // const response = await dispatch(invoicingStatusUpdateData(formData));
      dispatch(invoicingStatusUpdateData(formData)).then((response) => {
        if (response.status == 200) {
          setOpen(true);
          // if (invoiceUpdateStatus) {
          //   approvalListForInvoice = approvalListForInvoice.filter(
          //     (item) => item.invoicingRecId != value?.invoicingRecId
          //   );
          // }
          setApproveRejectData([]);
          // setRefreshTableToggle(
          //   (prevRefreshTableToggle) => prevRefreshTableToggle + 1
          // );

          setSnackBarData({
            name: value.empName,
            id: value.empCode,
          });

          handleApplyFilter();
        } else {
          setStatusSnackbarOpen(true);
        }
      });
    } catch (error) {
      // console.error("Post request failed:", error);
    }

    // forceUpdate();
  };
  const handleSingleApprove = async (row, status, comment, index) => {
    // console.log("here8", multiDownload);
    // console.log("here9", value);
    var newObject = [];
    setIsLoader(true);
    setApproveRejectStatus("Approved");
    setFirstAppRej("Approved");
    setCurrentIndex(index);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;

    const prevData = approveRejectData?.filter(
      (item) => item.identifier == row.identifier
    );
    // console.log("prevData", prevData, approveRejectData);
    if (prevData?.length == 0) {
      newObject = [
        {
          identifier: row.identifier,
          recid: row.invoicingRecId,
          totalsubmittedhours: row.totalSubmittedHours,
          remark: "",
          invapprovedbilledhours: row.approvedBilledHours,
          empId: Encrypt(key, JSON.stringify(row.empId)),
          projId: Encrypt(key, JSON.stringify(row.projId)),
          fromDate: row.fromDate,
          invrecbillablehours: row.recommandedBillabledHours,
        },
      ];
    }
    // setApproveRejectData([...approveRejectData, newObject]);
    setChecked(false);

    let formData = new FormData();

    // console.log("handleApproveRejectByManager", approveRejectData);
    formData.append(
      "EmpId",
      Encrypt(
        key,
        JSON.stringify(
          tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId
        )
      )
    );
    formData.append(
      "DelegateeId",
      ssoEmpIdDetails?.empId === tsDelEmpIdRedux
        ? JSON.stringify(null)
        : Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    formData.append("Status", JSON.stringify(status));
    formData.append("Comment", comment);
    formData.append(
      "InvoiceData",
      prevData?.length > 0
        ? JSON.stringify(prevData)
        : JSON.stringify(newObject)
    );

    // if (bulkVal) {
    //   formData.append("TmDetails", JSON.stringify(tmdetails1));
    // } else {
    //   formData.append("TmDetails", JSON.stringify(tmdetails));
    // }

    try {
      setShowApproveRejectDialog(false);
      // setIsLoader(true);
      // console.log("loader apply filter1", isLoader);
      // approvalListForInvoice=[];
      dispatch(ApprovalListForInvoicing());
      // const response = await dispatch(invoicingStatusUpdateData(formData));
      dispatch(invoicingStatusUpdateData(formData)).then((response) => {
        if (response.status == 200) {
          setOpen(true);
          // if (invoiceUpdateStatus) {
          //   approvalListForInvoice = approvalListForInvoice.filter(
          //     (item) => item.invoicingRecId != value?.invoicingRecId
          //   );
          // }
          setSelected([]);
          setMultiDownload([]);
          setApproveRejectData([]);
          // setRefreshTableToggle(
          //   (prevRefreshTableToggle) => prevRefreshTableToggle + 1
          // );

          setSnackBarData({
            name: row.empName,
            id: row.empCode,
          });

          handleApplyFilter();
        } else {
          setStatusSnackbarOpen(true);
        }
      });
    } catch (error) {
      // console.error("Post request failed:", error);
    }

    // forceUpdate();
  };
  const handleSelectAllClick = (event) => {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    if (event.target.checked) {
      setChecked(true);
      let filterData = stableSort(
        approvalListForInvoice,
        getComparator(order, orderBy)
      )
        // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.filter((row) => row.rejectBTFlag === 0 && row.btEnabledFlag === 1); // Filter out disabled rows
      // console.log("filterData", filterData);
      const newSelecteds = filterData.map((s) => s.identifier);
      // console.log("newSelecteds", newSelecteds);
      setSelected(newSelecteds);
      setMultiDownload(filterData);
      const filteredKeys = filterData.map((row) => ({
        identifier: row.identifier,
        recid: row.invoicingRecId,
        totalsubmittedhours: row.totalSubmittedHours,
        remark: "",
        invapprovedbilledhours: row.approvedBilledHours,
        empId: Encrypt(key, JSON.stringify(row.empId)),
        projId: Encrypt(key, JSON.stringify(row.projId)),
        fromDate: row.fromDate,
        invrecbillablehours: row.recommandedBillabledHours,
        // Add more keys as needed
      }));
      const bulkSelect = [...approveRejectData];
      const bulkSelectFiltered = filteredKeys?.filter(
        (item) =>
          !bulkSelect.some(
            (selectItem) => selectItem.identifier == item.identifier
          )
      );
      setApproveRejectData([...bulkSelect, ...bulkSelectFiltered]);

      return;
    } else {
      setChecked(false);
    }
    setMultiDownload([]);
    setSelected([]);
  };
  const handleClick = (event, name) => {
    // console.log("name", name , event , selected);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;

    var selectedIndex = -1;
    if (selected == null) {
      selectedIndex = -1;
    } else {
      selectedIndex = selected.indexOf(name.identifier);
    }
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.identifier);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      setChecked(false);
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      setChecked(false);
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      setChecked(false);
    }
    const trueCount = approvalListForInvoice?.filter(
      (item) => item.rejectBTFlag === 0 && item.btEnabledFlag === 1
    )?.length;
    // console.log("trueCount", trueCount);
    if (newSelected?.length == trueCount) setChecked(true);
    setSelected(newSelected);
    // console.log("selected value", newSelected);

    const objIndex = newSelected.findIndex((el) => el == name.identifier);
    const objIndex1 = approveRejectData.findIndex(
      (el) => el.identifier == name.identifier
    );
    let newApproveRejectData = approveRejectData;
    if (objIndex >= 0 && objIndex1 < 0) {
      const newObject = {
        identifier: name.identifier,
        recid: name.invoicingRecId,
        totalsubmittedhours: name.totalSubmittedHours,
        remark: event.target.value,
        invapprovedbilledhours: name.approvedBilledHours,
        empId: Encrypt(key, JSON.stringify(name.empId)),
        projId: Encrypt(key, JSON.stringify(name.projId)),
        fromDate: name.fromDate,
        invrecbillablehours: name.recommandedBillabledHours,
      };
      newApproveRejectData = [...approveRejectData, newObject];
    } else if (objIndex < 0) {
      newApproveRejectData = [
        ...approveRejectData?.filter((el) => el.identifier != name.identifier),
      ];
    }

    setApproveRejectData(newApproveRejectData);
  };

  useEffect(() => {
    if (parsedData) {
      setTotalCount(parsedData?.length);
    } else {
      setIsImportedData(false);
      setTotalCount();
    }
  }, [parsedData]);

  const handleimportSnackbarClose = () => {
    setImportSnackbarOpen(false);
  };

  const handleRemarkOpen = (row, index) => {
    setTooltipIndex(index);
    setRemarkText(row.remark);
    setRemarkOpen(true);
  };

  const handleRemarkClose = () => {
    setRemarkOpen(false);
  };

  useEffect(() => {
    // Close the tooltip when user clicks outside
    function handleClickOutside(event) {
      if (remarkRef.current && !remarkRef.current.contains(event.target)) {
        setRemarkOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAddRemarkOpen = (data) => {
    setAddRemarkOpen(true);
    setRowData(data);
  };

  const handleAddRemarkClose = () => {
    setAddRemarkOpen(false);
  };

  const handleAddRemark = (value, field) => {
    // setRemark(event.target.value);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;

    // Check if an object with the specified ID already exists in the array
    const existingObjectIndex = approveRejectData.findIndex(
      (obj) => obj.identifier === rowData.identifier
    );
  //  console .log(
  //     "confusion zero",
  //     existingObjectIndex,
  //     approveRejectData,
  //     rowData,
  //     value
  //   );

    // setShowHours({...showHours,[rowData.identifier]:value})
    if (existingObjectIndex !== -1) {
      // If object exists, update the specific field of the existing object

      const updatedObjectsArray = [...approveRejectData];
      // console.log("confusion half", updatedObjectsArray, value);
      // if(field == "remark"){
      updatedObjectsArray[existingObjectIndex][field] = value;
      // }else if(field == "totalsubmittedhours"){
      //   updatedObjectsArray[existingObjectIndex][field] = Number(value);
      // }
      // updatedObjectsArray[existingObjectIndex][field] = value;
      // console.log(
      //   "confusion one",
      //   updatedObjectsArray,
      //   updatedObjectsArray[existingObjectIndex][field]
      // );

      setApproveRejectData(updatedObjectsArray);
    } else {
      const newObject = {
        identifier: rowData.identifier,
        recid: rowData.invoicingRecId,
        totalsubmittedhours: rowData.totalSubmittedHours,
        remark: value,
        invapprovedbilledhours: rowData.approvedBilledHours,
        empId: Encrypt(key, JSON.stringify(rowData.empId)),
        projId: Encrypt(key, JSON.stringify(rowData.projId)),
        fromDate: rowData.fromDate,
        invrecbillablehours: rowData.recommandedBillabledHours,
      };
      // console.log("remark obj", newObject);
      setApproveRejectData([...approveRejectData, newObject]);
    }
  };
  const handleHours = (value, field, row) => {
    // setRemark(event.target.value);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;

    // Check if an object with the specified ID already exists in the array
    const existingObjectIndex = approveRejectData.findIndex(
      (obj) => obj.identifier === row.identifier
    );
    // console.log(
    //   "confusion zero1",
    //   existingObjectIndex,
    //   approveRejectData,
    //   row,
    //   value
    // );

    // setShowHours({...showHours,[row.identifier]:value || 0})
    if (existingObjectIndex !== -1) {
      // If object exists, update the specific field of the existing object

      const updatedObjectsArray = [...approveRejectData];
      // console.log("confusion half", updatedObjectsArray, value);

      updatedObjectsArray[existingObjectIndex][field] = Number(value);

      // console.log(
      //   "confusion one",
      //   updatedObjectsArray,
      //   updatedObjectsArray[existingObjectIndex][field]
      // );

      setApproveRejectData(updatedObjectsArray);
    } else {
      const newObject = {
        identifier: row.identifier,
        recid: row.invoicingRecId,
        totalsubmittedhours: Number(value),
        remark: "",
        invapprovedbilledhours: row.approvedBilledHours,
        empId: Encrypt(key, JSON.stringify(row.empId)),
        projId: Encrypt(key, JSON.stringify(row.projId)),
        fromDate: row.fromDate,
        invrecbillablehours: row.recommandedBillabledHours,
      };
      // console.log("confusion two", newObject, value);
      setApproveRejectData([...approveRejectData, newObject]);
      // }
    }
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    var key = process.env.REACT_APP_ENCRYPTION_KEY;

    setSelectedFileName(file.name);
    setIsImportedData(true);
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: "binary" });

      const sheetName = workbook.SheetNames[0]; // Assuming data is in the first sheet
      const worksheet = workbook.Sheets[sheetName];

      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Assuming the first row contains headers
      const headers = data[0];
      const excelRows = data.slice(1); // Removing the header row

      const excelObjects = excelRows.map((row) => {
        const obj = {};
        headers.forEach((header, index) => {
          let value = row[index];

          // Encrypt projectid and empcode columns
          if (header === "ProjectId" || header === "EmpCode") {
            value = Encrypt(key, JSON.stringify(value));
          }

          obj[header] = value;
        });
        return obj;
      });

      setParsedData(excelObjects);
    };

    reader.readAsBinaryString(file);
  };

  const handleFinalImport = async () => {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;

    const importFormData = new FormData();
    importFormData.append(
      "EmployeeInvoicingModels",
      JSON.stringify(parsedData)
    );
    importFormData.append(
      "Importby",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    setIsLoader(true);
    try {
      const response = await dispatch(
        postImportInvoicingDetailsData(importFormData)
      );
      if (response.status === 200) {
        setTimeout(() => {
          setImportOpen(false);
          dispatch(getTimesheetReportData());
          setIsLoader(false);
          setImportSnackbarOpen(true);
        }, 3000);

        setTimeout(() => {
          setImportSnackbarOpen(false);
          setSelectedFileName("");
          setParsedData(null);
          setIsImportedData(false);
        }, 5000);
      } else {
        setImportOpen(false);
      }
    } catch {
      setImportOpen(false);
    }
  };
  const handlecloseSnackbar = () => {
    setOpen(false);
  };

  // console.log("approvalListForInvoice", approvalListForInvoice);
  // useEffect(() => {
  //   const extractedData = approvalListForInvoice?.map((item) => {
  //     return {
  //       recid: item.invoicingRecId,
  //       totalsubmittedhours: item.totalSubmittedHours,
  //       remark: item.remark,
  //       invapprovedbilledhours: item.approvedBilledHours,
  //     };
  //   });
  //   setApproveRejectData(extractedData);
  // }, [approvalListForInvoice]);

  useEffect(() => {
    if (invoicingAuditDetail != null) {
      setAuditHistoryData(invoicingAuditDetail);
    }
  }, [invoicingAuditDetail]);

  useEffect(() => {
    if (timesheetReport && approvalListForInvoice) {
      setIsLoader(false);
    } else {
      setIsLoader(true);
    }
  }, [timesheetReport, approvalListForInvoice]);

  useEffect(() => {
    if (isNewChip) {
      setChips({
        // timePeriodChip: tempChips.timePeriodChip,
        // projectIdChip: [...tempChips.projectIdChip],
        dateChip: tempChips.dateChip,
        statusChip: tempChips.statusChip,
        empEntityChip: tempChips.empEntityChip,
        empTypeChip: tempChips.empTypeChip,
        verticalChip: tempChips.verticalChip,
        projectsChip: tempChips.projectsChip,
        employeeChip: tempChips.employeeChip,
        projPurposeChip: tempChips.projPurposeChip,
        customerNameChip: tempChips.customerNameChip,
        projGroupChip: tempChips.projGroupChip,
      });
      setIsNewChip(false);
    }
  }, [isNewChip]);
  // useEffect(() => {
  //   dispatch(getTimesheetReportData()).then((response) => {
  //     console.log("inside then",timesheetReportJson)
  //     setIsLoader(true);
  //     setSelectedDate([timesheetReportJson?.MonthRange[0]?.split("@")[0]]);
  //     setTempChips((prevChips) => ({
  //       ...prevChips,
  //       dateChip: timesheetReportJson?.MonthRange[0]?.split("@")[0],
  //     }));
  //     setIsNewChip(true);
  //     const date = new Date();
  //     date.setDate(1);
  //     const isoString = date.toISOString();
  //     const formattedDate = isoString.substring(0, 10);
  //     const obj = {
  //       //   empid: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
  //       // empid: ssoEmpIdDetails?.empCode,
  //       fromDate: timesheetReportJson?.MonthRange[0]?.split("@")[1],
  //       // fromDate: "2024-03-01",
  //       // toDate:  "",
  //       entity: [],
  //       projId: [],
  //       projpurpose: [],
  //       CustomerName: [],
  //       empDetails: [],
  //       empType: [],
  //       vertical: [],
  //       // status:  [],
  //       // frequency: "Weekly",
  //       body: "",
  //     };
  //     console.log("object", obj);
  //     dispatch(getApprovalListInvoiceData(obj, setIsLoader));
  //   }).catch(error=>{console.log("error")});
  // }, [dispatch, ssoEmpIdDetails]);
  // useEffect(() => {
  //   console.log("inside useffect scroll");
  //   // Add a scroll event listener when the component mounts
  //   if (approvalListForInvoice?.length > 0) {
  //     window.addEventListener("scroll", handleScroll);
  //     console.log("inside if addevent scroll");
  //     // Remove the scroll event listener when the component unmounts
  //     return () => {
  //       window.removeEventListener("scroll", handleScroll);
  //       console.log("inside if removeevent scroll");
  //     };
  //   }
  // }, [currentPage, reportViewState]);
  useEffect(() => {
    dispatch(getTimesheetReportData());
  }, []);
  // useEffect(() => {
  //   if (approvalListForInvoice?.length > 0) {
  //     // console.log("current page state inside if", approvalListForInvoice);
  //     setReportViewState((prev) => [...prev, ...approvalListForInvoice]);
  //     console.log(
  //       "State updated successfully:",
  //       reportViewState,
  //       approvalListForInvoice
  //     );
  //   }
  //   // setTimesheetReportViewState((prev) => [...prev, ...approvalListForInvoice]);

  //   // else if (approvalListForInvoice === null) setTimesheetReportViewState([]);
  //   //  console.log("current page state", approvalListForInvoice);
  // }, [approvalListForInvoice]);

  useEffect(() => {
    if (timesheetReportJson && ssoEmpIdDetails) {
      setIsLoader(true);
      setSelectedDate(
        allSetPMOChips?.selectedDate
          ? allSetPMOChips?.selectedDate
          : [timesheetReportJson?.MonthRange[0]]
      );
      setSelectedDateItem(
        allSetPMOChips?.selectedDateItem
          ? allSetPMOChips?.selectedDateItem
          : [timesheetReportJson?.MonthRange[0]?.split("@")[1]]
      );
      setTempChips((prevChips) => ({
        ...prevChips,
        dateChip: allSetPMOChips?.allTempChips?.dateChip
          ? allSetPMOChips?.allTempChips?.dateChip
          : [timesheetReportJson?.MonthRange[0]?.split("@")[0]],
      }));
      setDateChipValues(
        allSetPMOChips?.allTempChips?.dateChip
          ? allSetPMOChips?.allTempChips?.dateChip
          : [timesheetReportJson?.MonthRange[0]?.split("@")[0]]
      );
      // setIsNewChip(true);
      setChips((prevChips) => ({
        ...prevChips,
        dateChip: allSetPMOChips?.allTempChips?.dateChip
          ? allSetPMOChips?.allTempChips?.dateChip
          : [timesheetReportJson?.MonthRange[0]?.split("@")[0]],
      }));
      setCountShow(
        allSetPMOChips?.tempvalcount ? allSetPMOChips?.tempvalcount : countShow
      );
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      // const updatedCustomerNameChip =
      //   allSetPMOChips?.allTempChips?.customerNameChip.join("@");
      //   const updatedProjGroupChip = allSetPMOChips?.allTempChips?.projGroupChip.join("@");

      const encryptedChips = allSetPMOChips?.allTempChips?.customerNameChip.map(
        (chip) => Encrypt(key, JSON.stringify(chip))
      );
      const joinedEncryptedNames = encryptedChips?.join("@");
      const replacedString = joinedEncryptedNames?.replace(/\+/g, "%2B");

      const encryptedChips1 = allSetPMOChips?.allTempChips?.projGroupChip.map(
        (chip) => Encrypt(key, JSON.stringify(chip))
      );
      const joinedEncryptedNames1 = encryptedChips1?.join("@");
      const replacedString1 = joinedEncryptedNames1?.replace(/\+/g, "%2B");
       
      const obj = {
        loginBy: Encrypt(
          key,
          JSON.stringify(
            tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId
          )
        ),
        delegateeId:
          ssoEmpIdDetails?.empId === tsDelEmpIdRedux
            ? JSON.stringify(null)
            : Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId)),
        fromDate: allSetPMOChips?.selectedDateItem
          ? allSetPMOChips?.selectedDateItem
          : [timesheetReportJson?.MonthRange[0]?.split("@")[1]],
        entity: allSetPMOChips?.allTempChips?.empEntityChip
          ? allSetPMOChips?.allTempChips?.empEntityChip
          : [],
        projId: allSetPMOChips?.selectedProjItem
          ? allSetPMOChips?.selectedProjItem
          : [],
        projpurpose: allSetPMOChips?.allTempChips?.projPurposeChip
          ? allSetPMOChips?.allTempChips?.projPurposeChip
          : [],
        CustomerName:
          allSetPMOChips?.allTempChips?.customerNameChip?.length > 0
            ? replacedString
            : [],
        empDetails: allSetPMOChips?.allTempChips?.employeeChip
          ? allSetPMOChips?.allTempChips?.employeeChip
          : [],
        empType: allSetPMOChips?.allTempChips?.empTypeChip
          ? allSetPMOChips?.allTempChips?.empTypeChip
          : [],
        vertical: allSetPMOChips?.allTempChips?.verticalChip
          ? allSetPMOChips?.allTempChips?.verticalChip
          : [],
        status: allSetPMOChips?.allTempChips?.statusChip
          ? allSetPMOChips?.allTempChips?.statusChip
          : [],
        projGroup:
          allSetPMOChips?.allTempChips?.projGroupChip?.length > 0
            ? replacedString1
            : [],
        body: "",
        eCode: ssoEmpIdDetails?.empId,
        pagenumber: allSetPMOChips?.page?allSetPMOChips?.page+1:1,
        pagesize: allSetPMOChips?.rowsPerPage?allSetPMOChips?.rowsPerPage:rowsPerPage,
        reportflag: 0,
      };
     
      if (obj.fromDate?.length > 0) {
        dispatch(getApprovalListInvoiceData(obj, setIsLoader));
      }
    }
  }, [timesheetReport, ssoEmpIdDetails, statusSnackbarOpen]);

  const handleApplyFilter = () => {
    // setApplyFilterClicked(true);
    var countVal1 = 1,
      countVal2 = 0,
      countVal3 = 0,
      countVal4 = 0,
      countVal5 = 0,
      countVal6 = 0,
      countVal7 = 0,
      countVal8 = 0,
      countVal9 = 0;

    // if (selectedDate != "") {
    //     countVal1 = 1;
    //   }
    if (tempChips.statusChip != "") {
      countVal2 = 1;
    }
    if (tempChips.empEntityChip != "") {
      countVal3 = 1;
    }
    if (tempChips.empTypeChip != "") {
      countVal4 = 1;
    }
    if (tempChips.verticalChip != "") {
      countVal5 = 1;
    }
    if (tempChips.projGroupChip != "") {
      countVal6 = 1;
    }
    if (tempChips.projectsChip != "") {
      countVal7 = 1;
    }
    if (tempChips.customerNameChip != "") {
      countVal8 = 1;
    }
    if (tempChips.employeeChip != "") {
      countVal9 = 1;
    }
    let tempvalcount =
      countVal1 +
      countVal2 +
      countVal3 +
      countVal4 +
      countVal5 +
      countVal6 +
      countVal7 +
      countVal8 +
      countVal9;
    // console.log("count", countVal1, countVal2);
    setCountShow(tempvalcount);
    setState({
      ...state,
      bottom: false,
      top: false,
      right: false,
      left: false,
    });
    dispatch(
      setAllPMOChips({
        allTempChips: tempChips,
        // currentPage,
        selectedDateItem,
        selectedDate,
        selectedProjItem,
        selectedProjects,
        tempvalcount,
        rowsPerPage:rowsPerPage,
        page:page, 
        // maintainStateVar,
        // applyFilterClicked,
      })
    );
    // console.log("setAllpmochips", allSetPMOChips?.tempvalcount, tempvalcount);

    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    setIsLoader(true);
    const encryptedChips = tempChips?.customerNameChip.map((chip) =>
      Encrypt(key, JSON.stringify(chip))
    );
    const joinedEncryptedNames = encryptedChips?.join("@");
    const replacedString = joinedEncryptedNames?.replace(/\+/g, "%2B");

    const encryptedChips1 = tempChips?.projGroupChip.map((chip) =>
      Encrypt(key, JSON.stringify(chip))
    );
    const joinedEncryptedNames1 = encryptedChips1?.join("@");
    const replacedString1 = joinedEncryptedNames1?.replace(/\+/g, "%2B");

    const projFilter = tempChips.projectsChip?.map((item) => {
      const parts = item.split("PROJ");
      const first = parts[0].substring(parts[0].length - 3);
      const third = parts[1].split("-");
      return `${first}PROJ-${third[1]}`;
    });
    // const formattedValues = selectedProjects?.map((value) => value.split("@$@")[1]);
    const obj = {
      loginBy: Encrypt(
        key,
        JSON.stringify(
          tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId
        )
      ),
      delegateeId:
        ssoEmpIdDetails?.empId === tsDelEmpIdRedux
          ? JSON.stringify(null)
          : Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId)),
      fromDate: tempChips.dateChip ? selectedDateItem : [],
      entity: tempChips.empEntityChip ? tempChips.empEntityChip : [],
      projId: tempChips.projectsChip ? projFilter : [],
      projpurpose: tempChips.projPurposeChip ? tempChips.projPurposeChip : [],
      CustomerName:
        tempChips.customerNameChip?.length > 0 ? replacedString : [],
      empDetails: tempChips.employeeChip ? tempChips.employeeChip : [],
      empType: tempChips.empTypeChip ? tempChips.empTypeChip : [],
      vertical: tempChips.verticalChip ? tempChips.verticalChip : [],
      status: tempChips.statusChip ? tempChips.statusChip : [],
      projGroup: tempChips.projGroupChip?.length > 0 ? replacedString1 : [],
      body: "",
      eCode: ssoEmpIdDetails?.empId,
      // pagenumber: 1,
      // pagesize: rowsPerPage,
      pagenumber: allSetPMOChips?.page?allSetPMOChips?.page+1:1,
      pagesize: allSetPMOChips?.rowsPerPage?allSetPMOChips?.rowsPerPage:rowsPerPage,
      reportflag: 0,
      // pagenumber: 1,
      // pagesize: 25,
    };
   
    dispatch(getApprovalListInvoiceData(obj, setIsLoader)).then((response) => {
      // if(approvalListForInvoice?.length > 0){
      //   setReportViewState([...approvalListForInvoice]);
      // }
      // setReportViewState([]);
      // setCurrentPage(2);

      setIsLoader(false);
      // console.log("handle apply filter 2", checkLoader);
    });
    // setPage(0);

    // setIsLoader(false);
  };
  const handleNewApplyFilter = () => {
    // setApplyFilterClicked(true);
    var countVal1 = 1,
      countVal2 = 0,
      countVal3 = 0,
      countVal4 = 0,
      countVal5 = 0,
      countVal6 = 0,
      countVal7 = 0,
      countVal8 = 0,
      countVal9 = 0;

    // if (selectedDate != "") {
    //     countVal1 = 1;
    //   }
    if (tempChips.statusChip != "") {
      countVal2 = 1;
    }
    if (tempChips.empEntityChip != "") {
      countVal3 = 1;
    }
    if (tempChips.empTypeChip != "") {
      countVal4 = 1;
    }
    if (tempChips.verticalChip != "") {
      countVal5 = 1;
    }
    if (tempChips.projGroupChip != "") {
      countVal6 = 1;
    }
    if (tempChips.projectsChip != "") {
      countVal7 = 1;
    }
    if (tempChips.customerNameChip != "") {
      countVal8 = 1;
    }
    if (tempChips.employeeChip != "") {
      countVal9 = 1;
    }
    let tempvalcount =
      countVal1 +
      countVal2 +
      countVal3 +
      countVal4 +
      countVal5 +
      countVal6 +
      countVal7 +
      countVal8 +
      countVal9;
    // console.log("count", countVal1, countVal2);
    setCountShow(tempvalcount);
    setState({
      ...state,
      bottom: false,
      top: false,
      right: false,
      left: false,
    });
    dispatch(
      setAllPMOChips({
        allTempChips: tempChips,
        // currentPage,
        selectedDateItem,
        selectedDate,
        selectedProjItem,
        selectedProjects,
        tempvalcount,
        rowsPerPage:rowsPerPage,
        page:0, 
        // maintainStateVar,
        // applyFilterClicked,
      })
    );
    // console.log("setAllpmochips", allSetPMOChips?.tempvalcount, tempvalcount);

    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    setIsLoader(true);
    const encryptedChips = tempChips?.customerNameChip.map((chip) =>
      Encrypt(key, JSON.stringify(chip))
    );
    const joinedEncryptedNames = encryptedChips?.join("@");
    const replacedString = joinedEncryptedNames?.replace(/\+/g, "%2B");

    const encryptedChips1 = tempChips?.projGroupChip.map((chip) =>
      Encrypt(key, JSON.stringify(chip))
    );
    const joinedEncryptedNames1 = encryptedChips1?.join("@");
    const replacedString1 = joinedEncryptedNames1?.replace(/\+/g, "%2B");

    const projFilter = tempChips.projectsChip?.map((item) => {
      const parts = item.split("PROJ");
      const first = parts[0].substring(parts[0].length - 3);
      const third = parts[1].split("-");
      return `${first}PROJ-${third[1]}`;
    });
    // const formattedValues = selectedProjects?.map((value) => value.split("@$@")[1]);
    const obj = {
      loginBy: Encrypt(
        key,
        JSON.stringify(
          tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId
        )
      ),
      delegateeId:
        ssoEmpIdDetails?.empId === tsDelEmpIdRedux
          ? JSON.stringify(null)
          : Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId)),
      fromDate: tempChips.dateChip ? selectedDateItem : [],
      entity: tempChips.empEntityChip ? tempChips.empEntityChip : [],
      projId: tempChips.projectsChip ? projFilter : [],
      projpurpose: tempChips.projPurposeChip ? tempChips.projPurposeChip : [],
      CustomerName:
        tempChips.customerNameChip?.length > 0 ? replacedString : [],
      empDetails: tempChips.employeeChip ? tempChips.employeeChip : [],
      empType: tempChips.empTypeChip ? tempChips.empTypeChip : [],
      vertical: tempChips.verticalChip ? tempChips.verticalChip : [],
      status: tempChips.statusChip ? tempChips.statusChip : [],
      projGroup: tempChips.projGroupChip?.length > 0 ? replacedString1 : [],
      body: "",
      eCode: ssoEmpIdDetails?.empId,
      pagenumber: 1,
      pagesize: rowsPerPage,
      reportflag: 0,
      // pagenumber: 1,
      // pagesize: 25,
    };
   
    dispatch(getApprovalListInvoiceData(obj, setIsLoader)).then((response) => {
      // if(approvalListForInvoice?.length > 0){
      //   setReportViewState([...approvalListForInvoice]);
      // }
      // setReportViewState([]);
      // setCurrentPage(2);

      setIsLoader(false);
      // console.log("handle apply filter 2", checkLoader);
    });
    setPage(0);

    // setIsLoader(false);
  };
  const handleInitialData = () => {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    setIsLoader(true);
    // console.log("handle apply filter 3", checkLoader);

    const updatedCustomerNameChip = tempChips.customerNameChip.join("@");

    const obj = {
      fromDate: tempChips.dateChip ? selectedDateItem : [],
      entity: tempChips.empEntityChip ? tempChips.empEntityChip : [],
      projId: tempChips.projectsChip ? selectedProjItem : [],
      projpurpose: tempChips.projPurposeChip ? tempChips.projPurposeChip : [],
      CustomerName:
        tempChips.customerNameChip?.length > 0
          ? Encrypt(key, JSON.stringify(updatedCustomerNameChip))
          : [],
      empDetails: tempChips.employeeChip ? tempChips.employeeChip : [],
      empType: tempChips.empTypeChip ? tempChips.empTypeChip : [],
      vertical: tempChips.verticalChip ? tempChips.verticalChip : [],
      status: tempChips.statusChip ? tempChips.statusChip : [],
      projGroup:
        tempChips.projGroupChip?.length > 0
          ? Encrypt(key, JSON.stringify(tempChips.projGroupChip))
          : [],
      body: "",
      // pagenumber: currentPage,
      // pagesize: 25,
      eCode: ssoEmpIdDetails?.empId,
    };
    // console.log("obj summary download", obj);
    dispatch(getApprovalListInvoiceData(obj, setIsLoader));
    // setCurrentPage(currentPage);
    setTimeout(() => {
      setCurrentPage(currentPage);
      // setCurrentPageSize(currentPageSize + 100);
    }, 5000); // 5000 milliseconds = 5 seconds delay
    // setCurrentPageSize(currentPageSize+100);
  };
  const handleEmployee = (event) => {
    // setCurrentPage(1);
    const selectedValue = event.target.value.trimStart();
    const isDuplicate = tempChips.employeeChip.includes(selectedValue);
    // console.log("values employee", selectedValue, isDuplicate);
    const handleEmployeeSubmit = (shouldUpdateState) => {
      //co//sole.log("inside handleemployeesubmit");
      if (shouldUpdateState && !isDuplicate) {
        setSelectedEmpName(selectedValue);
        setTempChips((prevChips) => ({
          ...prevChips,
          employeeChip: [...prevChips.employeeChip, selectedValue],
        }));
        setIsNewChip(true);
        //co//sole.log("tempchips", tempChips);
      }
    };

    if (
      (event.key === "Enter" && selectedValue?.length > 0) ||
      (event.type === "blur" && selectedValue?.length > 0)
    ) {
      handleEmployeeSubmit(true);
    } else {
      setSelectedEmpName(selectedValue);
    }
  };

  const handleDateChange = (event, values) => {
    if (!values || values?.length === 0) {
      // If no values selected or all values cleared, select the first option
      values = [timesheetReportJson?.MonthRange[0]];
    } else if (values?.length > 1) {
      // If more than one value selected, keep only the last selected value
      values = [values[values?.length - 1]];
    }

    const formattedValues = values.map((value) => value.split("@")[1]);
    const originalValues = values.map((value) => value.split("@")[0]);

    setSelectedDate(values);
    setSelectedDateItem(formattedValues);
    setTempChips((prevChips) => ({
      ...prevChips,
      dateChip: originalValues,
    }));
    setDateChipValues(originalValues);
    setIsNewChip(true);
  };

  const handleStatusChange = (event, values) => {
    setCurrentPage(1);
    if (values) {
      // setApplyFilterClicked(true)
      //console.log("values", values);
      setSelectedStatus(values);

      setTempChips((prevChips) => ({
        ...prevChips,
        statusChip: values,
      }));
      setIsNewChip(true);
    } else {
      setSelectedStatus([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        statusChip: [],
      }));
    }
  };

  const handleCustomerNameChange = (event, values) => {
    // setCurrentPage(1);
    if (values) {
      // setApplyFilterClicked(true)
      //console.log("values", values);
      setSelectedCustomer(values);
      // Joining array elements with "@" symbol
      // const joinedValues = values.join('@');

      setTempChips((prevChips) => ({
        ...prevChips,
        customerNameChip: values,
      }));
      setIsNewChip(true);
    } else {
      setSelectedCustomer([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        customerNameChip: [],
      }));
    }
  };
  const handleProjectChange = (event, values) => {
    // setCurrentPage(1);
    if (values) {
      //co//sole.log("values projext", values);
      const tempArray = [];

      const formattedValues = values.map((value) => value.split("@$@")[1]);
      const originalValues = values.map((value) =>
        value.replace(/@\$\@/g, "-")
      );
      setSelectedProjects(values);
      ////console.log("values projext new", formattedValues);
      setSelectedProjItem(formattedValues);
      setTempChips((prevChips) => ({
        ...prevChips,
        projectsChip: originalValues,
      }));
      setIsNewChip(true);
    } else {
      setSelectedProjects([]);
      setSelectedProjItem([]);
      setTempChips((prevChips) => ({
        ...prevChips,
        projectsChip: [],
      }));
    }
  };
  const handleEntityChange = (event, values) => {
    // setCurrentPage(1);
    if (values) {
      // console.log("values", values);
      setSelectedEntityCode(values);

      setTempChips((prevChips) => ({
        ...prevChips,
        empEntityChip: values,
      }));
      setIsNewChip(true);
    } else {
      setSelectedEntityCode([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        empEntityChip: [],
      }));
    }
  };
  const handleEmpCodeChange = (event, values) => {
    if (values) {
      ////console.log("values", values);
      setSelectedEmpType(values);

      setTempChips((prevChips) => ({
        ...prevChips,
        empTypeChip: values,
      }));
      setIsNewChip(true);
    } else {
      setSelectedEmpType([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        empTypeChip: [],
      }));
    }
  };
  const handleProjGroupChange = (event, values) => {
    // setCurrentPage(1);
    if (values) {
      // console.log("values projgroup", values);
      setSelectedProjGroup(values);

      setTempChips((prevChips) => ({
        ...prevChips,
        projGroupChip: values,
      }));
      setIsNewChip(true);
    } else {
      setSelectedProjGroup([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        projGroupChip: [],
      }));
    }
  };
  const handleVerticalChange = (event, values) => {
    // setCurrentPage(1);
    if (values) {
      ////console.log("values", values);
      setSelectedVertical(values);

      setTempChips((prevChips) => ({
        ...prevChips,
        verticalChip: values,
      }));
      setIsNewChip(true);
    } else {
      setSelectedVertical([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        verticalChip: [],
      }));
    }
  };
  const handleChipDelete = (chipType, value) => {
    const originalValue = selectedProjects.filter(
      (item) => item.replace(/@\$\@/g, "-") !== value
    );
    const formattedValues = selectedProjects.map(
      (value) => value.split("@$@")[1]
    );
    setSelectedProjects(originalValue);
    setSelectedProjItem(formattedValues);
    // console.log("projects checking",selectedProjects,selectedProjItem)
    //   const originalValues = selectedProjects.map((value) =>
    //   value.replace(/@\$\@/g, "-")
    // );
    //   if(chipType=="projectsChip"){
    //     const indexNew = originalValues.findIndex(project => project==value);

    //     const index = chips[`${chipType}`].indexOf(indexNew);
    //     console.log("indexNew",indexNew,index,value,originalValues,selectedProjects)
    //     if (index != -1) {
    //       chips[`${chipType}`].splice(index, 1);
    //       setIsNewChip(true);
    //     }
    //   }else {
    const index = chips[`${chipType}`].indexOf(value);
    if (index != -1) {
      chips[`${chipType}`].splice(index, 1);
      setIsNewChip(true);
    }
    // }

    // console.log("handleChipDelete",index)

    // chips[`${chipType}`].filter((val) => val !== value);

    // console.log("chipchip", chips[`${chipType}`]);
  };
  // console.log("handle clear dispatch",allSetPMOChips)

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };
  const handleClearAll = () => {
    // setApplyFilterClicked(false);
    // dispatch(allPMOChipsClearData());
    // console.log("`tempchips checking`", tempChips, isNewChip);
    setTempChips({
      dateChip: tempChips.dateChip,
      verticalChip: [],
      projectsChip: [],
      statusChip: [],
      empEntityChip: [],
      empTypeChip: [],
      employeeChip: [],
      projPurposeChip: [],
      customerNameChip: [],
      // dateChip: [],
      projGroupChip: [],
    });
    setChips({
      dateChip: tempChips.dateChip,
      verticalChip: [],
      projectsChip: [],
      statusChip: [],
      empEntityChip: [],
      empTypeChip: [],
      employeeChip: [],
      projPurposeChip: [],
      customerNameChip: [],
      // dateChip: [],
      projGroupChip: [],
    });
    // setSelectedDateItem([]);
    setSelectedProjItem([]);
    setSelectedEmpType([]);
    setSelectedEntityCode([]);
    setSelectedVertical([]);
    setSelectedStatus([]);
    setSelectedProjects([]);
    setSelectedEmpName([]);
    setSelectedProjGroup([]);
    setSelectedCustomer([]);
    // console.log("dateChipValues", dateChipValues, tempChips, allSetPMOChips);
    setTempChips((prevChips) => ({
      ...prevChips,
      dateChip: dateChipValues,
    }));
    const tempAllChips = {
      dateChip: tempChips.dateChip,
      verticalChip: [],
      projectsChip: [],
      statusChip: [],
      empEntityChip: [],
      empTypeChip: [],
      employeeChip: [],
      projPurposeChip: [],
      customerNameChip: [],
      projGroupChip: [],
    };
    dispatch(
      setAllPMOChips({
        allTempChips: tempAllChips,
        // currentPage,
        selectedDateItem,
        selectedDate,
        selectedProjItem: [],
        selectedProjects: [],
        tempvalcount:1,
        rowsPerPage,page 
        // maintainStateVar,
        // applyFilterClicked,
      })
    );
    setCountShow(1);
    setIsNewChip(true);
  };

  const handleExportReporttDownload = async () => {
    setIsLoader(true);
    const updatedCustomerNameChip = tempChips.customerNameChip.join("@");

    const obj1 = {
      fromDate: tempChips.dateChip ? selectedDateItem : [],
      vertical: tempChips.verticalChip ? tempChips.verticalChip : [],
      projId: tempChips.projectsChip ? selectedProjItem : [],
      empType: tempChips.empTypeChip ? tempChips.empTypeChip : [],
      entity: tempChips.empEntityChip ? tempChips.empEntityChip : [],
      empDetails: tempChips.employeeChip ? tempChips.employeeChip : [],
      projpurpose: tempChips.projPurposeChip ? tempChips.projPurposeChip : [],
      CustomerName: tempChips.customerNameChip ? updatedCustomerNameChip : [],
      status: tempChips.statusChip ? tempChips.statusChip : [],
      projGroup: tempChips.projGroupChip ? tempChips.projGroupChip : [],
      body: "invoicingexportlist",
    };
    dispatch(getInvoiceExportReportData(obj1, setIsLoader));
  };

  const handleReportDownload = async () => {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    setIsLoader(true);
    // const updatedCustomerNameChip = tempChips.customerNameChip.join("@");
    // const updatedProjGroupChip = tempChips.projGroupChip.join("@");
    const encryptedChips = tempChips?.customerNameChip.map((chip) =>
      Encrypt(key, JSON.stringify(chip))
    );
    const joinedEncryptedNames = encryptedChips?.join("@");
    const replacedString = joinedEncryptedNames?.replace(/\+/g, "%2B");

    const encryptedChips1 = tempChips?.projGroupChip.map((chip) =>
      Encrypt(key, JSON.stringify(chip))
    );
    const joinedEncryptedNames1 = encryptedChips1?.join("@");
    const replacedString1 = joinedEncryptedNames1?.replace(/\+/g, "%2B");
    const projFilter = tempChips.projectsChip?.map((item) => {
      const parts = item.split("PROJ");
      const first = parts[0].substring(parts[0].length - 3);
      const third = parts[1].split("-");
      return `${first}PROJ-${third[1]}`;
    });
    // console.log("updatedCustomerNameChip>>>>",encryptedChips,joinedEncryptedNames,tempChips.customerNameChip?.length,replacedString)
    // console.log("updatedprojNameChip>>>>>",encryptedChips1,joinedEncryptedNames1,replacedString1)
    const obj1 = {
      loginBy: Encrypt(
        key,
        JSON.stringify(
          tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId
        )
      ),
      delegateeId:
        ssoEmpIdDetails?.empId === tsDelEmpIdRedux
          ? JSON.stringify(null)
          : Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId)),
      fromDate: tempChips.dateChip ? selectedDateItem : [],
      vertical: tempChips.verticalChip ? tempChips.verticalChip : [],
      projId: tempChips.projectsChip ? projFilter : [],
      empType: tempChips.empTypeChip ? tempChips.empTypeChip : [],
      entity: tempChips.empEntityChip ? tempChips.empEntityChip : [],
      empDetails: tempChips.employeeChip ? tempChips.employeeChip : [],
      projpurpose: tempChips.projPurposeChip ? tempChips.projPurposeChip : [],
      CustomerName:
        tempChips.customerNameChip?.length > 0 ? replacedString : [],
      status: tempChips.statusChip ? tempChips.statusChip : [],
      projGroup: tempChips.projGroupChip?.length > 0 ? replacedString1 : [],
      pagenumber: 1,
      pagesize: 10000,
      reportflag: 1,
      eC: Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empCode)),
    };
    dispatch(getApprovalInvoicingReportData(obj1, setIsLoader));
  };
  const handleReportExportDownload = async () => {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    setIsLoader(true);
    // const updatedCustomerNameChip = tempChips.customerNameChip.join("@");
    // const updatedProjGroupChip = tempChips.projGroupChip.join("@");
    const encryptedChips = tempChips?.customerNameChip.map((chip) =>
      Encrypt(key, JSON.stringify(chip))
    );
    const joinedEncryptedNames = encryptedChips?.join("@");
    const replacedString = joinedEncryptedNames?.replace(/\+/g, "%2B");

    const encryptedChips1 = tempChips?.projGroupChip.map((chip) =>
      Encrypt(key, JSON.stringify(chip))
    );
    const joinedEncryptedNames1 = encryptedChips1?.join("@");
    const replacedString1 = joinedEncryptedNames1?.replace(/\+/g, "%2B");
    const projFilter = tempChips.projectsChip?.map((item) => {
      const parts = item.split("PROJ");
      const first = parts[0].substring(parts[0].length - 3);
      const third = parts[1].split("-");
      return `${first}PROJ-${third[1]}`;
    });
    // console.log("updatedCustomerNameChip>>>>",encryptedChips,joinedEncryptedNames,tempChips.customerNameChip?.length,replacedString)
    // console.log("updatedprojNameChip>>>>>",encryptedChips1,joinedEncryptedNames1,replacedString1)
    const obj1 = {
      loginBy: Encrypt(
        key,
        JSON.stringify(
          tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId
        )
      ),
      delegateeId:
        ssoEmpIdDetails?.empId === tsDelEmpIdRedux
          ? JSON.stringify(null)
          : Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId)),
      fromDate: tempChips.dateChip ? selectedDateItem : [],
      vertical: tempChips.verticalChip ? tempChips.verticalChip : [],
      projId: tempChips.projectsChip ? projFilter : [],
      empType: tempChips.empTypeChip ? tempChips.empTypeChip : [],
      entity: tempChips.empEntityChip ? tempChips.empEntityChip : [],
      empDetails: tempChips.employeeChip ? tempChips.employeeChip : [],
      projpurpose: tempChips.projPurposeChip ? tempChips.projPurposeChip : [],
      CustomerName:
        tempChips.customerNameChip?.length > 0 ? replacedString : [],
      status: tempChips.statusChip ? tempChips.statusChip : [],
      projGroup: tempChips.projGroupChip?.length > 0 ? replacedString1 : [],
      pagenumber: 1,
      pagesize: 10000,
      reportflag: 1,
      eC: Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empCode)),
      // eC:ssoEmpIdDetails?.empCode,
    };
    dispatch(getApprovalInvoicingApprovalExportData(obj1, setIsLoader));
  };
  const handleStatusSnackClose = () => {
    // setIsSaveDisabled(false);
    setStatusSnackbarOpen(false);
    setIsLoader(false);
    // if (saveOrSubmitButton === "Submit") {
    //   setTimeout(() => {
    //     handleSubmittedClose();
    //     setStatusSnackbarOpen(false);
    //     history.push("/timesheet-management");
    //   }, 500);
    // }
  };
  const toggleDrawer = (anchor, open) => (event) => {
    // console.log("aa gaye");
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      style={{ overflowX: "hidden", overflowY: "hidden" }}
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      {" "}
      <ArrowBackIosIcon
        onClick={toggleDrawer(anchorFilter1, false)}
        style={{ paddingLeft: "0.75rem", marginTop: "1.188rem" }}
      />
      <span
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 600,
            lineHeight: "1.125rem",
            marginTop: "-1.563rem",
            marginLeft: "-14rem",
            // display:"flex",
            // justifyContent:"center"
          }}
        >
          Filter
        </Typography>
      </span>
      <Divider />
      <br />
      <Grid
        container
        spacing={3}
        style={{
          display: "flex",
          //marginBottom: "15px",
          alignItems: "baseline",
          //justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            // defaultValue={[timesheetReportJson?.MonthRange[0]]}
            options={
              Array.isArray(timesheetReportJson?.MonthRange)
                ? timesheetReportJson?.MonthRange
                : // .MonthRange.map(option => option.split('@')[0])
                  []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              //width: "60%",

              width: "18.75rem",
              // ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            //options={statusOptions}
            style={{
              //marginLeft: "223px",
              marginLeft: "3px",
            }}
            value={selectedDate}
            onChange={handleDateChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            // getOptionLabel={(option) => option.split('@')[0]}
            getOptionSelected={(option, value) =>
              selectedDate.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => {
              // console.log("siddhant", option);
              const val = option.split("@")[0];
              return (
                <li
                  style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  {...props}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: selected ? "#0B1941" : "" }}
                        checked={selected}
                      />
                    }
                    label={<span style={{ fontSize: "12px" }}>{val}</span>}
                  />
                </li>
              );
            }}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px" }}
                label="Select Time Period"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" }, // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.InvStatus)
                ? timesheetReportJson?.InvStatus
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              //width: "60%",
              width: "18.75rem",
              // ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            //options={statusOptions}
            style={{
              marginLeft: "3px",
            }}
            value={selectedStatus}
            onChange={handleStatusChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedStatus.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px" }}
                label="Status"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" }, // Set the font size here
                }}
              />
            )}
          />
        </Grid>

        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.EntityCode)
                ? timesheetReportJson?.EntityCode
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              // width: "70%",
              width: "18.75rem",
              // fontSize: "14px",
              // "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            style={{
              // marginLeft: "105px"
              marginLeft: "3px",
            }}
            //options={statusOptions}
            value={selectedEntityCode}
            onChange={handleEntityChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedEntityCode.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px", fontSize: "14px" }}
                label="Employee Legal Entity"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" }, // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.EmployeeType)
                ? timesheetReportJson?.EmployeeType
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              // width: "57%",
              width: "18.75rem",
              // ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            //options={statusOptions}
            style={{
              //marginLeft: "19px",
              marginLeft: "3px",
            }}
            value={selectedEmpType}
            onChange={handleEmpCodeChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedEmpType.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px" }}
                label="Employee Type"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" }, // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.Horizontal)
                ? timesheetReportJson?.Horizontal
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              // ".MuiInputLabel-outlined": {
              //   transform: "translate(15px, 10px) scale(1)",
              // },
              width: "18.75rem",
              // ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            style={{
              marginLeft: "3px",
            }}
            //options={statusOptions}
            value={selectedVertical}
            onChange={handleVerticalChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedVertical.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px" }}
                label="Project Vertical"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" }, // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.ProjGroup)
                ? timesheetReportJson?.ProjGroup
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              // ".MuiInputLabel-outlined": {
              //   transform: "translate(15px, 10px) scale(1)",
              // },
              width: "18.75rem",
              // ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            style={{
              marginLeft: "3px",
            }}
            //options={statusOptions}
            value={selectedProjGroup}
            onChange={handleProjGroupChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedProjGroup.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px" }}
                label="Project Group"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" }, // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.ProjName)
                ? timesheetReportJson?.ProjName
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              //width: "57%",
              width: "18.75rem",
              // ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            style={{
              // marginLeft: "-474px"
              marginLeft: "3px",
            }}
            //options={statusOptions}
            value={selectedProjects}
            onChange={handleProjectChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedProjects.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => {
              //console.log("12345", option);
              return (
                <li
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    paddingRight: "0px",
                  }}
                  {...props}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: selected ? "#0B1941" : "" }}
                        checked={selected}
                      />
                    }
                    label={
                      <span style={{ fontSize: "10px" }}>
                        {option.replace(/@\$\@/g, "-")}
                      </span>
                    }
                  />
                </li>
              );
            }}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px" }}
                label="Project Name/Id"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" }, // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.CustomerName)
                ? timesheetReportJson?.CustomerName
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              //width: "60%",
              width: "18.75rem",
              // ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            //options={statusOptions}
            style={{
              //marginLeft: "223px",
              marginLeft: "3px",
            }}
            value={selectedCustomer}
            onChange={handleCustomerNameChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedCustomer.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px" }}
                label="Customer Name"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" }, // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid
          style={{
            // marginLeft: "-62.87rem"

            marginLeft: "50px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                m: 1,
                width:
                  window.screen.availWidth == 1920
                    ? "45ch"
                    : window.screen.availWidth == 1536
                    ? "34ch"
                    : window.screen.availWidth == 1098
                    ? "25ch"
                    : "28.5ch",
                marginLeft: "3px",
              },
            }}
            noValidate
            autoComplete="off"
            // onSubmit={handleFormSubmit}
          >
            <div>
              <TextField
                id="outlined-size-small"
                label="Search by Employee Name/Id"
                size="small"
                InputProps={{
                  inputMode: "text",
                  pattern: "[a-zA-Z0-9,]*",
                  inputProps: {
                    style: { padding: "8.5px 14px" },
                  },
                }}
                InputLabelProps={{
                  style: { fontSize: "14px" }, // Set the font size here
                }}
                variant="outlined"
                // sx={{
                //   ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                //     fontSize: "14px",
                //   },
                // }}
                value={selectedEmpName}
                onChange={handleEmployee}
                onKeyDown={handleEmployee}
                onBlur={handleEmployee}
              />
            </div>
          </Box>
        </Grid>

        {/* <div>
          <Button
            variant="outlined"
            color="primary"
            //disabled={selected.length > 0 ? false : true}
            style={{
              whiteSpace: "nowrap",
              backgroundColor: "#0B1941",
              //backgroundColor: selected.length > 0 ? "#0B1941" : "#979797",
              textTransform: "unset",
              //  color: "#0B1941",
              border: "0.063rem solid #0B1941",
              color: "#0B1941",
              // border: "1px solid #979797",
              padding: "0.5rem",
            }}
            onClick={handleApplyFilter}
          >
            {/* {console.log("data again", getTeamTimesheetData)}{" "} 
            <Typography
              style={{
                fontSize: "0.625rem",
                fontWeight: 500,
                color: "#FFFFFF",
                backgroundColor: "#0B1941",
              }}
            >
              &nbsp;Apply Filters
            </Typography>
          </Button>
          <Button
            variant="outlined"
            size="small"
            // style={{ marginLeft: "37px", width: "121px" }}
            style={{
              marginLeft: "59px",
              width: "121px",
              textTransform: "inherit",
              fontSize: "14px",
            }}
            // onClick={handleRemoveFilter}
          >
            Reset
          </Button>
        </div> */}
        <Box>
          <Button
            variant="outlined"
            size="small"
            // style={{ marginLeft: "37px", width: "121px" }}
            style={{
              marginLeft: "2.438rem",
              width: "7.563rem",
              textTransform: "inherit",
              fontSize: "0.875rem",
            }}
            onClick={handleClearAll}
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            // disabled={buttonState}
            style={{
              background: "#0B1941",
              marginLeft: "1.25rem",
              marginBottom: "3.125rem",
              marginTop: "3.125rem",
              textTransform: "inherit",
              fontSize: "0.875rem",
            }}
            color="#fffff"
            size="small"
            onClick={() => handleNewApplyFilter()}
          >
            <Typography style={{ color: "white", margin: 0 }}>
              {" "}
              Apply Filter
            </Typography>
          </Button>
        </Box>
      </Grid>
    </div>
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  // const isSelectedRow = (id) =>  currentRow.find(name=>name==id);

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handlecloseSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.anchorStyle}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{
            background:
              approveRejectStatus === "Approved" ? "#F0FFF6" : "#FEF0F3",
          }}
        >
          {/* {console.log("showApproveRejectDialog", showApproveRejectDialog)} */}
          {approveRejectStatus == "Approved" ? (
            <Grid style={{ height: "67px", width: "300px" }}>
              <div style={{ display: "flex" }}>
                <Grid
                  style={{
                    marginLeft: "43px",
                    paddingTop: "9px",
                    marginRight: "90px",
                  }}
                  className={classes.snackbarMessage}
                >
                  {" "}
                  {"Timesheet Approved"}
                </Grid>
                <Grid style={{ marginleft: "90px", paddingTop: "5px" }}>
                  <CloseOutlinedIcon onClick={handlecloseSnackbar} />
                </Grid>
              </div>
              <div style={{ display: "flex" }}>
                <Grid style={{ marginLeft: "10px", marginTop: "-25px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15 2L20 7M5 22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21V7H15V2H5C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3V21C4 21.2652 4.10536 21.5196 4.29289 21.7071C4.48043 21.8946 4.73478 22 5 22Z"
                      stroke="#349259"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.5 14.5L11.5 17L16 11.5"
                      stroke="#349259"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Grid>
                {snackBarData?.name == "" ||
                snackBarData?.name == undefined ||
                snackBarData?.id == " " ||
                snackBarData?.id == undefined ? (
                  <Grid className={classes.snackbarMessage1}>
                    {/* {" "}

                  {snackBarData?.name} {" | "}

                  ID : {snackBarData?.id} */}
                  </Grid>
                ) : (
                  <Grid className={classes.snackbarMessage1}>
                    {" "}
                    {snackBarData?.name} {" | "}
                    ID : {snackBarData?.id}
                  </Grid>
                )}
              </div>
            </Grid>
          ) : (
            <Grid style={{ height: "67px", width: "300px" }}>
              <div style={{ display: "flex" }}>
                <Grid
                  style={{
                    marginLeft: "43px",
                    paddingTop: "9px",
                    marginRight: "90px",
                  }}
                  className={classes.snackbarMessage}
                >
                  {" "}
                  {"Timesheet Rejected"}
                </Grid>
                <Grid style={{ marginleft: "90px", paddingTop: "5px" }}>
                  <CloseOutlinedIcon onClick={handlecloseSnackbar} />
                </Grid>
              </div>
              <div style={{ display: "flex" }}>
                <Grid style={{ marginLeft: "10px", marginTop: "-25px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15 2L20 7M5 22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21V7H15V2H5C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3V21C4 21.2652 4.10536 21.5196 4.29289 21.7071C4.48043 21.8946 4.73478 22 5 22Z"
                      stroke="#D50606"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 11L15 17"
                      stroke="#D50606"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 11L9 17"
                      stroke="#D50606"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Grid>
                <Grid className={classes.snackbarMessage1}>
                  {snackBarData?.name} {" | "}
                  {snackBarData?.id}
                </Grid>
              </div>
            </Grid>
          )}
        </Grid>
      </Snackbar>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1px",
          //   backgroundColor: "#e3e8f7",
        }}
      >
        <Box style={{ fontSize: "12px", fontWeight: 600, marginLeft: "10px" }}>
          {tempChips.dateChip}
        </Box>
        <Box>
          <StyledBadge badgeContent={countShow} color="secondary">
            <IconButton onClick={toggleDrawer(anchorFilter1, true)}>
              <span class="material-symbols-outlined">filter_alt</span>
            </IconButton>
          </StyledBadge>
          {/* </IconButton> */}
          {/* <FilterAltOutlinedIcon onClick={toggleDrawer(anchorFilter1, true)} /> */}
          <Drawer
            anchor={anchorFilter1}
            open={state[anchorFilter1]}
            onClose={toggleDrawer(anchorFilter1, false)}
            PaperProps={{
              style: { height: "100%", width: "100%" }, // This ensures the drawer covers the whole screen
            }}
          >
            {list(anchorFilter1)}
          </Drawer>
          {
           timesheetAccessFlag &&
           timesheetAccessFlag?.invoicingTabMSDExport === 1 && ( <IconButton onClick={handleReportExportDownload}>
              <SystemUpdateAltIcon
                style={{
                  height: "1.375rem",
                  color: "#043365",
                }}
              />
            </IconButton>)
          }
         
          <IconButton onClick={handleReportDownload}>
            <LaunchIcon
              style={{
                height: "1.375rem",
                color: "#043365",
              }}
            />
          </IconButton>
          
        </Box>
      </Box>
      <Box
        item
        xs={2}
        md={2}
        style={{ textAlign: "end", marginRight: "14px", marginBottom: "9px" }}
      >
        {/* <CSVLink
              {...csvLink}
              style={
                selected.length > 0
                  ? {}
                  : {
                      color: "transparent",
                      pointerEvents: "none",
                      cursor: "pointer",
                    }
              }
            > */}
        {selected?.length > 0 && selected[0] !== "" && (
          <Button
            variant="outlined"
            color="primary"
            disabled={errorMessage == 1}
            style={{
              whiteSpace: "nowrap",
              //backgroundColor: selected.length > 0 ? "#0B1941" : "#979797",
              textTransform: "unset",
              padding: "6px",
              backgroundColor: errorMessage == 1 ? "grey" : "#349259",
              color: "#043365",
              border: "1px solid #979797",
            }}
            onClick={() => handleApprove("", "", "Yes")}
          >
            <CheckCircleOutlineIcon
              style={{
                width: "16px",
                height: "16px",
                color: "#FFFFFF",
              }}
            />
            <Typography
              style={{
                fontSize: "12px",
                //fontWeight: 500,
                color: "#FFFFFF",
              }}
            >
              &nbsp;Bulk Approval
            </Typography>
          </Button>
        )}

        {/* </CSVLink> */}
      </Box>
      <TableContainer>
        <Table style={{ overflow: "hidden" }}>
          <TableHead style={{ backgroundColor: "#e3e8f7" }}>
            <TableCell
              style={{
                padding: "0px",
                lineHeight: "1rem",
                //    width: "0%",
                transform: "scale(0.8)",
              }}
            >
              {" "}
              <Checkbox
                checked={checked}
                style={
                  {
                    //  paddingLeft: "12px"
                  }
                }
                checkedIcon={
                  <CheckBoxIcon
                    fontSize="small"
                    className={classes.checkBoxRoot}
                  />
                }
                //fontSize="small"
                onChange={handleSelectAllClick}
                inputProps={{ "aria-label": "select all rows" }}
                //className={classes.checkBoxRoot}
                // style={{
                //   background: "#0B1941",
                //   color: "white",
                //   "&$checked": {
                //     background: "#0B1941",
                //     color: "white",
                //   },
                // }}
              />
            </TableCell>

            <TableCell
              style={{
                fontWeight: 600,
                fontSize: "0.625rem",
                // width: "7%",
                padding: "0px",
                lineHeight: "1rem",
              }}
            >
              <div>Employee Name & Id</div>
            </TableCell>

            <TableCell
              style={{
                fontWeight: 600,
                fontSize: "0.625rem",
                // width: "10%",
                padding: "10px 0px 8px 13px",
                lineHeight: "1rem",
              }}
            >
              <div>Project</div>
            </TableCell>
            {/* <TableCell
              style={{
                fontWeight: 600,
                fontSize: "0.625rem",
                width: "22%",
                padding: "0px",
                lineHeight: "1rem",
              }}
            >
              Time Period
            </TableCell> */}

            {/* <TableCell
              style={{
                fontWeight: 600,
                fontSize: "0.625rem",
                width: "9%",
                paddingLeft: "0px",
                // textAlign: "right",
                padding: "0px 50px 0px 0px",
                lineHeight: "1rem",
              }}
            >
              Hours
            </TableCell> */}

            <TableCell
              style={{
                fontWeight: 600,
                fontSize: "0.625rem",
                // width: "5%",
                padding: "0px 0px 0px 0px",
                lineHeight: "1rem",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "0.625rem",
                    // width: "12%",
                    padding: "10px 0px 14px 0px",
                    lineHeight: "1rem",
                  }}
                >
                  {" "}
                  Hours
                </Typography>
                {/* <Typography style={{ marginRight: "14px",transform: "scale(0.8)",
                //  marginLeft: "-12px"
                  }}>
                  {" "}
                  <Tooltip
                    title={
                      approvalListForInvoice &&
                      approvalListForInvoice[0] &&
                      approvalListForInvoice[0]?.recommendedBillableHoursLogic
                    }
                    open={openTooltip}
                    onOpen={() => setOpenTooltip(true)}
                    onClose={() => setOpenTooltip(false)}
                    interactive
                    arrow
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                </Typography> */}
                <Typography
                  style={{ marginRight: "14px", transform: "scale(0.8)" }}
                >
                  <Tooltip
                    title={
                      approvalListForInvoice &&
                      approvalListForInvoice[0] &&
                      approvalListForInvoice[0]?.recommendedBillableHoursLogic
                    }
                    open={openTooltip}
                    onClose={handleTooltipClose}
                    interactive
                    arrow
                  >
                    <InfoOutlinedIcon
                      onClick={handleTooltipOpen}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </Typography>
              </Box>
            </TableCell>

            <TableCell
              style={{
                fontWeight: 600,
                fontSize: "0.625rem",
                // width: "12%",
                // paddingLeft: "45px",
                padding: "0px",
                lineHeight: "1rem",
              }}
            >
              Final Billable Hours
            </TableCell>
            <TableCell></TableCell>
            {/* <TableCell
              style={{
                fontWeight: 600,
                fontSize: "0.625rem",
                width: "9%",
                padding: "0px 0px 0px 14px",
                lineHeight: "1rem",
              }}
            >
              Remarks
            </TableCell> */}
          </TableHead>
          <TableBody>
            {approvalListForInvoice && approvalListForInvoice?.length > 0 ? (
              stableSort(approvalListForInvoice, getComparator(order, orderBy))
                // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  const isItemSelected = isSelected(row.identifier);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isHoursEnabled =
                    row.rejectBTFlag === 1 || row.btEnabledFlag == 0;
                  // const obj = {
                  //   invoicingId: row.invoicingRecId,
                  // };
                  // dispatch(getInvoicingAuditDetailsData(obj));                  // const isCurrentRow = isSelectedRow(row.identifier);
                  // console.log("isSelectedRow",isCurrentRow)

                  // console.log(
                  //   "slice value",
                  //   stableSort(
                  //     approvalListForInvoice,
                  //     getComparator(order, orderBy)
                  //   )?.slice(
                  //     page * rowsPerPage,
                  //     page * rowsPerPage + rowsPerPage
                  //   )
                  // );
                  return (
                    <TableRow
                      hover
                      className={classes.tableRow}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        // className={classes.tableCell}
                        style={{
                          position: "sticky",
                          left: 0,
                          background: "none",
                          zIndex: 800,
                          minWidth: "53px",
                          padding: "0px",
                          transform: "scale(0.8)",
                          // color:"#EFF2F8",
                        }}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          //   style={{ paddingLeft: "10px" }}
                          checkedIcon={
                            <CheckBoxIcon
                              fontSize="small"
                              className={classes.checkBoxRoot}
                            />
                          }
                          onClick={(event) => handleClick(event, row)}
                          inputProps={{ "aria-labelledby": labelId }}
                          disabled={isHoursEnabled}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "0px 0px 0px 1px",
                          //  width: "21%"
                        }}
                        onClick={() => {
                          // console.log("hi table row clicked! report");
                          const text = "/timesheetviewpage/:id";
                          const result = text.replace(":id", row.empId);
                          // allSetChips = null;
                          // dispatch(
                          //   setAllPMOChips({
                          //     allTempChips: tempChips,
                          //     // currentPage,
                          //     selectedDateItem,
                          //     selectedDate,
                          //     selectedProjItem,
                          //     selectedProjects,
                          //     countShow,
                          //     // applyFilterClicked,
                          //   })
                          // );
                          history.push({
                            pathname: result,
                            state: {
                              empId: row.empId,
                              managerId: null,
                              delegateeId: null,
                              fromDate: row.fromDate,
                              toDate: row.toDate,
                              projId: row.projId,
                              tab: "myPMOApproval",
                              compName: "DetailedView",
                            },
                          });
                        }}
                      >
                        {" "}
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <Typography
                              variant="body1"
                              style={{ fontSize: "0.625rem" }}
                            >
                              {row.empName}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body1"
                              style={{ fontSize: "0.563rem" }}
                            >
                              {row.empCode}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          // console.log("hi table row clicked! report");
                          const text = "/timesheetviewpage/:id";
                          const result = text.replace(":id", row.empId);

                          history.push({
                            pathname: result,
                            state: {
                              empId: row.empId,
                              managerId: null,
                              delegateeId: null,
                              fromDate: row.fromDate,
                              toDate: row.toDate,
                              projId: row.projId,
                              tab: "myPMOApproval",
                              compName: "DetailedView",
                            },
                          });
                        }}
                        style={{
                          padding: "0px 0px 0px 15px",
                          fontSize: "12px",
                          //   width: "25%",
                        }}
                      >
                        <Grid container direction="column" spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "0.625rem",
                              width: "100%",
                              paddingBottom:"4px",
                              paddingTop:"4px",
                              paddingRight:"6px",


                            }}
                          >
                            {row.projName}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ fontSize: "0.563rem",width:"70%",paddingBottom:"4px" }}
                          >
                            {row.projCode}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ fontSize: "0.563rem",width:"70%",paddingBottom:"4px" }}
                          >
                            {row.projGroup}
                          </Typography>
                        </Grid>
                      </TableCell>
                      {/* <TableCell
                        onClick={() => {
                          // console.log("hi table row clicked! report");
                          const text = "/timesheetviewpage/:id";
                          const result = text.replace(":id", row.empId);

                          history.push({
                            pathname: result,
                            state: {
                              empId: row.empId,
                              managerId: null,
                              delegateeId: null,
                              fromDate: row.fromDate,
                              toDate: row.toDate,
                              projId: row.projId,
                              tab: "myPMOApproval",
                              compName: "DetailedView",
                            },
                          });
                        }}
                        style={{ padding: "0px", fontSize: "12px" }}
                      >
                        {row.monthRange}
                      </TableCell> */}
                      <TableCell
                        onClick={() => {
                          // console.log("hi table row clicked! report");
                          const text = "/timesheetviewpage/:id";
                          const result = text.replace(":id", row.empId);

                          history.push({
                            pathname: result,
                            state: {
                              empId: row.empId,
                              managerId: null,
                              delegateeId: null,
                              fromDate: row.fromDate,
                              toDate: row.toDate,
                              projId: row.projId,
                              tab: "myPMOApproval",
                              compName: "DetailedView",
                            },
                          });
                        }}
                        style={{
                          padding: "0px 10px 0px 0px",
                          fontSize: "0.625rem",
                        }}
                      >
                        {" "}
                        {/* {"0 / 24"} */}
                        {/* <Grid item>
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "13px",
                              textAlign: "center",
                              marginRight: "35px",
                            }}
                          >
                            {row.billedHours}
                          </Typography>
                        </Grid> */}
                        <>
                          <div
                            style={{
                              fontWeight: 600,
                              paddingLeft: "29px",
                            }}
                          >
                            {row.billedHours}
                          </div>
                          <div
                            style={{
                              backgroundColor: "#E3E8F7",
                              width: "62px",
                              //padding: "2px",
                              height: "19px",
                              fontSize: "10px",
                              //paddingLeft: "22px",
                              padding: " 1px 2px 2px 2px",
                              height: " 15px",
                              fontSize: " 10px",
                            }}
                          >
                            {"Rec Hrs-"}
                            {row.recommandedBillabledHours}
                          </div>
                        </>
                      </TableCell>
                      {/* <TableCell
                        onClick={() => {
                          // console.log("hi table row clicked! report");
                          const text = "/timesheetviewpage/:id";
                          const result = text.replace(":id", row.empId);

                          history.push({
                            pathname: result,
                            state: {
                              empId: row.empId,
                              managerId: null,
                              delegateeId: null,
                              fromDate: row.fromDate,
                              toDate: row.toDate,
                              projId: row.projId,
                              tab: "myPMOApproval",
                              compName: "DetailedView",
                            },
                          });
                        }}
                        style={{
                          padding: "0px 30px 0px 0px",
                          fontSize: "12px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "12px",
                            textAlign: "center",
                            marginRight: "25px",
                          }}
                        >
                          {row.recommandedBillabledHours}
                        </Typography>
                      </TableCell> */}

                      <TableCell style={{ padding: "0px", fontSize: "12px" }}>
                        <NumericFormat
                          id={row.identifier}
                          //label="Hours"
                          variant="outlined"
                          name={"totalSubmittedHours"}
                          // defaultValue={row.totalSubmittedHours}
                          value={row.totalSubmittedHours}
                          style={{
                            borderRadius: "5px",
                            width: "60px",
                            borderRadius: "5px",
                            textAlign: "center",
                            border:
                              row.rejectBTFlag === 0 &&
                              row.billedHours !==
                                row.recommandedBillabledHours &&
                              row.btEnabledFlag == 1
                                ? "1px solid red"
                                : "1px solid #ccc",
                          }}
                          disabled={isHoursEnabled} // Disable editing if status is "approved"
                          customInput={TextField}
                          InputProps={{
                            inputProps: {
                              maxLength: 6,
                              min: 0,
                              step: 0.01, // Set step to 0.01 for two decimal places
                              onKeyDown: (event) => {
                                // Prevent entering negative values
                                if (event.key === "-" || event.key === "+") {
                                  event.preventDefault();
                                }
                              },
                              style: { padding: "6.5px 6px" },
                              // onKeyPress: preventInvalidInput,
                            },
                          }}
                          onClick={() => {
                            setRowData(row);
                          }}
                          onChange={(event) => {
                            // console.log("confusion four", event.target.value);
                            if (event.target.value === "") {
                              setCurrentRow({
                                ...currentRow,
                                [row.identifier]: 1,
                              });
                              setErrorMessage(1);
                            } else {
                              const obj = { ...currentRow };
                              delete obj[row.identifier];
                              // obj.filter(item=>item !== row.identifier)
                              setErrorMessage(0);
                              setCurrentRow(obj);
                            }
                            // console.log("currentRow>>>>", currentRow);
                            if (
                              row.rejectBTFlag === 0 &&
                              row.btEnabledFlag == 1
                            ) {
                              // Only handle click event if status is not "approved"
                              handleHours(
                                event.target.value,
                                "totalsubmittedhours",
                                row
                              );
                            }
                          }}
                        />
                        {currentRow[row.identifier] && (
                          <div
                            style={{
                              color: "red",
                              fontSize: "10px",
                              width: "108px",
                            }}
                          >
                            {"*Please enter a value."}
                          </div>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          position: "sticky",
                          right: 0,
                          background: "white",
                          zIndex: 800,
                          width: "53px",
                          padding: "0px",
                        }}
                        align="center"
                      >
                        <MoreVertIcon
                          className={classes.dotIcon}
                          onClick={(event) => {
                            handleClickAction(event, row);

                            setPopRow(row);
                          }}
                        />
                        <Menu
                          id="simple-menu"
                          key={index}
                          anchorEl={anchorE3}
                          className={classes.backdrop}
                          keepMounted
                          open={anchorE2 == row?.identifier}
                          onClose={handleClose}
                        >
                          <MenuItem>
                            {" "}
                            <Grid>
                              {row.rejectBTFlag === 0 ? (
                                <AddCommentIcon
                                  style={{
                                    color:
                                      row.btEnabledFlag !== 1
                                        ? "grey"
                                        : "#0B1941",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (row.btEnabledFlag == 1) {
                                      handleAddRemarkOpen(row);
                                    }
                                    handleClose();
                                  }}
                                />
                              ) : (
                                <AddCommentIcon
                                  style={{
                                    color: "grey",
                                    cursor: "not-allowed",
                                  }}
                                  onClick={() => {}}
                                />
                              )}
                            </Grid>
                          </MenuItem>
                          <MenuItem>
                            <Grid>
                              <CommentIcon
                                style={{
                                  color: "grey",
                                  cursor: "pointer",
                                  marginRight: "21px",
                                }}
                                onClick={() => {
                                  handleRemarkOpen(row, index);
                                  // handleClose();
                                }}
                              />
                              {tooltipIndex == index && (
                                <Tooltip
                                  key={index}
                                  open={remarkOpen}
                                  title={remarkText}
                                  onClose={handleRemarkClose}
                                  leaveDelay={2000}
                                  arrow
                                  placement="top-start"
                                >
                                  <div ref={remarkRef} />
                                </Tooltip>
                              )}
                            </Grid>
                          </MenuItem>
                          <MenuItem>
                            {" "}
                            {row.rejectBTFlag === 0 && (
                              <Grid>
                                <CheckCircleOutlinedIcon
                                  style={{
                                    color:
                                      row.btEnabledFlag !== 1
                                        ? "grey"
                                        : "green",
                                    cursor:
                                      Boolean(currentRow[row.identifier]) &&
                                      row.btEnabledFlag !== 1
                                        ? "not-allowed"
                                        : "pointer",
                                    // opacity: currentRow[row.identifier] ? 0.5 : 1
                                  }}
                                  // disabled={currentRow[row.identifier]?true:false}
                                  onClick={() => {
                                    if (
                                      !currentRow[row.identifier] &&
                                      row.btEnabledFlag == 1
                                    ) {
                                      // Only handle click if not disabled
                                      setCurrentRow([]);
                                      handleSingleApprove(
                                        row,
                                        "Approved",
                                        null,
                                        index
                                      );
                                    }
                                    handleClose();
                                  }}
                                />
                              </Grid>
                            )}
                            {row.rejectBTFlag === 1 && (
                              <Grid>
                                <CancelOutlinedIcon
                                  style={{
                                    color:
                                      row.rejectBTEnabledFlag !== 1 ||
                                      row.btEnabledFlag !== 1
                                        ? "grey"
                                        : "#e02020",
                                    // cursor: row.rejectBTEnabledFlag !== 1 ||
                                    // row.btEnabledFlag !== 1
                                    //   ? "not-allowed"
                                    //   : "pointer",
                                  }}
                                  onClick={
                                    () => {
                                      if (selected?.length > 0) {
                                        setSelected([]);
                                        setApproveRejectData([]);
                                      }
                                      if (
                                        row.rejectBTEnabledFlag == 1 &&
                                        row.btEnabledFlag == 1
                                      ) {
                                        handleApproveRejectDialog(
                                          index,
                                          "Rejected"
                                        );
                                      }
                                      handleClose();
                                    }

                                    // selected?.length>0
                                  }
                                />
                              </Grid>
                            )}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleAuditHistoryClick(row, index);
                              handleClose();
                            }}
                          >
                            <Grid>
                              <HistoryIcon
                                // onClick={() => {
                                //   // setAuditHistoryData([]);

                                //   const obj = {
                                //     invoicingId: row.invoicingRecId,
                                //   };
                                //   setIsLoader(true);
                                //   dispatch(getInvoicingAuditDetailsData(obj,setIsLoader));

                                //   handleOpenAuditHistDialog();
                                // }}

                                style={{
                                  cursor: "pointer",
                                  color: "#0B1941",
                                }}
                              />
                              {/* <AuditHistoryDialog
                              open={openAuditHistDialog}
                              handleClose={handleCloseAuditHistDialog}
                              auditHistoryData={auditHistoryData}
                            /> */}
                            </Grid>
                          </MenuItem>
                        </Menu>
                      </TableCell>

                      {/* <TableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {/* <span style={{ width: "60%" }}>{row.remark}</span> 
                        </div>
                      </TableCell> */}
                      {index == currentIndex ? (
                        <ApproveRejectByManager
                          loader={setIsLoader}
                          row={row}
                          handleApproveRejectByManager={
                            handleApproveRejectByManager
                          }
                          handleComment={handleComment}
                          //approveRejectStatus={approveRejectStatus}
                          firstAppRej={firstAppRej}
                          handleApproveRejectStatus={handleApproveRejectStatus}
                          // approveRejectComment={approveRejectComment}
                          showApproveRejectDialog={showApproveRejectDialog}
                          handleCloseApproveRejectDialog={
                            handleCloseApproveRejectDialog
                          }
                          // handleSnackbar={handleSnackbar}
                        />
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  );
                })
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {approvalListForInvoice?.length > 0 ? (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="caption"
              style={{
                paddingLeft: "8px",
              }}
            >
              <InfoOutlinedIcon />
            </Typography>

            <Typography
              variant="caption"
              style={{
                paddingLeft: "8px",
              }}
            >
              <b>Click on any row to view details</b>
            </Typography>
          </Box> */}
          <TablePagination
            rowsPerPageOptions={[25, 50, 75]}
            component="div"
            count={approvalListForInvoice[0]?.totalRecordCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Box>
      ) : (
        <></>
      )}

      {approvalListForInvoice?.length === 0 ? (
        <Grid>
          <Typography
            style={{
              fontSize: "20px",
              lineHeight: "200px",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            No Timesheet entry exists!
          </Typography>
        </Grid>
      ) : (
        <></>
      )}

      {/* Import Functionality BEGIN*/}
      <Dialog
        open={openImport}
        onClose={handleImportClose}
        aria-labelledby="responsive-dialog-title"
        //   onBlur={() => {setSelectedFileName(''); setParsedData(null);
        //   const fileInput = document.getElementById('file-input');
        //   fileInput.value = '';
        // }}
        BackdropProps={{ onClick: handleBackdropClick }}
      >
        <DialogTitle id="responsive-dialog-title" style={{ width: "400px" }}>
          <Box style={{ width: "100%", display: "inline-flex" }}>
            <Box style={{ width: "90%" }}>
              <span
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: 600,
                  marginLeft: "20px",
                }}
              >
                Import Client Submitted Hours
              </span>
            </Box>
            <Box style={{ width: "5%" }}>
              <CloseIcon
                onClick={() => {
                  handleImportClose();
                  setSelectedFileName("");
                  setParsedData(null);
                  const fileInput = document.getElementById("file-input");
                  fileInput.value = "";
                }}
                style={{
                  cursor: "pointer",
                  border: "2px solid #000",
                  borderRadius: "50%",
                  marginTop: "5px",
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent
          style={{
            height: "350px",
            width: "400px",
            textAlign: "-webkit-center",
          }}
        >
          <Box sx={{ height: "250px" }}>
            <IconButton
              aria-label="Select File"
              style={{
                minHeight: "70px",
                minWidth: "70px",
                marginTop: "20px",
                fontWeight: 600,
                cursor: "pointer",
                color: "#9E9E9E",
              }}
              onClick={() => {
                document.getElementById("file-input").click(); // Trigger file input click
              }}
            >
              <ImportIcon style={{ minHeight: "70px", minWidth: "70px" }} />
            </IconButton>
            <Typography
              style={{ fontSize: "14px", color: "#9E9E9E", fontWeight: 500 }}
            >
              Select a file to be imported
            </Typography>
            {selectedFileName && (
              <Chip
                label={selectedFileName}
                onDelete={() => {
                  setSelectedFileName("");
                  setParsedData(null);
                  const fileInput = document.getElementById("file-input");
                  fileInput.value = "";
                }}
                style={{ marginTop: "10px", width: "190px" }}
              />
            )}
            <p className="drop-file-input-label-validationText">
              <Typography style={{ fontSize: "12px" }}>
                <strong>Only XLS and XLSX allowed.</strong>
              </Typography>
            </p>
          </Box>
          <Button
            variant="contained"
            component="label"
            disabled={isImportedData ? false : true}
            color="primary"
            style={{
              width: "120px",
              textTransform: "none",
              backgroundColor: !isImportedData ? "#9E9E9E" : "#043365",
            }}
            // onClick={handleFinalImport}
          >
            <Typography
              className={classes.buttonText}
              style={{ textTransform: "none" }}
            >
              Import File
            </Typography>
          </Button>
          <input
            id="file-input"
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
          {/* <Backdrop
                    sx={{ color: "#092FED", zIndex: 999 }}
                    open={isLoader}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop> */}
        </DialogContent>
      </Dialog>
      {/* Import Functionality END */}
      <Dialog
        open={addRemarkOpen}
        onClose={handleAddRemarkClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" style={{ width: "260px" }}>
          <Box style={{ width: "100%", display: "inline-flex" }}>
            <Box
              style={{
                // display: "flex",
                width: "90%",
                // justifyContent: "left",
                fontSize: "21px",
                // fontWeight: 500,
                fontFamily: "Montserrat",
                // fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                textAlign: "left",
                // letterSpacing: "0.3px",
              }}
            >
              Add Remark
            </Box>
            <Box style={{ width: "9%" }}>
              <CloseIcon
                onClick={() => {
                  handleAddRemarkClose();
                }}
                style={{
                  cursor: "pointer",
                  border: "2px solid #000",
                  borderRadius: "50%",
                  marginTop: "0px",
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        {/* <Divider /> */}
        <DialogContent
          style={{
            height: "200px",
            width: "260px",
            textAlign: "-webkit-center",
          }}
        >
          <Box sx={{ height: "138px" }}>
            <TextField
              style={{ marginTop: "0px" }}
              label={
                <span
                  style={{
                    color: "#000",
                    fontWeight: "200",
                    fontFamily: "Montserrat",
                  }}
                >
                  Remark*
                </span>
              }
              name="remark"
              // value={
              //   approveRejectData.filter((item) => {
              //     item.recid == rowData.invoicingRecId;
              //   }).remark
              // }
              // style={{height:"100px"}}
              value={
                approveRejectData.find(
                  (item) => item.identifier === rowData.identifier
                )?.remark || ""
              }
              onChange={(event) =>
                handleAddRemark(event.target.value, "remark")
              }
              variant="outlined"
              fullWidth
              margin="normal"
              multiline // Enable multiline input
              rows={3} // Set the number of rows
              // maxLength={50}
              inputProps={{ maxLength: 100 }}
              InputProps={{
                // inputProps: {
                //   style: { height: "80px" },
                //   maxLength: 50,
                // },
                style: {
                  height: "141px",
                  paddingLeft: "4px",
                  fontSize: "14px",
                },
              }}
            />
          </Box>
        </DialogContent>
        <Box style={{ textAlign: "center", marginBottom: "30px" }}>
          <Button
            variant="contained"
            component="label"
            color="primary"
            style={{ backgroundColor: "#0B1941" }}
            onClick={handleAddRemarkClose}
          >
            <Typography
              className={classes.buttonText}
              style={{ textTransform: "none" }}
            >
              Add Remark
            </Typography>
          </Button>
        </Box>
      </Dialog>
      <Snackbar
        open={importSnackbarOpen}
        onClose={() => handleimportSnackbarClose(false)}
      >
        <Alert
          onClose={() => handleimportSnackbarClose(false)}
          severity="success"
          sx={{ width: "100%", backgroundColor: "green", color: "white" }}
        >
          Client submitted hours imported successfully.
        </Alert>
      </Snackbar>
      <div>
        <Backdrop
          sx={{ color: "#092FED", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <AuditHistoryDialog
        open={openAuditHistDialog}
        handleClose={handleCloseAuditHistDialog}
        auditHistoryData={auditHistoryData}
      />
      <Snackbar
        open={statusSnackbarOpen}
        onClose={() => handleStatusSnackClose(false)}
      >
        <Alert
          onClose={() => handleStatusSnackClose(false)}
          severity="error"
          sx={{ width: "100%", backgroundColor: "red", color: "white" }}
        >
          There is some problem with the application. Please connect with System
          Admin.
        </Alert>
      </Snackbar>
    </div>
  );
}
