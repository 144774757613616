import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { PinDropSharp } from "@material-ui/icons";
import { Typography, Box } from "@material-ui/core";
// import green from "@material-ui/core/colors/green";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#0B1941",
    color: "white",
    "&$checked": {
      background: "#0B1941",
      color: "white",
    },
  },
  // formControl: {
  //   margin: theme.spacing(1),

  //   width: "100%",
  // },
  formControl: {
    margin: "16px 0 8px 0",
    minWidth: "100%",
    // backgroundColor: "#FCFDFF",
    borderRadius: 4,
    notchedOutline: {
      borderColor: "#CB444A",
      borderRadius: 4,
    },

    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
      ["@media (max-width:600px)"]: { fontSize: "13px"}
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
        ["@media (max-width:600px)"]: { fontSize: "13px"}
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
      },
    },
  },
  formControl12: {
    margin: "16px 0 8px 0",
    minWidth: "100%",

    borderRadius: 4,
    notchedOutline: {
      borderColor: "red",
      borderRadius: 4,
    },

    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
      },
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  avatar: {
    maxWidth: theme.spacing(3),
    maxHeight: theme.spacing(3),
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  shortName: {
    borderRadius: "45px",
    background: "rgba(79, 163, 125, 0.2)",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    width: "29px",
    height: "23px",
    textAlign: "center",
    color: "#FFFFFF",
    marginLeft: "6px",
  },
  description: {
    marginLeft: "8px",
  },
  fullName: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    color: "#000000",
  },
  designation: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    color: "#424242",
  },
  checkbox: {
    "&$checked": {
      color: "green",
    },
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AttendeesBox({
  AttendeesList,
  handleAttendees,
  ...props
}) {
  const [personName, setPersonName] = React.useState([]);
  const classes = useStyles();
  const [dropdownOptions, setdropdownOptions] = React.useState(AttendeesList);
  const [firstIteration, setFirstIteration] = React.useState(0);
  const [projIdOnlyArray, setProjIdOnlyArray] = useState([]);
  useEffect(() => {
    if (props.Edit && props?.myExpenseList?.EmployeeDetails?.length > 0) {
      const dummyArray = [];
      props.myExpenseList?.EmployeeDetails?.forEach((element) => {
        const ele = AttendeesList.find((x) => x.empId === element?.EmpId);
        if (ele) {
          dummyArray.push(ele);
        }
      });

      setPersonName(dummyArray);
    }

    setdropdownOptions(AttendeesList);
  }, [props.myExpenseList]);
  const handleProjectChange = () => {
    let filteredData = [];
    let empIds = personName.map((s) => s.empId);

    filteredData = AttendeesList.filter((s) => !empIds.includes(s.empId));


    setdropdownOptions(filteredData);
    // setPersonName(value);
  };
  const handleChange = (event, value) => {

    let filteredData = [];
    let empIds = value.map((s) => s.empId);

    filteredData = AttendeesList.filter((s) => !empIds.includes(s.empId));

    setdropdownOptions(filteredData);
    setPersonName(value);
  };

  useEffect(() => {
    handleProjectChange();

  }, [AttendeesList]);
  useEffect(() => {

    handleAttendees(personName);
  }, [personName]);


  useEffect(() => {
   
    let tempProjectOnlyArray1 = [];
    props.selectedProjects.map((p) => {
      tempProjectOnlyArray1.push(p.projectName.projId);
    });
    if (tempProjectOnlyArray1.includes("")) {
      tempProjectOnlyArray1.pop();
    }

    let tempProjectOnlyArray2 = [];
    projIdOnlyArray.map((p) => {
      tempProjectOnlyArray2.push(p.projectName.projId);
    });
    if (tempProjectOnlyArray2.includes("")) {
      tempProjectOnlyArray2.pop();
    }
 
    const objectsEqual = (o1, o2) =>
      typeof o1 === "object" && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length &&
          Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
        : o1 === o2;
    const arraysEqual = (a1, a2) =>
      a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

  
    let arrayAreEqual = arraysEqual(
      tempProjectOnlyArray1,
      tempProjectOnlyArray2
    );
    if (props.Edit) {
      if (firstIteration > 0 && arrayAreEqual == false) {
   
        setPersonName([]);
      }
      setFirstIteration(firstIteration + 1);
    } else if (arrayAreEqual == false) {

      setPersonName([]);
    }
    setProjIdOnlyArray(props.selectedProjects);
  }, [props.selectedProjects]);

  const handleTags = () => {


    return (
      <div className={classes.chips}>
        {personName.map((value) => {
          return (
            <>
              <Chip
                avatar={
                  <Avatar
                    style={{
                      backgroundColor: value.color,
                      color: "white",
                    }}
                  >
                    {handleName(value.empName)}
                  </Avatar>
                }
                key={value}
                label={value.empName}
                className={classes.chip}
                clickable
                onDelete={() => {
                  handleDelete(value);
                }}
                deleteIcon={<CloseIcon />}
              />
            </>
          );
        })}
      </div>
    );
  };

  const handleName = (value) => {
    if (value) {
      const initArray = value.split(" ");
      let initial;
      if (initArray.length === 1) {
        initial = initArray[0][0];
      } else {
        initial = initArray[0][0] + initArray[initArray.length - 1].slice(0, 1);
      }

      return initial.toUpperCase();
    }
  };

  const handleDelete = (name) => {

    const existingToggles = [...personName];
    const users = existingToggles.filter((value) => value.empId !== name.empId);
    const empIds = users.map((s) => s.empId);

    const filteredData = AttendeesList.filter((s) => !empIds.includes(s.empId));


    setdropdownOptions(filteredData);
    setPersonName(users);
  };
  const handleCheck = (select, option) => {



    const a = personName?.indexOf(option.empName) > -1;
    return a;
  };

  const handleList = (option) => {

    return (
      <>
        <Box style={{ display: "flex", marginTop: "16px" }}>
          <Avatar
            className={classes.avatar}
            style={{
              backgroundColor: option.color,
              color: "white",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              {handleName(option.empName)}
            </Typography>
          </Avatar>
          <div className={classes.description}>
            <Typography component="div" className={classes.fullName}>
              {option.empName}
            </Typography>
            <Typography component="div" className={classes.designation}>
              {option.empCode} | {option.empDesignation} | RM :{" "}
              {option.reportingManager} | {option.horizontal}
            </Typography>
          </div>
        </Box>
      </>
    );
  };

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={dropdownOptions}
      className={
        props.attendeesError != "" ? classes.formControl12 : classes.formControl
      }
      // options={AttendeesList}
      value={personName}
      disableCloseOnSelect
      onChange={handleChange}
      getOptionLabel={(option) => option.empName}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            checkedIcon={
              <CheckBoxIcon fontSize="small" className={classes.root} />
            }
            checked={selected}
          />
          {handleList(option)}
        </React.Fragment>
      )}
      renderTags={(value) => handleTags(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Attendees"
          style={{ backgroundColor: "white" }}
          placeholder="Search Attendees"
        />
      )}
    />
  );
}
