//Accesstype actionType
export const GET_ACCESSTYPE_LIST = "GET_ACCESSTYPE_LIST";
export const GET_ACCESSTYPE_LIST_SUCCESS = "GET_ACCESSTYPE_LIST_SUCCESS";
export const GET_ACCESSTYPE_LIST_FAILED = "GET_ACCESSTYPE_LIST_FAILED";

export const GET_PURPOSE_LIST = "GET_PURPOSE_LIST";
export const GET_PURPOSE_LIST_SUCCESS = "GET_PURPOSE_LIST_SUCCESS";
export const GET_PURPOSE_LIST_FAILED = "GET_PURPOSE_LIST_FAILED";

// Category ActionType
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export const GET_CATEGORY_LIST_FAILED = "GET_CATEGORY_LIST_FAILED";

// Currency ActionType
export const GET_CURRENCY_LIST = "GET_CURRENCY_LIST";
export const GET_CURRENCY_LIST_SUCCESS = "GET_CURRENCY_LIST_SUCCESS";
export const GET_CURRENCY_LIST_FAILED = "GET_CURRENCY_LIST_FAILED";

//Employee Projects Action Types
export const GET_EMPLOYEE_PROJECTS_LIST = "GET_EMPLOYEE_PROJECTS_LIST";
export const GET_EMPLOYEE_PROJECTS_LIST_SUCCESS =
  "GET_EMPLOYEE_PROJECTS_LIST_SUCCESS";
export const GET_EMPLOYEE_PROJECTS_LIST_FAILED =
  "GET_EMPLOYEE_PROJECTS_LIST_FAILED";

// dependents ActionType
export const GET_DEPENDENTS_LIST = "GET_DEPENDENTS_LIST";
export const GET_DEPENDENTS_LIST_SUCCESS = "GET_DEPENDENTS_LIST_SUCCESS";
export const GET_DEPENDENTS_LIST_FAILED = "GET_DEPENDENTS_LIST_FAILED";

// Attendees ActionType
export const GET_ATTENDEES_LIST = "GET_ATTENDEES_LIST";
export const GET_ATTENDEES_LIST_SUCCESS = "GET_ATTENDEES_LIST_SUCCESS";
export const GET_ATTENDEES_LIST_FAILED = "GET_ATTENDEES_LIST_FAILED";

// Validate Attendees ActionType
export const GET_VALIDATE_ATTENDEES_LIST = "GET_VALIDATE_ATTENDEES_LIST";
export const GET_VALIDATE_ATTENDEES_LIST_SUCCESS =
  "GET_VALIDATE_ATTENDEES_LIST_SUCCESS";
export const GET_VALIDATE_ATTENDEES_LIST_FAILED =
  "GET_VALIDATE_ATTENDEES_LIST_FAILED";

// Payment ActionType
export const GET_PAYMENT_LIST = "GET_PAYMENT_LIST";
export const GET_PAYMENT_LIST_SUCCESS = "GET_PAYMENT_LIST_SUCCESS";
export const GET_PAYMENT_LIST_FAILED = "GET_PAYMENT_LIST_FAILED";

// Post CreateExpense ActionType
export const POST_CREATE_EXPENSE = "POST_CREATE_EXPENSE";
export const POST_CREATE_EXPENSE_SUCCESS = "POST_CREATE_EXPENSE_SUCCESS";
export const POST_CREATE_EXPENSE_FAILED = "POST_CREATE_EXPENSE_FAILED";

// Get My Expense ActionType
export const GET_MY_EXPENSE = "GET_MY_EXPENSE";
export const GET_MY_EXPENSE_SUCCESS = "GET_MY_EXPENSE_SUCCESS";
export const GET_MY_EXPENSE_FAILED = "GET_MY_EXPENSE_FAILED";

// Get My Team Approval ActionType
export const GET_MY_TEAM_APPROVAL = "GET_MY_TEAM_APPROVAL";
export const GET_MY_TEAM_APPROVAL_SUCCESS = "GET_MY_TEAM_APPROVAL_SUCCESS";
export const GET_MY_TEAM_APPROVAL_FAILED = "GET_MY_TEAM_APPROVAL_FAILED";

// Get My Expense Data With Travel
export const GET_MY_EXPENSE_TRAVEL = "GET_MY_EXPENSE_TRAVEL";
export const GET_MY_EXPENSE_SUCCESS_TRAVEL = "GET_MY_EXPENSE_SUCCESS_TRAVEL";
export const GET_MY_EXPENSE_FAILED_TRAVEL = "GET_MY_EXPENSE_FAILED_TRAVEL";

// DELETE My Expense ActionType
export const DELETE_MY_EXPENSE = "DELETE_MY_EXPENSE";
export const DELETE_MY_EXPENSE_SUCCESS = "DELETE_MY_EXPENSE_SUCCESS";
export const DELETE_MY_EXPENSE_FAILED = "DELETE_MY_EXPENSE_FAILED";

// POST Download File ActionType
export const POST_DOWNLOAD = "POST_DOWNLOAD";
export const POST_DOWNLOAD_SUCCESS = "POST_DOWNLOAD_SUCCESS";
export const POST_DOWNLOAD_FAILED = "POST_DOWNLOAD_FAILED";

// GET MyExpenseReportData ActionType
export const MY_EXPENSE_REPORT_DATA = "MY_EXPENSE_REPORT_DATA";
export const MY_EXPENSE_REPORT_DATA_SUCCESS = "MY_EXPENSE_REPORT_DATA_SUCCESS";
export const MY_EXPENSE_REPORT_DATA_FAILED = "MY_EXPENSE_REPORT_DATA_FAILED";

// Post Approve Expense ActionType
export const POST_APPROVE_EXPENSE = "POST_APPROVE_EXPENSE";
export const POST_APPROVE_EXPENSE_SUCCESS = "POST_APPROVE_EXPENSE_SUCCESS";
export const POST_APPROVE_EXPENSE_FAILED = "POST_APPROVE_EXPENSE_FAILED";

// GET MyViewExpenseData ActionType
export const MY_VIEW_EXPENSE_DATA = "MY_VIEW_EXPENSE_DATA";
export const MY_VIEW_EXPENSE_DATA_SUCCESS = "MY_VIEW_EXPENSE_DATA_SUCCESS";
export const MY_VIEW_EXPENSE_DATA_FAILED = "MY_VIEW_EXPENSE_DATA_FAILED";

//GET MyTeamApprovalPageFlag ActionType
export const GET_MY_TEAM_APPROVAL_PAGE_FLAG = "GET_MY_TEAM_APPROVAL_PAGE_FLAG";
export const GET_MY_TEAM_APPROVAL_PAGE_FLAG_SUCCESS =
  "GET_MY_TEAM_APPROVAL_PAGE_FLAG_SUCCESS";
export const GET_MY_TEAM_APPROVAL_PAGE_FLAG_FAILED =
  "GET_MY_TEAM_APPROVAL_PAGE_FLAG_FAILED";

// Post CreateExpense ActionType
export const POST_EDIT_EXPENSE = "POST_EDIT_EXPENSE";
export const POST_EDIT_EXPENSE_SUCCESS = "POST_EDIT_EXPENSE_SUCCESS";
export const POST_EDIT_EXPENSE_FAILED = "POST_EDIT_EXPENSE_FAILED";

// Get Finance Approval List ActionType
export const GET_FINANCE_APPROVAL_LIST = "GET_FINANCE_APPROVAL_LIST";
export const GET_FINANCE_APPROVAL_LIST_SUCCESS =
  "GET_FINANCE_APPROVAL_LIST_SUCCESS";
export const GET_FINANCE_APPROVAL_LIST_FAILED =
  "GET_FINANCE_APPROVAL_LIST_FAILED";

// Post Financial Approve / Reject Expense ActionType
export const POST_FINANCIAL_APPROVE_EXPENSE = "POST_FINANCIAL_APPROVE_EXPENSE";
export const POST_FINANCIAL_APPROVE_EXPENSE_SUCCESS =
  "POST_FINANCIAL_APPROVE_EXPENSE_SUCCESS";
export const POST_FINANCIAL_APPROVE_EXPENSE_FAILED =
  "POST_FINANCIAL_APPROVE_EXPENSE_FAILED";

// RESET EXPENSE RESPONSE
export const RESET_EXPENSE_RESPONSE = "RESET_EXPENSE_RESPONSE";

// GET GetDownloadExpenseReport
export const GET_DOWNLOAD_ALL_EXPENSES_REPORT =
  "GET_DOWNLOAD_ALL_EXPENSES_REPORT";
export const GET_DOWNLOAD_ALL_EXPENSES_REPORT_SUCCESS =
  "GET_DOWNLOAD_ALL_EXPENSES_REPORT_SUCCESS";
export const GET_DOWNLOAD_ALL_EXPENSES_REPORT_FAILED =
  "GET_DOWNLOAD_ALL_EXPENSES_REPORT_FAILED";
// GET GetDownloadExpenseReport pagewise
export const GET_DOWNLOAD_ALL_EXPENSES_PAGEWISE =
  "GET_DOWNLOAD_ALL_EXPENSES_PAGEWISE";
export const GET_DOWNLOAD_ALL_EXPENSES_PAGEWISE_SUCCESS =
  "GET_DOWNLOAD_ALL_EXPENSES_PAGEWISE_SUCCESS";
export const GET_DOWNLOAD_ALL_EXPENSES_PAGEWISE_FAILED =
  "GET_DOWNLOAD_ALL_EXPENSES_PAGEWISE_FAILED";
// GET Deligation ActionType
export const GET_DELEGATION_LIST = "GET_DELEGATION_LIST";
export const GET_DELEGATION_LIST_SUCCESS = "GET_DELEGATION_LIST_SUCCESS";
export const GET_DELEGATION_LIST_FAILED = "GET_DELEGATION_LIST_FAILED";

// GET DeligationDropeDown ActionType
export const GET_DELIGATION_DROPEDOWN_LIST = "GET_DELIGATION_DROPEDOWN_LIST";
export const GET_DELIGATION_DROPEDOWN_LIST_SUCCESS =
  "GET_DELIGATION_DROPEDOWN_LIST_SUCCESS";
export const GET_DELIGATION_DROPEDOWN_LIST_FAILED =
  "GET_DELIGATION_DROPEDOWN_LIST_FAILED";

// GET EmployeeDropeDown ActionType
export const GET_EMPLOYEE_DROPEDOWN_LIST = "GET_EMPLOYEE_DROPEDOWN_LIST";
export const GET_EMPLOYEE_DROPEDOWN_LIST_SUCCESS =
  "GET_EMPLOYEE_DROPEDOWN_LIST_SUCCESS";
export const GET_EMPLOYEE_DROPEDOWN_LIST_FAILED =
  "GET_EMPLOYEE_DROPEDOWN_LIST_FAILED";

// POST Deligate Save ActionType
export const POST_DELIGATE_SAVE_LIST = "POST_DELIGATE_SAVE_LIST";
export const POST_DELIGATE_SAVE_LIST_SUCCESS =
  "POST_DELIGATE_SAVE_LIST_SUCCESS";
export const POST_DELIGATE_SAVE_LIST_FAILED = "POST_DELIGATE_SAVE_LIST_FAILED";

// POST Deligate Update ActionType
export const POST_DELIGATE_UPDATE_LIST = "POST_DELIGATE_UPDATE_LIST";
export const POST_DELIGATE_UPDATE_LIST_SUCCESS =
  "POST_DELIGATE_UPDATE_LIST_SUCCESS";
export const POST_DELIGATE_UPDATE_LIST_FAILED =
  "POST_DELIGATE_UPDATE_LIST_FAILED";

// RESET DeligationType
export const RESET_DELIGATION = "RESET_DELIGATION";

// GET UserDelegatees ActionType
export const MY_DELEGATEES_DATA = "MY_DELEGATEES_DATA";
export const MY_DELEGATEES_DATA_SUCCESS = "MY_DELEGATEES_DATA_SUCCESS";
export const MY_DELEGATEES_DATA_FAILED = "MY_DELEGATEES_DATA_FAILED";

//SSO EMPlOYEE ID
export const UPDATED_SSO_EMP_ID = "UPDATED_SSO_EMP_ID";

//UPDATE DELEGATEE EMPLOYEE ID
export const UPDATE_DELEGATEE_EMP_ID = "UPDATE_DELEGATEE_EMP_ID";

// GET EmpDetails ActionType
export const GET_EMP_DETAILS_DATA = "GET_EMP_DETAILS_DATA";
export const GET_EMP_DETAILS_DATA_SUCCESS = "GET_EMP_DETAILS_DATA_SUCCESS";
export const GET_EMP_DETAILS_DATA_FAILED = "GET_EMP_DETAILS_DATA_FAILED";

// GET GetDelegationFlag ActionType
export const GET_DELEGATION_FLAG = "GET_DELEGATION_FLAG";
export const GET_DELEGATION_FLAG_SUCCESS = "GET_DELEGATION_FLAG_SUCCESS";
export const GET_DELEGATION_FLAG_FAILED = "GET_DELEGATION_FLAG_FAILED";

//RELOAD MODULE TOGGLE Action type
export const RELOAD_MODULE_TOGGLE = "RELOAD_MODULE_TOGGLE";

//MAINTAIN STATE
export const MAINTAIN_STATE_VARIABLE = "MAINTAIN_STATE_VARIABLE";

//FROM OTHER MODULE TOGGLE
export const SET_FROM_OTHER_MODULE_TOGGLE = "SET_FROM_OTHER_MODULE_TOGGLE";

//RESET FROM OTHER MODULE TOGGLE
export const RESET_FROM_OTHER_MODULE_TOGGLE = "RESET_FROM_OTHER_MODULE_TOGGLE";

//POST UPLOAD ATTACHMENTS
export const POST_UPLOAD_ATTACHMENTS = "POST_UPLOAD_ATTACHMENTS";
export const POST_UPLOAD_ATTACHMENTS_SUCCESS =
  "POST_UPLOAD_ATTACHMENTS_SUCCESS";
export const POST_UPLOAD_ATTACHMENTS_FAILED = "POST_UPLOAD_ATTACHMENTS_FAILED";

//GET AUDIT HISTORY
export const GET_AUDIT_HISTORY = "GET_AUDIT_HISTORY";
export const GET_AUDIT_HISTORY_SUCCESS = "GET_AUDIT_HISTORY_SUCCESS";
export const GET_AUDIT_HISTORY_FAILED = "GET_AUDIT_HISTORY_FAILED";
//RESET FROM OTHER MODULE TOGGLE
export const RESET_AUDIT_HISTORY_DATA = "RESET_AUDIT_HISTORY_DATA";

//GET AUDIT HISTORY
export const GET_EXPENSE_REPORT_COUNT = "GET_EXPENSE_REPORT_COUNT";
export const GET_EXPENSE_REPORT_COUNT_SUCCESS =
  "GET_EXPENSE_REPORT_COUNT_SUCCESS";
export const GET_EXPENSE_REPORT_COUNT_FAILED =
  "GET_EXPENSE_REPORT_COUNT_FAILED";

//SET FULL FINAL ALL EXPENSE DATA
export const SET_FULL_FINAL_ALL_EXPENSE_DATA =
  "SET_FULL_FINAL_ALL_EXPENSE_DATA";

//GET BILL NUMBER EXISTENCE
export const GET_BILLNUMBER_EXISTENCE="GET_BILLNUMBER_EXISTENCE";
export const GET_BILLNUMBER_EXISTENCE_SUCCESS="GET_BILLNUMBER_EXISTENCE_SUCCESS";
export const GET_BILLNUMBER_EXISTENCE_FAILURE="GET_BILLNUMBER_EXISTENCE_FAILURE";

export const GET_ALL_EXPENSES_REPORT =
  "GET_ALL_EXPENSES_REPORT";
export const GET_ALL_EXPENSES_REPORT_SUCCESS =
  "GET_ALL_EXPENSES_REPORT_SUCCESS";
export const GET_ALL_EXPENSES_REPORT_FAILED =
  "GET_ALL_EXPENSES_REPORT_FAILED";

  export const GET_ALL_EXPENSES_REPORT_DOWNLOAD =
  "GET_ALL_EXPENSES_REPORT_DOWNLOAD";
export const GET_ALL_EXPENSES_REPORT_DOWNLOAD_SUCCESS =
  "GET_ALL_EXPENSES_REPORT_DOWNLOAD_SUCCESS";
export const GET_ALL_EXPENSES_REPORT_DOWNLOAD_FAILED =
  "GET_ALL_EXPENSES_REPORT_DOWNLOAD_FAILED";

  export const GET_ALL_EXPENSES_REPORT_DROPDOWN =
  "GET_ALL_EXPENSES_REPORT_DROPDOWN";
export const GET_ALL_EXPENSES_REPORT_DROPDOWN_SUCCESS =
  "GET_ALL_EXPENSES_REPORT__DOWNLOAD_SUCCESS";
export const GET_ALL_EXPENSES_REPORT_DROPDOWN_FAILED =
  "GET_ALL_EXPENSES_REPORT__DOWNLOAD_FAILED";

export const SET_EXPENSE_CHIPS = 'SET_EXPENSE_CHIPS';

export const GET_SIGNED_URL = "GET_SIGNED_URL";
export const GET_SIGNED_URL_SUCCESS = "GET_SIGNED_URL_SUCCESS";
export const GET_SIGNED_URL_FAILED = "GET_SIGNED_URL_FAILED";

export const DELETE_FILE_UPLOAD = "DELETE_FILE_UPLOAD";
export const DELETE_FILE_UPLOAD_SUCCESS = "DELETE_FILE_UPLOAD_SUCCESS";
export const DELETE_FILE_UPLOAD_FAILED = "DELETE_FILE_UPLOAD_FAILED";