import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, loginApiRequest } from "../src/Utils/authConfig";
import axios from "axios";
import config from "../src/Utils/config";
import Encrypt from "./Utils/encryption";
import Decrypt from "./Utils/decryption";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);
//console.log("msalInstance", msalInstance);
axios.defaults.baseURL = config.apiUrl;
axios.interceptors.request.use(
  async (response) => {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;

    const accessToken = Decrypt(key, localStorage.getItem("token"));
    var email = "";

    //checking if accessToken exists
    if (accessToken) {
      //console.log("token", accessToken);
      // response.headers.Authorization = `Bearer ${accessToken}`;
      response.headers["X-Bearer-Token"] = `${accessToken}`;
    } else {
      const account = msalInstance.getAllAccounts()[0];
      const msalResponse = await msalInstance.acquireTokenSilent({
        ...loginApiRequest,
        account: account,
      });
      //console.log("msalResponse", msalResponse);
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      localStorage.setItem(
        "token",
        Encrypt(key, JSON.stringify(msalResponse.accessToken))
      );
      // response.headers.Authorization = `Bearer ${msalResponse.accessToken}`;
      response.headers["X-Bearer-Token"] = `${msalResponse.accessToken}`;
      email = account.username;
      //console.log("email,email", email);
    }
    //console.log("response", response);
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// axios.interceptors.response.use(
//   function (response) {
//     console.log("reponse ",response);
//     return response;
//   },
//   async function (error) {
//     if(error.response.status === 401){
//       // await logoutAuth()
//       window.location.reload();
//       // history.push('/login')
//     }
//     return Promise.reject(error);
//   }
// );

ReactDOM.render(
  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </BrowserRouter>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
