import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Box,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import Encrypt from "../Utils/encryption.js";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  getMyTeamApprovalData,
  postApproveRejectExpenseData,
  resetExpenseResponse,
  postFinancialApproveRejectExpenseData,
} from "../redux/Expense/action";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  mobileResH: {
    width: "15.875rem",
  },
  mobileRes: {
    width: "18.875rem",
  },
  mobileText: {
    width: "14.875rem",
  },
  normalText: {
    width: "25rem",
  },
}));

const ApproveRejectDialog = (props) => {
  const [rejectionComments, setRejectionComments] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:600px)");
  const [comments, setComments] = React.useState("");
  const { bulkDownload } = props;
  const history = useHistory();
  const dataValue = { comments };
  const [appValue, setAppValue] = React.useState(false);
  const classes = useStyles();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const handleComments = (data) => {
    setComments(data);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function handleSingleExpense() {

    props.handleSingleApproveRejectExpense(props.singleExpense);
    setRejectionComments("");
    props.handleCloseBulkApproveDialog();
  }
  const handleRejectionComments = (value) => {
    setRejectionComments(value);
    props.handleComments(value);
    handleComments(value);
  };

  useEffect(() => {
    rejectionComments != "" ? setBtnDisabled(false) : setBtnDisabled(true);
  }, [rejectionComments]);

  return (
    <div>
      <Dialog
        open={props.openBulkApproveDialog}
        onClose={props.handleCloseBulkApproveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.approveOrReject == 1 ? (
            <Typography
              style={{
                display: "flex",
                // alignItems: "center",
                justifyContent: "left",
                fontSize: "22px",
                fontWeight: 500,
              }}
            >
              Approve Expense
            </Typography>
          ) : (
            <Typography
              style={{
                display: "flex",

                justifyContent: "left",
                fontSize: "22px",
                fontWeight: 500,
                // paddingRight:"136px"
              }}
            >
              Reject Expense
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {matches ? "Are you sure you want to " : "You are about to"}{" "}
            {props.bulkDownload?.length > 1 ? "bulk" : ""}{" "}
            {props.approveOrReject == 1 ? "approve" : "reject"} the{" "}
            {props.viewDialog == true ? "following" : ""}
            expenses.
       
            {((props.approveOrReject == 1 && props.bulkDownload?.length < 1) ||
              (props.approveOrReject == 0 && props.bulkDownload?.length < 1)) &&
            props.bulkDownload?.length > 0 ? (
              props.bulkDownload?.map((item) => (
                <div>
            
                  Expense ID:{" "}
                  {props.finAppToggle == true ? item.expId : item.expCode},
                  Project ID:{" "}
                  {props.finAppToggle == true
                    ? item.projdetails.map((item) => `${item.projectId}, `)
                    : item.expCode}
                </div>
              ))
            ) : props.viewDialog == true ? (
              <div>
               
                Expense ID: {props.singleExpense?.expCode}, Project ID:{" "}
                {props.singleExpense?.projectId}
              </div>
            ) : (
              ""
            )}
          </DialogContentText>
          {props.approveOrReject == 0 || props.viewFinApproval == true ? (
            <Box>
              <TextField
                margin="dense"
                id="comments"
                label={matches ? "Add Comments" : "Comments*"}
                variant="outlined"
                multiline
                inputProps={{ maxLength: matches ? 100 : 500 }}
                className={
                  window.screen.availWidth > 400
                    ? classes.mobileResH
                    : window.screen.availWidth > 400 &&
                      window.screen.availWidth < 600
                    ? classes.mobileRes
                    : matches
                    ? classes.mobileText
                    : classes.normalText
                }
                value={rejectionComments}
                row={3}
                fullWidth
                onChange={(e) => {
                  handleRejectionComments(e.target.value);
                }}
              />
            </Box>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            style={{
              minWidth: "120px",
              minHeight: "36px",

              backgroundColor: "#FFFFFF",
              color: "#0B1941",
            }}
            onClick={props.handleCloseBulkApproveDialog}
            disabled={isBtnDisabled}
          >
            Cancel
          </Button>
          {props.approveOrReject == 1 ? (
            <Button
              variant="outlined"
              style={{
                minWidth: "120px",
                minHeight: "36px",

                backgroundColor: isBtnDisabled ? "#9E9E9E" : "#0B1941",
                color: "#FFFFFF",
                marginRight: "10px",
              }}
              onClick={() => {
                // matches && location?.state?.compName == "teamApprovalListing" ? () => {handleBulkApproveResult()} :
                // matches && location?.state?.compName == "financeApprovalListing" ? () => {handleSingleApproveRejectExpense('Approved')} :
                setIsBtnDisabled(true);
                props.bulkDownload?.length > 0
                  ? props.handleBulkApprove()
                  : handleSingleExpense();
              }}
              disabled={
                (props.viewFinApproval == true ? btnDisabled : false) ||
                isBtnDisabled
              }
              autoFocus
            >
              Approve
            </Button>
          ) : (
            <Button
              style={{
                minWidth: "120px",
                minHeight: "36px",
                marginRight: "15px",
                backgroundColor:
                  btnDisabled || isBtnDisabled ? "#9E9E9E" : "#0B1941",
                color: "#FFFFFF",
              }}
              onClick={() => {
                // matches && location?.state?.compName == "teamApprovalListing" ? () => {handleBulkApproveResult()} :
                // matches && location?.state?.compName == "financeApprovalListing" ? () => {handleSingleApproveRejectExpense('Rejected')} :
                setIsBtnDisabled(true);
                props.bulkDownload?.length > 0
                  ? props.handleBulkApprove()
                  : handleSingleExpense();
              }}
              disabled={btnDisabled || isBtnDisabled}
              autoFocus
            >
              Reject
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ApproveRejectDialog;
