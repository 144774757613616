import {
  Box,
  Dialog,
  makeStyles,
  Typography,
  withStyles,
  Grid,
  Link,
  Chip,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button } from "antd";
import CreateTimesheet from "./createTimesheet";
import { useDispatch, useSelector } from "react-redux";
import WeekSelection from "./weekSelection";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getTimesheetPurposeData,
  getDailyPreviousTimesheetList,
  exportAsPdfData,
  getWeeklyPreviousTimesheetList,
  getTempWeeklyPreviousTimesheetList,
  setAllPMOChips,
} from "../redux/TimeSheet/actionTs";
import Encrypt from "../Utils/encryption";
import MyTimesheetTable from "./myTimesheetTable";
import { useHistory } from "react-router";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import LaunchIcon from "@material-ui/icons/Launch";
import jsPDF from "jspdf";
import "jspdf-autotable";
//import moment from "moment";

//Styling start:
const useStyles = makeStyles((theme) => ({
  addExpenseButton: {
    height: "40px",
    width: "180px",
    background: "#0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: "#0B1941!important",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: "#0B1941!important",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  addExpenseButtonMac: {
    height: "42px",
    width: "180px",
    background: "#0B1941!important",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: "#0B1941!important",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: "#0B1941!important",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "& .MuiButton-root": {
      marginLeft: "630px",
    },
  },
  saveTemplateButton: {
    height: "42px",
    width: "180px",
  },

  buttonText: {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  plus: {
    fontSize: "20px",
  },
  auditHistory: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "14px",
    color: "#008CE6",
    paddingTop: "20px",
    textDecoration: "underline",
  },
  backgroundFilter: {
    backgroundColor: "#ffffff",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  //   filter:{
  // "    &.MuiInputBase-formControl .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":{
  //   padding: "0px"
  //   }
  //   }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

//Styling end.

//Component start:
const MyTimesheet = () => {
  const classes = useStyles();
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [chips, setChips] = useState({
    timePeriodChip: "",
    projectIdChip: [],
    statusChip: [],
  });

  const [tempChips, setTempChips] = useState({
    timePeriodChip: "",
    projectIdChip: [],
    statusChip: [],
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const statusOptions = [
    "In Review",
    "Auto Approved",
    "Approved",
    "Rejected",
    "Draft",
  ];
  const [newArrayValChild, setNewArrayValChild] = useState([]);
  const formData = new FormData();
  const [projID, setProjID] = useState([]);
  const [applyFilterClicked, setApplyFilterClicked] = useState(false);
  const [isNewChip, setIsNewChip] = useState(false);

  // useSelector start:
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const projectListTs = useSelector(
    (state) => state.timesheetData.getTimesheetPurposeList
  );
  const weeklyTimesheetList = useSelector(
    (state) => state.timesheetData.getWeeklyPreviousTimesheetList
  );
  const tempWeeklyTimesheetList = useSelector(
    (state) => state.timesheetData.getTempWeeklyPreviousTimesheetList
  );
  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );
  const [isLoader, isSetLoader] = useState(true);
  // useSelector end.

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu behavior
  };

  const handleKeyDown = (e) => {
    if ((e.ctrlKey && e.shiftKey && e.key === "I") || e.key === "F12") {
      e.preventDefault(); // Prevent opening developer tools
    }
  };

  useEffect(() => {
    // dispatch(setAllPMOChips({
    //   allTempChips:{
    //     dateChip: [],
    //     verticalChip: [],
    //     projectsChip: [],
    //     statusChip: [],
    //     empEntityChip: [],
    //     empTypeChip: [],
    //     employeeChip: [],
    //     projPurposeChip: [],
    //     customerNameChip: [],
    //     statusChip: [],
    //     projGroupChip: [],
    //   },
    //   // currentPage: 1, // Reset currentPage to initial value (or any other appropriate value)
    //   selectedDateItem: [], // Clear selectedDateItem
    //   selectedDate: [], // Clear selectedDate
    //   selectedProjItem: [], // Clear selectedProjItem
    //   selectedProjects: [], // Clear selectedProjects
    // }))
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const outPutData = useSelector(
    (state) => state.timesheetData?.postExtractPDFData
  );

  // useEffect Start:

  useEffect(() => {
    if (weeklyTimesheetList && tempWeeklyTimesheetList) {
      isSetLoader(false);
    } else {
      isSetLoader(true);
    }
  }, [weeklyTimesheetList, tempWeeklyTimesheetList]);

  useEffect(() => {
    if (ssoEmpIdDetails) {
      let weekly = {
        empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        date: moment(new Date()).format("yyyy-MM-DD"),
        tmId: null,
        projId: [],
        status: [],
      };
      dispatch(getTempWeeklyPreviousTimesheetList(weekly));
      dispatch(
        getTimesheetPurposeData(
          tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId
        )
      );
    }
  }, [ssoEmpIdDetails, tsDelEmpIdRedux, dispatch]);

  useEffect(() => {
    if (ssoEmpIdDetails && tempChips.projectIdChip.length === 0) {
      setProjID([]);
    }
  }, [ssoEmpIdDetails, tempChips]);

  useEffect(() => {
    if (
      tempWeeklyTimesheetList?.length > 0 &&
      tempChips.projectIdChip?.length > 0
    ) {
      const keyValue = Array.isArray(tempWeeklyTimesheetList)
        ? tempWeeklyTimesheetList
            ?.map((item) => item?.projectUniqueID?.split(", "))
            .flat()
            .filter(
              (projectUniqueID, index, self) =>
                self.indexOf(projectUniqueID) === index
            )
        : [];

      const extractedValues = [].concat.apply(
        [],
        tempChips.projectIdChip?.map((selectedId) => {
          if (selectedId) {
            return keyValue
              ?.filter((item) => item?.startsWith(selectedId + ":"))
              .map((item) => item?.split(":")[1]);
          }
          return [];
        })
      );
      setProjID(extractedValues);
    }
  }, [tempWeeklyTimesheetList, tempChips]);

  // useEffect End.

  // Functions start:
  const handleApplyFilter = () => {
    isSetLoader(true);
    setApplyFilterClicked(true);
    setIsNewChip(true);
    let tempTMID = tempWeeklyTimesheetList?.find(
      (item) => tempChips.timePeriodChip === item.timePeriod
    )?.timesheetId;

    if (ssoEmpIdDetails || tsDelEmpIdRedux) {
      let weeklyObj = {
        empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        date: moment(new Date()).format("yyyy-MM-DD"),
        tmId: tempTMID ? tempTMID : null,
        projId: projID ? projID : [],
        status: tempChips.statusChip ? tempChips.statusChip : [],
      };

      let dailyObj = {
        empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        tmId: tempTMID ? tempTMID : null,
        projId: projID ? projID : [],
        status: tempChips.statusChip ? tempChips.statusChip : [],
      };

      dispatch(getWeeklyPreviousTimesheetList(weeklyObj));
      dispatch(getDailyPreviousTimesheetList(dailyObj));
    }
  };

  useEffect(() => {
    if (isNewChip) {
      setChips({
        timePeriodChip: tempChips.timePeriodChip,
        projectIdChip: [...tempChips.projectIdChip],
        statusChip: [...tempChips.statusChip],
      });
    }
  }, [isNewChip]);

  const handleTimePeriodChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedTimePeriod(selectedValue);
    setTempChips((prevChips) => ({
      ...prevChips,
      timePeriodChip: selectedValue,
    }));
    setIsNewChip(false);
  };

  const handleProjectIdChange = (event, values) => {
    if (values) {
      setSelectedProjectId(values);

      setTempChips((prevChips) => ({
        ...prevChips,
        projectIdChip: values,
      }));
      setIsNewChip(false);
    } else {
      setSelectedProjectId([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        projectIdChip: [],
      }));
    }
  };

  const handleStatusChange = (event, values) => {
    if (values) {
      setSelectedStatus(values);

      setTempChips((prevChips) => ({
        ...prevChips,
        statusChip: values,
      }));
      setIsNewChip(false);
    } else {
      setSelectedStatus([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        statusChip: [],
      }));
    }
  };

  const handleChipDelete = (chipToDelete) => {
    setChips((prevChips) => {
      const updatedChips = { ...prevChips };

      if (updatedChips.timePeriodChip === chipToDelete) {
        updatedChips.timePeriodChip = "";
      }

      updatedChips.projectIdChip = updatedChips.projectIdChip.filter(
        (chip) => chip !== chipToDelete
      );
      updatedChips.statusChip = updatedChips.statusChip.filter(
        (chip) => chip !== chipToDelete
      );

      return updatedChips;
    });

    setTempChips((prevChips) => {
      const updatedChips = { ...prevChips };

      if (updatedChips.timePeriodChip === chipToDelete) {
        updatedChips.timePeriodChip = "";
      }

      updatedChips.projectIdChip = updatedChips.projectIdChip.filter(
        (chip) => chip !== chipToDelete
      );
      updatedChips.statusChip = updatedChips.statusChip.filter(
        (chip) => chip !== chipToDelete
      );

      return updatedChips;
    });
    if (selectedTimePeriod === chipToDelete) {
      setSelectedTimePeriod("");
    }
    setSelectedProjectId((prevSelectedProjectId) =>
      prevSelectedProjectId.filter((chip) => chip !== chipToDelete)
    );
    setSelectedStatus((prevSelectedStatus) =>
      prevSelectedStatus.filter((chip) => chip !== chipToDelete)
    );
  };

  const handleClearAll = () => {
    isSetLoader(true);
    setApplyFilterClicked(false);
    setTempChips({
      timePeriodChip: "",
      projectIdChip: [],
      statusChip: [],
    });
    setChips({
      timePeriodChip: "",
      projectIdChip: [],
      statusChip: [],
    });
    setSelectedTimePeriod("");
    setSelectedProjectId([]);
    setSelectedStatus([]);

    let weeklyObj = {
      empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
      date: moment(new Date()).format("yyyy-MM-DD"),
      tmId: null,
      projId: [],
      status: [],
    };

    dispatch(getWeeklyPreviousTimesheetList(weeklyObj));
  };

  const handleCreateTimesheet = () => {
    history.push({
      pathname: "/create-Timesheet-Management",
      state: {
        ssoEmpId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        compName: "createTimesheet",
        projListProp: projectListTs,
      },
    });
  };

  const exportPDFFunct = (data) => {
    // const data3 = [{"tmid": 10}, {"tmid": 20}];
    // const stringifiedData = JSON.stringify(data3);
    // formData.append("tMId", stringifiedData);
    // dispatch(exportAsPdfData(formData));

    setNewArrayValChild(data);

    const data3 = data;
    const stringifiedData = JSON.stringify(data3);
    formData.append("tMId", stringifiedData);
    dispatch(exportAsPdfData(formData));
  };

  const executeExportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const lengthLoopVal = outPutData ? outPutData?.length : "0";

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    for (let i = 0; i <= lengthLoopVal - 1; i++) {
      const currentDate = new Date();

      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();

      const formattedDate = `${day}/${month}/${year}`;
      const formattedTime = currentDate.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });

      const TimesheetNumberVal =
        outPutData[i]?.TimesheetMasterData.TimesheetNumber;
      const TimesheetNameVal = outPutData[i]?.TimesheetMasterData.EmpName;
      const ApprovalStatusVal =
        outPutData[i]?.TimesheetMasterData.ApprovalStatus;
      const StartDateVal = outPutData[i]?.TimesheetMasterData.StartDate;
      const EndDateVal = outPutData[i]?.TimesheetMasterData.EndDate;
      const TotalHoursVal = outPutData[i]?.TimesheetMasterData.TotalHours;
      const TotalBillableHoursVal =
        outPutData[i]?.TimesheetMasterData.TotalBillableHours;
      const WorkWeekVal = outPutData[i]?.TimesheetMasterData.WorkWeek;
      const compName = outPutData[i]?.TimesheetMasterData.CompanyName;
      const day1Val = outPutData[i]?.TimesheetDetails[0]?.day1;
      const day2Val = outPutData[i]?.TimesheetDetails[0]?.day2;
      const day3Val = outPutData[i]?.TimesheetDetails[0]?.day3;
      const day4Val = outPutData[i]?.TimesheetDetails[0]?.day4;
      const day5Val = outPutData[i]?.TimesheetDetails[0]?.day5;
      const day6Val = outPutData[i]?.TimesheetDetails[0]?.day6;
      const day7Val = outPutData[i]?.TimesheetDetails[0]?.day7;
      const date1Val = outPutData[i]?.TimesheetDetails[0]?.date1;
      const date2Val = outPutData[i]?.TimesheetDetails[0]?.date2;
      const date3Val = outPutData[i]?.TimesheetDetails[0]?.date3;
      const date4Val = outPutData[i]?.TimesheetDetails[0]?.date4;
      const date5Val = outPutData[i]?.TimesheetDetails[0]?.date5;
      const date6Val = outPutData[i]?.TimesheetDetails[0]?.date6;
      const date7Val = outPutData[i]?.TimesheetDetails[0]?.date7;

      doc.setFont("helvetica", "bold");
      doc.setFontSize(19);
      doc.text("Timesheet sign-off report", 39, 40);

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(`${compName}`, 39, 60);

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(`Page ${i + 1} of ${lengthLoopVal}`, 750, 40);
      doc.text(`${formattedDate}`, 751, 60);
      doc.text(`${formattedTime}`, 758, 80);

      doc.text("Timesheet number", 39, 120);
      doc.text(`${TimesheetNumberVal}`, 180, 120);
      doc.text(`${TimesheetNameVal}`, 39, 140);
      doc.text("Approval status", 39, 160);
      doc.text(`${ApprovalStatusVal}`, 180, 160);

      doc.text("Start date", 380, 120);
      doc.text(`${StartDateVal}`, 540, 120);
      doc.text("End date", 380, 140);
      doc.text(`${EndDateVal}`, 540, 140);
      doc.text("Total hours", 380, 160);
      doc.text(`${TotalHoursVal.toFixed(2)}`, 540, 160);
      doc.text("Total billable hours", 380, 180);
      doc.text(`${TotalBillableHoursVal.toFixed(2)}`, 540, 180);

      doc.setFont("helvetica", "bold");
      doc.text(`Work week: ${WorkWeekVal}`, 39, 220);

      const headers = [
        [
          "Legal Entity",
          "Customer Name",
          "Project ID",
          "Project Name",
          "Project Manager",
          "Category",
          `${day1Val} ${date1Val}`,
          `${day2Val} ${date2Val}`,
          `${day3Val} ${date3Val}`,
          `${day4Val} ${date4Val}`,
          `${day5Val} ${date5Val}`,
          `${day6Val} ${date6Val}`,
          `${day7Val} ${date7Val}`,
          "Total",
        ],
      ];

      const data = outPutData?.map((obj) =>
        obj?.TimesheetDetails?.map((elt) => [
          elt.LegalEntity,
          elt.CustomerName,
          elt.ProjectID,
          elt.ProjectName,
          elt.ProjectManagerName,
          elt.Category,
          elt.dayhours1,
          elt.dayhours2,
          elt.dayhours3,
          elt.dayhours4,
          elt.dayhours5,
          elt.dayhours6,
          elt.dayhours7,
          elt.Total,
        ])
      );

      const dataVal = data[i];

      // const convertedArray = [].concat(...data[0]).map(item => (item === undefined ? '' : item));

      function formatData(data) {
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].length; j++) {
            if (typeof data[i][j] === "number") {
              if (data[i][j] % 1 !== 0) {
                data[i][j] = data[i][j].toFixed(2);
              } else {
                data[i][j] = data[i][j].toFixed(2);
              }
            }
          }
        }
        return data;
      }

      const formattedData = formatData(dataVal);

      let checkLength = data[i]?.length;
      let content = {
        startY: 250,
        head: headers,
        body: formattedData,
        theme: "plain",
        tableLineWidth: 1,
        headStyles: { fillColor: "#BDBDBD", textColor: "black" },
        didParseCell: function (data) {
          if (data.row.index === (checkLength ? checkLength : 1) - 1) {
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.fillColor = "#EBECF0";
          }
        },
      };

      doc.autoTable(content);
      if (i < lengthLoopVal - 1) {
        doc.addPage("a4", "l");
      }
    }

    doc.save(
      `${moment(new Date()).format(
        "YYYY-MM-DD-HHmm"
      )}-Timesheet sign-off report.pdf`
    );
  };

  // Functions end.
  return (
    <div
      style={{ backgroundColor: "#f8f9ff" }}
      onContextMenu={handleContextMenu}
    >
      <Grid
        //className={classes.filter}

        style={{
          // paddingLeft: "12px",
          display: "flex",
          // alignItem:"center",

          justifyContent: "space-between",
          //width:"1162px",
          //backgroundColor:"#f8f9ff",
        }}
      >
        <Grid
          style={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            // paddingLeft: "20px",
            width: "66%",
            justifyContent: " space-between",
            paddingLeft: "13px",
          }}
          container
          spacing={1}
        >
          <Grid xs={3} className={classes.backgroundFilter}>
            <FormControl sx={{ width: "100%" }} size="small">
              <InputLabel>Select Time Period</InputLabel>
              <Select
                label="Select Time period"
                name="selectedTimePeriod"
                value={selectedTimePeriod}
                onChange={handleTimePeriodChange}
                SelectDisplayProps={{
                  style: { color: "black" },
                }}
                MenuProps={{
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      width: "min-content",
                      position: "absolute",
                      overflowX: "hidden",
                      overFlowY: "auto",
                      minHeight: "16px",
                      outline: 0,
                      WebkitOverflowScrolling: "touch",
                      transition:
                        "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    },
                  },
                }}
              >
                {tempWeeklyTimesheetList?.length > 0 &&
                  tempWeeklyTimesheetList.map((item) => (
                    <MenuItem
                      key={item.timePeriod}
                      value={item.timePeriod}
                      style={{
                        padding: "6px 16px",
                        width: "100%",
                      }}
                    >
                      {item.timePeriod}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={3} className={classes.backgroundFilter}>
            <Autocomplete
              multiple
              options={
                Array.isArray(tempWeeklyTimesheetList)
                  ? tempWeeklyTimesheetList
                      ?.map((item) => item.projectId.split(", "))
                      .flat()
                      .filter(
                        (projectId, index, self) =>
                          self.indexOf(projectId) === index
                      )
                  : []
              }
              sx={{
                ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                  top: "auto",
                },
                ".MuiIconButton-root": {
                  padding: "2px",
                },
                width: "100%",
              }}
              value={selectedProjectId}
              onChange={handleProjectIdChange}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) =>
                selectedProjectId.indexOf(option) !== -1
              }
              renderOption={(props, option, { selected }) => (
                <li
                  style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  {...props}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: selected ? "#0B1941" : "" }}
                        checked={selected}
                      />
                    }
                    label={<span style={{ fontSize: "14px" }}>{option}</span>}
                  />
                </li>
              )}
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ height: "40px" }}
                  label="Project ID"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid xs={3} className={classes.backgroundFilter}>
            <Autocomplete
              multiple
              sx={{
                ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                  top: "auto",
                },
                ".MuiIconButton-root": {
                  padding: "2px",
                },
                width: "100%",
              }}
              options={statusOptions}
              value={selectedStatus}
              onChange={handleStatusChange}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) =>
                selectedStatus.indexOf(option) !== -1
              }
              renderOption={(props, option, { selected }) => (
                <li
                  style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  {...props}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: selected ? "#0B1941" : "" }}
                        checked={selected}
                      />
                    }
                    label={<span style={{ fontSize: "14px" }}>{option}</span>}
                  />
                </li>
              )}
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ height: "40px" }}
                  label="Status"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>

          <Grid>
            <Link
              style={{
                color: "#008CE6",
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left",
                cursor: "pointer",
              }}
              underline="always"
              onClick={handleApplyFilter}
            >
              Apply Filter
            </Link>
          </Grid>
        </Grid>
        <Box style={{ paddingRight: "8px" }}>
          <Button
            className={
              window.screen.availWidth == 1792
                ? classes.addExpenseButtonMac
                : classes.addExpenseButton
            }
            onClick={handleCreateTimesheet}
          >
            <Typography className={classes.buttonText}>
              + Create Timesheet
            </Typography>
          </Button>
        </Box>
      </Grid>
      {applyFilterClicked &&
        (chips.projectIdChip.length > 0 ||
          chips.timePeriodChip.length > 0 ||
          chips.statusChip.length > 0) && (
          <Divider style={{ marginTop: "20px" }} />
        )}
      {applyFilterClicked && (
        <div style={{ paddingTop: "15px" }}>
          {Object.entries(chips).map(([chipType, chipValue], index) => (
            <React.Fragment key={index}>
              {Array.isArray(chipValue)
                ? chipValue.map((value, valueIndex) => (
                    <Chip
                      key={`${chipType}-${valueIndex}`}
                      label={value}
                      onDelete={() => handleChipDelete(value)}
                      style={{
                        margin: "5px",
                        backgroundColor: "#fff",
                        color: "#000",
                        fontWeight: "600",
                        border: "1px solid #000",
                      }}
                      deleteIcon={
                        <ClearIcon
                          style={{
                            color: "#000",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                      }
                    />
                  ))
                : chipValue && (
                    <Chip
                      key={`${chipType}-${chipValue}`}
                      label={chipValue}
                      onDelete={() => handleChipDelete(chipValue)}
                      style={{
                        margin: "5px",
                        backgroundColor: "#fff",
                        color: "#000",
                        fontWeight: "600",
                        border: "1px solid #000",
                      }}
                      deleteIcon={
                        <ClearIcon
                          style={{
                            color: "#000",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                      }
                    />
                  )}
            </React.Fragment>
          ))}
          {Object.values(chips).some((chipValue) =>
            Array.isArray(chipValue) ? chipValue.length > 0 : chipValue
          ) && (
            <Link
              style={{
                color: "#008CE6",
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left",
                cursor: "pointer",
              }}
              underline="always"
              onClick={handleClearAll}
            >
              Clear All
            </Link>
          )}
        </div>
      )}

      <Divider style={{ marginTop: "18px" }} />
      <Box
        style={{
          display: "flex",
          alignItems: "flex-end",
          padding: "18px 0px",
          backgroundColor: "#F8F9FF",
        }}
      >
        <Typography
          style={{
            marginLeft: "12px",
            fontWeight: 600,
            paddignTop: "16px",
            lineHeight: 2.5,
          }}
        >
          Previous Timesheets
        </Typography>
        {/* <CheckBoxIcon
                  fontSize="small"
                  //onClick={onSelectAllClick}
                  className={classes.checkBoxRoot}
                /> */}
        {newArrayValChild && newArrayValChild.length > 0 && (
          <Box
            style={{
              paddingLeft:
                window.screen.availWidth == 1920
                  ? "78.5%"
                  : window.screen.availWidth == 1536
                  ? "72%"
                  : window.screen.availWidth == 1098
                  ? "60%"
                  : "66.4%",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{
                whiteSpace: "nowrap",
                textTransform: "unset",
                color: "#0B1941",
                border: "1px solid #0B1941",
              }}
              onClick={executeExportPDF}
            >
              <LaunchIcon
                style={{
                  height: "16px",
                  width: "16px",
                  color: "#043365",
                  verticalAlign: "text-bottom",
                }}
              />
              &nbsp;Export Timesheet as PDF
            </Button>
          </Box>
        )}
      </Box>
      <Divider />
      <Box>
        <MyTimesheetTable
          filterData={tempChips}
          onClickSetVal={exportPDFFunct}
        />
        {/* {weeklyTimesheetList?.length === 0 ? 
        <Grid>
          <Typography
             style={{
              fontSize:"20px",
              lineHeight:"200px",
              textAlign:"center",
              fontWeight: "600",
              }}>
            No Timesheet entry exists!
          </Typography>
        </Grid> : <></>
        } */}
      </Box>
      <div>
        <Backdrop
          sx={{ color: "#092FED", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
};

export default MyTimesheet;
