import React, { useState, useEffect } from 'react';
import '../css/gmBUPage.css';
import {AppBar, Toolbar, Typography} from '@material-ui/core';
import logo from '../images/incedo-logo.svg';


function GMNavBar() {
  const [pageModule, setPageModule] = useState("");

  var path = window.location.href;
  var directories = path.split("/");
  var lastDirecotry = directories[directories.length - 1];
  var firstDirectory = directories[directories.length - 2];

  useEffect(() => {
    if(directories && directories?.length === 4){
      if(lastDirecotry === "empMobilityView"){
        setPageModule("GMVisa");
      }
    }
  }, [directories]);

	return (
		<>
      <AppBar position="static" style={{ backgroundColor: '#fff', boxShadow: 'none', border:'1.5px solid #D5D5D9'}}>
        <Toolbar>
          <img src={logo} alt="Logo" caption="Incedo" style={{ height: '16px' }} />
          <Typography variant="h6" style={{ fontWeight:'bolder',flexGrow: 1, fontFamily: 'calibri', fontSize: '24px', color: '#1C3663', marginLeft:'30px'}} className='title'>
            {/* {pageModule === "GMVisa" ? "Global Mobility Program Nomination" : } */}
            {"Global Mobility Calculator"}
          </Typography>

        </Toolbar>
      </AppBar>
		</>
	);
}

export default GMNavBar;