import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Box, Typography } from "@material-ui/core";
import CustomCard from "../../components/homePageCard";
import expenseCardImage from "../../images/Expense.svg";
import timesheetCardImage from "../../images/Timesheet.svg";
import globalMobilityCardImage from "../../images/GlobalMobility.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import {
  getEmpDetail,
  getEmpDetailsData,
  getAccessTypeData,
  updateDelegateeEmpId,
} from "../../redux/Expense/action";
import { updateTimesheetDelegateeEmpId,clearMaintainState ,maintainState} from "../../redux/TimeSheet/actionTs";
import { updateGlobalMobilityDelegateeEmpId } from "../../redux/GMCal/gmAction";
import { maxWidth, textAlign } from "@material-ui/system";
import Header from "../../components/header";
import transform from "@ant-design/cssinjs/lib/transformers/legacyLogicalProperties";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: window.screen.availWidth !== 1098 ? 45 : 0,
    // paddingLeft: 10,
    justifyContent: "center",
  },
  root1: {
    // marginLeft: "40px",
    // marginRight: "0px",
    justifyContent: "center",
  },
  root2: {
    // marginLeft: "100px",
    // marginRight: "-100px",
    justifyContent: "center",
  },
  root3: {
    marginRight: "-100px",
    justifyContent: "center",
  },
  rootMobile: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    marginLeft: "49px",
    maxWidth: "100px",
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  container: {
    padding: 27,
  },
  bigBox: {
    justifyContent: "center",
    padding: 10,
  },
  bigBoxMobile: {
    justifyContent: "center",
    padding: 10,
    height: 616,
    width: 360,
  },
  box1: { display: "flex", justifyContent: "left" },
  box2: {
    display: "flex",
    justifyContent: "left",
    paddingBottom:
      window.screen.availWidth == 1280
        ? ""
        : window.screen.availWidth == 1536
        ? 22
        : 26,
  },
  text1: {
    fontSize:
      window.screen.availWidth == 1280
        ? 30
        : window.screen.availWidth == 1536
        ? 40
        : 32,
  },
  text2: {
    fontSize:
      window.screen.availWidth == 1280
        ? 26
        : window.screen.availWidth == 1536
        ? 36
        : 26,
  },
}));

// const componentArray = [
//   { name: `Expenses`, image: expenseCardImage },
//   {
//     name: `Timesheet`,
//     image: timesheetCardImage,
//   },
// ];

// const componentArray = [
//   { name: `Expenses`, image: expenseCardImage },
//   {
//     name: `Timesheet `,
//     image: timesheetCardImage,
//   },
// ];
export default function Homepage() {
  const [componentArray, setComponentArray] = useState([]);
  const { instance, accounts, inProgress } = useMsal();
  const [spacing, setSpacing] = React.useState(2);
  const [ssoEmpId, setSsoEmpId] = useState(null);
  const [delEmpId, setDelEmpId] = useState(null);
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const account = useAccount(accounts[0] || {});
  const accessTypeList = useSelector(
    (state) => state.createExpenseData.getAccessTypeList
  );
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getAccessTypeList
  );
  const handleChange = (event) => {
    setSpacing(Number(event.target.value));
  };

  useEffect(() => {
    dispatch(getAccessTypeData(account?.username));
    dispatch(getEmpDetail(account?.username));
    dispatch(updateDelegateeEmpId(null));
    dispatch(updateTimesheetDelegateeEmpId(null));
    dispatch(updateGlobalMobilityDelegateeEmpId(null));
    dispatch(maintainState(0));
    // dispatch(clearMaintainState());
  }, []);

  // console.log("ssoEmpIdDetails123" , ssoEmpIdDetails)

  useEffect(() => {
    //console.log("responseAyushi1", accessTypeList , ssoEmpIdDetails , account?.username);
    if (accessTypeList 
      // || ssoEmpIdDetails?.gmNomineeFlag == 1
    ) {
      const updatedComponentArray = [];
      if (accessTypeList.expnese === 1) {
        updatedComponentArray.push({
          name: `Expenses`,
          image: expenseCardImage,
        });
      }
      if (accessTypeList.timesheet === 1) {
        updatedComponentArray.push({
          name: `Timesheet`,
          image: timesheetCardImage,
        });
      }
      if (accessTypeList.gmCalculator === 1) {
        updatedComponentArray.push({
          //name: `GMCal`,
          name: `Global Mobility Calculator`,
          image: globalMobilityCardImage,
        });
      }
      // if (accessTypeList.gmNomineeFlag === 1) {
      //   updatedComponentArray.push({
      //     //name: `GMCal`,
      //     name: `Global Mobility Nomination`,
      //     image: globalMobilityCardImage,
      //   });
      // }
      setComponentArray(updatedComponentArray);
    }
  }, [accessTypeList]);

  

  return (
    <>
      {ssoEmpIdDetails?.empId === 0 && ssoEmpIdDetails != null ? (
        <>
          <Header
            heading={""}
            ssoEmpId={ssoEmpIdDetails?.empId ? ssoEmpIdDetails?.empId : null}
            disableAccOptions={1}
          />
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "132px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontSize: "32px",
                fontWeight: "600",
                lineHeight: "40px",
                letterSpacing: "0px",
                textAlign: "center",
              }}
            >
              {"Sorry!! You are not authorized for the application"}
            </Typography>
          </Box>
        </>
      ) : (
        <div className={classes.container}>
          <Box
            className={classes.bigBox}
            style={{
              marginLeft: matches ? "" : "23px",
            }}
          >
            <Box className={classes.box1}>
              <Typography
                className={classes.text1}
                style={{
                  fontSize: matches ? "15px" : "",
                  marginTop: matches ? "-22px" : "",
                  //      lineHeight:matches?"18px":"",
                  color: "#252733",
                }}
              >
                <strong>Hello Colleagues!</strong>
              </Typography>
            </Box>
            <Box className={classes.box2}>
              <Typography className={classes.text2}>
                <span
                  style={{
                    fontSize: matches ? "16px" : "",
                    color: "#686767",
                  }}
                >
                  {" "}
                  <strong> Please choose an option to proceed </strong>
                </span>
              </Typography>
            </Box>
          </Box>
         
          <Box>
            <Grid
              container
              className={matches ? classes.rootMobile : classes.root}
              spacing={componentArray.length}
            >
              {/* <Grid item> */}
              {/* <Grid container justifyContent="center" spacing={window.screen.availWidth == 1280 ? 4 : window.screen.availWidth == 1536 ? 4 : 7}> */}

              {componentArray &&
                componentArray?.map((value) => (
                  <Grid
                    className={
                      !matches && (componentArray && componentArray.length) == 2
                        ? classes.root1
                        : matches &&
                          (componentArray && componentArray.length) == 1
                        ? classes.root3
                        : (componentArray && componentArray.length) == 1
                        ? classes.root2
                        : ""
                    }
                    key={value}
                    item
                    xs={
                      12 /
                      (componentArray.length <= 2
                        ? maxWidth
                        : matches
                        ? ""
                        : "665px")
                    }
                  >
                    <CustomCard componentName={value} />
                  </Grid>
                ))}
              {/* </Grid>
          </Grid> */}
            </Grid>
          </Box>
        </div>
      )}
    </>
  );
}