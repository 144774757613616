import * as types from "./actionTypeTs.js";
import Decrypt from "../../Utils/decryption";
import Encrypt from "../../Utils/encryption";
import config from "../../Utils/config";
import fileDownload from "js-file-download";
import { AwsClient } from "aws4fetch";

//GET TIMESHEET PURPOSE ACTION start:
export function TimesheetPurposeData() {
  return {
    type: types.GET_TIMESHEET_PURPOSE_LIST,
  };
}

export function TimesheetPurposeDataSuccess(payload) {
  return {
    type: types.GET_TIMESHEET_PURPOSE_LIST_SUCCESS,
    payload,
  };
}

export function TimesheetPurposeDataFailed(payload) {
  return {
    type: types.GET_TIMESHEET_PURPOSE_LIST_FAILED,
    payload,
  };
}
//GET TIMESHEET PURPOSE ACTION end.
//GET TIMESHEET CATEGORY ACTION start:
export function TimesheetCategoryData() {
  return {
    type: types.GET_TIMESHEET_CATEGORIES_LIST,
  };
}

export function TimesheetCategoryDataSucess(payload) {
  return {
    type: types.GET_TIMESHEET_CATEGORIES_LIST_SUCCESS,
    payload,
  };
}

export function TimesheetCategoryDataFailed(payload) {
  return {
    type: types.GET_TIMESHEET_CATEGORIES_LIST_FAILED,
    payload,
  };
}
//GET TIMESHEET CATEGORY ACTION end.
// POST CREATE TIMESHEET action start:
export function postCreateTimesheet() {
  return {
    type: types.POST_CREATE_TIMESHEET,
  };
}

export function postCreateTimesheetSuccess(payload) {
  return {
    type: types.POST_CREATE_TIMESHEET_SUCCESS,
    payload,
  };
}

export function postCreateTimesheetFailed(payload) {
  return {
    type: types.POST_CREATE_TIMESHEET_FAILED,
    payload,
  };
}
// POST CREATE TIMESHEET action end.
//GET TIMESHEET CATEGORY ACTION start:
export function myTimesheetData() {
  return {
    type: types.GET_MY_TIMESHEET_LIST,
  };
}

export function myTimesheetDataSuccess(payload) {
  return {
    type: types.GET_MY_TIMESHEET_LIST_SUCCESS,
    payload,
  };
}

export function myTimesheetDataFailed(payload) {
  return {
    type: types.GET_MY_TIMESHEET_LIST_FAILED,
    payload,
  };
}
//GET TIMESHEET CATEGORY ACTION end.
//GET ALL PROJECTS ACTION start:
export function getAllProjects() {
  return {
    type: types.GET_ALL_PROJECTS,
  };
}

export function getAllProjectsSuccess(payload) {
  return {
    type: types.GET_ALL_PROJECTS_SUCCESS,
    payload,
  };
}

export function getAllProjectsFailed(payload) {
  return {
    type: types.GET_ALL_PROJECTS_FAILED,
    payload,
  };
}
//GET ALL PROJECTS ACTION end.
// GET UPDATED REFERENCE DATE start:
export function updateReferenceDate(payload) {
  // console.log("updatedRefDate2: ", payload);
  return {
    type: types.UPDATED_REF_DATE,
    payload: payload,
  };
}
// GET UPDATED REFERENCE DATE end.
export function weeklyPreviousTimesheetList() {
  return {
    type: types.GET_WEEKLY_PREVIOUS_TIMESHEET_LIST,
  };
}
export function weeklyPreviousTimesheetListSuccess(payload) {
  return {
    type: types.GET_WEEKLY_PREVIOUS_TIMESHEET_LIST_SUCCESS,
    payload,
  };
}
export function weeklyPreviousTimesheetListFailed(payload) {
  return {
    type: types.GET_WEEKLY_PREVIOUS_TIMESHEET_LIST_FAILED,
    payload,
  };
}
export function tempWeeklyPreviousTimesheetList() {
  return {
    type: types.GET_TEMP_WEEKLY_PREVIOUS_TIMESHEET_LIST,
  };
}
export function tempWeeklyPreviousTimesheetListSuccess(payload) {
  return {
    type: types.GET_TEMP_WEEKLY_PREVIOUS_TIMESHEET_LIST_SUCCESS,
    payload,
  };
}
export function tempWeeklyPreviousTimesheetListFailed(payload) {
  return {
    type: types.GET_TEMP_WEEKLY_PREVIOUS_TIMESHEET_LIST_FAILED,
    payload,
  };
}
export function dailyPreviousTimesheetList() {
  return {
    type: types.GET_DAILY_PREVIOUS_TIMESHEET_LIST,
  };
}
export function dailyPreviousTimesheetListSuccess(payload, tmId) {
  return {
    type: types.GET_DAILY_PREVIOUS_TIMESHEET_LIST_SUCCESS,
    payload,
    tmId,
  };
}
export function dailyPreviousTimesheetListFailed(payload) {
  return {
    type: types.GET_DAILY_PREVIOUS_TIMESHEET_LIST_FAILED,
    payload,
  };
}
//GET MISSED WEEK ACTION start:

export function getMissedWeek() {
  return {
    type: types.GET_MISSED_WEEK,
  };
}

export function getMissedWeekSuccess(payload) {
  return {
    type: types.GET_MISSED_WEEK_SUCCESS,

    payload,
  };
}

export function getMissedWeekFailed(payload) {
  return {
    type: types.GET_MISSED_WEEK_FAILED,

    payload,
  };
}

//GET MISSED WEEK ACTION end.
//GET MY TEAM APPROVAL ACTION start:

export function getMyTeamApprovalCount() {
  return {
    type: types.GET_TOTAL_COUNT_APPROVAL_DATA,
  };
}

export function getMyTeamApprovalCountSuccess(payload) {
  return {
    type: types.GET_TOTAL_COUNT_APPROVAL_DATA_SUCCESS,
    payload,
  };
}

export function getMyTeamApprovalCountFailed(payload) {
  return {
    type: types.GET_TOTAL_COUNT_APPROVAL_DATA_FAILED,
    payload,
  };
}

export function getMyTeamApprovalPageWise() {
  return {
    type: types.GET_MY_TEAM_APPROVAL_PAGEWISE,
  };
}

export function getMyTeamApprovalPageWiseSuccess(payload) {
  return {
    type: types.GET_MY_TEAM_APPROVAL_PAGEWISE_SUCCESS,
    payload,
  };
}

export function getMyTeamApprovalPageWiseFailed(payload) {
  return {
    type: types.GET_MY_TEAM_APPROVAL_PAGEWISE_FAILED,
    payload,
  };
}
export function GetAutoApprovedAllocatedProjList() {
  return {
    type: types.GET_AUTOAPPROVAL_ALLOCATED_PROJ_LIST,
  };
}

export function GetAutoApprovedAllocatedProjListSuccess(payload) {
  return {
    type: types.GET_AUTOAPPROVAL_ALLOCATED_PROJ_LIST_SUCCESS,
    payload,
  };
}

export function GetAutoApprovedAllocatedProjListFailed(payload) {
  return {
    type: types.GET_AUTOAPPROVAL_ALLOCATED_PROJ_LIST_FAILED,
    payload,
  };
}

export function getMyTeamApproval() {
  return {
    type: types.GET_MY_TEAM_APPROVAL,
  };
}

export function getMyTeamApprovalSuccess(payload) {
  return {
    type: types.GET_MY_TEAM_APPROVAL_SUCCESS,

    payload,
  };
}

export function getMyTeamApprovalFailed(payload) {
  return {
    type: types.GET_MY_TEAM_APPROVAL_FAILED,
    payload,
  };
}

//GET MISSED WEEK ACTION end.
//UPDATE STATUS BY MANAGER ACTION starts
export function updateStatusByManager() {
  return {
    type: types.UPDATE_STATUS_BY_MANAGER,
  };
}
export function updateStatusByManagerSuccess(payload) {
  return {
    type: types.UPDATE_STATUS_BY_MANAGER_SUCCESS,
    payload,
  };
}
export function updateStatusByManagerFailed(payload) {
  return {
    type: types.UPDATE_STATUS_BY_MANAGER_FAILED,
    payload,
  };
}
//UPDATE STATUS BY MANAGER ACTION ends
//GET MISSED WEEK ACTION start:

export function getCalendarDate() {
  return {
    type: types.GET_CALENDAR_DATE_LIST,
  };
}
export function getCalendarDateSuccess(payload) {
  return {
    type: types.GET_CALENDAR_DATE_LIST_SUCCESS,
    payload,
  };
}
export function getCalendarDateFailed(payload) {
  return {
    type: types.GET_CALENDAR_DATE_LIST_FAILED,
    payload,
  };
}

//GET MISSED WEEK ACTION end.

// Get Timesheet Dashboard Landing Page Detailed View
export function viewData() {
  return {
    type: types.GET_VIEW_LIST,
  };
}

export function viewDataSucess(payload) {
  return {
    type: types.GET_VIEW_LIST_SUCCESS,
    payload,
  };
}

export function viewDataFailed(payload) {
  return {
    type: types.GET_VIEW_LIST_FAILED,
    payload,
  };
}

// GET TEAM TIMESHEET action start
export function getTeamTimesheet() {
  return {
    type: types.GET_TEAM_TIMESHEET,
  };
}
export function getTeamTimesheetSuccess(payload) {
  return {
    type: types.GET_TEAM_TIMESHEET_SUCCESS,
    payload,
  };
}
export function getTeamTimesheetFailed(payload) {
  return {
    type: types.GET_TEAM_TIMESHEET_FAIED,
    payload,
  };
}
// GET TEAM TIMESHEET action end

//GET TIMESHEET ACCESS FLAG action start
export function getTimesheetAccessFlag() {
  return {
    type: types.GET_TIMESHEET_ACCESS_FLAG,
  };
}
export function getTimesheetAccessFlagSuccess(payload) {
  return {
    type: types.GET_TIMESHEET_ACCESS_FLAG_SUCCESS,
    payload,
  };
}
export function getTimesheetAccessFlagFailed(payload) {
  return {
    type: types.GET_TIMESHEET_ACCESS_FLAG_FAILED,
    payload,
  };
}

//POST PDF action
export function exportAsPdf() {
  return {
    type: types.POST_EXPORT_AS_PDF,
  };
}
export function exportAsPdfSuccess(payload) {
  return {
    type: types.POST_EXPORT_AS_PDF_SUCCESS,
    payload,
  };
}
export function exportAsPdfFailed(payload) {
  return {
    type: types.POST_EXPORT_AS_PDF_FAILED,
    payload,
  };
}

//GET TIMESHEET ACCESS FLAG action end
export function maintainStateVariableFxn(payload) {
  return {
    type: types.MAINTAIN_STATE_VARIABLE,
    payload: payload,
  };
}

export function TimesheetReportAccessData() {
  return {
    type: types.GET_TIMESHEET_REPORT_ACCESS_FLAG,
  };
}

export function TimesheetReportAccessDataSuccess(payload) {
  return {
    type: types.GET_TIMESHEET_REPORT_ACCESS_FLAG_SUCCESS,
    payload,
  };
}

export function TimesheetReportAccessDataFailed(payload) {
  return {
    type: types.GET_TIMESHEET_REPORT_ACCESS_FLAG_FAILED,
    payload,
  };
}

export function PendencyReportData() {
  return {
    type: types.GET_PENDENCY_REPORT_DATA,
  };
}

export function PendencyReportDataSuccess(payload) {
  return {
    type: types.GET_PENDENCY_REPORT_DATA_SUCCESS,
    payload,
  };
}

export function PendencyReportDataFailed(payload) {
  return {
    type: types.GET_PENDENCY_REPORT_DATA_FAILED,
    payload,
  };
}

// POST RATING AND REVIEW action start:
export function postRatingReview() {
  return {
    type: types.POST_RATING_AND_REVIEW,
  };
}

export function postRatingReviewSuccess(payload) {
  return {
    type: types.POST_RATING_AND_REVIEW_SUCCESS,
    payload,
  };
}

export function postRatingReviewFailed(payload) {
  return {
    type: types.POST_RATING_AND_REVIEW_FAILED,
    payload,
  };
}
// GET RATING DESCRIPTION action end.
// GET RATING DESCRIPTION action end.

export function getRatingDescription() {
  return {
    type: types.GET_RATING_DESCRIPTION,
  };
}

export function getRatingDescriptionSuccess(payload) {
  return {
    type: types.GET_RATING_DESCRIPTION_SUCCESS,
    payload,
  };
}

export function getRatingDescriptionFailed(payload) {
  return {
    type: types.GET_RATING_DESCRIPTION_FAILED,
    payload,
  };
}

//GET TIMESHEET REPORT action start
export function TimesheetReport() {
  return {
    type: types.GET_TIMESHEET_REPORT_DATA,
  };
}

export function TimesheetReportSuccess(payload) {
  return {
    type: types.GET_TIMESHEET_REPORT_DATA_SUCCESS,
    payload,
  };
}

export function TimesheetReportFailed(payload) {
  return {
    type: types.GET_TIMESHEET_REPORT_DATA_FAILED,
    payload,
  };
}

export function AllTimesheetReport() {
  return {
    type: types.GET_All_TIMESHEET_REPORT_DATA,
  };
}
export function AllTimesheetReportSuccess(payload) {
  return {
    type: types.GET_All_TIMESHEET_REPORT_DATA_SUCCESS,
    payload,
  };
}

export function AllTimesheetReportFailed(payload) {
  return {
    type: types.GET_All_TIMESHEET_REPORT_DATA_FAILED,
    payload,
  };
}

export function AllTimesheetReport1() {
  return {
    type: types.GET_All_TIMESHEET_REPORT_DATA_NEW,
  };
}
export function AllTimesheetReportSuccess1(payload) {
  return {
    type: types.GET_All_TIMESHEET_REPORT_DATA_SUCCESS_NEW,
    payload,
  };
}

export function AllTimesheetReportFailed1(payload) {
  return {
    type: types.GET_All_TIMESHEET_REPORT_DATA_FAILED_NEW,
    payload,
  };
}

export function AllTimesheetReportView() {
  return {
    type: types.GET_ALL_TIMESHEET_REPORT_VIEW,
  };
}
export function AllTimesheetReportViewSuccess(payload) {
  return {
    type: types.GET_ALL_TIMESHEET_REPORT_VIEW_SUCCESS,
    payload,
  };
}

export function AllTimesheetReportViewFailed(payload) {
  return {
    type: types.GET_ALL_TIMESHEET_REPORT_VIEW_FAILED,
    payload,
  };
}

export function AllTimesheetReportClear() {
  return {
    type: types.CLEAR_ALL_TIMESHEET_REPORT,
  };
}

export function AllTimesheetReportAudit() {
  return {
    type: types.GET_ALL_TIMESHEET_AUDIT,
  };
}
export function AllTimesheetReportAuditSuccess(payload) {
  return {
    type: types.GET_ALL_TIMESHEET_AUDIT_SUCCESS,
    payload,
  };
}

export function AllTimesheetReportAuditFailed(payload) {
  return {
    type: types.GET_ALL_TIMESHEET_AUDIT_FAILED,
    payload,
  };
}

// GET Timesheet Search Employee action starts.

export function getTimesheetSearchEmployee() {
  return {
    type: types.GET_TIMESHEET_SEARCH_EMPLOYEE,
  };
}

export function getTimesheetSearchEmployeeSuccess(payload) {
  return {
    type: types.GET_TIMESHEET_SEARCH_EMPLOYEE_SUCCESS,
    payload,
  };
}

export function getTimesheetSearchEmployeeFailed(payload) {
  return {
    type: types.GET_TIMESHEET_SEARCH_EMPLOYEE_FAILED,
    payload,
  };
}
// GET Timesheet Search Employee action ends.

// GET Timesheet Search Delegate action starts.

export function getTimesheetSearchDelegate() {
  return {
    type: types.GET_TIMESHEET_SEARCH_DELEGATE,
  };
}

export function getTimesheetSearchDelegateSuccess(payload) {
  return {
    type: types.GET_TIMESHEET_SEARCH_DELEGATE_SUCCESS,
    payload,
  };
}

export function getTimesheetSearchDelegateFailed(payload) {
  return {
    type: types.GET_TIMESHEET_SEARCH_DELEGATE_FAILED,
    payload,
  };
}
// GET Timesheet Search Employee action ends.

//GET ViewDelegateDetails action starts:

export function getTimesheetViewDelegateDetails() {
  return {
    type: types.GET_TIMESHEET_VIEW_DELEGATE_DETAILS,
  };
}

export function getTimesheetViewDelegateDetailsSuccess(payload) {
  return {
    type: types.GET_TIMESHEET_VIEW_DELEGATE_DETAILS_SUCCESS,
    payload,
  };
}

export function getTimesheetViewDelegateDetailsFailed(payload) {
  return {
    type: types.GET_TIMESHEET_VIEW_DELEGATE_DETAILS_FAILED,
    payload,
  };
}

//GET ViewDelegateDetails action ends.

//GET TimesheetSearchEmpAndDel action starts:

export function getTimesheetSearchEmpAndDel() {
  return {
    type: types.GET_TIMESHEET_SEARCH_EMP_AND_DEL,
  };
}

export function getTimesheetSearchEmpAndDelSuccess(payload) {
  return {
    type: types.GET_TIMESHEET_SEARCH_EMP_AND_DEL_SUCCESS,
    payload,
  };
}

export function getTimesheetSearchEmpAndDelFailed(payload) {
  return {
    type: types.GET_TIMESHEET_SEARCH_EMP_AND_DEL_FAILED,
    payload,
  };
}

//GET TimesheetSearchEmpAndDel action ends.

//GET TimesheetGetEmpDetailsAsPerDelegatee action starts:

export function getTimesheetEmpDetailsAsPerDelegatee() {
  return {
    type: types.GET_TIMESHEET_EMP_DETAILS_AS_PER_DELEGATEE,
  };
}

export function getTimesheetEmpDetailsAsPerDelegateeSuccess(payload) {
  return {
    type: types.GET_TIMESHEET_EMP_DETAILS_AS_PER_DELEGATEE_SUCCESS,
    payload,
  };
}

export function getTimesheetEmpDetailsAsPerDelegateeFailed(payload) {
  return {
    type: types.GET_TIMESHEET_EMP_DETAILS_AS_PER_DELEGATEE_FAILED,
    payload,
  };
}

//GET TimesheetGetEmpDetailsAsPerDelegatee action ends.

//GET TimesheetGetDelAccess action starts:

export function getTimesheetDelAccess() {
  return {
    type: types.GET_TIMESHEET_DELEGATION_ACCESS,
  };
}

export function getTimesheetDelAccessSuccess(payload) {
  return {
    type: types.GET_TIMESHEET_DELEGATION_ACCESS_SUCCESS,
    payload,
  };
}

export function getTimesheetDelAccessFailed(payload) {
  return {
    type: types.GET_TIMESHEET_DELEGATION_ACCESS_FAILED,
    payload,
  };
}

//GET TimesheetGetDelAccess action ends.

// POST Timesheet Add Delegate action start:
export function postTimesheetAddDelegate() {
  return {
    type: types.POST_TIMESHEET_ADD_DELEGATE,
  };
}

export function postTimesheetAddDelegateSuccess(payload) {
  return {
    type: types.POST_TIMESHEET_ADD_DELEGATE_SUCCESS,
    payload,
  };
}

export function postTimesheetAddDelegateFailed(payload) {
  return {
    type: types.POST_TIMESHEET_ADD_DELEGATE_FAILED,
    payload,
  };
}

// POST Timesheet Edit Delegate action start:
export function postTimesheetEditDelegate() {
  return {
    type: types.POST_TIMESHEET_EDIT_DELEGATE,
  };
}

export function postTimesheetEditDelegateSuccess(payload) {
  return {
    type: types.POST_TIMESHEET_EDIT_DELEGATE_SUCCESS,
    payload,
  };
}

export function postTimesheetEditDelegateFailed(payload) {
  return {
    type: types.POST_TIMESHEET_EDIT_DELEGATE_FAILED,
    payload,
  };
}

//ResetDeligationSnakbar Action
export function resetDeliationSnakbarTs() {
  return {
    type: types.RESET_TIMESHEET_DELIGATION,
  };
}

// Get timesheet updated delegatee ID:
export function updateTimesheetDelEmpId(payload) {
  return {
    type: types.UPDATE_TIMESHEET_DELEGATEE_EMP_ID,
    payload: payload,
  };
}

// Get updated SSO ID:
export function updateTimesheetSSOEmpId(payload) {
  return {
    type: types.UPDATED_TIMESHEET_SSO_EMP_ID,
    payload: payload,
  };
}

export function setAllChips(chips) {
  return {
    type: types.SET_CHIPS,
    payload: chips,
  };
}
//GET APPROVAL LIST FOR INVOICING
export function ApprovalListForInvoicing() {
  return {
    type: types.GET_APPROVAL_LIST_INVOICE,
  };
}
export function ApprovalListForInvoicingSuccess(payload) {
  return {
    type: types.GET_APPROVAL_LIST_INVOICE_SUCCESS,
    payload,
  };
}

export function ApprovalListForInvoicingFailed(payload) {
  return {
    type: types.GET_APPROVAL_LIST_INVOICE_FAILED,
    payload,
  };
}

// POST Import Invoicing Details action start:
export function postImportInvoicingDetails() {
  return {
    type: types.POST_IMPORT_INVOICING_DETAILS,
  };
}

export function postImportInvoicingDetailsSuccess(payload) {
  return {
    type: types.POST_IMPORT_INVOICING_DETAILS_SUCCESS,
    payload,
  };
}

export function postImportInvoicingDetailsFailed(payload) {
  return {
    type: types.POST_IMPORT_INVOICING_DETAILS_FAILED,
    payload,
  };
}
// POST Import Invoicing Details action end.

//GET APPROVAL INVOICING REPORT
export function ApprovalInvoicingReport() {
  return {
    type: types.GET_APPROVAL_INVOICING_REPORT,
  };
}
export function ApprovalInvoicingReportSuccess(payload) {
  return {
    type: types.GET_APPROVAL_INVOICING_REPORT_SUCCESS,
    payload,
  };
}

export function ApprovalInvoicingReportFailed(payload) {
  return {
    type: types.GET_APPROVAL_INVOICING_REPORT_FAILED,
    payload,
  };
}

//GET_INVOICING_AUDIT_DETAILS
export function getInvoicingAuditDetails() {
  return {
    type: types.GET_INVOICING_AUDIT_DETAILS,
  };
}
export function getInvoicingAuditDetailsSuccess(payload) {
  return {
    type: types.GET_INVOICING_AUDIT_DETAILS_SUCCESS,
    payload,
  };
}

export function getInvoicingAuditDetailsFailed(payload) {
  return {
    type: types.GET_INVOICING_AUDIT_DETAILS_FAILED,
    payload,
  };
}

//GET INVOICING EXPORT REPORT
export function InvoicingExportReport() {
  return {
    type: types.GET_INVOICING_EXPORT_REPORT,
  };
}
export function InvoicingExportReportSuccess(payload) {
  return {
    type: types.GET_INVOICING_EXPORT_REPORT_SUCCESS,
    payload,
  };
}

export function InvoicingExportReportFailed(payload) {
  return {
    type: types.GET_INVOICING_EXPORT_REPORT_FAILED,
    payload,
  };
}

export function InvoicingStatusUpdate() {
  return {
    type: types.UPDATE_STATUS_BY_MANAGER,
  };
}
export function InvoicingStatusUpdateSuccess(payload) {
  return {
    type: types.UPDATE_STATUS_BY_MANAGER_SUCCESS,
    payload,
  };
}
export function InvoicingStatusUpdateFailed(payload) {
  return {
    type: types.UPDATE_STATUS_BY_MANAGER_FAILED,
    payload,
  };
}

export function setAllPMOChips(chips) {
  return {
    type: types.SET_PMO_CHIPS,
    payload: chips,
  };
}

export function AllPMOChipsClear() {
  return {
    type: types.CLEAR_SET_PMO_CHIPS,
  };
}
export function clearMaintainStateVariableFxn() {
  return {
    type: types.CLEAR_MAINTAIN,
    // payload: payload,
  };
}

//MY TEAM APPROVAL PAGE FILTER DATA
export function myTeamApprovalFilterDropDown() {
  return {
    type: types.MY_TEAM_APPROVAL_FILTER_DROP_DOWN,
  };
}
export function myTeamApprovalFilterDropDownSuccess(payload) {
  return {
    type: types.MY_TEAM_APPROVAL_FILTER_DROP_DOWN_SUCCESS,
    payload,
  };
}
export function myTeamApprovalFilterDropDownFailed(payload) {
  return {
    type: types.MY_TEAM_APPROVAL_FILTER_DROP_DOWN_FAILED,
    payload,
  };
}

export function ApprovalInvoicingExportReport() {
  return {
    type: types.GET_APPROVAL_INVOICING_EXPORT_REPORT,
  };
}
export function ApprovalInvoicingExportReportSuccess(payload) {
  return {
    type: types.GET_APPROVAL_INVOICING_EXPORT_REPORT_SUCCESS,
    payload,
  };
}

export function ApprovalInvoicingExportReportFailed(payload) {
  return {
    type: types.GET_APPROVAL_INVOICING_EXPORT_REPORT_FAILED,
    payload,
  };
}

const aws = new AwsClient({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY, // Replace with your Access Key ID
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY, // Replace with your Secret Access Key
  service: "execute-api", // The service name
  region: "ap-south-1", // Your AWS region
});
/*------------------------------------------------------------------------------------------------------------------------------------- */
//APIs start:

//Get Timesheet Purpose API start:
export const getTimesheetReportAccessData =
  (payload) => async (dispatch, getState, api) => {
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload));
      const empId = encodeURIComponent(encryptedPara1);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/ReportsAccess?empId=${empId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheetMaster/ReportsAccess?empId=
      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/ReportsAccess?empId=${encryptedPara1}`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log("checking the payload of getTimesheetReportAccessData" ,decryptedResponse );

        dispatch(TimesheetReportAccessDataSuccess(decryptedResponse));
      }
      return response;
    } catch (error) {
      dispatch(TimesheetReportAccessDataFailed());
    }
  };

export const getTimesheetPurposeData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(TimesheetPurposeData());
    try {
      // console.log("checking the payload of getTimesheetPurposeData", payload);
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload));
      const empid = encodeURIComponent(encryptedPara1);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetTimesheetProjList?empid=${empid}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheetMaster/GetTimesheetProjList?empid=2IZXKJ96bX7LO7zY5E52Sw==
      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetTimesheetProjList?empid=${encryptedPara1}`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log(
        //   "checking the response of getTimesheetPurposeData",
        //   decryptedResponse
        // );
        dispatch(TimesheetPurposeDataSuccess(decryptedResponse));
      }
      return response;
    } catch (error) {
      dispatch(TimesheetPurposeDataFailed());
    }
  };
//Get Timesheet Purpose API end.
//Get Timesheet Category API start:
// export const getTimesheetCategoryData =
//   (payload) => async (dispatch, getState, api) => {
//     dispatch(TimesheetCategoryData());
//     try {
//       var key = process.env.REACT_APP_ENCRYPTION_KEY;
//       let encryptedPara1 = Encrypt(key, JSON.stringify(payload));
//       //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheetMaster/GetTimesheetCategories?projid=2IZXKJ96bX7LO7zY5E52Sw==
//       const response = await api.get(
//         `${config.apiUrl}TimeSheetMaster/GetTimesheetCategories?projid=${encryptedPara1}`
//       );
//       if (response.status === 200) {
//         let decryptedResponse = Decrypt(key, response.data);
//         console.log(
//           "checking the response of getTimesheetCategoryData",
//           decryptedResponse
//         );
//         dispatch(TimesheetCategoryDataSucess(decryptedResponse));
//       }
//     } catch (error) {
//       dispatch(TimesheetCategoryDataFailed());
//     }
//   };
//Get Timesheet Category API end.
//Post Create Timesheet API start:

export const postCreateTimesheetData =
  (payload) => async (dispatch, getState, api) => {
    // console.log(
    //   "payload postCreateTimesheetData is",
    //   payload.get("TimeSheetProjectLinks")
    // );
    // console.log("payload postCreateTimesheetData is", payload.get("files"));
    dispatch(postCreateTimesheet());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      const accessToken = Decrypt(key, localStorage.getItem("token"));

      const url = `${config.apiUrl}TimeSheet/CreateOrEditTimesheet`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
        body: payload,
      });
      // Sign and fetch the request
      const signedRequest = await aws.sign(request);

      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await fetch(signedRequest);
      const data = await response.text();

      //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheet/CreateTimeSheet
      // const response = await api.post(
      //   `${config.apiUrl}TimeSheet/CreateOrEditTimesheet`,
      //   payload,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      dispatch(postCreateTimesheetSuccess(data));
      return response;
    } catch (error) {
      dispatch(postCreateTimesheetFailed());

      if (error.response && error.response.status !== 200) {
        const errorResponse = error.response.data;

        return errorResponse;
      }
      throw error;
    }
  };
//Post Create Timesheet API end.
//Get My Timesheet API start:
export const getMyTimesheetData =
  (payload) => async (dispatch, getState, api) => {
    // console.log("checking the payload of getMyTimesheetData1", payload);
    dispatch(myTimesheetData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.empId));
      let startDatePara = payload?.startDate;
      let endDatePara = payload?.endDate;
      let lastWeekFlagPara = payload?.lastWeekFlag;

      const empid = encodeURIComponent(encryptedPara1);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheet/GetTimesheetData?empid=${empid}&startDate=${startDatePara}&endDate=${endDatePara}&copyLastweekFlag=${lastWeekFlagPara}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // let lastWeekFlagPara = 1 ;
      // const response = await api.get(
      //   `${config.apiUrl}TimeSheet/GetTimesheetData?empid=${encryptedPara1}&startDate=${startDatePara}&endDate=${endDatePara}&copyLastweekFlag=${lastWeekFlagPara}`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(myTimesheetDataSuccess(decryptedResponse));
        // console.log("Not Sending 200!!!", response.status);
      }
      // console.log("check res12" , response)
      return response;
    } catch (error) {
      dispatch(myTimesheetDataFailed());
      if (error.response && error.response.status !== 200) {
        const errorResponse = error.response.data;
        // You can save the errorResponse to a variable, store it in state, or handle it as needed
        // console.log("check res12err" , errorResponse)

        return errorResponse;
      }
    }
  };

//Get Pendency report data

export const getPendencyReportData =
  (payload, setIsLoader) => async (dispatch, getState, api) => {
    // console.log("payload", payload);
    // console.log("payload", payload);
    dispatch(PendencyReportData());

    const AWS = require("aws-sdk");
    // AWS.config.update({
    //   accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    //   secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    // });
    // const endpoint = `${config.pendencyUrl}?reporttype=${payload.body}`;
    // console.log("secret val before");

    // try {
    //   const response = await api.post(endpoint, JSON.stringify(payload), {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   });

    //   if (response.status === 200) {
    //     const s3 = new AWS.S3();
    //     const tempKey = `${payload.body}.xlsx`;
    //     const params = {
    //       Bucket: process.env.REACT_APP_S3_BUCKET_TIMESHEET,
    //       Key: tempKey,
    //     };
    //     s3.getObject(params, (err, data) => {
    //       if (err) {
    //       } else {
    //         let res = fileDownload(data.Body, tempKey);
    //         //console.log("payload12345" ,tempKey)
    //         setIsLoader(false);
    //         // return tempKey;
    //       }
    //     });
    //   }
    //   //return response;
    // } catch (error) {
    //   dispatch(PendencyReportDataFailed());
    //   // const s3 = new AWS.S3();
    //   // const tempKey = `${payload.body}.xlsx`;
    //   // const params = {
    //   //   Bucket: process.env.REACT_APP_S3_BUCKET_TIMESHEET,
    //   //   Key: tempKey,
    //   // };
    //   // s3.getObject(params, (err, data) => {
    //   //   if (err) {
    //   //   } else {

    //   //     let res = fileDownload(data.Body, tempKey);
    //   //     //console.log("payload12345" ,tempKey)
    //   //     setIsLoader(false);
    //   //    // return tempKey;
    //   //   }
    //   // });
    //   //console.log("return1 " , tempKey);
    //   //loader = false;
    //   //console.log("payload1" ,tempKey)
    //   // return tempKey;
    // }

    AWS.config.update({
      region: "ap-south-1",
      accessKeyId: process.env.REACT_APP_ACCESS_KEY, // Replace with your Access Key ID
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY, // Replace with your Secret Access Key
    });

    const load = {
      reporttype: payload?.body,
    };
    const stringParams = {};
    for (const key in load) {
      stringParams[key] = String(load[key]);
    }
    const lambda = new AWS.Lambda({
      httpOptions: {
        timeout: 15 * 60 * 1000, // 15 minutes in milliseconds
      },
    });
    const params = {
      FunctionName: process.env.REACT_APP_FUNCTION_NAME, // Replace with your Lambda function name
      InvocationType: "RequestResponse",
      Payload: JSON.stringify({
        queryStringParameters: stringParams,
        body: JSON.stringify(""),
      }),
    };

    try {
      const response = await lambda.invoke(params).promise();

      // console.log("secret val 1", response);
      if (response.StatusCode === 200) {
        const result = JSON.parse(response.Payload);
        const fileDown = result.body;
        // console.log("secret val 2", result, fileDown);

        // const fileName = fileDown.downloadUrl; // Extract filename from URL
        const s3 = new AWS.S3();
        const tempKey = `${payload.body}.xlsx`;
        const params = {
          Bucket: process.env.REACT_APP_S3_BUCKET_TIMESHEET,
          Key: tempKey,
        };
        s3.getObject(params, (err, data) => {
          if (err) {
          } else {
            let res = fileDownload(data.Body, tempKey);
            setIsLoader(false);
          }
        });
      }
    } catch (error) {
      dispatch(PendencyReportDataFailed());
    }

    //return loader;
  };

//Get All Projects API start:
export const getAllProjectsData = () => async (dispatch, getState, api) => {
  dispatch(getAllProjects());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;

    //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

    // Define the request
    const url = `${config.apiUrl}TimeSheetMaster/GetAllProjList`;
    const request = new Request(url, {
      method: "GET",
      headers: {
        // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
      },
    });

    const signedRequest = await aws.sign(request);
    // const response = await fetch(signedRequest);
    const headers = {};
    signedRequest.headers.forEach((value, key) => {
      headers[key] = value;
    });
    const response = await api.get(signedRequest.url, {
      headers: headers, // Attach the signed headers
    });
    //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheetMaster/GetAllProjList
    // const response = await api.get(
    //   `${config.apiUrl}TimeSheetMaster/GetAllProjList`
    // );
    if (response.status === 200) {
      let decryptedResponse = Decrypt(key, response.data);
      // console.log(
      //   "checking the response of getAllProjectsData",
      //   decryptedResponse
      // );
      dispatch(getAllProjectsSuccess(decryptedResponse));
    }
    return response;
  } catch (error) {
    dispatch(getAllProjectsFailed());
  }
};
//Get My Timesheet API end.
//Update reference date start:
export const updateReferenceDateFxn =
  (updatedRefDate) => (dispatch, getState, api) => {
    // console.log("payload in updatedRefDate: ", updatedRefDate);
    dispatch(updateReferenceDate(updatedRefDate));
  };
//Update reference date end.

//Get Weekly previous timesheetList start:
export const getWeeklyPreviousTimesheetList =
  (payload) => async (dispatch, getState, api) => {
    dispatch(weeklyPreviousTimesheetList());
    try {
      // console.log(
      //   "checking the payload of getWeeklyPreviousTimesheetData",
      //   payload
      // );
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.empId));
      let datePara = payload?.date;
      let encryptedTMId = Encrypt(key, JSON.stringify(payload?.tmId));
      let projId = payload?.projId;
      let status = payload?.status;
      //let encryptedTMId = Encrypt(key, JSON.stringify(payload?.tmId));
      //let datePara = "2023/11/01";
      const empId = encodeURIComponent(encryptedPara1);
      const tmId = encodeURIComponent(encryptedTMId);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetWeeklyPreviousTimesheetList?empId=${empId}&date=${datePara}&tmId=${tmId}&projid=${projId}&status=${status}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetWeeklyPreviousTimesheetList?empId=${encryptedPara1}&date=${datePara}&tmId=${encryptedTMId}&projid=${projId}&status=${status}`
      //   //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheet/GetTimesheetdata?empId=2IZXKJ96bX7LO7zY5E52Sw%3D%3D&startDate=2023-03-03&endDate=2023-06-03&copyLastweekFlag=1
      //   //GetWeeklyPreviousTimesheetListempId=61332&date=2023-08-10&tmId=null
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        dispatch(weeklyPreviousTimesheetListSuccess(decryptedResponse));
      }
      return response;
    } catch (error) {
      // console.log("action error", error);
      dispatch(weeklyPreviousTimesheetListFailed(error));
    }
  };

//Get Weekly previous timesheetList end.

//Get Weekly previous timesheetList start New:
export const getTempWeeklyPreviousTimesheetList =
  (payload) => async (dispatch, getState, api) => {
    dispatch(tempWeeklyPreviousTimesheetList());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.empId));
      let datePara = payload?.date;
      let encryptedTMId = Encrypt(key, JSON.stringify(payload?.tmId));
      let projId = payload?.projId;
      let status = payload?.status;
      const empId = encodeURIComponent(encryptedPara1);
      const tmId = encodeURIComponent(encryptedTMId);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetWeeklyPreviousTimesheetList?empId=${empId}&date=${datePara}&tmId=${tmId}&projid=${projId}&status=${status}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetWeeklyPreviousTimesheetList?empId=${encryptedPara1}&date=${datePara}&tmId=${encryptedTMId}&projid=${projId}&status=${status}`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(tempWeeklyPreviousTimesheetListSuccess(decryptedResponse));
      }
      return response;
    } catch (error) {
      dispatch(tempWeeklyPreviousTimesheetListFailed(error));
    }
  };

//Get Weekly previous timesheetList end New.

//Get Daily previous timesheetList start:
export const getDailyPreviousTimesheetList =
  (payload) => async (dispatch, getState, api) => {
    dispatch(dailyPreviousTimesheetList());
    // console.log("checking");
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.empId));
      let tmId = Encrypt(key, JSON.stringify(payload?.tmId));
      let projId = payload?.projId;
      let status = payload?.status;
      const empId = encodeURIComponent(encryptedPara1);
      const tmid = encodeURIComponent(tmId);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetDailyPreviousTimesheetList?empId=${empId}&tmId=${tmid}&projid=${projId}&status=${status}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetDailyPreviousTimesheetList?empId=${encryptedPara1}&tmId=${tmId}&projid=${projId}&status=${status}`
      //   //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheet/GetTimesheetdata?empId=2IZXKJ96bX7LO7zY5E52Sw%3D%3D&startDate=2023-03-03&endDate=2023-06-03&copyLastweekFlag=1
      // );
      // console.log("random console", response);
      // console.log("response");
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log(
        //   "checking the response of getDailyPreviousTimesheetList",
        //   decryptedResponse
        // );
        // console.log(
        //   "dailyPreviousTimesheet1",
        //   decryptedResponse,
        //   payload?.tmId
        // );
        dispatch(dailyPreviousTimesheetListSuccess(decryptedResponse));
      }
      return response;
    } catch (error) {
      // console.log("action error", error);
      dispatch(dailyPreviousTimesheetListFailed(error));
    }
  };

//Get My Timesheet API start:
export const getMissedWeekData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getMissedWeek());
    try {
      // console.log("checking the payload of getMyMissedWeekData", payload);
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload));
      const empId = encodeURIComponent(encryptedPara1);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetMissedWeeks?empId=${empId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetMissedWeeks?empId=${encryptedPara1}`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log(
        //   "checking the response of getMissedWeeks",
        //   decryptedResponse
        // );
        dispatch(getMissedWeekSuccess(decryptedResponse));
      }
      return response;
    } catch (error) {
      dispatch(getMissedWeekFailed());
    }
  };
//Get My Timesheet API end.

//Get My team Approval API start

export const getTotalCountApprovalData =
  (payload) => async (dispatch, getState, api) => {
    // console.log("payload", payload);
    dispatch(getMyTeamApprovalCount());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.managerEmpId));
      const managerEmpId = encodeURIComponent(encryptedPara1);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetMyTeamApprovalCount?managerEmpId=${managerEmpId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetMyTeamApprovalCount?managerEmpId=${encryptedPara1}`
      //   //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheetMaster/GetMyTeamApprovalCount?managerEmpId=Pr1hbVCeDBACTC6b88M6AQ==&emDetails=&&projDetails=
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        dispatch(getMyTeamApprovalCountSuccess(decryptedResponse));
      }
    } catch (error) {
      // console.log("action error1", error);
      dispatch(getMyTeamApprovalCountFailed(error));
    }
  };

export const getMyTeamApprovalPageWiseData =
  (payload) => async (dispatch, getState, api) => {
    // console.log("payload", payload);
    dispatch(getMyTeamApprovalPageWise());
    try {
      // console.log("checking the payload of getMyTeamApprovalData", payload);
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.empId));
      const managerEmpId = encodeURIComponent(encryptedPara1);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetMyTeamApprovalByPage?managerEmpId=${managerEmpId}&pagenumber=${payload?.pageNo}&pagesize=100`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetMyTeamApprovalByPage?managerEmpId=${encryptedPara1}&pagenumber=${payload?.pageNo}&pagesize=100`
      //   //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheetMaster/GetMyTeamApprovalByPage?managerEmpId=Pr1hbVCeDBACTC6b88M6AQ==&pagenumber=10&pagesize=100
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log(
        //   "checking the response of getMyTeamApproval",
        //   decryptedResponse
        // );
        dispatch(getMyTeamApprovalPageWiseSuccess(decryptedResponse));
      }
    } catch (error) {
      // console.log("action error1", error);
      dispatch(getMyTeamApprovalPageWiseFailed(error));
    }
  };
//all fixed project data api
export const GetAutoApprovedAllocatedProjListData =
  (payload) => async (dispatch, getState, api) => {
    // console.log("payload", payload);
    dispatch(GetAutoApprovedAllocatedProjList());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheet/GetAutoApprovedAllocatedProjList`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}TimeSheet/GetAutoApprovedAllocatedProjList`
      //   // https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheet/GetAutoApprovedAllocatedProjList
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        dispatch(GetAutoApprovedAllocatedProjListSuccess(decryptedResponse));
      }
      //return response;
    } catch (error) {
      // console.log("action error1", error);
      dispatch(GetAutoApprovedAllocatedProjListFailed(error));
    }
  };

export const getMyTeamApprovalData =
  (payload) => async (dispatch, getState, api) => {
    // console.log("props on change 2", payload);
    dispatch(getMyTeamApproval());
    try {
      // console.log("checking the payload of getMyTeamApprovalData", payload);
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.managerEmpId));
      const managerEmpId = encodeURIComponent(encryptedPara1);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetMyTeamApproval?managerEmpId=${managerEmpId}&pagenumber=${payload?.pagenumber}&pagesize=${payload?.pagesize}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetMyTeamApproval?managerEmpId=${encryptedPara1}&pagenumber=${payload?.pagenumber}&pagesize=${payload?.pagesize}`
      //   //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheetMaster/GetMyTeamApproval?managerEmpId=
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        dispatch(getMyTeamApprovalSuccess(decryptedResponse));
      }
      return response;
    } catch (error) {
      // console.log("action error1", error);
      dispatch(getMyTeamApprovalFailed(error));
    }
  };
//Get My Team Approval API end

//Update Status By Manager API starts
export const updateStatusByManagerData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(updateStatusByManager());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      const accessToken = Decrypt(key, localStorage.getItem("token"));

      const url = `${config.apiUrl}TimeSheet/UpdateStatusByManager`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
        body: payload,
      });
      // Sign and fetch the request
      const signedRequest = await aws.sign(request);

      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await fetch(signedRequest);
      //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheet/UpdateStatusByManager
      // const response = await api.post(
      //   `${config.apiUrl}TimeSheet/UpdateStatusByManager`,
      //   payload,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      if (response.status === 200) {
        const data = await response.text();

        dispatch(updateStatusByManagerSuccess(data));
      }
      return response;
    } catch (error) {
      // console.log(" bada wala error", error);
      dispatch(updateStatusByManagerFailed());
      return error;
    }
  };
//Get Calendar Date List API start:
export const getCalendarDateData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getCalendarDate());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara = Encrypt(key, JSON.stringify(payload?.empId));
      let date = payload?.date;

      const empId = encodeURIComponent(encryptedPara);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheet/GetCalenderDatesList?empId=${empId}&date=${date}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}TimeSheet/GetCalenderDatesList?empId=${encryptedPara}&date=${date}`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(getCalendarDateSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(getCalendarDateFailed());
    }
  };
//Get Calendar Date List API end.

// // Get Timesheet Dashboard Landing Page Detailed View

// export const getDetailedView = (payload) => async (dispatch, getState, api) => {
//   console.log("payload getPaymentData is", payload);
//   dispatch(viewData());
//   try {
//     var key = process.env.REACT_APP_ENCRYPTION_KEY;
//     let managerId = Encrypt(key, JSON.stringify(payload?.managerId));
//     let fromDate = payload?.fromDate;
//     let toDate = payload?.toDate;
//     let encryptedEmpId = Encrypt(key, JSON.stringify(payload?.empId));

//     const response = await api.get(
//       `${config.apiUrl}TimeSheetMaster/GetTeamTimesheetDetailView?empId=${encryptedEmpId}&managerEmpId=${managerId}&fromDate=${fromDate}&toDate=${toDate}`
//     );
//     //console.log("checking the response of getpaymentData", response.data);
//     if (response.status === 200) {
//       let decryptedResponse = Decrypt(key, response.data);
//       console.log("decryptionGetPayMethodCategoryWies : ", decryptedResponse);
//       dispatch(viewDataSucess(decryptedResponse));
//     }
//   } catch (error) {
//     dispatch(viewDataFailed());
//   }
// };

// Get Timesheet Dashboard Landing Page Detailed View

export const getDetailedView = (payload) => async (dispatch, getState, api) => {

  dispatch(viewData());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    // let managerId = Encrypt(key, JSON.stringify(payload?.managerId));
    let fromDate = payload?.fromDate;
    let toDate = payload?.toDate;
    // let encryptedEmpId = Encrypt(key, JSON.stringify(payload?.empId));
    // let encryptedDelegateeId = Encrypt(
    //   key,
    //   JSON.stringify(payload?.delegateeId)
    // );
    // Check if payload?.empId is not null, then encrypt it
    let encryptedEmpId =
      payload?.empId !== null
        ? Encrypt(key, JSON.stringify(payload?.empId))
        : payload?.empId;

    // Check if payload?.delegateeId is not null, then encrypt it
    let encryptedDelegateeId =
      payload?.delegateeId !== null
        ? Encrypt(key, JSON.stringify(payload?.delegateeId))
        : payload?.delegateeId;

    // Check if payload?.managerId is not null, then encrypt it
    let managerId =
      payload?.managerId !== null
        ? Encrypt(key, JSON.stringify(payload?.managerId))
        : payload?.managerId;

    let projId = payload?.projId;
    const empId = encodeURIComponent(encryptedEmpId);
    const managerEmpId = encodeURIComponent(managerId);
    const delegateeId = encodeURIComponent(encryptedDelegateeId);

    //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

    // Define the request
    const url = `${config.apiUrl}TimeSheetMaster/GetTeamTimesheetDetailView?empId=${empId}&managerEmpId=${managerEmpId}&delegateeId=${delegateeId}&fromDate=${fromDate}&toDate=${toDate}&projId=${projId}`;
    const request = new Request(url, {
      method: "GET",
      headers: {
        // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
      },
    });

    const signedRequest = await aws.sign(request);
    // const response = await fetch(signedRequest);
    const headers = {};
    signedRequest.headers.forEach((value, key) => {
      headers[key] = value;
    });
    const response = await api.get(signedRequest.url, {
      headers: headers, // Attach the signed headers
    });
    // const response = await api.get(
    //   `${config.apiUrl}TimeSheetMaster/GetTeamTimesheetDetailView?empId=${encryptedEmpId}&managerEmpId=${managerId}&delegateeId=${encryptedDelegateeId}&fromDate=${fromDate}&toDate=${toDate}&projId=${projId}`
    // );
    //console.log("checking the response of getpaymentData", response.data);
    if (response.status === 200) {
      let decryptedResponse = Decrypt(key, response.data);
      dispatch(viewDataSucess(decryptedResponse));
    }
    return response;
  } catch (error) {
    dispatch(viewDataFailed());
  }
};

//GET TEAM TIMESHEET API starts:
export const getTeamTimesheetData =
  (payload) => async (dispatch, getState, api) => {
    // console.log("payload team timesheet", payload);
    dispatch(getTeamTimesheet());
    try {
      // console.log("checking the payload of getTeamTimesheetData", payload);
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.managerEmpId));
      let fromDate = payload?.fromDate;
      let toDate = payload?.toDate;
      let empName = payload?.empName;
      let empCode = payload?.empCode;
      const managerEmpId = encodeURIComponent(encryptedPara1);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetTeamTimesheet?managerEmpId=${managerEmpId}&fromDate=${fromDate}&toDate=${toDate}&emName=${empName}&emCode=${empCode}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetTeamTimesheet?managerEmpId=${encryptedPara1}&fromDate=${fromDate}&toDate=${toDate}&emName=${empName}&emCode=${empCode}`
      //   //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheetMaster/GetTeamTimesheet?managerEmpId=&fromDate=&toDate=&emName=&emCode=
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log(
        //   "checking the response of getTeamTimesheetData",
        //   decryptedResponse
        // );
        dispatch(getTeamTimesheetSuccess(decryptedResponse));
      }
      return response;
    } catch (error) {
      // console.log("action error1", error);
      dispatch(getTeamTimesheetFailed(error));
    }
  };
//GET TEAM TIMESHEET DATA API ends

//Team Approval timesheet filter
export const getFilterTeamTimesheetData =
  (payload) => async (dispatch, getState, api) => {
    // console.log("payload team timesheet", payload);
    dispatch(getTeamTimesheet());
    try {
      // console.log("checking the payload of getTeamTimesheetData", payload);
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.managerEmpId));
      let fromDate = payload?.fromDate;
      let toDate = payload?.toDate;
      let projNameId = payload?.projNameId;
      let empNameId = payload?.empNameId;
      let empType = payload?.empType;
      let pagenumber = payload?.pagenumber;
      let pagesize = payload?.pagesize;
      let empCat = payload?.empCat;
      const managerEmpId = encodeURIComponent(encryptedPara1);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetMyTeamApproval?managerEmpId=${managerEmpId}&fromDate=${fromDate}&toDate=${toDate}&emDetails=${empNameId}&projDetails=${projNameId}&employeeType=${empType}&employeeCategory=${empCat}&pagenumber=${pagenumber}&pagesize=${pagesize}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetMyTeamApproval?managerEmpId=${encryptedPara1}&fromDate=${fromDate}&toDate=${toDate}&emDetails=${empNameId}&projDetails=${projNameId}&employeeType=${empType}&employeeCategory=${empCat}&pagenumber=${pagenumber}&pagesize=${pagesize}`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        // console.log(
        //   "checking the response of getTeamTimesheetData",
        //   decryptedResponse
        // );
        // console.log("here2", decryptedResponse)
        dispatch(getTeamTimesheetSuccess(decryptedResponse));
      }
    } catch (error) {
      // console.log("action error1", error);
      dispatch(getTeamTimesheetFailed(error));
    }
  };

//GET TIMESHEET ACCESS FLAG API STARTS
export const getTimesheetAccessFlagData =
  (payload) => async (dispatch, getState, api) => {
    // console.log("payload timesheet flag data", payload);
    dispatch(getTimesheetAccessFlag());
    try {
      // console.log("checking the payload of getTimesheetflagData", payload);
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.employeeId));

      const empId = encodeURIComponent(encryptedPara1);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetTimesheetAccessFlag?empId=${empId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetTimesheetAccessFlag?empId=${encryptedPara1}`
      //   //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/Master/GetTimesheetAccessFlag?empId=
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log(
        //   "checking the response of getTimesheetflagData",
        //   decryptedResponse
        // );
        dispatch(getTimesheetAccessFlagSuccess(decryptedResponse));
      }
    } catch (error) {
      // console.log("action error112", error);
      dispatch(getTimesheetAccessFlagFailed(error));
    }
  };

export const maintainState =
  (maintainStateVar) => (dispatch, getState, api) => {
    // console.log("payload in maintainState: ", maintainStateVar);
    dispatch(maintainStateVariableFxn(maintainStateVar));
  };

//post api for export as pdf

export const exportAsPdfData = (payload) => async (dispatch, getState, api) => {
  dispatch(exportAsPdf());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const accessToken = Decrypt(key, localStorage.getItem("token"));

    const url = `${config.apiUrl}TimeSheet/GetWeeklyPreviousApprovedTM`;
    const request = new Request(url, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
      },
      body: payload,
    });
    // Sign and fetch the request
    const signedRequest = await aws.sign(request);

    const headers = {};
    signedRequest.headers.forEach((value, key) => {
      headers[key] = value;
    });
    const response = await fetch(signedRequest);

    //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheet/GetWeeklyPreviousApprovedTM
    // const response = await api.post(
    //   `${config.apiUrl}TimeSheet/GetWeeklyPreviousApprovedTM`,
    //   payload,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }
    // );
    if (response.status === 200) {
      const data = await response.text();
      let decryptedResponse = Decrypt(key, data);

      dispatch(exportAsPdfSuccess(decryptedResponse));
    }
  } catch (error) {
    dispatch(exportAsPdfFailed());
  }
};

//Post Create Timesheet API start:

export const postRatingReviewData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(postRatingReview());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      const accessToken = Decrypt(key, localStorage.getItem("token"));

      const url = `${config.apiUrl}TimeSheet/InsertINextRatingEmpLink`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
        body: payload,
      });
      // Sign and fetch the request
      const signedRequest = await aws.sign(request);

      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await fetch(signedRequest);
      const data = await response.text();

      // const response = await api.post(
      //   `${config.apiUrl}TimeSheet/InsertINextRatingEmpLink`,
      //   payload,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      dispatch(postRatingReviewSuccess(data));
      return response;
    } catch (error) {
      dispatch(postRatingReviewFailed());

      if (error.response && error.response.status !== 200) {
        const errorResponse = error.response.data;
        return errorResponse;
      }
      throw error;
    }
  };
//Post Rating and Review API end.

//Get Rating Description API start.

export const getRatingDescriptionData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getRatingDescription());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheet/GetINextGetRatingDescription`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}TimeSheet/GetINextGetRatingDescription`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(getRatingDescriptionSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(getRatingDescriptionFailed(error));
    }
  };

//Get Timesheet Search Employee API start.

export const getTimesheetSearchEmployeeData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getTimesheetSearchEmployee());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetAllEmployeesSearch`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetAllEmployeesSearch`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(getTimesheetSearchEmployeeSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(getTimesheetSearchEmployeeFailed(error));
    }
  };

//Get Timesheet Search Employee API ends.

//Get Get All Delegate Search API start.

export const getTimesheetSearchDelegateData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getTimesheetSearchDelegate());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetAllDelegateSearch`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetAllDelegateSearch`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(getTimesheetSearchDelegateSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(getTimesheetSearchDelegateFailed(error));
    }
  };

//Get Timesheet Search Employee API ends.

//Get View Delegate Details API start.

export const getTimesheetViewDelegateData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getTimesheetViewDelegateDetails());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/TimesheetViewDelegateDetails`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/TimesheetViewDelegateDetails`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        dispatch(getTimesheetViewDelegateDetailsSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(getTimesheetViewDelegateDetailsFailed(error));
    }
  };

//Get View Delegate Details API ends.

//Get TimesheetGetEmpDetailsAsPerDelegatee API starts.

export const getMyTimesheetDelegateeData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getTimesheetEmpDetailsAsPerDelegatee());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara = Encrypt(key, JSON.stringify(payload));
      const delegateeId = encodeURIComponent(encryptedPara);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/TimesheetGetEmpDetailsAsPerDelegatee?delegateeId=${delegateeId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/TimesheetGetEmpDetailsAsPerDelegatee?delegateeId=${encryptedPara}`
      // );
      //TimeSheetMaster/TimesheetGetEmpDetailsAsPerDelegatee?delegateeId
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log(
        //   "checking the response of getMyTimesheetDelegateeData",
        //   decryptedResponse
        // )
        dispatch(
          getTimesheetEmpDetailsAsPerDelegateeSuccess(decryptedResponse)
        );
      }
    } catch (error) {
      dispatch(getTimesheetEmpDetailsAsPerDelegateeFailed(error));
    }
  };
//Get TimesheetGetEmpDetailsAsPerDelegatee API ends.

//Get TimesheetGetDelAccess API starts.

export const getTimesheetDelAccessData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getTimesheetDelAccess());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara = Encrypt(key, JSON.stringify(payload));
      const empId = encodeURIComponent(encryptedPara);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/TimesheetGetDelAccess?empId=${empId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/TimesheetGetDelAccess?empId=${empId}`
      // );

      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        //    console.log(
        //   "checking the response of getTimesheetDelAccessData",
        //   decryptedResponse
        // )
        dispatch(getTimesheetDelAccessSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(getTimesheetDelAccessFailed(error));
    }
  };

//Get TimesheetGetDelAccess API ends.

//Post Timesheet Add Delegate API start:

export const postTimesheetAddDelegateData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(postTimesheetAddDelegate());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      const accessToken = Decrypt(key, localStorage.getItem("token"));

      const url = `${config.apiUrl}TimeSheet/TimesheetAddDelegate`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
        body: payload,
      });
      // Sign and fetch the request
      const signedRequest = await aws.sign(request);

      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await fetch(signedRequest);
      const data = await response.text();

      //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheet/TimesheetAddDelegate' \
      // const response = await api.post(
      //   `${config.apiUrl}TimeSheet/TimesheetAddDelegate`,
      //   payload,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      dispatch(postTimesheetAddDelegateSuccess(data));
      return response;
    } catch (error) {
      dispatch(postTimesheetAddDelegateFailed());

      if (error.response && error.response.status !== 200) {
        const errorResponse = error.response.data;

        return errorResponse;
      }
      throw error;
    }
  };
//Post Timesheet Add Delegate API end.

//Post Timesheet Edit Delegate API start:

export const postTimesheetEditDelegateData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(postTimesheetEditDelegate());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      const accessToken = Decrypt(key, localStorage.getItem("token"));

      const url = `${config.apiUrl}TimeSheet/TimesheetEditDelegate`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
        body: payload,
      });
      // Sign and fetch the request
      const signedRequest = await aws.sign(request);

      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await fetch(signedRequest);
      const data = await response.text();

      // const response = await api.post(
      //   `${config.apiUrl}TimeSheet/TimesheetEditDelegate`,
      //   payload,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      dispatch(postTimesheetEditDelegateSuccess(data));
      return response;
    } catch (error) {
      dispatch(postTimesheetEditDelegateFailed());

      if (error.response && error.response.status !== 200) {
        const errorResponse = error.response.data;

        return errorResponse;
      }
      throw error;
    }
  };
//Post Timesheet Edit Delegate API end.

//Reset DeligationSnakbar

export const ResetDeligatinSnakbarTs =
  (payload) => async (dispatch, getState, api) => {
    dispatch(resetDeliationSnakbarTs());
  };

//Update Timesheet Delegatee Employee Id:
export const updateTimesheetDelegateeEmpId =
  (updatedDelEmpId) => (dispatch, getState, api) => {

    dispatch(updateTimesheetDelEmpId(updatedDelEmpId));
  };

//Update Timesheet SSO Employee Id:
export const updateTimesheetSSOEmployeeId =
  (updatedTsSSOEmpId) => (dispatch, getState, api) => {
    //console.log("payload in updateSsoEmployeeEmpId: ", updatedSsoEmpId);
    dispatch(updateTimesheetSSOEmpId(updatedTsSSOEmpId));
  };

export const getAllTimesheetReportViewData =
  (payload, setIsLoader) => async (dispatch, getState, api) => {
    // console.log("payload", payload.body);
    dispatch(AllTimesheetReportView());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      // let totalCount = payload?.totalCount;
      //   let pageSize = payload?.pageSize;
      let fromDate = payload?.fromDate;
      let toDate = payload?.toDate;
      let empDetails = payload?.empDetails;
      let empType = payload?.empType;
      let entity = payload?.entity;
      let projId = payload?.projId;
      let vertical = payload?.vertical;
      let status = payload?.status;
      let projpurpose = payload?.projpurpose;
      let pagesize = payload?.pagesize;
      let pagenumber = payload?.pagenumber;
      let CustomerName = payload?.CustomerName;

      const encstatus = encodeURIComponent(status);
      const encvertical = encodeURIComponent(vertical);
      const encprojpurpose = encodeURIComponent(projpurpose);
      const encCustomerName = encodeURIComponent(CustomerName);
      const encprojId = encodeURIComponent(projId);
      const encempType = encodeURIComponent(empType);
      const encentity = encodeURIComponent(entity);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheet/GetAllTimesheetReportViewPage?fromDate=${fromDate}&toDate=${toDate}&reporttype=${payload.body}&status=${encstatus}&entity=${encentity}&projname=${encprojId}&emDetails=${empDetails}&type=${encempType}&vertical=${encvertical}&projpurpose=${encprojpurpose}&pagenumber=${pagenumber}&pagesize=${pagesize}&CustomerName=${encCustomerName}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}TimeSheet/GetAllTimesheetReportViewPage?fromDate=${fromDate}&toDate=${toDate}&reporttype=${payload.body}&status=${status}&entity=${entity}&projname=${projId}&emDetails=${empDetails}&type=${empType}&vertical=${vertical}&projpurpose=${projpurpose}&pagenumber=${pagenumber}&pagesize=${pagesize}&CustomerName=${CustomerName}`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(AllTimesheetReportViewSuccess(decryptedResponse));
        setIsLoader(false);
      }
    } catch (error) {
      dispatch(AllTimesheetReportViewFailed(error));
    }
  };

export const getAllTimesheetReportData =
  (payload, setIsLoader) => async (dispatch, getState, api) => {
    //  console.log("payload" , payload  )
    dispatch(AllTimesheetReport());

    const AWS = require("aws-sdk");

    // const val = await getCred();
    // const value = await getPresignedUrl();
    // try {
    //   const va = await api.get(
    //     "https://fileuploadbucketdotnet.s3.ap-south-1.amazonaws.com/iNext_cred.txt?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIX%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiSDBGAiEA%2BYjgTbN2tQKcAw30aqstFXAEx103kgtlechcMae3mC4CIQDd6pWpyWfcqVODKd6DT15ENa1%2FZdcoNwZpAvuDHIpA%2FCrvAwhuEAEaDDI2NzU4MjM2NzAyNCIMB540qcqC76EUDoQVKswDZKP0mjdVgYGN%2BScuk7iCdKwZRXWg0HDkTQdSBY%2BxCOzgGdxAmC0X23W65anWZpBT69vOu7ta4290wzyElMZpU%2FBuPz4dXqxEDsvcUV48EXzMCzcSi8SelFZzR46r4gzUypRS1zxqiK6XmaVDLerdaMviSu%2BH3T27VbfIIY19x74vZXzJGZMVlwlWQUUS9BYSIW7um8aquwvm6sFLNYCkREHkUxJAma3fg7aVqck4mc7IZ%2BEWy6XPAsd3tVUG%2FrmNzlNCp36kvLb%2F030T%2FfFagZhGy1A%2FQK8CZssBF4VJ9du102GI86mmboXDgqIj173gBS0rXpqWbEjrhrRXC8GIiNoigTA4uMtd8X6HSfyrvJXuwO0UX%2F902rcoPohMg5mk3aVwdTBywuHeKrBfxPdCAcTGgN8DK6q4VJx2n5cb%2B7e8n6f%2B0VSEA2H0DB7hLCVtL2MZ25z7iks4SMmqlEq1RLArLBXaY8eVyzNS9zTELBstFZ0%2BJlMhmbV4iRClLzpAkl38ZZvA53f3d2OeXvCAH6gEEBStqndzFDtrNECG8ApGSzBQ8G7BZQhk9vFATVbzj8nw%2F8zvjyo0PKQB1nslfw5h0GoI007NFW%2BuWDD3sqy1BjqTAiOu0rTEj7dkVs0AVpBXW7NmGSrlY6QSiJMibCXbDu1DxhSCBMjtl5IjUyd3PpDtyMONcJTbHh6%2Bb8F%2Fh19QTxZbytHD82u8jMfGIPKGMlXAWkvPgcYvFSwG0AXHxG3bmbjr5F5AjE26DOEp8QoDm0vMwELLwxga20ltvdvdpR4nMJhk73PkFEj12v83iBW9kgYAXE27ZuKsrsXJOSgefvAUg1NYE5qREcxLUUnkkCK50notnuPzlOliNH7Qtn%2FbenhBHcm0mONzxY4p7TIXmPf4DCQNNWjQoYYwZ5wDE3%2BAknUVHPJWU94LhKnUfQGWOgzu2o3Mu9%2BpgQq9moEZ3EsPY0T5fnLjmii5%2BYrbayasWJQK&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240801T061037Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Credential=ASIAT4TJG4UYIXIL2NOC%2F20240801%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=95dd265f5a54cc2cf8ae14a382f15629e8c907e54fbbdd437b30c8787ab9edd1"
    //   );
    //   // const data=  JSON.parse(va);
    //   console.log("secret val 1", va, va.data, va.data.REACT_APP_ACCESS_KEY);
    //   // const data = va.data;
    //   // console.log("secret val 11", data.REACT_APP_API_URL);
    // } catch (error) {
    //   console.log("error with url : ", error);
    // }

    // AWS.config.update({
    //   accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    //   secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    // });
    // let empId = payload?.empid;
    // let fromDate = payload?.fromDate;
    // let toDate = payload?.toDate;
    // let empDetails = payload?.empDetails;
    // let empType = payload?.empType;
    // let entity = payload?.entity;
    // let projId = payload?.projId;
    // let vertical = payload?.vertical;
    // let status = payload?.status;
    // let frequency = payload?.frequency;
    // let projpurpose = payload?.projpurpose;
    // let CustomerName = payload?.CustomerName;
    // // const endpoint =`${config.pendencyUrl}?reporttype=${payload.body}`;
    // const endpoint = `${config.pendencyUrl}?reporttype=${payload.body}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&entity=${entity}&projname=${projId}&emDetails=${empDetails}&type=${empType}&vertical=${vertical}&frequency=${frequency}&projpurpose=${projpurpose}&empid=${empId}&CustomerName=${CustomerName}`;

    AWS.config.update({
      region: "ap-south-1",
      accessKeyId: process.env.REACT_APP_ACCESS_KEY, // Replace with your Access Key ID
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY, // Replace with your Secret Access Key
    });

    const load = {
      reporttype: payload?.body,
      empid: payload?.empid,
      fromDate: payload?.fromDate,
      toDate: payload?.toDate,
      emDetails: payload?.empDetails,
      type: payload?.empType,
      entity: payload?.entity,
      projname: payload?.projId,
      vertical: payload?.vertical,
      status: payload?.status,
      frequency: payload?.frequency,
      projpurpose: payload?.projpurpose,
      CustomerName: payload?.CustomerName,
    };
    const stringParams = {};
    for (const key in load) {
      stringParams[key] = String(load[key]);
    }
    const lambda = new AWS.Lambda({
      httpOptions: {
        timeout: 15 * 60 * 1000, // 15 minutes in milliseconds
      },
    });
    const params = {
      FunctionName: process.env.REACT_APP_FUNCTION_NAME, // Replace with your Lambda function name
      InvocationType: "RequestResponse",
      Payload: JSON.stringify({
        queryStringParameters: stringParams,
        body: JSON.stringify(""),
      }),
    };

    try {
      const response = await lambda.invoke(params).promise();

      // console.log("secret val 1", response);
      if (response.StatusCode === 200) {
        const result = JSON.parse(response.Payload);
        const fileDown = result.body;
        // console.log("secret val 2", result, fileDown);

        // const fileName = fileDown.downloadUrl; // Extract filename from URL
        const s3 = new AWS.S3();
        const tempKey = `${fileDown}`;
        const params = {
          Bucket: process.env.REACT_APP_S3_BUCKET_TIMESHEET,
          Key: tempKey,
        };
        s3.getObject(params, (err, data) => {
          if (err) {
          } else {
            let res = fileDownload(data.Body, tempKey);
            setIsLoader(false);
          }
        });
      }
    } catch (error) {
      dispatch(AllTimesheetReportFailed());
    }

    // try {
    //   const response = await api.post(endpoint, JSON.stringify(payload), {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   });

    //   if (response.status === 200) {
    //     const fileNameResponse = response.data;
    //     // const fileName = fileNameResponse.downloadUrl; // Extract filename from URL
    //     const s3 = new AWS.S3();
    //     const tempKey = `${fileNameResponse}`;

    //     const params = {
    //       Bucket: process.env.REACT_APP_S3_BUCKET_TIMESHEET,
    //       Key: tempKey,
    //     };
    //     s3.getObject(params, (err, data) => {
    //       if (err) {
    //       } else {
    //         let res = fileDownload(data.Body, tempKey);
    //         setIsLoader(false);
    //       }
    //     });
    //   }
    // } catch (error) {
    //   dispatch(AllTimesheetReportFailed());
    // }
  };

export const getAllTimesheetReportDataNew =
  (payload, setIsLoader) => async (dispatch, getState, api) => {
    // console.log("payload" , payload  )
    dispatch(AllTimesheetReport1());

    const AWS = require("aws-sdk");
    // AWS.config.update({
    //   accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    //   secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    // });
    // let empId = payload?.empid;
    // let fromDate = payload?.fromDate;
    // let toDate = payload?.toDate;
    // let empDetails = payload?.empDetails;
    // let empType = payload?.empType;
    // let entity = payload?.entity;
    // let projId = payload?.projId;
    // let vertical = payload?.vertical;
    // let status = payload?.status;
    // let frequency = payload?.frequency;
    // let projpurpose = payload?.projpurpose;
    // let CustomerName = payload?.CustomerName;

    // // const endpoint =`${config.pendencyUrl}?reporttype=${payload.body}`;
    // const endpoint = `${config.pendencyUrl}?reporttype=${payload.body}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&entity=${entity}&projname=${projId}&emDetails=${empDetails}&type=${empType}&vertical=${vertical}&frequency=${frequency}&projpurpose=${projpurpose}&empid=${empId}&CustomerName=${CustomerName}`;

    // try {
    //   const response = await api.post(endpoint, JSON.stringify(payload), {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   });

    //   if (response.status === 200) {
    //     const fileNameResponse = response.data;
    //     // const fileName = fileNameResponse.downloadUrl; // Extract filename from URL
    //     const s3 = new AWS.S3();
    //     const tempKey = `${fileNameResponse}`;
    //     const params = {
    //       Bucket: process.env.REACT_APP_S3_BUCKET_TIMESHEET,
    //       Key: tempKey,
    //     };
    //     s3.getObject(params, (err, data) => {
    //       if (err) {
    //       } else {
    //         let res = fileDownload(data.Body, tempKey);
    //         setIsLoader(false);
    //       }
    //     });
    //   }
    // } catch (error) {
    //   dispatch(AllTimesheetReportFailed1());
    // }

    AWS.config.update({
      region: "ap-south-1",
      accessKeyId: process.env.REACT_APP_ACCESS_KEY, // Replace with your Access Key ID
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY, // Replace with your Secret Access Key
    });

    const load = {
      reporttype: payload?.body,
      empid: payload?.empid,
      fromDate: payload?.fromDate,
      toDate: payload?.toDate,
      emDetails: payload?.empDetails,
      type: payload?.empType,
      entity: payload?.entity,
      projname: payload?.projId,
      vertical: payload?.vertical,
      status: payload?.status,
      frequency: payload?.frequency,
      projpurpose: payload?.projpurpose,
      CustomerName: payload?.CustomerName,
    };
    const stringParams = {};
    for (const key in load) {
      stringParams[key] = String(load[key]);
    }
    const lambda = new AWS.Lambda({
      httpOptions: {
        timeout: 15 * 60 * 1000, // 15 minutes in milliseconds
      },
    });
    const params = {
      FunctionName: process.env.REACT_APP_FUNCTION_NAME, // Replace with your Lambda function name
      InvocationType: "RequestResponse",
      Payload: JSON.stringify({
        queryStringParameters: stringParams,
        body: JSON.stringify(""),
      }),
    };

    try {
      const response = await lambda.invoke(params).promise();

      // console.log("secret val getAllTimesheetReportDataNew", response);
      if (response.StatusCode === 200) {
        const result = JSON.parse(response.Payload);
        const fileDown = result.body;
        // console.log("secret val 2", result, fileDown);

        // const fileName = fileDown.downloadUrl; // Extract filename from URL
        const s3 = new AWS.S3();
        const tempKey = `${fileDown}`;
        const params = {
          Bucket: process.env.REACT_APP_S3_BUCKET_TIMESHEET,
          Key: tempKey,
        };
        s3.getObject(params, (err, data) => {
          if (err) {
          } else {
            let res = fileDownload(data.Body, tempKey);
            setIsLoader(false);
          }
        });
      }
    } catch (error) {
      dispatch(AllTimesheetReportFailed1());
    }
  };
export const getTimesheetReportData =
  (payload) => async (dispatch, getState, api) => {
    // console.log("payload timesheet flag data", payload);
    dispatch(TimesheetReport());
    try {
      // console.log("checking the payload of getTimesheetflagData", payload);
      var key = process.env.REACT_APP_ENCRYPTION_KEY;

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/SchdAllTimesheetsFilterDetails`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/SchdAllTimesheetsFilterDetails`
      //   //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheetMaster/SchdAllTimesheetsFilterDetails
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log("return report 1", response.data);

        dispatch(TimesheetReportSuccess(decryptedResponse));
      }
    } catch (error) {
      // console.log("action error112", error);
      dispatch(TimesheetReportFailed(error));
    }
  };

export const allTimesheetReportClearData =
  (setIsLoader) => async (dispatch) => {
    dispatch(AllTimesheetReportClear());
    setIsLoader(false);
  };

export const getTimesheetReportAuditData =
  (payload) => async (dispatch, getState, api) => {
    //  console.log("payload timesheet flag data", payload);
    dispatch(AllTimesheetReportAudit());
    try {
      // console.log("checking the payload of getTimesheetflagData", payload);
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload));
      const tmId = encodeURIComponent(encryptedPara1);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/AuditHistroyByTMId?tmId=${tmId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/AuditHistroyByTMId?tmId=${encryptedPara1}`
      //   //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheetMaster/AuditHistroyByTMId?tmId
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log("return report 1", response.data);
        // console.log(
        //   "checking the response of getTimesheetReportauditData",
        //   decryptedResponse
        // );
        dispatch(AllTimesheetReportAuditSuccess(decryptedResponse));
      }
    } catch (error) {
      // console.log("action error112", error);
      dispatch(AllTimesheetReportAuditFailed(error));
    }
  };

// export const getApprovalListInvoiceData =
// (payload) => async (dispatch, getState, api) => {
//   //  console.log("payload timesheet flag data", payload);
//   dispatch(ApprovalListForInvoicing());
//   try {
//     // console.log("checking the payload of getTimesheetflagData", payload);
//     var key = process.env.REACT_APP_ENCRYPTION_KEY;
//     let fromDate = payload?.fromDate;
//     // let toDate = payload?.toDate;
//     let empDetails = payload?.empDetails;
//     let empType = payload?.empType;
//     let entity = payload?.entity;
//     let projId = payload?.projId;
//     let vertical = payload?.vertical;
//     // let status = payload?.status;
//     // let frequency = payload?.frequency;
//     let projpurpose = payload?.projpurpose;
//     let CustomerName=payload?.CustomerName;
//     const response = await api.get(
//       `${config.apiUrl}TimeSheetMaster/GetApprovalListforInvoicing?fromdate=${fromDate}&entity=${entity}&projName=${projId}&projPurpose=${projpurpose}&customerName=${CustomerName}&emDetails=${empDetails}&type=${empType}&vertical=${vertical}`
//       //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheetMaster/GetApprovalListforInvoicing?fromdate=&entity=&projName=&projPurpose=&customerName=&emDetails=&type=&vertical=
//     );
//     if (response.status === 200) {
//       let decryptedResponse = Decrypt(key, response.data);
//       // console.log("return report 1", response.data);
//       console.log(
//         "checking the response of getApprovalListInvoiceData",
//         decryptedResponse
//       );
//       dispatch(ApprovalListForInvoicingSuccess(decryptedResponse));
//     }
//   } catch (error) {
//     // console.log("action error112", error);
//     dispatch(ApprovalListForInvoicingFailed(error));
//   }
// };

export const getApprovalListInvoiceData =
  (payload, setIsLoader) => async (dispatch, getState, api) => {
    dispatch(ApprovalListForInvoicing());

    try {
      // console.log("checking the payload of getTimesheetflagData", payload);
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      // let encryptedPara1 = Encrypt(key, JSON.stringify(payload));
      let loginBy = payload?.loginBy;
      let delegateeId = payload?.delegateeId;
      let fromDate = payload?.fromDate;
      let toDate = payload?.toDate;
      let empDetails = payload?.empDetails;
      let empType = payload?.empType;
      let entity = payload?.entity;
      let projId = payload?.projId;
      let vertical = payload?.vertical;
      let status = payload?.status;
      // let projpurpose = payload?.projpurpose;
      // let pagesize = payload?.pagesize;
      // let pagenumber = payload?.pagenumber;
      let CustomerName = payload?.CustomerName;
      let projGroup = payload?.projGroup;
      let eCode = payload?.eCode;
      let pagenumber = payload?.pagenumber;
      let pagesize = payload?.pagesize;
      let reportflag = payload?.reportflag;
      // let eName=payload?.eName;

      const encloginBy = encodeURIComponent(loginBy);
      const encdelegateeId = encodeURIComponent(delegateeId);
      const encCustomerName = encodeURIComponent(CustomerName);
      const encvertical = encodeURIComponent(vertical);
      const encstatus = encodeURIComponent(status);
      const encprojGroup = encodeURIComponent(projGroup);
      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetApprovalListforInvoicing?reporttype=approvallistforinvoicing&loginBy=${encloginBy}&delegateeId=${encdelegateeId}&fromDate=${fromDate}&entity=${entity}&projName=${projId}&customerName=${encCustomerName}&emDetails=${empDetails}&type=${empType}&vertical=${encvertical}&status=${encstatus}&projGroup=${encprojGroup}&eCode=${eCode}&pagenumber=${pagenumber}&pagesize=${pagesize}&reportflag=${reportflag}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetApprovalListforInvoicing?reporttype=approvallistforinvoicing&loginBy=${loginBy}&delegateeId=${delegateeId}&fromDate=${fromDate}&entity=${entity}&projName=${projId}&customerName=${CustomerName}&emDetails=${empDetails}&type=${empType}&vertical=${vertical}&status=${status}&projGroup=${projGroup}&eCode=${eCode}&pagenumber=${pagenumber}&pagesize=${pagesize}&reportflag=${reportflag}`
      //   //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheetMaster/AuditHistroyByTMId?tmId
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log("return report 1", response.data);
        dispatch(ApprovalListForInvoicingSuccess(decryptedResponse));
        setIsLoader(false);
      }
    } catch (error) {
      // console.log("action error112", error);
      dispatch(ApprovalListForInvoicingFailed(error));
    }
  };

// export const getApprovalListInvoiceData =
//   (payload, setIsLoader) => async (dispatch, getState, api) => {
//     console.log("payload", payload.body);
//     dispatch(ApprovalListForInvoicing());
//     const AWS = require("aws-sdk");

//     const s3 = new AWS.S3({
//       region: "ap-south-1",
//     });
//     AWS.config.update({
//       accessKeyId: process.env.REACT_APP_ACCESS_KEY,
//       secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
//     });
//     // let totalCount = payload?.totalCount;
//     //   let pageSize = payload?.pageSize;
//     let fromDate = payload?.fromDate;
//     let toDate = payload?.toDate;
//     let empDetails = payload?.empDetails;
//     let empType = payload?.empType;
//     let entity = payload?.entity;
//     let projId = payload?.projId;
//     let vertical = payload?.vertical;
//     let status = payload?.status;
//     // let projpurpose = payload?.projpurpose;
//     // let pagesize = payload?.pagesize;
//     // let pagenumber = payload?.pagenumber;
//     let CustomerName = payload?.CustomerName;
//     // let status=payload?.invStatus;
//     let projGroup = payload?.projGroup;
//     let eName=payload?.eName;

//     const endpoint = `${config.pendencyUrl}?reporttype=approvallistforinvoicing&fromDate=${fromDate}&entity=${entity}&projName=${projId}&customerName=${CustomerName}&emDetails=${empDetails}&type=${empType}&vertical=${vertical}&status=${status}&projGroup=${projGroup}&eName=${eName}`;
//     // let frequency = payload?.frequency;
//     // const endpoint = `${config.pendencyUrl}?fromDate=${fromDate}&toDate=${toDate}&reporttype=${payload.body}&status=${status}&entity=${entity}&projname=${projId}&emDetails=${empDetails}&type=${empType}&vertical=${vertical}&projpurpose=${projpurpose}&pagenumber=${pagenumber}&pagesize=${pagesize}&CustomerName=${CustomerName}`;
//     //const responseText="";
//     //https://hswh45hyudkisls4nxz4x4nnqy0ercfi.lambda-url.ap-south-1.on.aws/?fromDate=2023-10-01&toDate=2023-10-31&reporttype=alltimesheetreportviewpage&status&entity&projname&emDetails&type&vertical
//     try {
//       var key = process.env.REACT_APP_ENCRYPTION_KEY;

//       const response = await api.post(endpoint, JSON.stringify(payload), {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//       const newUrl = JSON.parse(response.data.body);
//       // console.log("siddhant", newUrl);
//       // console.log("shivam", newUrl.downloadUrl);
//       // const getResponse=await api.get(newUrl.downloadUrl,{
//       //   headers: {
//       //     'Authorization': "",
//       //   },
//       // });
//       const getResponse = await fetch(newUrl.downloadUrl, {
//         method: "GET",
//         headers: {
//           // You can omit the 'Authorization' header here
//         },
//       });

//       // console.log("data in new report", getResponse);
//       if (getResponse.status === 200) {
//         if (getResponse.ok) {
//           const responseText = await getResponse.text(); // or .json() if it's JSON data
//           console.log("Response data:", responseText);
//           let decryptedResponse = Decrypt(key, responseText);
//           console.log("return report all new data", decryptedResponse);
//           dispatch(ApprovalListForInvoicingSuccess(decryptedResponse));
//           //setIsLoader(false);
//         }
//         setIsLoader(false);
//         //console.log("return report", response.data.body);
//         //const getResponseJson = await getResponse.json();
//       }
//       //return response;
//     } catch (error) {
//       dispatch(ApprovalListForInvoicingFailed());
//     }
//   };

//Post Import Invoicing Details API start:
export const postImportInvoicingDetailsData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(postImportInvoicingDetails());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      const accessToken = Decrypt(key, localStorage.getItem("token"));

      const url = `${config.apiUrl}TimeSheetMaster/ImportInvoicingDetails`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
        body: payload,
      });
      // Sign and fetch the request
      const signedRequest = await aws.sign(request);

      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await fetch(signedRequest);
      const data = await response.text();

      // const response = await api.post(
      //   `${config.apiUrl}TimeSheetMaster/ImportInvoicingDetails`,
      //   payload,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      dispatch(postImportInvoicingDetailsSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(postImportInvoicingDetailsFailed());

      if (error.response && error.response.status !== 200) {
        const errorResponse = error.response.data;

        return errorResponse;
      }
      throw error;
    }
  };
//Post Import Invoicing Details API end.

export const getApprovalInvoicingReportData =
  (payload, setIsLoader) => async (dispatch, getState, api) => {
    dispatch(ApprovalInvoicingReport());
    // console.log("payload of post report", payload);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const AWS = require("aws-sdk");
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    });
    let loginBy = payload?.loginBy;
    let delegateeId = payload?.delegateeId;
    let fromDate = payload?.fromDate;
    let empDetails = payload?.empDetails;
    let empType = payload?.empType;
    let entity = payload?.entity;
    let projId = payload?.projId;
    let vertical = payload?.vertical;
    // let projpurpose = payload?.projpurpose;
    let CustomerName = payload?.CustomerName;
    let status = payload?.status;
    let projGroup = payload?.projGroup;
    let pagesize = payload?.pagesize;
    let pagenumber = payload?.pagenumber;
    let reportflag = payload?.reportflag;

    let eC = payload?.eC;

    const encloginBy = encodeURIComponent(loginBy);
    const encdelegateeId = encodeURIComponent(delegateeId);
    const encCustomerName = encodeURIComponent(CustomerName);
    const encvertical = encodeURIComponent(vertical);
    const encstatus = encodeURIComponent(status);
    const enceC = encodeURIComponent(eC);
    // const endpoint = `${config.apiUrl}TimeSheetMaster/DownloadInvoicingReport?fromDate=${fromDate}&entity=${entity}&projName=${projId}&customerName=${CustomerName}&emDetails=${empDetails}&type=${empType}&vertical=${vertical}&status=${status}&projGroup=${projGroup}&eC=${eC}`;

    try {
      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/DownloadInvoicingReport?loginBy=${encloginBy}&delegateeId=${encdelegateeId}&fromDate=${fromDate}&entity=${entity}&projName=${projId}&customerName=${encCustomerName}&emDetails=${empDetails}&type=${empType}&vertical=${encvertical}&status=${encstatus}&projGroup=${projGroup}&eC=${enceC}&pagenumber=${pagenumber}&pagesize=${pagesize}&reportflag=${reportflag}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/DownloadInvoicingReport?loginBy=${loginBy}&delegateeId=${delegateeId}&fromDate=${fromDate}&entity=${entity}&projName=${projId}&customerName=${CustomerName}&emDetails=${empDetails}&type=${empType}&vertical=${vertical}&status=${status}&projGroup=${projGroup}&eC=${eC}&pagenumber=${pagenumber}&pagesize=${pagesize}&reportflag=${reportflag}`
      // );

      if (response.status === 200) {
        // const fileNameResponse = JSON.parse(response.data.body);
        // console.log("fileNameResponse",fileNameResponse)
        // const fileName = fileNameResponse.downloadUrl; // Extract filename from URL
        // console.log("fileNameResponse>>>",fileName)

        const s3 = new AWS.S3();
        // const parsedUrl = new URL(fileName);
        // console.log("fileNameResponse<<<",parsedUrl)
        // const tempKey = parsedUrl.pathname.substring(1);
        let decryptedResponse = Decrypt(key, payload?.eC);
        const tempKey = `${decryptedResponse}_${"PMOApprovalReport"}.xlsx`;
        // const tempKey = `${fileName}`;
        const params = {
          Bucket: process.env.REACT_APP_S3_BUCKET_TIMESHEET,
          Key: tempKey,
        };
        s3.getObject(params, (err, data) => {
          if (err) {
          } else {
            let res = fileDownload(data.Body, tempKey);
            setIsLoader(false);
          }
        });
      }
    } catch (error) {
      dispatch(ApprovalInvoicingReportFailed());
    }
  };

//Get Invoicing Audit Details API start:
export const getInvoicingAuditDetailsData =
  (payload, setIsLoader) => async (dispatch, getState, api) => {
    dispatch(getInvoicingAuditDetails());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetInvoicingAuditDetails?invoicingId=${payload?.invoicingId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetInvoicingAuditDetails?invoicingId=${payload?.invoicingId}`
      // );

      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(getInvoicingAuditDetailsSuccess(decryptedResponse));
        setIsLoader(false);
      }

      return response;
    } catch (error) {
      dispatch(getInvoicingAuditDetailsFailed());
    }
  };

export const getInvoiceExportReportData =
  (payload, setIsLoader) => async (dispatch, getState, api) => {
    dispatch(InvoicingExportReport());

    const AWS = require("aws-sdk");
    // AWS.config.update({
    //   accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    //   secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    // });
    // let fromDate = payload?.fromDate;
    // let empDetails = payload?.empDetails;
    // let empType = payload?.empType;
    // let entity = payload?.entity;
    // let projId = payload?.projId;
    // let vertical = payload?.vertical;
    // // let projpurpose = payload?.projpurpose;
    // let CustomerName = payload?.CustomerName;
    // let status = payload?.status;
    // let projGroup = payload?.projGroup;

    // const endpoint = `${config.pendencyUrl}?reporttype=${payload.body}&fromDate=${fromDate}&entity=${entity}&projName=${projId}&customerName=${CustomerName}&emDetails=${empDetails}&type=${empType}&vertical=${vertical}&status=${status}&projGroup=${projGroup}`;

    // try {
    //   const response = await api.post(endpoint, JSON.stringify(payload), {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   });

    //   if (response.status === 200) {
    //     const fileNameResponse = JSON.parse(response.data.body);
    //     const fileName = fileNameResponse.downloadUrl; // Extract filename from URL
    //     const s3 = new AWS.S3();
    //     const parsedUrl = new URL(fileName);
    //     const tempKey = parsedUrl.pathname.substring(1);
    //     // const tempKey = `${fileName}`;
    //     const params = {
    //       Bucket: process.env.REACT_APP_S3_BUCKET_TIMESHEET,
    //       Key: tempKey,
    //     };
    //     s3.getObject(params, (err, data) => {
    //       if (err) {
    //       } else {
    //         let res = fileDownload(data.Body, tempKey);
    //         setIsLoader(false);
    //       }
    //     });
    //   }
    // } catch (error) {
    //   dispatch(InvoicingExportReportFailed());
    // }

    AWS.config.update({
      region: "ap-south-1",
      accessKeyId: process.env.REACT_APP_ACCESS_KEY, // Replace with your Access Key ID
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY, // Replace with your Secret Access Key
    });

    const load = {
      reporttype: payload?.body,
      fromDate: payload?.fromDate,
      emDetails: payload?.empDetails,
      type: payload?.empType,
      entity: payload?.entity,
      projName: payload?.projId,
      vertical: payload?.vertical,
      status: payload?.status,
      customerName: payload?.CustomerName,
      projGroup: payload?.projGroup,
    };
    const stringParams = {};
    for (const key in load) {
      stringParams[key] = String(load[key]);
    }
    const lambda = new AWS.Lambda({
      httpOptions: {
        timeout: 15 * 60 * 1000, // 15 minutes in milliseconds
      },
    });
    const params = {
      FunctionName: process.env.REACT_APP_FUNCTION_NAME, // Replace with your Lambda function name
      InvocationType: "RequestResponse",
      Payload: JSON.stringify({
        queryStringParameters: stringParams,
        body: JSON.stringify(""),
      }),
    };

    try {
      const response = await lambda.invoke(params).promise();

      // console.log("secret val getInvoiceExportReportData", response);
      if (response.StatusCode === 200) {
        const result = JSON.parse(response.Payload);
        const fileDown = result.body;
        // console.log("secret val 2", result, fileDown);

        // const fileName = fileDown.downloadUrl; // Extract filename from URL
        const s3 = new AWS.S3();
        const tempKey = `${fileDown}`;
        const params = {
          Bucket: process.env.REACT_APP_S3_BUCKET_TIMESHEET,
          Key: tempKey,
        };
        s3.getObject(params, (err, data) => {
          if (err) {
          } else {
            let res = fileDownload(data.Body, tempKey);
            setIsLoader(false);
          }
        });
      }
    } catch (error) {
      dispatch(InvoicingExportReportFailed());
    }
  };
// invoicingStatusUpdate api
export const invoicingStatusUpdateData =
  (payload) => async (dispatch, getState, api) => {
    // console.log(
    //   "invoicingStatusUpdateData",
    //   payload.get("EmpId", "DelegateeId")
    // );
    dispatch(InvoicingStatusUpdate());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      const accessToken = Decrypt(key, localStorage.getItem("token"));

      const url = `${config.apiUrl}TimeSheetMaster/InvoicingStatusUpdate`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
        body: payload,
      });
      // Sign and fetch the request
      const signedRequest = await aws.sign(request);

      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await fetch(signedRequest);

      // https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/TimeSheet/UpdateStatusByManager
      // const response = await api.post(
      //   `${config.apiUrl}TimeSheetMaster/InvoicingStatusUpdate`,
      //   payload,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      if (response.status === 200) {
        const data = await response.text();

        dispatch(InvoicingStatusUpdateSuccess(data));
      }
      return response;
      // return
    } catch (error) {
      // console.log(" bada wala error", error);
      dispatch(InvoicingStatusUpdateFailed());
      return error;
    }
  };

export const allPMOChipsClearData = (setIsLoader) => async (dispatch) => {
  dispatch(AllPMOChipsClear());
  // setIsLoader(false);
};
export const clearMaintainState = (maintainStateVar) => (dispatch) => {
  // console.log("payload in maintainState: ", maintainStateVar);
  dispatch(clearMaintainStateVariableFxn());
};

export const getMyTeamApprovalFilterDropDownData =
  (payload) => async (dispatch, getState, api) => {
    // dispatch(myTeamApprovalFilterDropDown());
    // console.log("is coming here", payload)
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.managerEmpId));
      const managerid = encodeURIComponent(encryptedPara1);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetMyTeamApprovalFilters?managerId=${managerid}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}TimeSheetMaster/GetMyTeamApprovalFilters?managerId=${encryptedPara1}`
      // );
      if (response.status == 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(myTeamApprovalFilterDropDownSuccess(decryptedResponse));
        // setIsLoader(false);
      }
      return response;
    } catch (error) {
      dispatch(myTeamApprovalFilterDropDownFailed());
    }
  };

export const getApprovalInvoicingApprovalExportData =
  (payload, setIsLoader) => async (dispatch, getState, api) => {
    dispatch(ApprovalInvoicingExportReport());
    // console.log("payload of post report", payload);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const AWS = require("aws-sdk");
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    });
    let loginBy = payload?.loginBy;
    let delegateeId = payload?.delegateeId;
    let fromDate = payload?.fromDate;
    let empDetails = payload?.empDetails;
    let empType = payload?.empType;
    let entity = payload?.entity;
    let projId = payload?.projId;
    let vertical = payload?.vertical;
    // let projpurpose = payload?.projpurpose;
    let CustomerName = payload?.CustomerName;
    let status = payload?.status;
    let projGroup = payload?.projGroup;
    let pagesize = payload?.pagesize;
    let pagenumber = payload?.pagenumber;
    let reportflag = payload?.reportflag;

    let eC = payload?.eC;
    const encloginBy = encodeURIComponent(loginBy);
    const encdelegateeId = encodeURIComponent(delegateeId);
    const enceC = encodeURIComponent(eC);
    const encCustomerName = encodeURIComponent(CustomerName);
    const encprojGroup = encodeURIComponent(projGroup);
    const encvertical = encodeURIComponent(vertical);
    const encstatus = encodeURIComponent(status);
    // const endpoint = `${config.apiUrl}TimeSheetMaster/DownloadInvoicingReport?fromDate=${fromDate}&entity=${entity}&projName=${projId}&customerName=${CustomerName}&emDetails=${empDetails}&type=${empType}&vertical=${vertical}&status=${status}&projGroup=${projGroup}&eC=${eC}`;

    try {
      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}TimeSheetMaster/GetInvoicingExportToMSD?loginBy=${encloginBy}&delegateeId=${encdelegateeId}&fromDate=${fromDate}&entity=${entity}&projName=${projId}&customerName=${encCustomerName}&emDetails=${empDetails}&type=${empType}&vertical=${encvertical}&projGroup=${encprojGroup}&eC=${enceC}&status=${encstatus}&pagenumber=${pagenumber}&pagesize=${pagesize}&reportflag=${reportflag}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      //       const response = await api.get(
      //         `${config.apiUrl}TimeSheetMaster/GetInvoicingExportToMSD?loginBy=${loginBy}&delegateeId=${delegateeId}&fromDate=${fromDate}&entity=${entity}&projName=${projId}&customerName=${CustomerName}&emDetails=${empDetails}&type=${empType}&vertical=${vertical}&projGroup=${projGroup}&eC=${eC}&status
      // =${status}&pagenumber=${pagenumber}&pagesize=${pagesize}&reportflag=${reportflag}`
      //       );

      if (response.status === 200) {
        // const fileNameResponse = JSON.parse(response.data.body);
        // console.log("fileNameResponse",fileNameResponse)
        // const fileName = fileNameResponse.downloadUrl; // Extract filename from URL
        // console.log("fileNameResponse>>>",fileName)

        const s3 = new AWS.S3();
        // const parsedUrl = new URL(fileName);
        // console.log("fileNameResponse<<<",parsedUrl)
        // const tempKey = parsedUrl.pathname.substring(1);
        let decryptedResponse = Decrypt(key, payload?.eC);
        const tempKey = `${decryptedResponse}_InvoicingExportToMSD.xlsx`;
        // const tempKey = `${fileName}`;
        // console.log("fileNameResponse!!!", tempKey);
        const params = {
          Bucket: process.env.REACT_APP_S3_BUCKET_TIMESHEET,
          Key: tempKey,
        };
        s3.getObject(params, (err, data) => {
          if (err) {
            // console.log("errrrr>>>>",err)
          } else {
            // console.log("checkign")
            let res = fileDownload(data.Body, tempKey);
            setIsLoader(false);
          }
        });
      }
    } catch (error) {
      dispatch(ApprovalInvoicingExportReportFailed());
    }
  };
