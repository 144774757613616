import * as types from "./gmActionType";
import API from "../../Utils/endpoints";
import config from "../../Utils/config";
import history from "../../history";
import Decrypt from "../../Utils/decryption";
import Encrypt from "../../Utils/encryption";
import Dec from "../../Utils/decryptString";
import axios from "axios";
import { useState } from "react";
import { AwsClient } from "aws4fetch";

//Employee Details Action
export function employeeData() {
  return {
    type: types.GET_EMPLOYEE_LIST,
  };
}

export function employeeDataSuccess(payload) {
  return {
    type: types.GET_EMPLOYEE_LIST_SUCCESS,
    payload,
  };
}

export function employeeDataFailed(payload) {
  return {
    type: types.GET_EMPLOYEE_LIST_FAILED,
    payload,
  };
}

//Employee Skill Expertise Details Action
export function employeeSkillExpertiseData() {
  return {
    type: types.GET_EMPLOYEE_SKILL_EXPERTISE_LIST,
  };
}

export function employeeSkillExpertiseDataSuccess(payload) {
  return {
    type: types.GET_EMPLOYEE_SKILL_EXPERTISE_LIST_SUCCESS,
    payload,
  };
}

export function employeeSkillExpertiseDataFailed(payload) {
  return {
    type: types.GET_EMPLOYEE_SKILL_EXPERTISE_LIST_FAILED,
    payload,
  };
}

//Skills Action
export function skillData() {
  return {
    type: types.GET_SKILL_LIST,
  };
}

export function skillDataSuccess(payload) {
  return {
    type: types.GET_SKILL_LIST_SUCCESS,
    payload,
  };
}
export function skillDataFailed(payload) {
  return {
    type: types.GET_SKILL_LIST_FAILED,
    payload,
  };
}

//Skill Range Action
export function skillRangeData() {
  return {
    type: types.GET_SKILL_RANGE_LIST,
  };
}

export function skillRangeDataSuccess(payload) {
  return {
    type: types.GET_SKILL_RANGE_LIST_SUCCESS,
    payload,
  };
}
export function skillRangeDataFailed(payload) {
  return {
    type: types.GET_SKILL_RANGE_LIST_FAILED,
    payload,
  };
}

// Business Unit Action
export function businessUnitData() {
  return {
    type: types.GET_BUSINESS_UNIT_LIST,
  };
}

export function businessUnitDataSuccess(payload) {
  return {
    type: types.GET_BUSINESS_UNIT_LIST_SUCCESS,
    payload,
  };
}

export function businessUnitDataFailed(payload) {
  return {
    type: types.GET_BUSINESS_UNIT_LIST_FAILED,
    payload,
  };
}

// Client Action
export function clientData() {
  return {
    type: types.GET_CLIENT_LIST,
  };
}

export function clientDataSuccess(payload) {
  return {
    type: types.GET_CLIENT_LIST_SUCCESS,
    payload,
  };
}

export function clientDataFailed(payload) {
  return {
    type: types.GET_CLIENT_LIST_FAILED,
    payload,
  };
}

// Visa Type Action
export function visaTypeData() {
  return {
    type: types.GET_VISA_TYPE_LIST,
  };
}

export function visaTypeDataSuccess(payload) {
  return {
    type: types.GET_VISA_TYPE_LIST_SUCCESS,
    payload,
  };
}

export function visaTypeDataFailed(payload) {
  return {
    type: types.GET_VISA_TYPE_LIST_FAILED,
    payload,
  };
}

// BGV Type Action
export function bgvTypeData() {
  return {
    type: types.GET_BGV_TYPE_LIST,
  };
}

export function bgvTypeDataSuccess(payload) {
  return {
    type: types.GET_BGV_TYPE_LIST_SUCCESS,
    payload,
  };
}

export function bgvTypeDataFailed(payload) {
  return {
    type: types.GET_BGV_TYPE_LIST_FAILED,
    payload,
  };
}

// State Action
export function stateData() {
  return {
    type: types.GET_STATE_LIST,
  };
}

export function stateDataSuccess(payload) {
  return {
    type: types.GET_STATE_LIST_SUCCESS,
    payload,
  };
}

export function stateDataFailed(payload) {
  return {
    type: types.GET_STATE_LIST_FAILED,
    payload,
  };
}

// City Action
export function cityData() {
  return {
    type: types.GET_CITY_LIST,
  };
}

export function cityDataSuccess(payload) {
  return {
    type: types.GET_CITY_LIST_SUCCESS,
    payload,
  };
}

export function cityDataFailed(payload) {
  return {
    type: types.GET_CITY_LIST_FAILED,
    payload,
  };
}

// Work Location Action
export function workLocationData() {
  return {
    type: types.GET_WORK_LOCATION_LIST,
  };
}

export function workLocationSuccess(payload) {
  return {
    type: types.GET_WORK_LOCATION_LIST_SUCCESS,
    payload,
  };
}

export function workLocationFailed(payload) {
  return {
    type: types.GET_WORK_LOCATION_LIST_FAILED,
    payload,
  };
}

// Other Charges Action
export function otherChargesData() {
  return {
    type: types.GET_OTHER_CHARGES,
  };
}

export function otherChargesSuccess(payload) {
  return {
    type: types.GET_OTHER_CHARGES_SUCCESS,
    payload,
  };
}

export function otherChargesFailed(payload) {
  return {
    type: types.GET_OTHER_CHARGES_FAILED,
    payload,
  };
}

// Insert Into Master Action
export function postMobilityForm() {
  return {
    type: types.POST_MOBILITY_FORM,
  };
}

export function postMobilityFormSuccess(payload) {
  return {
    type: types.POST_MOBILITY_FORM_SUCCESS,
    payload,
  };
}

export function postMobilityFormFailed(payload) {
  return {
    type: types.POST_MOBILITY_FORM_FAILED,
    payload,
  };
}

// Update Into Master Action
export function postBUHeadForm() {
  return {
    type: types.POST_BUHEAD_FORM,
  };
}

export function postBUHeadFormSuccess(payload) {
  return {
    type: types.POST_BUHEAD_FORM_SUCCESS,
    payload,
  };
}

export function postBUHeadFormFailed(payload) {
  return {
    type: types.POST_BUHEAD_FORM_FAILED,
    payload,
  };
}

export function postGMStatusL1L2() {
  return {
    type: types.POST_GM_STATUS_L1_L2,
  };
}

export function postGMStatusL1L2Success(payload) {
  return {
    type: types.POST_GM_STATUS_L1_L2_SUCCESS,
    payload,
  };
}

export function postGMStatusL1L2Failed(payload) {
  return {
    type: types.POST_GM_STATUS_L1_L2_FAILED,
    payload,
  };
}

// View Details Action
export function gmIdWiseEmployeeData() {
  return {
    type: types.GET_GMIDWISEEMPOYEE_DETAILS,
  };
}

export function gmIdWiseEmployeeDataSuccess(payload) {
  return {
    type: types.GET_GMIDWISEEMPOYEE_DETAILS_SUCCESS,
    payload,
  };
}

export function gmIdWiseEmployeeDataFailed(payload) {
  return {
    type: types.GET_GMIDWISEEMPOYEE_DETAILS_FAILED,
    payload,
  };
}

//VIEW DETAILS GM
export function viewDetailsData() {
  return {
    type: types.GET_VIEW_DETAILS_DATA,
  };
}

export function viewDetailsDataSuccess(payload) {
  return {
    type: types.GET_VIEW_DETAILS_DATA_SUCCESS,
    payload,
  };
}

export function viewDetailsDataFailed(payload) {
  return {
    type: types.GET_VIEW_DETAILS_DATA_FAILED,
    payload,
  };
}
//EMPLOYEEWISEDATA
export function employeeWiseData() {
  return {
    type: types.GET_EMPLOYEEWISE_DATA,
  };
}

export function employeeWiseDataSuccess(payload) {
  return {
    type: types.GET_EMPLOYEEWISE_DATA_SUCCESS,
    payload,
  };
}

export function employeeWiseDataFailed(payload) {
  return {
    type: types.GET_EMPLOYEEWISE_DATA_FAILED,
    payload,
  };
}

//EMPLOYEEWISEDATA
export function employeeWiseSkillData() {
  return {
    type: types.POST_EMPLOYEEWISESKILL_DATA,
  };
}

export function employeeWiseSkillDataSuccess(payload) {
  return {
    type: types.POST_EMPLOYEEWISESKILL_DATA_SUCCESS,
    payload,
  };
}

export function employeeWiseSkillDataFailed(payload) {
  return {
    type: types.POST_EMPLOYEEWISESKILL_DATA_FAILED,
    payload,
  };
}

//GM STATUS
export function statusData() {
  return {
    type: types.GET_STATUS_DATA,
  };
}

export function statusDataSUCCESS(payload) {
  return {
    type: types.GET_STATUS_DATA_SUCCESS,
    payload,
  };
}

export function statusDataFailed(payload) {
  return {
    type: types.GET_STATUS_DATA_FAILED,
    payload,
  };
}
//STEPPERDATA
export function stepperData() {
  return {
    type: types.GET_STEPPER_DATA,
  };
}

export function stepperDataSuccess(payload) {
  return {
    type: types.GET_STEPPER_DATA_SUCCESS,
    payload,
  };
}

export function stepperDataFailed(payload) {
  return {
    type: types.GET_STEPPER_DATA_FAILED,
    payload,
  };
}

//Dependents Cost Action
export function dependentCostData() {
  return {
    type: types.GET_DEPENDENTS_COST,
  };
}

export function dependentCostDataSuccess(payload) {
  return {
    type: types.GET_DEPENDENTS_COST_SUCCESS,
    payload,
  };
}

export function dependentCostDataFailed(payload) {
  return {
    type: types.GET_DEPENDENTS_COST_FAILED,
    payload,
  };
}

//Dashboard
export function dashboardData() {
  return {
    type: types.GET_DASH_DATA_LIST,
  };
}

//Dashboard Success Response
export function dashboardDataSuccess(payload) {
  return {
    type: types.GET_DASH_DATA_LIST_SUCCESS,
    payload,
  };
}

//Dashboard Failed Response
export function dashboardDataFailed(payload) {
  return {
    type: types.GET_DASH_DATA_LIST_FAILED,
    payload,
  };
}

//Dashboard Status
export function dashboardStatusData() {
  return {
    type: types.GET_DASH_STATUS_DATA_LIST,
  };
}

//Employee Roles
export function employeeRolesData() {
  return {
    type: types.GET_EMP_ROLES_DATA_LIST,
  };
}

//Employee Roles Failed
export function employeeRolesDataFailed(payload) {
  return {
    type: types.GET_EMP_ROLES_DATA_LIST_FAILED,
    payload,
  };
}

//Dashboard Status
export function employeeRolesDataSuccess(payload) {
  return {
    type: types.GET_EMP_ROLES_DATA_LIST_SUCCESS,
    payload,
  };
}

//Dashboard Status Success Response
export function dashboardStatusDataSuccess(payload) {
  return {
    type: types.GET_DASH_STATUS_DATA_LIST_SUCCESS,
    payload,
  };
}

//Dashboard Status Failed Response
export function dashboardStatusDataFailed(payload) {
  return {
    type: types.GET_DASH_STATUS_DATA_LIST_FAILED,
    payload,
  };
}

//Dashboard BU Data List
export function dashboardBUData() {
  return {
    type: types.GET_DASH_BU_DATA_LIST,
  };
}

//Dashboard Status Success Response
export function dashboardBUDataSuccess(payload) {
  return {
    type: types.GET_DASH_BU_DATA_LIST_SUCCESS,
    payload,
  };
}

//Dashboard Status Failed Response
export function dashboardBUDataFailed(payload) {
  return {
    type: types.GET_DASH_BU_DATA_LIST_FAILED,
    payload,
  };
}

//Dashboard Delete Row Data
export function deleteRow() {
  return {
    type: types.GET_DELETE_ROW,
  };
}

//Dashboard Delete Row Data Success Response
export function deleteRowSuccess(payload) {
  return {
    type: types.GET_DELETE_ROW_SUCCESS,
    payload,
  };
}

//Dashboard Delete Row Data Failed Response
export function deleteRowFailed(payload) {
  return {
    type: types.GET_DELETE_ROW_FAILED,
    payload,
  };
}

// Reset Reponse

export function resetGmResponse() {
  return {
    type: types.RESET_GM_RESPONSE,
  };
}

//Recommended Salary Range Action
export function recommendedSalaryRangeData() {
  return {
    type: types.GET_RECOMMENDED_SALARY_RANGE,
  };
}

export function recommendedSalaryRangeDataSuccess(payload) {
  return {
    type: types.GET_RECOMMENDED_SALARY_RANGE_SUCCESS,
    payload,
  };
}

export function recommendedSalaryRangeDataFailed(payload) {
  return {
    type: types.GET_RECOMMENDED_SALARY_RANGE_FAILED,
    payload,
  };
}

//Variable Action
export function variableData() {
  return {
    type: types.GET_VARIABLE,
  };
}

export function variableDataSuccess(payload) {
  return {
    type: types.GET_VARIABLE_SUCCESS,
    payload,
  };
}

export function variableDataFailed(payload) {
  return {
    type: types.GET_VARIABLE_FAILED,
    payload,
  };
}

// GET GlobalMobility Search Employee action starts.

export function getGlobalMobilitySearchEmployee() {
  return {
    type: types.GET_GLOBAL_MOBILITY_SEARCH_EMPLOYEE,
  };
}

export function getGlobalMobilitySearchEmployeeSuccess(payload) {
  return {
    type: types.GET_GLOBAL_MOBILITY_SEARCH_EMPLOYEE_SUCCESS,
    payload,
  };
}

export function getGlobalMobilitySearchEmployeeFailed(payload) {
  return {
    type: types.GET_GLOBAL_MOBILITY_SEARCH_EMPLOYEE_FAILED,
    payload,
  };
}
// GET GlobalMobility Search Employee action ends.

// GET GlobalMobility Search Delegate action starts.

export function getGlobalMobilitySearchDelegate() {
  return {
    type: types.GET_GLOBAL_MOBILITY_SEARCH_DELEGATE,
  };
}

export function getGlobalMobilitySearchDelegateSuccess(payload) {
  return {
    type: types.GET_GLOBAL_MOBILITY_SEARCH_DELEGATE_SUCCESS,
    payload,
  };
}

export function getGlobalMobilitySearchDelegateFailed(payload) {
  return {
    type: types.GET_GLOBAL_MOBILITY_SEARCH_DELEGATE_FAILED,
    payload,
  };
}
// GET GlobalMobility Search Employee action ends.

//GET ViewDelegateDetails action starts:

export function getGlobalMobilityViewDelegateDetails() {
  return {
    type: types.GET_GLOBAL_MOBILITY_VIEW_DELEGATE_DETAILS,
  };
}

export function getGlobalMobilityViewDelegateDetailsSuccess(payload) {
  return {
    type: types.GET_GLOBAL_MOBILITY_VIEW_DELEGATE_DETAILS_SUCCESS,
    payload,
  };
}

export function getGlobalMobilityViewDelegateDetailsFailed(payload) {
  return {
    type: types.GET_GLOBAL_MOBILITY_VIEW_DELEGATE_DETAILS_FAILED,
    payload,
  };
}

//GET ViewDelegateDetails action ends.

//GET GlobalMobilitySearchEmpAndDel action starts:

export function getGlobalMobilitySearchEmpAndDel() {
  return {
    type: types.GET_GLOBAL_MOBILITY_SEARCH_EMP_AND_DEL,
  };
}

export function getGlobalMobilitySearchEmpAndDelSuccess(payload) {
  return {
    type: types.GET_GLOBAL_MOBILITY_SEARCH_EMP_AND_DEL_SUCCESS,
    payload,
  };
}

export function getGlobalMobilitySearchEmpAndDelFailed(payload) {
  return {
    type: types.GET_GLOBAL_MOBILITY_SEARCH_EMP_AND_DEL_FAILED,
    payload,
  };
}

//GET GlobalMobilitySearchEmpAndDel action ends.

//GET GMGetEmpDetailsAsPerDelegatee action starts:

export function getGlobalMobilityEmpDetailsAsPerDelegatee() {
  return {
    type: types.GET_GLOBAL_MOBILITY_EMP_DETAILS_AS_PER_DELEGATEE,
  };
}

export function getGlobalMobiityEmpDetailsAsPerDelegateeSuccess(payload) {
  return {
    type: types.GET_GLOBAL_MOBILITY_EMP_DETAILS_AS_PER_DELEGATEE_SUCCESS,
    payload,
  };
}

export function getGlobalMobilityEmpDetailsAsPerDelegateeFailed(payload) {
  return {
    type: types.GET_GLOBAL_MOBILITY_EMP_DETAILS_AS_PER_DELEGATEE_FAILED,
    payload,
  };
}

//GET GMGetEmpDetailsAsPerDelegatee action ends.

//GET GLOBAL MOBILITY ACCESS FLAG action start
export function getGlobalMobilityAccessFlag() {
  return {
    type: types.GET_GLOBAL_MOBILITY_ACCESS_FLAG,
  };
}
export function getGlobalMobilityAccessFlagSuccess(payload) {
  return {
    type: types.GET_GLOBAL_MOBILITY_ACCESS_FLAG_SUCCESS,
    payload,
  };
}
export function getGlobalMobilityAccessFlagFailed(payload) {
  return {
    type: types.GET_GLOBAL_MOBILITY_ACCESS_FLAG_FAILED,
    payload,
  };
}

//GET GlobalMobilityGetDelAccess action starts:

export function getGlobalMobilityDelAccess() {
  return {
    type: types.GET_GLOBAL_MOBILITY_DELEGATION_ACCESS,
  };
}

export function getGlobalMobilityDelAccessSuccess(payload) {
  return {
    type: types.GET_GLOBAL_MOBILITY_DELEGATION_ACCESS_SUCCESS,
    payload,
  };
}

export function getGlobalMobilityDelAccessFailed(payload) {
  return {
    type: types.GET_GLOBAL_MOBILITY_DELEGATION_ACCESS_FAILED,
    payload,
  };
}

//GET GlobalMobilityGetDelAccess action ends.

// POST GlobalMobility Add Delegate action start:
export function postGlobalMobilityAddDelegate() {
  return {
    type: types.POST_GLOBAL_MOBILITY_ADD_DELEGATE,
  };
}

export function postGlobalMobilityAddDelegateSuccess(payload) {
  return {
    type: types.POST_GLOBAL_MOBILITY_ADD_DELEGATE_SUCCESS,
    payload,
  };
}

export function postGlobalMobilityAddDelegateFailed(payload) {
  return {
    type: types.POST_GLOBAL_MOBILITY_ADD_DELEGATE_FAILED,
    payload,
  };
}

// POST GlobalMobility Add Delegate action start:

// POST GlobalMobility Edit Delegate action start:
export function postGlobalMobilityEditDelegate() {
  return {
    type: types.POST_GLOBAL_MOBILITY_EDIT_DELEGATE,
  };
}

export function postGlobalMobilityEditDelegateSuccess(payload) {
  return {
    type: types.POST_GLOBAL_MOBILITY_EDIT_DELEGATE_SUCCESS,
    payload,
  };
}

export function postGlobalMobilityEditDelegateFailed(payload) {
  return {
    type: types.POST_GLOBAL_MOBILITY_EDIT_DELEGATE_FAILED,
    payload,
  };
}

// POST GlobalMobility Edit Delegate action start:

//ResetDeligationSnakbar Action
export function resetDeliationSnakbarGM() {
  return {
    type: types.RESET_GLOBAL_MOBILITY_DELIGATION,
  };
}

// Get Global Mobility updated delegatee ID:
export function updateGlobalMobilityDelEmpId(payload) {
  return {
    type: types.UPDATE_GLOBAL_MOBILITY_DELEGATEE_EMP_ID,
    payload: payload,
  };
}

// Get updated SSO ID:
export function updateGlobalMobilitySSOEmpId(payload) {
  return {
    type: types.UPDATED_GLOBAL_MOBILITY_SSO_EMP_ID,
    payload: payload,
  };
}











export function getViewBandwiseSalaryRange() {
  return {
    type: types.GET_VIEW_BANDWISE_SALARY_RANGE,
  };
}
export function getViewBandwiseSalaryRangeSuccess(payload) {
  return {
    type: types.GET_VIEW_BANDWISE_SALARY_RANGE_SUCCESS,
    payload,
  };
}
export function getViewBandwiseSalaryRangeFailed(payload) {
  return {
    type: types.GET_VIEW_BANDWISE_SALARY_RANGE_FAILED,
    payload,
  };
}

export function getEmployeeSpecificSalaryRange() {
  return {
    type: types.GET_EMPLOYEE_SPECIFIC_SALARY_RANGE,
  };
}

export function getEmployeeSpecificSalaryRangeSuccess(payload) {
  return {
    type: types.GET_EMPLOYEE_SPECIFIC_SALARY_RANGE_SUCCESS,
    payload,
  };
}

export function getEmployeeSpecificSalaryRangeFailed(payload) {
  return {
    type: types.GET_EMPLOYEE_SPECIFIC_SALARY_RANGE_FAILED,
    payload,
  };
}



const aws = new AwsClient({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY, // Replace with your Access Key ID
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY, // Replace with your Secret Access Key
  service: "execute-api", // The service name
  region: "ap-south-1", // Your AWS region
});

export function getGMSignedURL() {
  return {
    type: types.GET_GM_SIGNED_URL,
  };
}

export function getGMSignedURLSuccess(payload) {
  return {
    type: types.GET_GM_SIGNED_URL_SUCCESS,
    payload,
  };
}

export function getGMSignedURLFailed(payload) {
  return {
    type: types.GET_GM_SIGNED_URL_FAILED,
    payload,
  };
}

export function deleteGMFileUpload() {
  return {
    type: types.DELETE_GM_FILE_UPLOAD,
  };
}

export function deleteGMFileUploadSuccess(payload) {
  return {
    type: types.DELETE_GM_FILE_UPLOAD_SUCCESS,
    payload,
  };
}

export function deleteGMFileUploadFailed(payload) {
  return {
    type: types.DELETE_GM_FILE_UPLOAD_FAILED,
    payload,
  };
}
//-----------------------------------------------------------------------------------
//GET GM VIEW DETAILS
export const getViewDetailsData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(viewDetailsData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara = Encrypt(key, JSON.stringify(payload)); //gmid//payload?.gmid

      const gmId = encodeURIComponent(encryptedPara);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/ViewDetailsCityWise?gmId=${gmId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/ViewDetailsCityWise?gmId=${encryptedPara}`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        //console.log("Checking 2", decryptedResponse);
        dispatch(viewDetailsDataSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(viewDetailsDataFailed);
    }
  };

//GET EMPLOYEEWISEDATA
export const getEmployeeWiseData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(employeeWiseData);
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara = Encrypt(key, JSON.stringify(payload)); //gmid//payload?.gmid

      const empid = encodeURIComponent(encryptedPara);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GetEmployeeRole?empid=${empid}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // s
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GetEmployeeRole?empid=${encryptedPara}`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        //console.log("Checking 2.... ", decryptedResponse);
        dispatch(employeeWiseDataSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(employeeWiseDataFailed);
    }
  };

// Post Mobility Form
export const postEmployeeWiseSkillData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(employeeWiseSkillData());
    try {
      const url = `${config.apiUrl}GMCalculator/EmployeeWiseSkills`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
        body: JSON.stringify(payload),
      });
      // Sign and fetch the request
      const signedRequest = await aws.sign(request);

      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.post(
        signedRequest.url,
        JSON.stringify(payload),
        {
          headers: headers,
        }
      );

      // const response = await api.post(
      //   `${config.apiUrl}GMCalculator/EmployeeWiseSkills`,
      //   payload,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );

      if (response.status === 200) {
        dispatch(employeeWiseSkillDataSuccess(response.data));
      }
      return response; // Return the response object
    } catch (error) {
      dispatch(employeeWiseSkillDataFailed());
      throw error; // Re-throw the error to be caught in the calling code
    }
  };

//GET GM STATUS DATA
export const getStatusData = (payload) => async (dispatch, getState, api) => {
  dispatch(statusData());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let encryptedPara = Encrypt(key, JSON.stringify());
    const empid = encodeURIComponent(encryptedPara);

    //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

    // Define the request
    const url = `${config.apiUrl}GMCalculator/GetGMStatus?empid=${empid}`;
    const request = new Request(url, {
      method: "GET",
      headers: {
        // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
      },
    });

    const signedRequest = await aws.sign(request);
    // const response = await fetch(signedRequest);
    const headers = {};
    signedRequest.headers.forEach((value, key) => {
      headers[key] = value;
    });
    const response = await api.get(signedRequest.url, {
      headers: headers, // Attach the signed headers
    });
    // const response = await api.get(
    //   `${config.apiUrl}GMCalculator/GetGMStatus?empid=${encryptedPara}`
    // );
    if (response.status === 200) {
      let decryptedResponse = Decrypt(key, response.data);
      //console.log("Hello Employees", decryptedResponse);
      dispatch(statusDataSUCCESS(decryptedResponse));
    }
  } catch (error) {
    dispatch(statusDataFailed);
  }
};

export const getStepperData = (payload) => async (dispatch, getState, api) => {
  dispatch(stepperData());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    //console.log("test1", payload)
    let encryptedPara = Encrypt(key, JSON.stringify(payload)); //gmid//db team
    const gmid = encodeURIComponent(encryptedPara);

    //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

    // Define the request
    const url = `${config.apiUrl}GMCalculator/ViewStepperDetails?gmid=${gmid}`;
    const request = new Request(url, {
      method: "GET",
      headers: {
        // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
      },
    });

    const signedRequest = await aws.sign(request);
    // const response = await fetch(signedRequest);
    const headers = {};
    signedRequest.headers.forEach((value, key) => {
      headers[key] = value;
    });
    const response = await api.get(signedRequest.url, {
      headers: headers, // Attach the signed headers
    });
    // const response = await api.get(
    //   `${config.apiUrl}GMCalculator/ViewStepperDetails?gmid=${encryptedPara}`
    // );
    if (response.status === 200) {
      let decryptedResponse = Decrypt(key, response.data);
      //console.log("Hello Employees",response.data );
      dispatch(stepperDataSuccess(decryptedResponse));
    }
  } catch (error) {
    dispatch(stepperDataFailed);
  }
};
// Get Employee Lists
export const getEmployeeData = () => async (dispatch, getState, api) => {
  dispatch(employeeData());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;

    //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

    // Define the request
    const url = `${config.apiUrl}GMCalculator/GetEmpList`;
    const request = new Request(url, {
      method: "GET",
      headers: {
        // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
      },
    });

    const signedRequest = await aws.sign(request);
    // const response = await fetch(signedRequest);
    const headers = {};
    signedRequest.headers.forEach((value, key) => {
      headers[key] = value;
    });
    const response = await api.get(signedRequest.url, {
      headers: headers, // Attach the signed headers
    });
    // const response = await api.get(`${config.apiUrl}GMCalculator/GetEmpList`);
    if (response.status === 200) {
      let decryptedResponse = Decrypt(key, response.data);
      // //console.log("Hello Employees", decryptedResponse);
      dispatch(employeeDataSuccess(decryptedResponse));
    }
  } catch (error) {
    dispatch(employeeDataFailed());
  }
};

// Get Employee Lists
export const getEmployeeSkillExpertiseData =
  () => async (dispatch, getState, api) => {
    dispatch(employeeSkillExpertiseData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GetSkillExpertiseList`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GetSkillExpertiseList`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // //console.log("Hello Employees", decryptedResponse);
        dispatch(employeeSkillExpertiseDataSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(employeeSkillExpertiseDataFailed());
    }
  };

// Get Skills by Employee Id
export const getSkillData = (empid) => async (dispatch, getState, api) => {
  dispatch(skillData());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let encryptedPara = Encrypt(key, JSON.stringify(empid));

    const empId = encodeURIComponent(encryptedPara);

    //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

    // Define the request
    const url = `${config.apiUrl}GMCalculator/GetSkill?empid=${empId}`;
    const request = new Request(url, {
      method: "GET",
      headers: {
        // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
      },
    });

    const signedRequest = await aws.sign(request);
    // const response = await fetch(signedRequest);
    const headers = {};
    signedRequest.headers.forEach((value, key) => {
      headers[key] = value;
    });
    const response = await api.get(signedRequest.url, {
      headers: headers, // Attach the signed headers
    });
    // const response = await api.get(
    //   `${config.apiUrl}GMCalculator/GetSkill?empid=${encryptedPara}`
    // );
    // //console.log("Response", response, config.apiUrl);

    if (response.status === 200) {
      // //console.log("Hello Skills", response.data, response)
      let decryptedResponse = Decrypt(key, response.data);
      // //console.log("Hello Skills", decryptedResponse);
      dispatch(skillDataSuccess(decryptedResponse));
    }
  } catch (error) {
    dispatch(skillDataFailed());
  }
};

// Get Business Units
export const getBusinessUnitData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(businessUnitData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GetBusinessUnit`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GetBusinessUnit`
      // );
      // //console.log("Response Business: ", response);
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(businessUnitDataSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(businessUnitDataFailed());
    }
  };

// Get Clients by buId API
export const getClientData = (buId) => async (dispatch, getState, api) => {
  dispatch(clientData());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let encryptedPara = Encrypt(key, JSON.stringify(buId));
    const buid = encodeURIComponent(encryptedPara);

    //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

    // Define the request
    const url = `${config.apiUrl}GMCalculator/GetClient?buId=${buid}`;
    const request = new Request(url, {
      method: "GET",
      headers: {
        // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
      },
    });

    const signedRequest = await aws.sign(request);
    // const response = await fetch(signedRequest);
    const headers = {};
    signedRequest.headers.forEach((value, key) => {
      headers[key] = value;
    });
    const response = await api.get(signedRequest.url, {
      headers: headers, // Attach the signed headers
    });
    // const response = await api.get(
    //   `${config.apiUrl}GMCalculator/GetClient/?buId=${encryptedPara}`
    // );
    //console.log("Response", response, config.apiUrl);

    if (response.status === 200) {
      let decryptedResponse = Decrypt(key, response.data);
      //console.log("Hello Clients ------->>>>", decryptedResponse);
      dispatch(clientDataSuccess(decryptedResponse));
    }
  } catch (error) {
    //console.log("Hello Clients ------->>>> I am in Catch");
    dispatch(clientDataFailed());
  }
};

// Get Visa Type by countryID
export const getVisaTypeData = (payload) => async (dispatch, getState, api) => {
  dispatch(visaTypeData());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let encryptedPara = Encrypt(key, JSON.stringify(2));

    const vcountryid = encodeURIComponent(encryptedPara);

    //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

    // Define the request
    const url = `${config.apiUrl}GMCalculator/GetVisaType?vcountryid=${vcountryid}`;
    const request = new Request(url, {
      method: "GET",
      headers: {
        // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
      },
    });

    const signedRequest = await aws.sign(request);
    // const response = await fetch(signedRequest);
    const headers = {};
    signedRequest.headers.forEach((value, key) => {
      headers[key] = value;
    });
    const response = await api.get(signedRequest.url, {
      headers: headers, // Attach the signed headers
    });

    // const response = await api.get(
    //   `${config.apiUrl}GMCalculator/GetVisaType?vcountryid=${encryptedPara}`
    // );

    if (response.status === 200) {
      //console.log("Hello Visa", response.data, response)
      let decryptedResponse = Decrypt(key, response.data);
      dispatch(visaTypeDataSuccess(decryptedResponse));
    }
  } catch (error) {
    dispatch(visaTypeDataFailed());
  }
};

// Get BGV Type by countryID
export const getBGVTypeData = (payload) => async (dispatch, getState, api) => {
  dispatch(bgvTypeData());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let encryptedPara = Encrypt(key, JSON.stringify(2));
    const vcountryid = encodeURIComponent(encryptedPara);

    //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

    // Define the request
    const url = `${config.apiUrl}GMCalculator/GetBGVType/?vcountryid=${vcountryid}`;
    const request = new Request(url, {
      method: "GET",
      headers: {
        // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
      },
    });

    const signedRequest = await aws.sign(request);
    // const response = await fetch(signedRequest);
    const headers = {};
    signedRequest.headers.forEach((value, key) => {
      headers[key] = value;
    });
    const response = await api.get(signedRequest.url, {
      headers: headers, // Attach the signed headers
    });
    // const response = await api.get(
    //   `${config.apiUrl}GMCalculator/GetBGVType/?vcountryid=${encryptedPara}`
    // );

    if (response.status === 200) {
      //console.log("Hello, BGV Type");
      let decryptedResponse = Decrypt(key, response.data);
      dispatch(bgvTypeDataSuccess(decryptedResponse));
    }
  } catch (error) {
    dispatch(bgvTypeDataFailed());
  }
};

// Get State by countryID
// export const getStateData = (payload) => async (dispatch, getState, api) => {
//   dispatch(stateData());
//   try {
//     var key = process.env.REACT_APP_ENCRYPTION_KEY;
//     let encryptedPara = Encrypt(key, JSON.stringify(2));
//     const response = await api.get(`${config.apiUrl}GMCalculator/GetState/?vcountryid=${encryptedPara}`);

//     if (response.status === 200) {
//       let decryptedResponse = Decrypt(key, response.data);
//       dispatch(stateDataSuccess(decryptedResponse));
//     }
//   } catch (error) {
//     dispatch(stateDataFailed());
//   }
// };

// Get State by countryID
export const getCityData = (payload) => async (dispatch, getState, api) => {
  dispatch(cityData());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let encryptedPara = Encrypt(key, JSON.stringify(2));
    const countryId = encodeURIComponent(encryptedPara);

    //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

    // Define the request
    const url = `${config.apiUrl}GMCalculator/GetCity?countryId=${countryId}`;
    const request = new Request(url, {
      method: "GET",
      headers: {
        // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
      },
    });

    const signedRequest = await aws.sign(request);
    // const response = await fetch(signedRequest);
    const headers = {};
    signedRequest.headers.forEach((value, key) => {
      headers[key] = value;
    });
    const response = await api.get(signedRequest.url, {
      headers: headers, // Attach the signed headers
    });
    // const response = await api.get(
    //   `${config.apiUrl}GMCalculator/GetCity?countryId=${encryptedPara}`
    // );

    if (response.status === 200) {
      let decryptedResponse = Decrypt(key, response.data);
      dispatch(cityDataSuccess(decryptedResponse));
    }
  } catch (error) {
    dispatch(cityDataFailed());
  }
};

// Get work Location Office
export const getWorkLocationData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(workLocationData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GetWorkLocation`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GetWorkLocation`
      // );

      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(workLocationSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(workLocationFailed());
    }
  };

// Get Other Charges by countryID
export const getOtherChargesData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(otherChargesData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara = Encrypt(key, JSON.stringify(2));
      const vcountryid = encodeURIComponent(encryptedPara);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GetOtherCharges/?vcountryid=${vcountryid}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      //console.log("Encrypted Compensation: ", Encrypt(key, JSON.stringify("100000")));
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GetOtherCharges/?vcountryid=${encryptedPara}`
      // );

      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(otherChargesSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(otherChargesFailed());
    }
  };

// Post Mobility Form
export const postMobilityFormData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(postMobilityForm());

    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      const accessToken = Decrypt(key, localStorage.getItem("token"));

      const url = `${config.apiUrl}GMCalculator/InsertInGMMaster`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
        body: payload,
      });
      // Sign and fetch the request
      const signedRequest = await aws.sign(request);

      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await fetch(signedRequest);
      // const response = await api.post(
      //   `${config.apiUrl}GMCalculator/InsertInGMMaster`,
      //   payload,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );

      dispatch(postMobilityFormSuccess(response));
      return response; // Return the response object
    } catch (error) {
      dispatch(postMobilityFormFailed());

      // Store the error response with status code 400
      if (error.response && error.response.status !== 200) {
        const errorResponse = error.response.data;
        // You can save the errorResponse to a variable, store it in state, or handle it as needed
        return errorResponse;
      }
      throw error; // Re-throw the error to be caught in the calling code
    }
  };

export const postGmStatusL1L2Data =
  (payload) => async (dispatch, getState, api) => {
    dispatch(postGMStatusL1L2());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      const accessToken = Decrypt(key, localStorage.getItem("token"));
      const url = `${config.apiUrl}GMCalculator/UpdateGmStatusByL1L2`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
        body: payload,
      });
      // Sign and fetch the request
      const signedRequest = await aws.sign(request);

      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await fetch(signedRequest);
      // const response = await api.post(
      //   `${config.apiUrl}GMCalculator/UpdateGmStatusByL1L2`,
      //   payload,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );

      dispatch(postGMStatusL1L2Success(response.data));

      return response; // Return the response object
    } catch (error) {
      dispatch(postGMStatusL1L2Failed());
      throw error; // Re-throw the error to be caught in the calling code
    }
  };

// Post BUHead Form
export const getBUFormData = (payload) => async (dispatch, getState, api) => {
  dispatch(postBUHeadForm());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const accessToken = Decrypt(key, localStorage.getItem("token"));

    const url = `${config.apiUrl}GMCalculator/UpdateInGMMasterCityWise`;
    const request = new Request(url, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
      },
      body: payload,
    });
    // Sign and fetch the request
    const signedRequest = await aws.sign(request);

    const headers = {};
    signedRequest.headers.forEach((value, key) => {
      headers[key] = value;
    });
    const response = await fetch(signedRequest);
    // const response = await api.post(
    //   `${config.apiUrl}GMCalculator/UpdateInGMMasterCityWise`,
    //   payload,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }
    // );
    const data = await response.text();
    let decryptedResponse = Decrypt(key, data);
    dispatch(postBUHeadFormSuccess(decryptedResponse));

    return { response, data }; // Return the response object
  } catch (error) {
    //console.log("Post Error Catch for BU");
    dispatch(postBUHeadFormFailed());
    if (error.response && error.response.status !== 200) {
      const errorResponse = error.response.data;
      // You can save the errorResponse to a variable, store it in state, or handle it as needed
      return errorResponse;
    }
    throw error;
  }
};

// Get gmId Wise Employee Details
export const getGMIdWiseEmployeeData =
  (gmid) => async (dispatch, getState, api) => {
    dispatch(gmIdWiseEmployeeData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara = Encrypt(key, JSON.stringify(gmid));
      const gmId = encodeURIComponent(encryptedPara);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GetGMIDWiseEmployeeDetails?gmid=${gmId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/GMCalculator/GetGMIDWiseEmployeeDetails?gmid=SImdye9HoSk6059vyQ5axQ==
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GetGMIDWiseEmployeeDetails?gmid=${encryptedPara}`
      // );

      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(gmIdWiseEmployeeDataSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(gmIdWiseEmployeeDataFailed());
    }
  };
// Get Dependents Cost Details
export const getDependentsData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(dependentCostData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(2));
      const countryid = encodeURIComponent(encryptedPara1);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GetDependentsCost?countryid=${countryid}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/GMCalculator/GetGMIDWiseEmployeeDetails?gmid=SImdye9HoSk6059vyQ5axQ==
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GetDependentsCost?countryid=${encryptedPara1}`
      // );

      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        //console.log("DependB", decryptedResponse);
        dispatch(dependentCostDataSuccess(decryptedResponse));
      }
    } catch (error) {
      // console.log("Dependent in Catch")
      dispatch(dependentCostDataFailed());
    }
  };

// Get Recommanded Salary Range Details
export const getGetRecommendedSalaryRangeData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(recommendedSalaryRangeData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload.cityid));
      let encryptedPara2 = Encrypt(key, JSON.stringify(payload.skillid));
      const cityId = encodeURIComponent(encryptedPara1);
      const expertSkillId = encodeURIComponent(encryptedPara2);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GetMobilityUSCISRangeCityWise?cityId=${cityId}&expertSkillId=${expertSkillId}&band=${payload.band}&YOE=${payload.YOE}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GetMobilityUSCISRangeCityWise?cityId=${encryptedPara1}&expertSkillId=${encryptedPara2}&band=${payload.band}&YOE=${payload.YOE}`
      // );
      //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/GMCalculator/GetMobilityUSCISRangeCityWise?cityId=1&expertSkillId=1&band=3A
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(recommendedSalaryRangeDataSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(recommendedSalaryRangeDataFailed());
    }
  };

// Get Recommanded Salary Range Details
export const getGetVariableData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(variableData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GetVariableCompBandWise?band=${payload}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GetVariableCompBandWise?band=${payload}`
      // );
      //GMCalculator/GetVariableCompBandWise?band
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(variableDataSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(variableDataFailed());
    }
  };

// Get Dashboard Card Data
export const getDashboardData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(dashboardData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let empIdVal = payload.empId;
      //let encryptedPara = Encrypt(key, JSON.stringify(63430));
      let encryptedPara = Encrypt(key, JSON.stringify(empIdVal));
      //const response = await api.get(`${config.apiUrl}GMCalculator/GetAllRequests?empId=${encryptedPara}`);
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      // let encryptedBUNamePara = "Tele";
      // let encryptedStateNamePara = "Tele";
      //let encryptedBillStartDatePara = "02/11/2023";
      // let encryptedsteadyStateGMPara = "8.9";
      // let encryptedStatusPara = "In Review";
      // let encryptedBillRatePara = "140";
      // let encryptedEmpNamePara = "Arun Aggarwal";
      // let encryptedEmpCodePara = "602";
      //let encryptedEmpCodePara = Encrypt(key, JSON.stringify(602));
      //console.log("sdlkfj", payload.pendingFinance, payload.pendingCHRO, payload.pendingBU, payload.empId)
      let businessFinanceValue = payload.pendingFinance
        ? payload.pendingFinance
        : "";
      let buHeadValue = payload.pendingBU ? payload.pendingBU : "";
      let chroValue = payload.pendingCHRO ? payload.pendingCHRO : "";
      let deliveryHeadValue = payload.pendingDeliveryHead
        ? payload.pendingDeliveryHead
        : "";
      let pendingFilterValue = deliveryHeadValue
        ? deliveryHeadValue
        : businessFinanceValue
        ? businessFinanceValue
        : buHeadValue
        ? buHeadValue
        : chroValue
        ? chroValue
        : "";
      let encryptedBUNamePara = "";
      let encryptedClientNamePara = payload.encryptedBUNamePara
        ? payload.encryptedBUNamePara
        : "";
      let encryptedStateNamePara = payload.encryptedStateNamePara
        ? payload.encryptedStateNamePara
        : "";
      let encryptedBillStartDatePara = payload.encryptedBillStartDatePara
        ? payload.encryptedBillStartDatePara
        : null;
      let encryptedsteadyStateGMPara = payload.encryptedsteadyStateGMPara
        ? payload.encryptedsteadyStateGMPara
        : "";
      let encryptedStatusPara = payload.encryptedStatusPara
        ? payload.encryptedStatusPara
        : "";
      let encryptedBillRatePara = payload.encryptedBillRatePara
        ? payload.encryptedBillRatePara
        : "";
      let encryptedEmpNamePara = payload.encryptedEmpNamePara
        ? payload.encryptedEmpNamePara
        : "";
      let encryptedEmpCodePara =
        payload.encryptedIDPara != undefined && payload.encryptedIDPara
          ? Encrypt(key, JSON.stringify(payload.encryptedIDPara))
          : null;

      const empId = encodeURIComponent(encryptedPara);
      const BUName = encodeURIComponent(encryptedBUNamePara);
      const stateName = encodeURIComponent(encryptedStateNamePara);
      const billStartDate = encodeURIComponent(encryptedBillStartDatePara);
      const steadyStateGM = encodeURIComponent(encryptedsteadyStateGMPara);
      const status = encodeURIComponent(encryptedStatusPara);
      const billRate = encodeURIComponent(encryptedBillRatePara);
      const empName = encodeURIComponent(encryptedEmpNamePara);
      const empCode = encodeURIComponent(encryptedEmpCodePara);
      const clientname = encodeURIComponent(encryptedClientNamePara);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GetAllRequests?empId=${empId}&BUName=${BUName}&stateName=${stateName}&billStartDate=${billStartDate}&steadyStateGM=${steadyStateGM}&status=${status}&billRate=${billRate}&empName=${empName}&empCode=${empCode}&clientname=${clientname}&pfilterPending=${pendingFilterValue}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GetAllRequests?empId=${encryptedPara}&BUName=${encryptedBUNamePara}&stateName=${encryptedStateNamePara}&billStartDate=${encryptedBillStartDatePara}&steadyStateGM=${encryptedsteadyStateGMPara}&status=${encryptedStatusPara}&billRate=${encryptedBillRatePara}&empName=${encryptedEmpNamePara}&empCode=${encryptedEmpCodePara}&clientname=${encryptedClientNamePara}&pfilterPending=${pendingFilterValue}`
      // );

      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(dashboardDataSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(dashboardDataFailed());
    }
  };

// Get Dashboard Status Data
export const getDashboardStatusData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(dashboardStatusData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      //let encryptedPara = Encrypt(key, JSON.stringify(63430));
      let encryptedPara = Encrypt(key, JSON.stringify(payload.empId));

      const empId = encodeURIComponent(encryptedPara);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GetGMStatus?empId=${empId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GetGMStatus?empId=${encryptedPara}`
      // );

      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(dashboardStatusDataSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(dashboardStatusDataFailed());
    }
  };

// Get Employee Roles
export const getEmployeeRolesData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(employeeRolesData());
    //console.log('getDashboardStatusData', payload.empId);
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      //let encryptedPara = Encrypt(key, JSON.stringify(63430));
      let encryptedPara = Encrypt(key, JSON.stringify(payload.empId));
      const empId = encodeURIComponent(encryptedPara);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GetEmployeeRole?empId=${empId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GetEmployeeRole?empId=${encryptedPara}`
      // );

      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(employeeRolesDataSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(employeeRolesDataFailed());
    }
  };

// Get Dashboard BU Data List
export const getBUDataList = (payload) => async (dispatch, getState, api) => {
  dispatch(dashboardBUData());
  //console.log('getDashboardStatusData', payload.empId);
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;

    //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

    // Define the request
    const url = `${config.apiUrl}GMCalculator/GetBusinessUnit`;
    const request = new Request(url, {
      method: "GET",
      headers: {
        // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
      },
    });

    const signedRequest = await aws.sign(request);
    // const response = await fetch(signedRequest);
    const headers = {};
    signedRequest.headers.forEach((value, key) => {
      headers[key] = value;
    });
    const response = await api.get(signedRequest.url, {
      headers: headers, // Attach the signed headers
    });
    // const response = await api.get(
    //   `${config.apiUrl}GMCalculator/GetBusinessUnit`
    // );
    if (response.status === 200) {
      let decryptedResponse = Decrypt(key, response.data);
      dispatch(dashboardBUDataSuccess(decryptedResponse));
    }
  } catch (error) {
    dispatch(dashboardBUDataFailed());
  }
};

// Delete Employee Roles
export const getDelete = (payload) => async (dispatch, getState, api) => {
  dispatch(deleteRow());
  //console.log('getDashboardStatusData', payload.empId);
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    //let encryptedPara = Encrypt(key, JSON.stringify(63430));
    let gmIdVal = Encrypt(key, JSON.stringify(payload?.gmId));
    let empIdVal = Encrypt(key, JSON.stringify(payload?.empId));
    const gmId = encodeURIComponent(gmIdVal);
    const empId = encodeURIComponent(empIdVal);

    const url = `${config.apiUrl}GMCalculator/DeleteGM?gmId=${gmId}&empId=${empId}`;
    const request = new Request(url, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
      },
      body: null,
    });
    // Sign and fetch the request
    const signedRequest = await aws.sign(request);

    const headers = {};
    signedRequest.headers.forEach((value, key) => {
      headers[key] = value;
    });
    const response = await api.post(signedRequest.url, null, {
      headers: headers,
    });
    // const response = await api.post(
    //   `${config.apiUrl}GMCalculator/DeleteGM?gmId=${gmIdVal}&empId=${empIdVal}`,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }
    // );

    if (response.status === 200) {
      let decryptedResponse = Decrypt(key, response.data);
      dispatch(deleteRowSuccess(decryptedResponse));
    }
  } catch (error) {
    dispatch(deleteRowFailed());
  }
};

export const resetGMResponse = () => (dispatch, getState, api) => {
  //console.log("payload of resetExpenseResponse; AK1 ");
  dispatch(resetGMResponse());
};

//Reset DeligationSnakbar
export const ResetDeligatinSnakbarGM =
  (payload) => async (dispatch, getState, api) => {
    dispatch(resetDeliationSnakbarGM());
  };

//Update Global Mobility Delegatee Employee Id:
export const updateGlobalMobilityDelegateeEmpId =
  (updatedDelEmpId) => (dispatch, getState, api) => {
    //console.log("payload in updateDelegateeEmpId: ", updatedDelEmpId);
    dispatch(updateGlobalMobilityDelEmpId(updatedDelEmpId));
  };

//Update Global Mobility SSO Employee Id:
export const updateGlobalMobilitySSOEmployeeId =
  (updatedGMSSOEmpId) => (dispatch, getState, api) => {
    //console.log("payload in updateSsoEmployeeEmpId: ", updatedSsoEmpId);
    dispatch(updateGlobalMobilitySSOEmpId(updatedGMSSOEmpId));
  };

//Get GlobalMobility Search Employee API start.

export const getGlobalMobilitySearchEmployeeData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getGlobalMobilitySearchEmployee());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GMGetAllEmployeesSearch`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GMGetAllEmployeesSearch`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        dispatch(getGlobalMobilitySearchEmployeeSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(getGlobalMobilitySearchEmployeeFailed(error));
    }
  };

//Get GlobalMobility Search Employee API ends.

//Get GlobalMobility Delegate Search API start.

export const getGlobalMobilitySearchDelegateData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getGlobalMobilitySearchDelegate());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GMGetAllDelegateSearch`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GMGetAllDelegateSearch`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        dispatch(getGlobalMobilitySearchDelegateSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(getGlobalMobilitySearchDelegateFailed(error));
    }
  };

//Get GlobalMobility Search Employee API ends.

//Get View Delegate Details API start.

export const getGlobalMobilityViewDelegateData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getGlobalMobilityViewDelegateDetails());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GMViewDelegateDetails`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GMViewDelegateDetails`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        dispatch(
          getGlobalMobilityViewDelegateDetailsSuccess(decryptedResponse)
        );
      }
    } catch (error) {
      dispatch(getGlobalMobilityViewDelegateDetailsFailed(error));
    }
  };

//Get View Delegate Details API ends.

//Get GlobalMobilityGetDelAccess API starts.

export const getGlobalMobilityDelAccessData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getGlobalMobilityDelAccess());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara = Encrypt(key, JSON.stringify(payload));
      const empId = encodeURIComponent(encryptedPara);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GMGetDelAccess?empId=${empId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GMGetDelAccess?empId=${encryptedPara}`
      // );

      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        dispatch(getGlobalMobilityDelAccessSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(getGlobalMobilityDelAccessFailed(error));
    }
  };

//Get GlobalMobilityGetDelAccess API ends.

//Get GMGetEmpDetailsAsPerDelegatee API starts.

export const getMyGlobalMobilityDelegateeData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getGlobalMobilityEmpDetailsAsPerDelegatee());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara = Encrypt(key, JSON.stringify(payload));
      const delegateeId = encodeURIComponent(encryptedPara);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GMGetEmpDetailsAsPerDelegatee?delegateeId=${delegateeId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GMGetDelAccess?empId=${encryptedPara}`
      // );

      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        // const response = await api.get(
        //   `${config.apiUrl}GMCalculator/GMGetEmpDetailsAsPerDelegatee?delegateeId=${encryptedPara}`
        // );

        dispatch(
          getGlobalMobiityEmpDetailsAsPerDelegateeSuccess(decryptedResponse)
        );
      }
    } catch (error) {
      dispatch(getGlobalMobilityEmpDetailsAsPerDelegateeFailed(error));
    }
  };
//Get GMGetEmpDetailsAsPerDelegatee API ends.

//Post GlobalMobility Add Delegate API start:

export const postGlobalMobilityAddDelegateData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(postGlobalMobilityAddDelegate());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      const accessToken = Decrypt(key, localStorage.getItem("token"));

      const url = `${config.apiUrl}GMCalculator/GMAddDelegate`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
        body: payload,
      });
      // Sign and fetch the request
      const signedRequest = await aws.sign(request);

      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await fetch(signedRequest);
      // const response = await api.post(
      //   `${config.apiUrl}GMCalculator/GMAddDelegate`,
      //   payload,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      dispatch(postGlobalMobilityAddDelegateSuccess(await response.text()));
      return response;
    } catch (error) {
      dispatch(postGlobalMobilityAddDelegateFailed());

      if (error.response && error.response.status !== 200) {
        const errorResponse = error.response.data;

        return errorResponse;
      }
      throw error;
    }
  };
//Post GlobalMobility Add Delegate API end.

//Post GlobalMobility Edit Delegate API start:

export const postGlobalMobilityEditDelegateData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(postGlobalMobilityEditDelegate());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      const accessToken = Decrypt(key, localStorage.getItem("token"));

      const url = `${config.apiUrl}GMCalculator/GMEditDelegate`;
      const request = new Request(url, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
        body: payload,
      });
      // Sign and fetch the request
      const signedRequest = await aws.sign(request);

      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await fetch(signedRequest);
      // const response = await api.post(
      //   `${config.apiUrl}GMCalculator/GMEditDelegate`,
      //   payload,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      dispatch(postGlobalMobilityEditDelegateSuccess(await response.text()));
      return response;
    } catch (error) {
      dispatch(postGlobalMobilityEditDelegateFailed());

      if (error.response && error.response.status !== 200) {
        const errorResponse = error.response.data;

        return errorResponse;
      }
      throw error;
    }
  };
//Post GlobalMobility Edit Delegate API end.

//GET GLOBAL MOBILITY ACCESS FLAG API STARTS
export const getGlobalMobilityAccessFlagData =
  (payload) => async (dispatch, getState, api) => {
    // console.log("payload timesheet flag data", payload);
    dispatch(getGlobalMobilityAccessFlag());
    try {
      // console.log("checking the payload of getTimesheetflagData", payload);
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.employeeId));

      const empId = encodeURIComponent(encryptedPara1);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GMGetDelAccess?empId=${empId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GMGetDelAccess?empId=${encryptedPara1}`
      //   //https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/Master/GetTimesheetAccessFlag?empId=
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log(
        //   "checking the response of getTimesheetflagData",
        //   decryptedResponse
        // );
        dispatch(getGlobalMobilityAccessFlagSuccess(decryptedResponse));
      }
    } catch (error) {
      // console.log("action error112", error);
      dispatch(getGlobalMobilityAccessFlagFailed(error));
    }
  };









//GM view bandwise salary range
export const getViewBandwiseSalaryRangeData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getViewBandwiseSalaryRange());
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.empId));
    try {
      const empId = encodeURIComponent(encryptedPara1);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GMViewBandWiseSalaryRange?empId=${empId}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GMViewBandWiseSalaryRange?empId=${encryptedPara1}`
      // );

      if (response.status == 200) {
        const decryptedResponse = Decrypt(key, response.data);
        dispatch(getViewBandwiseSalaryRangeSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(getViewBandwiseSalaryRangeFailed(error));
    }
  };

export const getEmployeeSpecificSalaryRangeData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getEmployeeSpecificSalaryRange);
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara0 = Encrypt(key, JSON.stringify(payload?.cityId));
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.skillId));
      let encryptedPara2 = payload?.band; //category ID
      let year = payload?.year;

      const cityid = encodeURIComponent(encryptedPara0);
      const skillid = encodeURIComponent(encryptedPara1);
      const band = encodeURIComponent(encryptedPara2);

      //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GMEmployeeSalaryRange?cityid=${cityid}&skillid=${skillid}&band=${band}&yoe=${year}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });

      const signedRequest = await aws.sign(request);
      // const response = await fetch(signedRequest);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });
      // const response = await api.get(
      //   `${config.apiUrl}GMCalculator/GMEmployeeSalaryRange?cityid=${encryptedPara0}&skillid=${encryptedPara1}&band=${encryptedPara2}&yoe=${year}`
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        dispatch(getEmployeeSpecificSalaryRangeSuccess(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(getEmployeeSpecificSalaryRangeFailed());
    }
  };



  export const handleSignedUrlUpload = 
  (payload) => async (dispatch, getState, api) => {
    const AllFiles = payload?.fList;
    const AllSignedUrl = payload?.allSignedURL || payload?.allSignedURL;
    const orginalFileList = payload?.orginalFileList;

    let allResponses = [];  // Array to store responses

    for (let i = 0; i < AllFiles?.length; i++) {

      let val = orginalFileList.find((item) => 
        AllFiles[i].name == item.name && 
        AllFiles[i].size == item.size && 
        AllFiles[i].type == item.type && 
        AllFiles[i].lastModified == item.lastModified
      )

      try {
        const mynewFile = new File(
          [val],
          `${AllFiles[i]?.uuid}_${AllFiles[i].name.toLowerCase()}`
        );

        const response = await api.put(AllSignedUrl[AllSignedUrl.length == AllFiles.length ? i : i + 1], mynewFile, {

          headers: {
            // You can add appropriate Content-Type here if needed
          },
        });

        // Collecting all the successful responses
        allResponses.push({
          fileName: AllFiles[i].name,
          status: response.status,
          message: 'Success',
        });

      } catch (err) {

        // Collecting any failed uploads
        allResponses.push({
          fileName: AllFiles[AllFiles[0].name == undefined ? i-1 : i].name,
          status: 500,
          message: err.message || 'Error occurred',
        });
      }
    }

    // Optionally, you can return the responses for further action or dispatch
    return allResponses;
  };

// export const handleSignedUrlUpload =
//   (payload) => async (dispatch, getState, api) => {
//     const AllFiles = payload?.file;
//     const AllSignedUrl = payload?.allSignedURL;
//     // console.log("filelist map", payload);
//     for (let i = 0; i < AllFiles?.length; i++) {
//       try {
//         const mynewFile = new File(
//           [AllFiles[i]],
//           `${payload?.uuID}_${AllFiles[i].name.toLowerCase()}`
//         );
//         await api.put(AllSignedUrl[i], mynewFile, {
//           headers: {
//             // "Content-Type": "image/png",
//           },
//         });
//       } catch (err) {
//         console.log("filelist map 1", err);
//       }
//     }
//     // uploadFile(file, config)
//     //   .then((data) => console.log(""))
//     //   .catch((err) => console.error("handleError", err));
//   };

  export const getGMSignedURLData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(getGMSignedURL());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      const aws = new AwsClient({
        accessKeyId: process.env.REACT_APP_ACCESS_KEY, // Replace with your Access Key ID
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY, // Replace with your Secret Access Key
        service: "execute-api", // The service name
        region: "ap-south-1", // Your AWS region
      });

      // Define the request
      const url = `${config.apiUrl}GMCalculator/GetSignedUrlForUpload?fileName=${payload?.fileName}&uuId=${payload?.uuID}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          // "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
        },
      });
      const signedRequest = await aws.sign(request);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await api.get(signedRequest.url, {
        headers: headers, // Attach the signed headers
      });

      if (response.status === 200) {
        dispatch(getGMSignedURLSuccess(response.data));
      }
      return response;
    } catch (error) {
      dispatch(getGMSignedURLFailed());
    }
  };

  export const deleteGMFileUploadData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(deleteGMFileUpload());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      const accessToken = Decrypt(key, localStorage.getItem("token"));
      const url = `${config.apiUrl}GMCalculator/DeleteFileUpload`;

      const request = new Request(url, {
        method: "POST",
        headers: {
          "X-Bearer-Token": `${accessToken}`, // Replace with your Bearer token
          "Content-Type": "application/json" 
        },
        body: JSON.stringify(payload), 
      });
      // Sign and fetch the request
      const signedRequest = await aws.sign(request);
      const headers = {};
      signedRequest.headers.forEach((value, key) => {
        headers[key] = value;
      });
      const response = await fetch(signedRequest);
      if (response.status === 200) {
        dispatch(deleteGMFileUploadSuccess(response.data));
      }
    } catch (error) {
      dispatch(deleteGMFileUploadFailed());
    }
  };