import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  FormControl,
  Link,
  Chip,
  Divider,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import DateFnsUtils from "@date-io/date-fns";
import { startOfMonth, endOfMonth } from "date-fns";
import ClearIcon from "@mui/icons-material/Clear";
import { maintainState } from "../redux/TimeSheet/actionTs";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useMsal, useAccount } from "@azure/msal-react";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { setAllChips } from "../redux/TimeSheet/actionTs";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/header";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useLocation, useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { style } from "@material-ui/system";
import {
  getAllTimesheetReportData,
  getAllTimesheetReportDataNew,
  getTimesheetReportData,
  getPendencyReportData,
  getAllTimesheetReportViewData,
  exportAsPdfData,
  allTimesheetReportClearData,
  getAllProjectsData,
} from "../redux/TimeSheet/actionTs";
import { getEmpDetail } from "../redux/Expense/action";
import AuditHistoryDialog from "./auditHistoryAllTimesheetReport";
const useStyles = makeStyles((theme) => ({
  addExpenseButton: {
    height: "42px",
    width: "187px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  buttonText: {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
  },
  addExpenseButton1: {
    height: "34px",
    width: "119px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  buttonText1: {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: "10px",
    fontWeight: 500,
  },
  customRow: {
    height: "20px", // Adjust this value as needed
  },
  statusStyle: {
    borderRadius: "100px",
    width: " 110px",
    height: "22px",
    flexShrink: 0,
  },
  tablerow: {
    height: 58,
  },
  auditHistory: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "14px",
    color: "#008CE6",
    paddingTop: "20px",
    textDecoration: "underline",
  },
}));
function TimesheetReport() {
  const history = useHistory();
  const classes = useStyles();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedEntityCode, setSelectedEntityCode] = useState([]);
  const [selectedVertical, setSelectedVertical] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedEmpType, setSelectedEmpType] = useState([]);
  const [selectedEmpName, setSelectedEmpName] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);

  const [isNewChip, setIsNewChip] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [frequency, setFrequency] = useState("Weekly");
  const [newArray, setNewArray] = useState([]);
  const dispatch = useDispatch();
  const [systemLiveDate, setSystemLiveDate] = useState("2023-09-01T00:00:00");
  const [futureLimitDate, setFutureLimitDate] = useState(null);
  const [tempFromDate, setTempFromDate] = useState(null);
  const [tempToDate, setTempToDate] = useState(null);
  const [selectedDownloadType, setSelectedDownloadType] = useState("Weekly");
  const [value, setValue] = React.useState(0);
  const csvref = useRef();
  const csvDailyref = useRef();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [downloadButtonClicked, setDownloadButtonClicked] = useState(false);
  const [dailyDownloadButtonClicked, setDailyDownloadButtonClicked] =
    useState(false);
  const { instance, accounts, inProgress } = useMsal();

  const account = useAccount(accounts[0] || {});

  const [chips, setChips] = useState({
    verticalChip: [],
    projectsChip: [],
    statusChip: [],
    empEntityChip: [],
    empTypeChip: [],
    employeeChip: [],
    projPurposeChip: [],
    customerNameChip:[],
    // statusChip: [],
  });

  const [tempChips, setTempChips] = useState({
    verticalChip: [],
    projectsChip: [],
    statusChip: [],
    empEntityChip: [],
    empTypeChip: [],
    employeeChip: [],
    projPurposeChip: [],
    customerNameChip:[],

  });
  const [applyFilterClicked, setApplyFilterClicked] = useState(true);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [selectedProjItem, setSelectedProjItem] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 100000; // Adjust the page size as needed
  const [allData, setAllData] = useState([]);
  const [csvLinkNew, setCsvLinkNew] = useState({
    headers: [], // Define your headers
    data: [],
    filename: "",
  });

  const [timesheetReportViewState, setTimesheetReportViewState] = useState([]);
  const formData = new FormData();
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const myTimesheetDelegateesList = useSelector(
    (state) => state?.timesheetData?.getMyTimesheetDelegateeData
  );
  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );
  //   console.log("sso", ssoEmpIdDetails);
  const timesheetReport = useSelector(
    (state) => state?.timesheetData?.getTimesheetReport
  );
  const timesheetReportData = useSelector(
    (state) => state.timesheetData.getAllTimesheetReport
  );

  const timesheetReportDataNew = useSelector(
    (state) => state.timesheetData.getAllTimesheetReport1
  );

  const allTimesheetReportCount = useSelector(
    (state) => state.timesheetData.getAllTimesheetReportCount
  );
  const outPutData = useSelector(
    (state) => state.timesheetData?.postExtractPDFData
  );
  const maintainStateVar = useSelector(
    (state) => state.timesheetData.maintainStateVariable
  );
  var timesheetReportView = useSelector(
    (state) => state.timesheetData?.getAllTimesheetReportView
  );
  //   console.log("report view",timesheetReportView)
  const allProjects = useSelector(
    (state) => state.timesheetData.getAllProjectsList
  );
  var allSetChips = useSelector((state) => state.timesheetData.getSetChips);
  const timesheetReportJson = JSON.parse(timesheetReport);
  // useEffect(() => {
  //   // Initial data fetch when the component mounts
  //   handleTimesheetReport(currentPage);
  // }, []); // Run once on mount

  // useEffect(() => {
  //   // Add a scroll event listener
  //   console.log("inside useeffect......");
  //   // return () => {
  //   //   // Remove the scroll event listener when the component unmounts
  //   //   window.removeEventListener('scroll', handleScroll);
  //   // };
  // }, [currentPage]);
  useEffect(() => {
    // console.log("chips chips", allSetChips);

    if (allSetChips) {
      setTempChips({
        statusChip: allSetChips.allTempChips.statusChip,
        empEntityChip: allSetChips.allTempChips.empEntityChip,
        empTypeChip: allSetChips.allTempChips.empTypeChip,
        verticalChip: allSetChips.allTempChips.verticalChip,
        projectsChip: allSetChips.allTempChips.projectsChip,
        employeeChip: allSetChips.allTempChips.employeeChip,
        projPurposeChip: allSetChips.allTempChips.projPurposeChip,
        customerNameChip: allSetChips.allTempChips.customerNameChip,

      });
      setChips({
        statusChip: allSetChips.allTempChips.statusChip,
        empEntityChip: allSetChips.allTempChips.empEntityChip,
        empTypeChip: allSetChips.allTempChips.empTypeChip,
        verticalChip: allSetChips.allTempChips.verticalChip,
        projectsChip: allSetChips.allTempChips.projectsChip,
        employeeChip: allSetChips.allTempChips.employeeChip,
        projPurposeChip: allSetChips.allTempChips.projPurposeChip,
        customerNameChip: allSetChips.allTempChips.customerNameChip,

      });
      setFromDate(allSetChips.fromDate);
      setToDate(allSetChips.toDate);
      setSelectedStatus(allSetChips.allTempChips.statusChip);
      // setSelectedProjItem(allSetChips.allTempChips.projectsChip);
      setSelectedEmpType(allSetChips.allTempChips.empTypeChip);
      setSelectedEntityCode(allSetChips.allTempChips.empEntityChip);
      setSelectedVertical(allSetChips.allTempChips.verticalChip);
      setSelectedProjects(allSetChips.selectedProjects);
      // setSelectedEmpName(allSetChips.allTempChips.statusChip);
      setSelectedPurpose(allSetChips.allTempChips.projPurposeChip);
      setSelectedCustomer(allSetChips.allTempChips.customerNameChip);
      setCurrentPage(allSetChips.currentPage);
      setTimesheetReportViewState(allSetChips.timesheetReportViewState);
    }
  }, [allSetChips]);

  useEffect(() => {
    // Add a scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentPage, timesheetReportViewState]);

  useEffect(() => {
    if (timesheetReportView?.length > 0) {
      // console.log("current page state inside if", timesheetReportView);
      setTimesheetReportViewState((prev) => [...prev, ...timesheetReportView]);
      // console.log(
      //   "State updated successfully:",
      //   timesheetReportViewState,
      //   timesheetReportView
      // );
    }
    // setTimesheetReportViewState((prev) => [...prev, ...timesheetReportView]);

    // else if (timesheetReportView === null) setTimesheetReportViewState([]);
    //  console.log("current page state", timesheetReportView);
  }, [timesheetReportView]);
  // useEffect(() => {
  //   console.log("current page state useeffect", timesheetReportViewState);
  //   console.log("chips>>>>>>", chips);
  // }, [timesheetReportViewState]);

  useEffect(() => {
    dispatch(getTimesheetReportData());
  }, [dispatch, ssoEmpIdDetails]);
  // console.log("all reports", timesheetReportView);
  useEffect(() => {
    dispatch(getEmpDetail(account?.username));
  }, []);

  let currentDate = new Date();
  let firstDayOfMonth = startOfMonth(currentDate);
  let lastDayOfMonth = endOfMonth(currentDate);
  useEffect(() => {
    setFromDate(
      allSetChips?.fromDate ? allSetChips?.fromDate : firstDayOfMonth
    );
    setToDate(allSetChips?.toDate ? allSetChips?.toDate : lastDayOfMonth);
    // setTempFromDate(firstDayOfMonth);
    // setTempToDate(lastDayOfMonth);
  }, []);

  useEffect(() => {
    if (isNewChip) {
      setChips({
        // timePeriodChip: tempChips.timePeriodChip,
        // projectIdChip: [...tempChips.projectIdChip],
        statusChip: tempChips.statusChip,
        empEntityChip: tempChips.empEntityChip,
        empTypeChip: tempChips.empTypeChip,
        verticalChip: tempChips.verticalChip,
        projectsChip: tempChips.projectsChip,
        employeeChip: tempChips.employeeChip,
        projPurposeChip: tempChips.projPurposeChip,
        customerNameChip:tempChips.customerNameChip,
      });
      setIsNewChip(false);
    }
  }, [isNewChip]);

  // useEffect(() => {
  //   ////console.log("inside useEffect");
  //   if (timesheetReportData && downloadButtonClicked) {
  //     csvref.current.link.click();
  //     setSnackbarOpen(true);
  //     // Reset the state variable to false to avoid re-triggering the useEffect
  //     setDownloadButtonClicked(false);
  //   }
  // }, [timesheetReportData, downloadButtonClicked]);

  // useEffect(() => {
  //   if (timesheetReportDataNew && dailyDownloadButtonClicked) {
  //     //  console.log("inside useEffect");
  //     csvDailyref.current.link.click();
  //     setSnackbarOpen(true);
  //     setDailyDownloadButtonClicked(false);
  //   }
  // }, [timesheetReportDataNew, dailyDownloadButtonClicked]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSummaryDownload = () => {
    setIsLoader(true);
    // setDownloadButtonClicked(true);
//     const updatedCustomerNameChip = tempChips.customerNameChip.map(item => item.replace(/,/g, '@'));
// console.log("first",updatedCustomerNameChip)
const updatedCustomerNameChip = tempChips.customerNameChip.join('@');

    const obj = {
      //   empid: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
      empid: ssoEmpIdDetails?.empCode,
      fromDate: fromDate ? moment(fromDate).format("yyyy-MM-DD") : "",
      toDate: toDate ? moment(toDate).format("yyyy-MM-DD") : "",
      vertical: tempChips.verticalChip ? tempChips.verticalChip : [],
      projId: tempChips.projectsChip ? selectedProjItem : [],
      empType: tempChips.empTypeChip ? tempChips.empTypeChip : [],
      entity: tempChips.empEntityChip ? tempChips.empEntityChip : [],
      empDetails: tempChips.employeeChip ? tempChips.employeeChip : [],
      status: tempChips.statusChip ? tempChips.statusChip : [],
      projpurpose: tempChips.projPurposeChip ? tempChips.projPurposeChip : [],
      CustomerName:tempChips.customerNameChip ? updatedCustomerNameChip : [],
      frequency: "Weekly",
      body: "alltimesheetreport",
    };
    // console.log("obj summary download", obj);
    dispatch(getAllTimesheetReportData(obj, setIsLoader));
  };

  const handleDetailDownload = () => {
    setIsLoader(true);
    // setDailyDownloadButtonClicked(true);
//     const updatedCustomerNameChip = tempChips.customerNameChip.map(item => item.replace(/,/g, '@'));
// console.log("first",updatedCustomerNameChip)
const updatedCustomerNameChip = tempChips.customerNameChip.join('@');

    const obj1 = {
      //   empid: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
      empid: ssoEmpIdDetails?.empCode,
      fromDate: fromDate ? moment(fromDate).format("yyyy-MM-DD") : "",
      toDate: toDate ? moment(toDate).format("yyyy-MM-DD") : "",
      vertical: tempChips.verticalChip ? tempChips.verticalChip : [],
      projId: tempChips.projectsChip ? selectedProjItem : [],
      empType: tempChips.empTypeChip ? tempChips.empTypeChip : [],
      entity: tempChips.empEntityChip ? tempChips.empEntityChip : [],
      empDetails: tempChips.employeeChip ? tempChips.employeeChip : [],
      status: tempChips.statusChip ? tempChips.statusChip : [],
      projpurpose: tempChips.projPurposeChip ? tempChips.projPurposeChip : [],
      CustomerName:tempChips.customerNameChip ? updatedCustomerNameChip : [],

      frequency: "Daily",
      body: "alltimesheetreport",
    };
    dispatch(getAllTimesheetReportDataNew(obj1, setIsLoader));
  };
  const handleTimesheetReport = (e) => {
    // console.log("current page1", currentPage);

    setIsLoader(true);
    // const updatedCustomerNameChip = tempChips.customerNameChip.map(item => item.replace(/,/g, '@'));
    // console.log("first",updatedCustomerNameChip)
    const updatedCustomerNameChip = tempChips.customerNameChip.join('@');

    const obj3 = {
      fromDate: fromDate
        ? moment(fromDate).format("yyyy-MM-DD")
        : moment(firstDayOfMonth).format("yyyy-MM-DD"),
      toDate: toDate
        ? moment(toDate).format("yyyy-MM-DD")
        : moment(lastDayOfMonth).format("yyyy-MM-DD"),
      vertical: tempChips.verticalChip ? tempChips.verticalChip : [],
      projId: tempChips.projectsChip ? selectedProjItem : [],
      empType: tempChips.empTypeChip ? tempChips.empTypeChip : [],
      entity: tempChips.empEntityChip ? tempChips.empEntityChip : [],
      empDetails: tempChips.employeeChip ? tempChips.employeeChip : [],
      status: tempChips.statusChip ? tempChips.statusChip : [],
      projpurpose: tempChips.projPurposeChip ? tempChips.projPurposeChip : [],
      pagenumber: currentPage,
      pagesize: 10,
      CustomerName:tempChips.customerNameChip ? updatedCustomerNameChip : [],

      //empCode: tempChips.employeeIdChip ? tempChips.employeeIdChip : [],
      // frequency: "Weekly",
      body: "alltimesheetreportviewpage",
    };
    ////console.log("object of view page",obj3)
    dispatch(getAllTimesheetReportViewData(obj3, setIsLoader));
    setCurrentPage(currentPage + 1);
    // console.log("current page", currentPage);
    // console.log("current page state handletimesheet", timesheetReportView);

    // if (fromDate && toDate && toDate >= fromDate) {
    //     dispatch(getAllTimesheetReportViewData(obj3, setIsLoader));
    //   } else {
    //     // Prevent the default behavior when the cursor is not allowed
    //     e.preventDefault();
    //     console.log("Apply Filter is not clickable");
    //   }
    //setIsLoader(false);
  };
  const handleTimesheetReportApplyFilter = (e) => {
    // console.log("current page1", currentPage);

    setIsLoader(true);
// const customer=tempChips.customerNameChip.map((item)=>{
//   item.
// })
// const updatedCustomerNameChip = tempChips.customerNameChip.map(item => item.replace(/,/g, '@'));
const updatedCustomerNameChip = tempChips.customerNameChip.join('@');

// console.log("first",updatedCustomerNameChip)
    const obj3 = {
      fromDate: fromDate
        ? moment(fromDate).format("yyyy-MM-DD")
        : moment(firstDayOfMonth).format("yyyy-MM-DD"),
      toDate: toDate
        ? moment(toDate).format("yyyy-MM-DD")
        : moment(lastDayOfMonth).format("yyyy-MM-DD"),
      vertical: tempChips.verticalChip ? tempChips.verticalChip : [],
      projId: tempChips.projectsChip ? selectedProjItem : [],
      empType: tempChips.empTypeChip ? tempChips.empTypeChip : [],
      entity: tempChips.empEntityChip ? tempChips.empEntityChip : [],
      empDetails: tempChips.employeeChip ? tempChips.employeeChip : [],
      status: tempChips.statusChip ? tempChips.statusChip : [],
      projpurpose: tempChips.projPurposeChip ? tempChips.projPurposeChip : [],
      pagenumber: 1,
      pagesize: 10,
      CustomerName:tempChips.customerNameChip ? updatedCustomerNameChip : [],

      //empCode: tempChips.employeeIdChip ? tempChips.employeeIdChip : [],
      // frequency: "Weekly",
      body: "alltimesheetreportviewpage",
    };
    ////console.log("object of view page",obj3)
    dispatch(getAllTimesheetReportViewData(obj3, setIsLoader));

    setCurrentPage(2);
    setTimesheetReportViewState([]);
  };
  const handleScroll = () => {
    // Check if the user has scrolled to the bottom
    // console.log("current page view state", timesheetReportViewState);
    if (
      window.innerHeight + document.documentElement.scrollTop + 2 >=
        document.documentElement.offsetHeight &&
      currentPage !== 1 &&
      (currentPage - 1) * 10 < timesheetReportViewState[0]?.totalRecordCount
    ) {
      // setCurrentPage(currentPage + 1);
      // Load the next page when scrolling to the bottom
      // console.log("inside if");
      handleTimesheetReport();
    }
  };

  // window.addEventListener("scroll", handleScroll);

  const handleClearAll = () => {
    // setApplyFilterClicked(false);
    setTempChips({
      verticalChip: [],
      projectsChip: [],
      statusChip: [],
      empEntityChip: [],
      empTypeChip: [],
      employeeChip: [],
      projPurposeChip: [],
      customerNameChip:[],
    });
    setChips({
      verticalChip: [],
      projectsChip: [],
      statusChip: [],
      empEntityChip: [],
      empTypeChip: [],
      employeeChip: [],
      projPurposeChip: [],
      customerNameChip:[],
    });
    setSelectedProjItem([]);
    setSelectedEmpType([]);
    setSelectedEntityCode([]);
    setSelectedVertical([]);
    setSelectedStatus([]);
    setSelectedProjects([]);
    setSelectedEmpName([]);
    setSelectedPurpose([]);
    setSelectedCustomer([]);
    setIsLoader(true);
    firstDayOfMonth = startOfMonth(currentDate);
    lastDayOfMonth = endOfMonth(currentDate);
    setFromDate(
      firstDayOfMonth < systemLiveDate ? systemLiveDate : firstDayOfMonth
    );

    setToDate(
      firstDayOfMonth < systemLiveDate
        ? endOfMonth(new Date(systemLiveDate))
        : endOfMonth(new Date(firstDayOfMonth))
    );

    dispatch(allTimesheetReportClearData(setIsLoader));
    //timesheetReportView=[];
    ////console.log("data data data", timesheetReportView);
    ////console.log("looking in states after clear all", tempChips);
  };
  //console.log("looking in states after clear all",employeeChip,empTypeChip,empEntityChip,statusChip,verticalChip,projectsChip)
  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // You can add additional logic here if needed
  };

  const handleEmployee = (event) => {
    setCurrentPage(1);
    const selectedValue = event.target.value.trimStart();
    const isDuplicate = tempChips.employeeChip.includes(selectedValue);
    //console.log("values employee", selectedValue);
    const handleEmployeeSubmit = (shouldUpdateState) => {
      //co//sole.log("inside handleemployeesubmit");
      if (shouldUpdateState && !isDuplicate) {
        setSelectedEmpName(selectedValue);
        setTempChips((prevChips) => ({
          ...prevChips,
          employeeChip: [...prevChips.employeeChip, selectedValue],
        }));
        setIsNewChip(true);
        //co//sole.log("tempchips", tempChips);
      }
    };

    if (
      (event.key === "Enter" && selectedValue?.length > 0) ||
      (event.type === "blur" && selectedValue?.length > 0)
    ) {
      handleEmployeeSubmit(true);
    } else {
      setSelectedEmpName(selectedValue);
    }
  };
  const handleStatusChange = (event, values) => {
    setCurrentPage(1);
    if (values) {
      // setApplyFilterClicked(true)
      //console.log("values", values);
      setSelectedStatus(values);

      setTempChips((prevChips) => ({
        ...prevChips,
        statusChip: values,
      }));
      setIsNewChip(true);
    } else {
      setSelectedStatus([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        statusChip: [],
      }));
    }
  };
  const handleCustomerNameChange = (event, values) => {
    setCurrentPage(1);
    if (values) {
      // setApplyFilterClicked(true)
      //console.log("values", values);
      setSelectedCustomer(values);
        // Joining array elements with "@" symbol
        // const joinedValues = values.join('@');

      setTempChips((prevChips) => ({
        ...prevChips,
        customerNameChip: values,
      }));
      setIsNewChip(true);
    } else {
      setSelectedCustomer([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        customerNameChip: [],
      }));
    }
  };
  const handleProjectChange = (event, values) => {
    setCurrentPage(1);
    if (values) {
      //co//sole.log("values projext", values);
      const tempArray = [];
      // const formattedValues = values.map((value) => {
      //   const parts = value.split("%20");
      //   return parts.join(" ").replace(/[^a-zA-Z0-9- ]/g, ""); // Remove special characters
      // });
      const formattedValues = values.map((value) => value.split("@$@")[1]);
      const originalValues = values.map((value) =>
        value.replace(/@\$\@/g, "-")
      );
      setSelectedProjects(values);
      ////console.log("values projext new", formattedValues);
      setSelectedProjItem(formattedValues);
      setTempChips((prevChips) => ({
        ...prevChips,
        projectsChip: originalValues,
      }));
      setIsNewChip(true);
    } else {
      setSelectedProjects([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        projectsChip: [],
      }));
    }
  };
  const handleEntityChange = (event, values) => {
    setCurrentPage(1);
    if (values) {
      ////console.log("values", values);
      setSelectedEntityCode(values);

      setTempChips((prevChips) => ({
        ...prevChips,
        empEntityChip: values,
      }));
      setIsNewChip(true);
    } else {
      setSelectedEntityCode([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        empEntityChip: [],
      }));
    }
  };
  const handleEmpCodeChange = (event, values) => {
    // console.log("empType", selectedEmpType);
    setCurrentPage(1);
    if (values) {
      ////console.log("values", values);
      setSelectedEmpType(values);

      setTempChips((prevChips) => ({
        ...prevChips,
        empTypeChip: values,
      }));
      setIsNewChip(true);
    } else {
      setSelectedEmpType([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        empTypeChip: [],
      }));
    }
  };
  const handlePurposeChange = (event, values) => {
    setCurrentPage(1);
    if (values) {
      ////console.log("values", values);
      setSelectedPurpose(values);

      setTempChips((prevChips) => ({
        ...prevChips,
        projPurposeChip: values,
      }));
      setIsNewChip(true);
    } else {
      setSelectedPurpose([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        projPurposeChip: [],
      }));
    }
  };
  const handleVerticalChange = (event, values) => {
    setCurrentPage(1);
    if (values) {
      ////console.log("values", values);
      setSelectedVertical(values);

      setTempChips((prevChips) => ({
        ...prevChips,
        verticalChip: values,
      }));
      setIsNewChip(true);
    } else {
      setSelectedVertical([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        verticalChip: [],
      }));
    }
  };
  const handleChipDelete = (chipType, value) => {
    // console.log("chipchip", chips[`${chipType}`]);
    
    const originalValue=selectedProjects.filter(item=>item.replace(/@\$\@/g, "-")!==value)
    setSelectedProjects(originalValue);
    const index = chips[`${chipType}`].indexOf(value);
    if (index != -1) {
      chips[`${chipType}`].splice(index, 1);
      setIsNewChip(true);
    }
    // chips[`${chipType}`].filter((val) => val !== value);

    // console.log("chipchip", chips[`${chipType}`]);
  };
  return (
    <div>
      {" "}
      <Header
        heading={"Timesheet"}
        ssoEmpId={tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId}
        disableAccOptions={1}
      />
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#e3e8f7",
        }}
      >
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity="success"
            sx={{ width: "100%", backgroundColor: "green", color: "white" }} // Set the background color
          >
            Download successful!
          </MuiAlert>
        </Snackbar>{" "}
        <Box>
          {" "}
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              history.push("/timesheet-management");
              window.location.reload();
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Box>
        <Box>
          <Typography
            style={{
              fontSize: "20px",
              fontWeight: "600",
              //marginRight: "37rem",
              marginRight:
                window.screen.availWidth == 1920
                  ? "80rem"
                  : window.screen.availWidth == 1536
                  ? "55rem"
                  : window.screen.availWidth == 1098
                  ? "37rem"
                  : "37rem",
            }}
          >
            Timesheet Report
          </Typography>
        </Box>
        <Button
          onClick={handleSummaryDownload}
          className={classes.addExpenseButton}
        >
          <Typography className={classes.buttonText}>
            Summary Download
          </Typography>
        </Button>
        {/* <CSVLink
            {...csvLink1}
            style={{ color: "transparent" }}
            ref={csvref}
          ></CSVLink> */}
        <Button
          onClick={handleDetailDownload}
          className={classes.addExpenseButton}
        >
          <Typography className={classes.buttonText}>
            Detail Download
          </Typography>
        </Button>
        {/* <CSVLink
            {...csvLink}
            style={{ color: "transparent" }}
            ref={csvDailyref}
          ></CSVLink> */}
      </Box>
      <Divider style={{ marginBottom: "23px" }} />
      <Box
        container
        //spacing={0}
        style={{
          display: "flex",
          marginBottom: "24px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={1} md={1}>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            // style={{ width: "136px" }}
          >
            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              fullWidth
              style={{
                marginLeft: "20px",
                //width: "180px",
                width:
                  window.screen.availWidth == 1920
                    ? "200px"
                    : window.screen.availWidth == 1536
                    ? "160px"
                    : window.screen.availWidth == 1098
                    ? "136px"
                    : "115px",
              }}
              variant="inline"
              size="small"
              format="MMM d, yy"
              autoOk={true}
              minDate={systemLiveDate}
              //   maxDate={futureLimitDate}
              id="date-picker-inline"
              label={<span style={{ color: "#000" }}>From Date*</span>}
              value={fromDate}
              onChange={(date) => {
                setCurrentPage(1);
                setFromDate(date);
              }}
              inputProps={{
                style: { fontSize: "13px", fontWeight: "500" },
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
                style: { height: "16px", width: "16px", paddingRight: "3px" },
              }}
              helperText={
                !toDate && !fromDate
                  ? "*Please enter both From Date and To Date"
                  : !fromDate
                  ? "*Please enter From Date"
                  : !toDate
                  ? "*Please enter To Date"
                  : toDate !== "" && fromDate !== "" && toDate < fromDate
                  ? "*To Date should be greater than From Date"
                  : ""
              }
              FormHelperTextProps={{
                style: {
                  color: "red",
                  paddingTop: "5px",
                  marginLeft: "0px",
                  marginRight: "0px",
                  marginTop: 0,
                  marginBottom: "-24px",
                  width: "max-content",
                },
              }}
            />
            {/* {fromDateErrorMesssage && (
            <div style={{ color: 'red' }}>{fromDateErrorMesssage}</div>
          )} */}
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid
          item
          xs={1}
          md={1}
          // style={{ marginLeft: "30px" }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              fullWidth
              style={{
                // marginLeft: "136px",
                marginLeft:
                  window.screen.availWidth == 1920
                    ? "101px"
                    : window.screen.availWidth == 1536
                    ? "89px"
                    : window.screen.availWidth == 1098
                    ? "136px"
                    : "47px",
                width:
                  window.screen.availWidth == 1920
                    ? "200px"
                    : window.screen.availWidth == 1536
                    ? "160px"
                    : window.screen.availWidth == 1098
                    ? "136px"
                    : "115px",
              }}
              variant="inline"
              minDate={systemLiveDate}
              //   maxDate={futureLimitDate}
              size="small"
              format="MMM d, yy"
              autoOk={true}
              id="date-picker-inline"
              label={<span style={{ color: "#000" }}>To Date*</span>}
              value={toDate}
              onChange={(date) => {
                setCurrentPage(1);
                setToDate(date);
              }}
              inputProps={{
                style: { fontSize: "13px", fontWeight: "500" },
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
                style: { height: "16px", width: "16px", paddingRight: "3px" },
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.TMStatus)
                ? timesheetReportJson?.TMStatus
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              //width: "60%",
              width:
                window.screen.availWidth == 1920
                  ? "60%"
                  : window.screen.availWidth == 1536
                  ? "60%"
                  : window.screen.availWidth == 1098
                  ? "57%"
                  : "80%",
              // ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            //options={statusOptions}
            style={{
              //marginLeft: "223px",
              marginLeft:
                window.screen.availWidth == 1920
                  ? "186px"
                  : window.screen.availWidth == 1536
                  ? "160px"
                  : window.screen.availWidth == 1098
                  ? "136px"
                  : "75px",
            }}
            value={selectedStatus}
            onChange={handleStatusChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedStatus.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px" }}
                label="Week Status"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" } // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.EmployeeType)
                ? timesheetReportJson?.EmployeeType
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              // width: "57%",
              width:
                window.screen.availWidth == 1920
                  ? "60%"
                  : window.screen.availWidth == 1536
                  ? "60%"
                  : window.screen.availWidth == 1098
                  ? "57%"
                  : "75%",
              // ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            //options={statusOptions}
            style={{
              //marginLeft: "19px",
              marginLeft:
                window.screen.availWidth == 1920
                  ? "118px"
                  : window.screen.availWidth == 1536
                  ? "88px"
                  : window.screen.availWidth == 1098
                  ? "19px"
                  : "53px",
            }}
            value={selectedEmpType}
            onChange={handleEmpCodeChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedEmpType.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px" }}
                label="Employee Type"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" } // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.EntityCode)
                ? timesheetReportJson?.EntityCode
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              // width: "70%",
              width:
                window.screen.availWidth == 1920
                  ? "80%"
                  : window.screen.availWidth == 1536
                  ? "82%"
                  : window.screen.availWidth == 1098
                  ? "57%"
                  : "90%",
                  // fontSize: "14px",
              // "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            style={{
              // marginLeft: "105px"
              marginLeft:
                window.screen.availWidth == 1920
                  ? "45px"
                  : window.screen.availWidth == 1536
                  ? "24px"
                  : window.screen.availWidth == 1098
                  ? "41px"
                  : "20px",
            }}
            //options={statusOptions}
            value={selectedEntityCode}
            onChange={handleEntityChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedEntityCode.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px", fontSize: "14px" }}
                label="Employee Legal Entity"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" } // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.Horizontal)
                ? timesheetReportJson?.Horizontal
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              // ".MuiInputLabel-outlined": {
              //   transform: "translate(15px, 10px) scale(1)",
              // },
              width:
                window.screen.availWidth == 1920
                  ? "80%"
                  : window.screen.availWidth == 1536
                  ? "85%"
                  : window.screen.availWidth == 1098
                  ? "57%"
                  : "90%",
              // ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            style={{
              marginLeft:
                window.screen.availWidth == 1920
                  ? "40px"
                  : window.screen.availWidth == 1536
                  ? "19px"
                  : window.screen.availWidth == 1098
                  ? "59px"
                  : "20px",
            }}
            //options={statusOptions}
            value={selectedVertical}
            onChange={handleVerticalChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedVertical.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px" }}
                label="Project Vertical"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" } // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.ProjPurpose)
                ? timesheetReportJson?.ProjPurpose
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              // ".MuiInputLabel-outlined": {
              //   transform: "translate(15px, 10px) scale(1)",
              // },
              width:
                window.screen.availWidth == 1920
                  ? "87%"
                  : window.screen.availWidth == 1536
                  ? "86%"
                  : window.screen.availWidth == 1098
                  ? "57%"
                  : "85%",
              // ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            style={{
              marginLeft:
                window.screen.availWidth == 1920
                  ? "29px"
                  : window.screen.availWidth == 1536
                  ? "21px"
                  : window.screen.availWidth == 1098
                  ? "59px"
                  : "20px",
            }}
            //options={statusOptions}
            value={selectedPurpose}
            onChange={handlePurposeChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedPurpose.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px" }}
                label="Project Purpose"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" } // Set the font size here
                }}
              />
            )}
          />
        </Grid>
      </Box>
      <Grid
        container
        spacing={1}
        style={{
          display: "flex",
          marginBottom: "15px",
          marginTop: "2px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {" "}
        
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            // options={
            //   Array.isArray(timesheetReportJson?.ProjName)
            //     ? timesheetReportJson?.ProjName
            //     : //?.map((item) => item.EntityCode.split(", "))
            //       // .flat()
            //       // .filter(
            //       //   (EntityCode, index, self) =>
            //       //     self.indexOf(EntityCode) === index
            //       // )
            //       []
            // }
            options={
              Array.isArray(timesheetReportJson?.ProjName)
                ? timesheetReportJson?.ProjName
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              //width: "57%",
              width:
                window.screen.availWidth == 1920
                  ? "102%"
                  : window.screen.availWidth == 1536
                  ? "103%"
                  : window.screen.availWidth == 1098
                  ? "57%"
                  : "136%",
              // ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            style={{
              // marginLeft: "-474px"
              marginLeft:
                window.screen.availWidth == 1920
                  ? "23px"
                  : window.screen.availWidth == 1536
                  ? "24px"
                  : window.screen.availWidth == 1098
                  ? "-474px"
                  : "24px",
            }}
            //options={statusOptions}
            value={selectedProjects}
            onChange={handleProjectChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedProjects.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => {
              //console.log("12345", option);
              return (
                <li
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    paddingRight: "0px",
                  }}
                  {...props}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: selected ? "#0B1941" : "" }}
                        checked={selected}
                      />
                    }
                    label={
                      <span style={{ fontSize: "10px" }}>
                        {option.replace(/@\$\@/g, "-")}
                      </span>
                    }
                  />
                </li>
              );
            }}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px" }}
                label="Project Name/Id"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" } // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} className={classes.backgroundFilter}>
          <Autocomplete
            multiple
            options={
              Array.isArray(timesheetReportJson?.CustomerName)
                ? timesheetReportJson?.CustomerName
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "2px",
              },
              //width: "60%",
              width:
                window.screen.availWidth == 1920
                  ? "70%"
                  : window.screen.availWidth == 1536
                  ? "68%"
                  : window.screen.availWidth == 1098
                  ? "57%"
                  : "88%",
              // ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              //   fontSize: "14px",
              // },
            }}
            //options={statusOptions}
            style={{
              //marginLeft: "223px",
              marginLeft:
                window.screen.availWidth == 1920
                  ? "39px"
                  : window.screen.availWidth == 1536
                  ? "9px"
                  : window.screen.availWidth == 1098
                  ? "136px"
                  : "90px",
            }}
            value={selectedCustomer}
            onChange={handleCustomerNameChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) =>
              selectedCustomer.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "12px" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "40px" }}
                label="Customer Name"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  style: { fontSize: "14px" } // Set the font size here
                }}
              />
            )}
          />
        </Grid>
        <Grid
          style={{
            // marginLeft: "-62.87rem"
            marginRight:
              window.screen.availWidth == 1920
                ? "29.13rem"
                : window.screen.availWidth == 1536
                ? "21.87rem"
                : window.screen.availWidth == 1098
                ? "-62.87rem"
                : "91px",
                marginLeft: window.screen.availWidth == 1920?"-6rem":
                 window.screen.availWidth == 1536
                  ? "-9rem"
                  : "",
          }}
        >
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                m: 1,
                width:
                  window.screen.availWidth == 1920
                    ? "30.5ch"
                    : window.screen.availWidth == 1536
                    ? "25ch"
                    : window.screen.availWidth == 1098
                    ? "25ch"
                    : "31ch",
              },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleFormSubmit}
          >
            <div>
              <TextField
                id="outlined-size-small"
                label="Search by Employee Name/Id"
                size="small"
                InputProps={{
                  inputMode: "text",
                  pattern: "[a-zA-Z0-9,]*",
                }}
                InputLabelProps={{
                  style: { fontSize: "14px" } // Set the font size here
                }}
                variant="outlined"
                // sx={{
                //   ".css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                //     fontSize: "14px",
                //   },
                // }}
                value={selectedEmpName}
                onChange={handleEmployee}
                onKeyDown={handleEmployee}
                onBlur={handleEmployee}
              />
            </div>
          </Box>
        </Grid>
        <Grid item xs={1} md={1}>
          <Box style={{ width: "100px" }}>
            <Link
              style={{
                color:
                  fromDate && toDate && toDate >= fromDate
                    ? "#008CE6"
                    : "#808080",
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left",
                //cursor: "pointer",
                cursor:
                  fromDate && toDate && toDate >= fromDate
                    ? "pointer"
                    : "not-allowed",
                pointerEvents:
                  fromDate && toDate && toDate >= fromDate ? "" : "none",
                //marginLeft: "-23rem",
                marginLeft:
                  window.screen.availWidth == 1920
                    ? "-24rem"
                    : window.screen.availWidth == 1536
                    ? "-20rem"
                    : window.screen.availWidth == 1098
                    ? "-23rem"
                    : "-3rem",
              }}
              underline="always"
              onClick={(e) => {
                // setCurrentPage(1);
                // setTimesheetReportViewState([]);
                handleTimesheetReportApplyFilter();
              }}
            >
              Apply Filter
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      {/* {console.log("status chip", chips.employeeChip)} */}
      {applyFilterClicked && (
        <div style={{ marginLeft: "16px" }}>
          {Object.entries(chips).map(([chipType, chipValue], index) => (
            <React.Fragment key={index}>
              {/* {console.log("chip check", chipType, chipValue)} */}
              {Array.isArray(chipValue)
                ? chipValue.map((value, valueIndex) => (
                    <Chip
                      key={`${chipType}-${valueIndex}`}
                      label={value}
                      onDelete={() => handleChipDelete(chipType, value)}
                      style={{
                        margin: "10px 5px 10px 5px",
                        backgroundColor: "#fff",
                        color: "#000",
                        fontWeight: "600",
                        border: "1px solid #000",
                        fontSize: "10px",
                        height: "24px",
                      }}
                      deleteIcon={
                        <ClearIcon
                          style={{
                            color: "#000",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                      }
                    />
                  ))
                : chipValue && (
                    <Chip
                      key={`${chipType}-${chipValue}`}
                      label={chipValue}
                      onDelete={() => handleChipDelete(chipValue)}
                      style={{
                        margin: "5px",
                        backgroundColor: "#fff",
                        color: "#000",
                        fontWeight: "600",
                        border: "1px solid #000",
                      }}
                      deleteIcon={
                        <ClearIcon
                          style={{
                            color: "#000",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                      }
                    />
                  )}
            </React.Fragment>
          ))}
          {Object.values(chips).some((chipValue) =>
            Array.isArray(chipValue) ? chipValue?.length > 0 : chipValue
          ) && (
            <Link
              style={{
                color: "#008CE6",
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left",
                cursor: "pointer",
              }}
              underline="always"
              onClick={handleClearAll}
            >
              Clear All
            </Link>
          )}
        </div>
      )}
      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: "#e3e8f7" }}>
            <TableCell
              style={{
                fontWeight: 600,
                fontSize: "14px",
                width: "15%",
                paddingLeft: "20px",
              }}
            >
              Time Period
            </TableCell>
            <TableCell
              style={{ fontWeight: 600, fontSize: "14px", width: "10%" }}
            >
              Employee Id
            </TableCell>
            <TableCell
              style={{ fontWeight: 600, fontSize: "14px", width: "18%" }}
            >
              Employee Name
            </TableCell>

            <TableCell
              style={{ fontWeight: 600, fontSize: "14px", width: "12%" }}
            >
              Employee Type
            </TableCell>
            <TableCell
              style={{ fontWeight: 600, fontSize: "14px", width: "5%" }}
            >
              Entity
            </TableCell>
            <TableCell
              style={{
                fontWeight: 600,
                fontSize: "14px",
                width: "10%",
                paddingLeft: "0px",
                textAlign: "right",
              }}
            >
              Billed Hours
            </TableCell>
            <TableCell
              style={{
                fontWeight: 600,
                fontSize: "14px",
                width: "13%",
                paddingLeft: "0px",
                textAlign: "right",
                paddingRight: "15px",
              }}
            >
              Non Billed Hours
            </TableCell>
            <TableCell
              style={{
                fontWeight: 600,
                fontSize: "14px",
                width: "13%",
                paddingLeft: "0px",
                textAlign: "right",
                paddingRight: "10px",
              }}
            >
              Approved Hours
            </TableCell>

            <TableCell
              style={{
                fontWeight: 600,
                fontSize: "14px",
                width: "15%",
                paddingLeft: "45px",
              }}
            >
              Status
            </TableCell>
          </TableHead>
          <TableBody>
            {timesheetReportViewState?.map((row, index) => (
              <TableRow className={classes.customRow} key={index}>
                <TableCell
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    paddingLeft: "22px",
                  }}
                  onClick={() => {
                    // console.log("hi table row clicked! report");
                    const text = "/timesheetviewpage/:id";
                    const result = text.replace(":id", row.empId);
                    dispatch(
                      setAllChips({
                        allTempChips: tempChips,
                        fromDate,
                        toDate,
                        currentPage,
                        timesheetReportViewState,
                        selectedProjects,
                      })
                    );
                    history.push({
                      pathname: result,
                      state: {
                        empId: row.empId,
                        managerId: 0,
                        delegateeId: 0,
                        fromDate: row.weekStartDate
                          ? moment(row.weekStartDate).format("yyyy-MM-DD")
                          : "",
                        toDate: row.weekEndDate
                          ? moment(row.weekEndDate).format("yyyy-MM-DD")
                          : "",
                        //   fromDate:"2023-10-29",
                        //   toDate:"2023-11-04",
                        tmId: row.tmId,
                        weekStatus: row.weekStatus,
                        projId:"",
                        tab: "allTimesheetReport",

                        compName: "DetailedView",
                      },
                    });
                  }}
                >
                  {/* {console.log(
                        "dates of report", moment(row.weekEndDate).format(
                          "yyyy-MM-DD"
                        )
                       
                      )} */}
                  {row.week}
                </TableCell>
                <TableCell
                  style={{ fontSize: "12px", fontWeight: 500 }}
                  onClick={() => {
                    // console.log("hi table row clicked! report");
                    const text = "/timesheetviewpage/:id";
                    const result = text.replace(":id", row.empId);
                    dispatch(
                      setAllChips({
                        allTempChips: tempChips,
                        fromDate,
                        toDate,
                        currentPage,
                        timesheetReportViewState,
                        selectedProjects,
                      })
                    );
                    history.push({
                      pathname: result,
                      state: {
                        empId: row.empId,
                        managerId: 0,
                        delegateeId: 0,
                        fromDate: row.weekStartDate
                          ? moment(row.weekStartDate).format("yyyy-MM-DD")
                          : "",
                        toDate: row.weekEndDate
                          ? moment(row.weekEndDate).format("yyyy-MM-DD")
                          : "",
                        //   fromDate:"2023-10-29",
                        //   toDate:"2023-11-04",
                        tmId: row.tmId,
                        weekStatus: row.weekStatus,
                        projId:"",
                        tab: "allTimesheetReport",

                        compName: "DetailedView",
                      },
                    });
                  }}
                >
                  {" "}
                  {row.resourceId}
                </TableCell>
                <TableCell
                  onClick={() => {
                    // console.log("hi table row clicked! report");
                    const text = "/timesheetviewpage/:id";
                    const result = text.replace(":id", row.empId);
                    dispatch(
                      setAllChips({
                        allTempChips: tempChips,
                        fromDate,
                        toDate,
                        currentPage,
                        timesheetReportViewState,
                        selectedProjects,
                      })
                    );
                    history.push({
                      pathname: result,
                      state: {
                        empId: row.empId,
                        managerId: 0,
                        delegateeId: 0,
                        fromDate: row.weekStartDate
                          ? moment(row.weekStartDate).format("yyyy-MM-DD")
                          : "",
                        toDate: row.weekEndDate
                          ? moment(row.weekEndDate).format("yyyy-MM-DD")
                          : "",
                        //   fromDate:"2023-10-29",
                        //   toDate:"2023-11-04",
                        tmId: row.tmId,
                        weekStatus: row.weekStatus,
                        projId:"",
                        tab: "allTimesheetReport",

                        compName: "DetailedView",
                      },
                    });
                  }}
                >
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography
                        variant="body1"
                        style={{ fontSize: "12px", fontWeight: 500 }}
                      >
                        {row.resourceName}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell
                  onClick={() => {
                    // console.log("hi table row clicked! report");
                    const text = "/timesheetviewpage/:id";
                    const result = text.replace(":id", row.empId);
                    dispatch(
                      setAllChips({
                        allTempChips: tempChips,
                        fromDate,
                        toDate,
                        currentPage,
                        timesheetReportViewState,
                        selectedProjects,
                      })
                    );
                    history.push({
                      pathname: result,
                      state: {
                        empId: row.empId,
                        managerId: 0,
                        delegateeId: 0,
                        fromDate: row.weekStartDate
                          ? moment(row.weekStartDate).format("yyyy-MM-DD")
                          : "",
                        toDate: row.weekEndDate
                          ? moment(row.weekEndDate).format("yyyy-MM-DD")
                          : "",
                        //   fromDate:"2023-10-29",
                        //   toDate:"2023-11-04",
                        tmId: row.tmId,
                        weekStatus: row.weekStatus,
                        projId:"",
                        tab: "allTimesheetReport",

                        compName: "DetailedView",
                      },
                    });
                  }}
                >
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography
                        variant="body1"
                        style={{ fontSize: "12px", fontWeight: 500 }}
                      >
                        {row.employeeType}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell
                  style={{ fontSize: "12px", fontWeight: 500 }}
                  onClick={() => {
                    // console.log("hi table row clicked! report");
                    const text = "/timesheetviewpage/:id";
                    const result = text.replace(":id", row.empId);
                    dispatch(
                      setAllChips({
                        allTempChips: tempChips,
                        fromDate,
                        toDate,
                        currentPage,
                        timesheetReportViewState,
                        selectedProjects,
                      })
                    );
                    history.push({
                      pathname: result,
                      state: {
                        empId: row.empId,
                        managerId: 0,
                        delegateeId: 0,
                        fromDate: row.weekStartDate
                          ? moment(row.weekStartDate).format("yyyy-MM-DD")
                          : "",
                        toDate: row.weekEndDate
                          ? moment(row.weekEndDate).format("yyyy-MM-DD")
                          : "",
                        //   fromDate:"2023-10-29",
                        //   toDate:"2023-11-04",
                        tmId: row.tmId,
                        weekStatus: row.weekStatus,
                        projId:"",
                        tab: "allTimesheetReport",

                        compName: "DetailedView",
                      },
                    });
                  }}
                >
                  {row.resourceEntity}
                </TableCell>
                <TableCell
                  style={{
                    paddingLeft: "0px",
                    textAlign: "right",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    // console.log("hi table row clicked! report");
                    const text = "/timesheetviewpage/:id";
                    const result = text.replace(":id", row.empId);
                    dispatch(
                      setAllChips({
                        allTempChips: tempChips,
                        fromDate,
                        toDate,
                        currentPage,
                        timesheetReportViewState,
                        selectedProjects,
                      })
                    );
                    history.push({
                      pathname: result,
                      state: {
                        empId: row.empId,
                        managerId: 0,
                        delegateeId: 0,
                        fromDate: row.weekStartDate
                          ? moment(row.weekStartDate).format("yyyy-MM-DD")
                          : "",
                        toDate: row.weekEndDate
                          ? moment(row.weekEndDate).format("yyyy-MM-DD")
                          : "",
                        //   fromDate:"2023-10-29",
                        //   toDate:"2023-11-04",
                        tmId: row.tmId,
                        weekStatus: row.weekStatus,
                        projId:"",
                        tab: "allTimesheetReport",

                        compName: "DetailedView",
                      },
                    });
                  }}
                >
                  {row.billedHours}
                </TableCell>
                <TableCell
                  style={{
                    paddingLeft: "0px",
                    textAlign: "right",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    // console.log("hi table row clicked! report");
                    const text = "/timesheetviewpage/:id";
                    const result = text.replace(":id", row.empId);
                    dispatch(
                      setAllChips({
                        allTempChips: tempChips,
                        fromDate,
                        toDate,
                        currentPage,
                        timesheetReportViewState,
                        selectedProjects,
                      })
                    );
                    history.push({
                      pathname: result,
                      state: {
                        empId: row.empId,
                        managerId: 0,
                        delegateeId: 0,
                        fromDate: row.weekStartDate
                          ? moment(row.weekStartDate).format("yyyy-MM-DD")
                          : "",
                        toDate: row.weekEndDate
                          ? moment(row.weekEndDate).format("yyyy-MM-DD")
                          : "",
                        //   fromDate:"2023-10-29",
                        //   toDate:"2023-11-04",
                        tmId: row.tmId,
                        weekStatus: row.weekStatus,
                        projId:"",
                        tab: "allTimesheetReport",

                        compName: "DetailedView",
                      },
                    });
                  }}
                >
                  {row.nonBilledHours}
                </TableCell>
                <TableCell
                  style={{
                    paddingLeft: "0px",
                    textAlign: "right",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    // console.log("hi table row clicked! report");
                    const text = "/timesheetviewpage/:id";
                    const result = text.replace(":id", row.empId);
                    dispatch(
                      setAllChips({
                        allTempChips: tempChips,
                        fromDate,
                        toDate,
                        currentPage,
                        timesheetReportViewState,
                        selectedProjects,
                      })
                    );
                    history.push({
                      pathname: result,
                      state: {
                        empId: row.empId,
                        managerId: 0,
                        delegateeId: 0,
                        fromDate: row.weekStartDate
                          ? moment(row.weekStartDate).format("yyyy-MM-DD")
                          : "",
                        toDate: row.weekEndDate
                          ? moment(row.weekEndDate).format("yyyy-MM-DD")
                          : "",
                        //   fromDate:"2023-10-29",
                        //   toDate:"2023-11-04",
                        tmId: row.tmId,
                        weekStatus: row.weekStatus,
                        projId:"",
                        tab: "allTimesheetReport",

                        compName: "DetailedView",
                      },
                    });
                  }}
                >
                  {row.approvedHours}
                </TableCell>

                <TableCell
                  style={{ fontSize: "12px", fontWeight: 500 }}
                  onClick={() => {
                    // console.log("hi table row clicked! report");
                    const text = "/timesheetviewpage/:id";
                    const result = text.replace(":id", row.empId);
                    // allSetChips = null;
                    dispatch(
                      setAllChips({
                        allTempChips: tempChips,
                        fromDate,
                        toDate,
                        currentPage,
                        timesheetReportViewState,
                        selectedProjects,
                      })
                    );
                    history.push({
                      pathname: result,
                      state: {
                        empId: row.empId,
                        managerId: 0,
                        delegateeId: 0,
                        fromDate: row.weekStartDate
                          ? moment(row.weekStartDate).format("yyyy-MM-DD")
                          : "",
                        toDate: row.weekEndDate
                          ? moment(row.weekEndDate).format("yyyy-MM-DD")
                          : "",
                        //   fromDate:"2023-10-29",
                        //   toDate:"2023-11-04",
                        tmId: row.tmId,
                        weekStatus: row.weekStatus,
                        projId:"",
                        tab: "allTimesheetReport",

                        compName: "DetailedView",
                      },
                    });
                  }}
                >
                  <Box>
                    <Chip
                      label={row.weekStatus}
                      className={classes.statusStyle}
                      style={{
                        background:
                          row.weekStatus === "In Review"
                            ? "#E0B15C"
                            : row.weekStatus === "Approved"
                            ? " #349259"
                            : row.weekStatus === "Auto Approved"
                            ? " #349259"
                            : row.weekStatus === "Rejected"
                            ? "#D40606"
                            : row.weekStatus === "Draft"
                            ? "#808080"
                            : row.weekStatus === "Not Created"
                            ? "#808080"
                            : "",
                        display:
                          row.weekStatus === "In Review" ||
                          row.weekStatus === "Approved" ||
                          row.weekStatus === "Auto Approved" ||
                          row.weekStatus === "Rejected" ||
                          row.weekStatus === "Draft" ||
                          row.weekStatus === "Not Created"
                            ? "inline-flex"
                            : "none",
                        color: "#ffffff",
                        fontSize: "12px",
                      }}
                    />
                  </Box>
                  {/* {row.weekStatus} */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {timesheetReportView?.length === 0 ? (
        <Grid>
          <Typography
            style={{
              fontSize: "20px",
              lineHeight: "240px",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            No Timesheet entry exists!
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
      <div>
        <Backdrop
          sx={{ color: "#092FED", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
}

export default TimesheetReport;