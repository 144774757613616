import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Tabs,
  Tab,
  Typography,
  Grid,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmpDetail,
  updateSsoEmpId,
  maintainState,
  getMyTeamApprovalPageFlagData,
  resetAuditHistoryData
} from "../redux/Expense/action";
import Box from "@material-ui/core/Box";
import MobileViewNavbar from './mobileViewNavbar';
import MobileViewReportListBox from "./mobileViewReportListBox";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MobileViewTable from './mobileViewTable';
import MobileViewTeamApproval from "./mobileViewTeamApproval";
import MobileViewFinanceApproval from "./mobileViewFinanceApproval"
// import MobileViewDelegation from "./mobileViewDelegation"
import { useTheme, withStyles } from "@material-ui/core/styles";
import { getMyExpenseReportData } from './../redux/Expense/action';
import { useMsal, useAccount } from "@azure/msal-react";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  tabText: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "0.888rem",
    lineHeight: "1.25rem",
    letterSpacing: "-0.005rem",
    display: "flex",
    color: "#0B1941",
  },
  iPhoneSEtabText: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "0.66rem",
    lineHeight: "1.25rem",
    letterSpacing: "-0.005rem",
    display: "flex",
    color: "#0B1941",
  },
  tabText2: {
    fontSize: "0.7rem",
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    color: "#0B1941",
  },
  myExpenseTab: {
    backgroundColor: "#0B1941",
    marginLeft: "1.375rem",
    paddingLeft: "1.375rem",
    width: "17% !important"
  },
  teamApproveTab: {
    backgroundColor: "#0B1941",
    marginLeft: "0.375rem",
    paddingLeft: "1.375rem",
    width: "25% !important"
  },
  financeTab: {
    backgroundColor: "#0B1941",
    marginLeft: "0.375rem",
    paddingLeft: "1.375rem",
    width: "29% !important"
  },
  myExpenseTabXR: {
    backgroundColor: "#0B1941",
    marginLeft: "0.75rem",
    paddingLeft: "1.375rem",
    width: "14% !important"
  },
  teamApproveTabXR: {
    backgroundColor: "#0B1941",
    marginLeft: "0.1rem",
    paddingLeft: "1.375rem",
    width: "22% !important"
  },
  financeTabXR: {
    backgroundColor: "#0B1941",
    marginLeft: "1.375rem",
    paddingLeft: "1.375rem",
    width: "28% !important"
  },
  financeTabPixel: {
    backgroundColor: "#0B1941",
    marginLeft: "1rem",
    paddingLeft: "1.375rem",
    width: "28% !important"
  },
  financeTabSE: {
    backgroundColor: "#0B1941",
    marginLeft: "0.375rem",
    paddingLeft: "1.375rem",
    width: "28% !important"
  },
  iPhoneSE: {
    padding: "6px 0px"
  }
});

export default function MobileViewDashboard(props) {
  const [ssoEmpId, setSsoEmpId] = useState(null);
  const [myExpenseData, setMyExpenseData] = React.useState([]);
  const [teamApprovalTab, setTeamApprovalTab] = useState(false);
  const [myExpensesTab, setMyExpensesTab] = useState(true);
  const [financeApprovalTab, setFinanceApprovalTab] = useState(false);
  const [empId, setEmpId] = React.useState(null);
  const [inProgressExpenses, setInProgressExpenses] = useState(0);
  const [inProgressFinancialExpenses, setInProgressFinancialExpenses] = useState(0);
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:415px)");
  const matchesiPhoneSe = useMediaQuery("(max-width:375px)");
  const matchesPixel = useMediaQuery("(max-width:393px)");
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const location = useLocation();
  const [state, setState] = useState(90);

  const data = location.state;

  function handleState(newValue) {
    if (newValue !== undefined) {
      setState(newValue);
    }
  }

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );

  const getMyExpenseList = useSelector(
    (state) => state.createExpenseData.getMyExpense
  );
  const myExpenseReportList = useSelector(
    (state) => state.createExpenseData.getMyExpenseReportList
  );
  const getMyTeamApprovalFlagData = useSelector(
    (state) => state.createExpenseData.getMyTeamApprovalPageFlag
  );
  const maintainStateVar = useSelector(
    (state) => state.createExpenseData.maintainStateVariable
  );
  const delEmpIDRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );
  // console.log("getMyTeamApprovalFlagData",getMyTeamApprovalFlagData,ssoEmpIdDetails)

  useEffect(() => {
    dispatch(resetAuditHistoryData());
  }, []);

  useEffect(() => {
    dispatch(getEmpDetail(account?.username));
  }, [account?.username]);

  useEffect(() => {
    if (ssoEmpIdDetails != null && ssoEmpIdDetails != undefined) {
      let obj = {
        empid: ssoEmpIdDetails?.empId,
        nday: state,
      };
      dispatch(getMyExpenseReportData(obj));
    }
  }, [ssoEmpIdDetails]);

  useEffect(() => {
    if (getMyTeamApprovalFlagData != null) {
      if (
        getMyTeamApprovalFlagData?.level1Flag == 0 &&
        getMyTeamApprovalFlagData?.level2Flag == 0
      ) {
        setValue(0);
      } else if (
        getMyTeamApprovalFlagData?.level1Flag == 1 &&
        getMyTeamApprovalFlagData?.level2Flag == 0
      ) {
        setInProgressExpenses(getMyTeamApprovalFlagData?.level1PendingCount);
        if (
          maintainStateVar != null &&
          maintainStateVar != undefined &&
          maintainStateVar != 2
        ) {
          setValue(maintainStateVar);
        } else setValue(0);
      } else if (
        getMyTeamApprovalFlagData?.level1Flag == 0 &&
        getMyTeamApprovalFlagData?.level2Flag == 1
      ) {
        setInProgressFinancialExpenses(
          getMyTeamApprovalFlagData?.level2PendingCount
        );
        if (maintainStateVar != null && maintainStateVar != undefined) {
          if (maintainStateVar == 2) {
            setValue(0);
          } else {
            setValue(maintainStateVar);
          }
        } else setValue(0);
      } else if (
        getMyTeamApprovalFlagData?.level1Flag == 1 &&
        getMyTeamApprovalFlagData?.level2Flag == 1
      ) {
        setInProgressExpenses(getMyTeamApprovalFlagData?.level1PendingCount);
        setInProgressFinancialExpenses(
          getMyTeamApprovalFlagData?.level2PendingCount
        );
        if (maintainStateVar != null && maintainStateVar != undefined) {
          setValue(maintainStateVar);
        } else setValue(0);
      }
    }
  }, [getMyTeamApprovalFlagData]);

  useEffect(() => {
    if (maintainStateVar != null && maintainStateVar != undefined) {
      setValue(maintainStateVar);
    }
    if (delEmpIDRedux != null) {
      let obj = {
        empId: delEmpIDRedux,
        nday: state,
      };
      dispatch(getMyTeamApprovalPageFlagData(obj));
    } else {
      if (ssoEmpIdDetails?.empId !== null && ssoEmpIdDetails?.empId !== undefined) {
        let obj = {
          empId: ssoEmpIdDetails?.empId,
          nday: state,
        };
        dispatch(getMyTeamApprovalPageFlagData(obj));
      }
    }
  }, [delEmpIDRedux]);
  useEffect(() => {
    // console.log("first")
    if (ssoEmpIdDetails?.empId !== null && ssoEmpIdDetails?.empId !== undefined) {
      let obj = {
        empId: ssoEmpIdDetails?.empId,
        nday: state,
      };
      // console.log("second")
      dispatch(getMyTeamApprovalPageFlagData(obj));
    }
  }, [ssoEmpIdDetails])
  

  useEffect(() => {
    if (getMyExpenseList !== null) {
      setMyExpenseData(getMyExpenseList);
    }
  }, [getMyExpenseList]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(maintainState(newValue));
  };

  const handleDropeDown = (day, delEmpIDRed) => {
    setState(day);

    if (delEmpIDRed != null) {
      let obj = {
        empid: delEmpIDRed,
        nday: day,
      };
      dispatch(getMyExpenseReportData(obj));
      dispatch(getMyTeamApprovalPageFlagData(obj));
    } else {
      if (ssoEmpIdDetails?.empId !== null && ssoEmpIdDetails?.empId !== undefined) {
        let obj = {
          empid: ssoEmpIdDetails?.empId,
          nday: day,
        };
        dispatch(getMyExpenseReportData(obj));
        dispatch(getMyTeamApprovalPageFlagData(obj));
      }
    }
  };



  return (
    <>
      <MobileViewNavbar ssoEmpId={ssoEmpIdDetails?.empId} change={handleState} />
      <MobileViewReportListBox
        handleDropeDown={handleDropeDown}
        myExpenseReportList={myExpenseReportList}
        ssoEmpId={ssoEmpIdDetails?.empId}
        ndayValue={state}
        setNdayValue={setState}
      />


      <Box>
        <Box style={{ background: '#EDF2FF' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              className: (!matches && value == 0) ? classes.myExpenseTab : (matches && value == 0) ? classes.myExpenseTabXR :
                (matches && value == 1) ? classes.teamApproveTabXR : (!matches && value == 1) ? classes.teamApproveTab : (matchesiPhoneSe && value == 2) ? classes.financeTabSE :
                  (matchesPixel && value == 2) ? classes.financeTabPixel : (matches && value == 2) ? classes.financeTabXR : (!matches && value == 2) ? classes.financeTab : ''
            }}
            aria-label="full width tabs example"
          >
            <Tab style={{
              //paddingLeft:"6px",
              //paddingRight:"6px",
              //padding: "0.375rem 0.70rem",
              paddingLeft: " 0.70rem",
              paddingRight: "4px",
              alignItems: "center",

            }}
              label={
                <Typography className={(matchesiPhoneSe || matches) ? classes.iPhoneSEtabText : classes.tabText}
                >
                  {" "}
                  My Expenses
                </Typography>
              }
              className={(matchesiPhoneSe || matches) ? classes.iPhoneSE : ''}
              {...a11yProps(0)}
            />
            {getMyTeamApprovalFlagData?.level1Flag == 1 ? (
              <Tab style={{
                //paddingLeft:"6px",
                //paddingRight:"6px",
                padding: "0.375rem 4px",
                alignItems: "center",

              }}
                label={
                  <Typography className={(matchesiPhoneSe || matches) ? classes.iPhoneSEtabText : classes.tabText}>
                    Team Approvals ({inProgressExpenses})
                  </Typography>
                }
                className={(matchesiPhoneSe || matches) ? classes.iPhoneSE : ''}
                {...a11yProps(1)}
              />
            ) : (
              ""
            )}
            {getMyTeamApprovalFlagData?.level2Flag == 1 ? (
              <Tab
                style={{
                   //minWidth: (matchesPixel && !matchesiPhoneSe) ? "10.188rem" : matchesiPhoneSe ? "9.188rem" : matches ? "11.188rem"  : "12.188rem",
                  //paddingLeft:"3px",
                  //paddingRight:"6px",
                  padding: "0.375rem 4px",
                  alignItems: "center",
                }}
                label={
                  <Typography className={(matchesiPhoneSe || matches) ? classes.iPhoneSEtabText : classes.tabText}>
                    Finance Approvals ({inProgressFinancialExpenses})
                  </Typography>
                }
                className={(matchesiPhoneSe || matches) ? classes.iPhoneSE : ''}
                {...a11yProps(1)}
              />
            ) : (
              ""
            )}
          </Tabs>
          <TabPanel value={value} index={0} >
            <Box className={matches ? classes.enhanceTableContent : classes.enhanceTable}>
              <MobileViewTable ssoEmpId={ssoEmpIdDetails?.empId} ndayValue={state} commentValue={data} setNdayValue={setState} />
            </Box>
          </TabPanel>
          {getMyTeamApprovalFlagData?.level1Flag == 1 && (
            <TabPanel
              value={value}
              index={
                getMyTeamApprovalFlagData?.level1Flag == 1 &&
                  getMyTeamApprovalFlagData?.level2Flag !== 0
                  ? 1
                  : getMyTeamApprovalFlagData?.level1Flag == 1 &&
                    getMyTeamApprovalFlagData?.level2Flag == 0
                    ? 1
                    : ""
              }
            >
              <Box style={{ marginTop: "-7px" }}>
                <MobileViewTeamApproval ssoEmpId={ssoEmpIdDetails?.empId} ndayValue={state} />
              </Box>
            </TabPanel>
          )}
          {getMyTeamApprovalFlagData?.level2Flag !== 0 && (
            <TabPanel
              value={value}
              index={
                getMyTeamApprovalFlagData?.level1Flag == 1 &&
                  getMyTeamApprovalFlagData?.level2Flag !== 0
                  ? 2
                  : getMyTeamApprovalFlagData?.level1Flag == 0 &&
                    getMyTeamApprovalFlagData?.level2Flag == 1
                    ? 1
                    : ""
              }
            >
              <Box style={{ marginTop: "-7px" }}>
                <MobileViewFinanceApproval ssoEmpId={ssoEmpIdDetails?.empId} ndayValue={state} />
              </Box>
            </TabPanel>
          )}
        </Box>
      </Box>
    </>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          <Typography>{children}</Typography>
        </>
      )}
    </div>
  );
}
