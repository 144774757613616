import * as types from "./gmActionType";
const initialState = {
  getBusinessUnitList: null,
  isBusinessUnitList: false,
  getClientList: null,
  isClientList: false,
  getVisaTypeList: null,
  isVisaTypeList: null,
  getBGVTypeList: null,
  isBGVTypeList: false,
  getStateList: null,
  isStateList: false,
  getCityList: null,
  isCityList: false,
  isDataGet: false,
  gmCalCardList: null,
  gmStatusList: null,
  getEmployeeList: null,
  isEmployeeList: false,
  getSkillList: null,
  isSkillList: false,
  getGMIdWiseEmployeeList: null,
  isGMIdWiseEmployeeList: false,
  getWorkLocationList: null,
  isWorkLocationList: false,
  postBUHeadList: null,
  ispostBUHeadUpdateList: false,
  getOtherChargesList: null,
  getViewDetailsList: null,
  getEmployeeWiseData: null,
  getStatusData: null,
  getStepperData: null,
  getStateData: null,
  isOtherChargesList: false,
  postMobilityList: null,
  ispostMobilityList: false,
  postEmployeeWiseSkill: null,
  isPostEmployeeWiseSkill: false,
  postGMStatusL1L2List: null,
  ispostGMStatusL1L2List: false,
  isApprovedDisabled: null,
  getDependentCost: null,
  isGetDependentCost: false,
  getBUDataList: null,
  getRecommendedSalaryRange: null,
  isGetRecommendedSalaryRange: false,
  getVariableData: null,
  isGetVariableData: false,
  getEmpRolesList: null,
  dashboardDeleteRow: null,
  isDeleteMoblity: null,
  isGetGlobalMobilityDelegationFlag: false,
  getGlobalMobilityDelegationFlag: null,
  isGetGlobalMobilityViewDelegateData: false,
  getGlobalMobilityViewDelegateData: null,
  isGetGlobalMobilitySearchDelegateData: false,
  getGlobalMobilitySearchDelegateData: null,
  isGetGlobalMobilitySearchEmployeeData: false,
  getGlobalMobilitySearchEmployeeData: null,
  isPostGlobalMobilityAddDelegate: false,
  postGlobalMobilityAddDelegate: null,
  isPostGlobalMobilityEditDelegate: false,
  postGlobalMobilityEditDelegate: null,
  GMDelEmpId: null,
  isGlobalMobilitytDelegateeData: false,
  getGlobalMobilityDelegateeData: null,

  isGetViewBandwiseSalaryRangeData: false,
  getViewBandwiseSalaryRangeData: null,
  isGetEmployeeSpecificSalaryRange: false,
  getEmployeeSpecificSalaryRange: null,
  isGetGMSalaryValidCombinationList: false,
  getGMSalaryValidCombinationList: null,
  isGetSignedUrl: false,
  getSignedUrlData: null,
  isDeleteUploadedFile: false,
  deleteUploadedFile: null,
};
function GMCalForm(state = initialState, gmAction) {
  switch (gmAction.type) {
    case types.GET_EMPLOYEE_LIST:
      return {
        ...state,
        isEmployeeList: false,
        getEmployeeList: null,
      };
    case types.GET_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        isEmployeeList: true,
        getEmployeeList: gmAction.payload,
      };
    case types.GET_EMPLOYEE_LIST_FAILED:
      return {
        ...state,
        isEmployeeList: false,
        getEmployeeList: gmAction.payload,
      };
    case types.GET_EMPLOYEE_SKILL_EXPERTISE_LIST:
      return {
        ...state,
        isEmployeeSkillExpertiseList: false,
        getEmployeeSkillExpertiseList: null,
      };
    case types.GET_EMPLOYEE_SKILL_EXPERTISE_LIST_SUCCESS:
      return {
        ...state,
        isEmployeeSkillExpertiseList: true,
        getEmployeeSkillExpertiseList: gmAction.payload,
      };
    case types.GET_EMPLOYEE_SKILL_EXPERTISE_LIST_FAILED:
      return {
        ...state,
        isEmployeeSkillExpertiseList: false,
        getEmployeeSkillExpertiseList: gmAction.payload,
      };
    case types.GET_SKILL_LIST:
      return {
        ...state,
        isSkillList: false,
        getSkillList: null,
      };
    case types.GET_SKILL_LIST_SUCCESS:
      return {
        ...state,
        isSkillList: true,
        getSkillList: gmAction.payload,
      };
    case types.GET_SKILL_LIST_FAILED:
      return {
        ...state,
        isSkillList: false,
        getSkillList: gmAction.payload,
      };
    case types.GET_BUSINESS_UNIT_LIST:
      return {
        ...state,
        isBusinessUnitList: false,
        getBusinessUnitList: null,
      };
    case types.GET_BUSINESS_UNIT_LIST_SUCCESS:
      return {
        ...state,
        isBusinessUnitList: true,
        getBusinessUnitList: gmAction.payload,
      };
    case types.GET_BUSINESS_UNIT_LIST_FAILED:
      return {
        ...state,
        isBusinessUnitList: false,
        getBusinessUnitList: gmAction.payload,
      };
    case types.GET_CLIENT_LIST:
      return {
        ...state,
        isClientList: false,
        getClientList: null,
      };
    case types.GET_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        isClientList: true,
        getClientList: gmAction.payload,
      };
    case types.GET_CLIENT_LIST_FAILED:
      return {
        ...state,
        isClientList: false,
        getClientList: gmAction.payload,
      };
    case types.GET_VISA_TYPE_LIST:
      return {
        ...state,
        isVisaTypeList: false,
        getVisaTypeList: null,
      };
    case types.GET_VISA_TYPE_LIST_SUCCESS:
      return {
        ...state,
        isVisaTypeList: true,
        getVisaTypeList: gmAction.payload,
      };
    case types.GET_VISA_TYPE_LIST_FAILED:
      return {
        ...state,
        isVisaTypeList: false,
        getVisaTypeList: gmAction.payload,
      };
    case types.GET_BGV_TYPE_LIST:
      return {
        ...state,
        isBGVTypeList: false,
        getBGVTypeList: null,
      };
    case types.GET_BGV_TYPE_LIST_SUCCESS:
      return {
        ...state,
        isBGVTypeList: true,
        getBGVTypeList: gmAction.payload,
      };
    case types.GET_BGV_TYPE_LIST_FAILED:
      return {
        ...state,
        isBGVTypeList: false,
        getBGVTypeList: gmAction.payload,
      };
    case types.GET_STATE_LIST:
      return {
        ...state,
        isStateList: false,
        getStateList: null,
      };
    case types.GET_STATE_LIST_SUCCESS:
      return {
        ...state,
        isStateList: true,
        getStateList: gmAction.payload,
      };
    case types.GET_STATE_LIST_FAILED:
      return {
        ...state,
        isStateList: false,
        getStateList: gmAction.payload,
      };
    case types.GET_CITY_LIST:
      return {
        ...state,
        isCityList: false,
        getCityList: null,
      };
    case types.GET_CITY_LIST_SUCCESS:
      return {
        ...state,
        isCityList: true,
        getCityList: gmAction.payload,
      };
    case types.GET_CITY_LIST_FAILED:
      return {
        ...state,
        isCityList: false,
        getCityList: gmAction.payload,
      };
    case types.GET_WORK_LOCATION_LIST:
      return {
        ...state,
        isWorkLocationList: false,
        getWorkLocationList: null,
      };
    case types.GET_WORK_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        isWorkLocationList: true,
        getWorkLocationList: gmAction.payload,
      };
    case types.GET_WORK_LOCATION_LIST_FAILED:
      return {
        ...state,
        isWorkLocationList: false,
        getWorkLocationList: gmAction.payload,
      };
    case types.GET_OTHER_CHARGES:
      return {
        ...state,
        isOtherChargesList: false,
        getOtherChargesList: null,
      };
    case types.GET_OTHER_CHARGES_SUCCESS:
      return {
        ...state,
        isOtherChargesList: true,
        getOtherChargesList: gmAction.payload,
      };
    case types.GET_OTHER_CHARGES_FAILED:
      return {
        ...state,
        isOtherChargesList: false,
        getOtherChargesList: gmAction.payload,
      };
    case types.POST_MOBILITY_FORM:
      return {
        ...state,
        ispostMobilityList: false,
        postMobilityList: null,
      };
    case types.POST_MOBILITY_FORM_SUCCESS:
      return {
        ...state,
        ispostMobilityList: true,
        postMobilityList: gmAction.payload,
      };
    case types.POST_MOBILITY_FORM_FAILED:
      return {
        ...state,
        ispostMobilityList: false,
        postMobilityList: gmAction.payload,
      };
    case types.POST_EMPLOYEEWISESKILL_DATA:
      return {
        ...state,
        isPostEmployeeWiseSkill: false,
        postEmployeeWiseSkill: null,
      };
    case types.POST_EMPLOYEEWISESKILL_DATA_SUCCESS:
      return {
        ...state,
        isPostEmployeeWiseSkill: true,
        postEmployeeWiseSkill: gmAction.payload,
      };
    case types.POST_EMPLOYEEWISESKILL_DATA_FAILED:
      return {
        ...state,
        isPostEmployeeWiseSkill: false,
        postEmployeeWiseSkill: gmAction.payload,
      };

    case types.POST_GM_STATUS_L1_L2:
      return {
        ...state,
        ispostGMStatusL1L2List: false,
        postGMStatusL1L2List: null,
      };
    case types.POST_GM_STATUS_L1_L2_SUCCESS:
      return {
        ...state,
        ispostGMStatusL1L2List: true,
        isApprovedDisabled: true,
        postGMStatusL1L2List: gmAction.payload,
      };
    case types.POST_GM_STATUS_L1_L2_FAILED:
      return {
        ...state,
        ispostGMStatusL1L2List: false,
        postGMStatusL1L2List: gmAction.payload,
      };

    case types.GET_VIEW_DETAILS_DATA:
      return {
        ...state,
        isDataGet: false,
        getViewDetailsList: null,
      };
    case types.GET_VIEW_DETAILS_DATA_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getViewDetailsList: gmAction.payload,
      };
    case types.GET_VIEW_DETAILS_DATA_FAILED:
      return {
        ...state,
        isDataGet: false,
        getViewDetailsList: gmAction.payload,
      };
    case types.GET_EMPLOYEEWISE_DATA:
      return {
        ...state,
        isDataGet: false,
        getEmployeeWiseData: null,
      };
    case types.GET_EMPLOYEEWISE_DATA_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getEmployeeWiseData: gmAction.payload,
      };

    case types.GET_EMPLOYEEWISE_DATA_FAILED:
      return {
        ...state,
        isDataGet: true,
        getEmployeeWiseData: gmAction.payload,
      };

    case types.GET_STATUS_DATA:
      return {
        ...state,
        isDataGet: false,
        getStatusData: null,
      };
    case types.GET_STATUS_DATA_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getStatusData: gmAction.payload,
      };
    case types.GET_STATUS_DATA_FAILED:
      return {
        ...state,
        isDataGet: false,
        getStatusData: gmAction.payload,
      };
    case types.GET_STEPPER_DATA:
      return {
        ...state,
        isDataGet: false,
        getStepperData: null,
      };
    case types.GET_STEPPER_DATA_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getStepperData: gmAction.payload,
      };
    case types.GET_STEPPER_DATA_FAILED:
      return {
        ...state,
        isDataGet: false,
        getStepperData: gmAction.payload,
      };
    case types.POST_BUHEAD_FORM:
      return {
        ...state,
        ispostBUHeadUpdateList: false,
        postBUHeadList: null,
      };
    case types.POST_BUHEAD_FORM_SUCCESS:
      return {
        ...state,
        ispostBUHeadUpdateList: true,
        postBUHeadList: gmAction.payload,
      };
    case types.POST_BUHEAD_FORM_FAILED:
      return {
        ...state,
        ispostBUHeadUpdateList: false,
        postBUHeadList: gmAction.payload,
      };
    case types.GET_GMIDWISEEMPOYEE_DETAILS:
      return {
        ...state,
        isGMIdWiseEmployeeList: false,
        getGMIdWiseEmployeeList: null,
      };
    case types.GET_GMIDWISEEMPOYEE_DETAILS_SUCCESS:
      return {
        ...state,
        isGMIdWiseEmployeeList: true,
        getGMIdWiseEmployeeList: gmAction.payload,
      };
    case types.GET_GMIDWISEEMPOYEE_DETAILS_FAILED:
      return {
        ...state,
        isGMIdWiseEmployeeList: false,
        getGMIdWiseEmployeeList: gmAction.payload,
      };
    case types.GET_DEPENDENTS_COST:
      return {
        ...state,
        isGetDependentCost: false,
        getDependentCost: null,
      };
    case types.GET_DEPENDENTS_COST_SUCCESS:
      return {
        ...state,
        isGetDependentCost: true,
        getDependentCost: gmAction.payload,
      };
    case types.GET_DEPENDENTS_COST_FAILED:
      return {
        ...state,
        isGetDependentCost: false,
        getDependentCost: gmAction.payload,
      };
    case types.GET_RECOMMENDED_SALARY_RANGE:
      return {
        ...state,
        isGetRecommendedSalaryRange: false,
        getRecommendedSalaryRange: null,
      };
    case types.GET_RECOMMENDED_SALARY_RANGE_SUCCESS:
      return {
        ...state,
        isGetRecommendedSalaryRange: true,
        getRecommendedSalaryRange: gmAction.payload,
      };
    case types.GET_RECOMMENDED_SALARY_RANGE_FAILED:
      return {
        ...state,
        isGetRecommendedSalaryRange: false,
        getRecommendedSalaryRange: gmAction.payload,
      };
    case types.GET_VARIABLE:
      return {
        ...state,
        isGetVariableData: false,
        getVariableData: null,
      };
    case types.GET_VARIABLE_SUCCESS:
      return {
        ...state,
        isGetVariableData: true,
        getVariableData: gmAction.payload,
      };
    case types.GET_VARIABLE_FAILED:
      return {
        ...state,
        isGetVariableData: false,
        getVariableData: gmAction.payload,
      };
    case types.GET_DASH_DATA_LIST:
      return {
        ...state,
        isDataGet: false,
        gmCalCardList: null,
      };
    case types.GET_DASH_DATA_LIST_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        gmCalCardList: gmAction.payload,
      };
    case types.GET_DASH_DATA_LIST_FAILED:
      return {
        ...state,
        isDataGet: false,
        gmCalCardList: gmAction.payload,
      };
    case types.GET_DASH_STATUS_DATA_LIST:
      return {
        ...state,
        isDataGet: false,
        gmStatusList: null,
      };
    case types.GET_DASH_STATUS_DATA_LIST_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        gmStatusList: gmAction.payload,
      };
    case types.GET_DASH_STATUS_DATA_LIST_FAILED:
      return {
        ...state,
        isDataGet: false,
        gmStatusList: gmAction.payload,
      };
    case types.GET_DASH_BU_DATA_LIST:
      return {
        ...state,
        isDataGet: false,
        getBUDataList: null,
      };
    case types.GET_DASH_BU_DATA_LIST_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getBUDataList: gmAction.payload,
      };
    case types.GET_DASH_BU_DATA_LIST_FAILED:
      return {
        ...state,
        isDataGet: false,
        getBUDataList: gmAction.payload,
      };
    case types.GET_EMP_ROLES_DATA_LIST:
      return {
        ...state,
        isDataGet: false,
        getEmpRolesList: null,
      };
    case types.GET_EMP_ROLES_DATA_LIST_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getEmpRolesList: gmAction.payload,
      };
    case types.GET_EMP_ROLES_DATA_LIST_FAILED:
      return {
        ...state,
        isDataGet: false,
        getEmpRolesList: gmAction.payload,
      };
    case types.GET_DELETE_ROW:
      return {
        ...state,
        isDeleteMoblity: false,
        dashboardDeleteRow: null,
      };
    case types.GET_DELETE_ROW_SUCCESS:
      return {
        ...state,
        isDeleteMoblity: true,
        dashboardDeleteRow: gmAction.payload,
      };
    case types.GET_DELETE_ROW_FAILED:
      return {
        ...state,
        isDeleteMoblity: false,
        dashboardDeleteRow: gmAction.payload,
      };
    case types.RESET_GM_RESPONSE:
      return {
        ...state,
        isDeleteMoblity: false,
      };
    case types.GET_GLOBAL_MOBILITY_DELEGATION_ACCESS:
      return {
        ...state,
        isGetGlobalMobilityDelegationFlag: false,
        getGlobalMobilityDelegationFlag: null,
      };
    case types.GET_GLOBAL_MOBILITY_DELEGATION_ACCESS_SUCCESS:
      return {
        ...state,
        isGetGlobalMobilityDelegationFlag: true,
        getGlobalMobilityDelegationFlag: gmAction.payload,
      };
    case types.GET_GLOBAL_MOBILITY_DELEGATION_ACCESS_FAILED:
      return {
        ...state,
        isGetGlobalMobilityDelegationFlag: false,
        getGlobalMobilityDelegationFlag: gmAction.payload,
      };
    case types.GET_GLOBAL_MOBILITY_SEARCH_EMPLOYEE:
      return {
        ...state,
        isGlobalMobilitySearchEmployee: false,
        getGlobalMobilitySearchEmployeeData: null,
      };
    case types.GET_GLOBAL_MOBILITY_SEARCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isGlobalMobilitySearchEmployee: true,
        getGlobalMobilitySearchEmployeeData: gmAction.payload,
      };
    case types.GET_GLOBAL_MOBILITY_SEARCH_EMPLOYEE_FAILED:
      return {
        ...state,
        isGlobalMobilitySearchEmployee: false,
        getGlobalMobilitySearchEmployeeData: gmAction.payload,
      };
    case types.GET_GLOBAL_MOBILITY_SEARCH_DELEGATE:
      return {
        ...state,
        isGlobalMobilitySearchDelegate: false,
        getGlobalMobilitySearchDelegateData: null,
      };
    case types.GET_GLOBAL_MOBILITY_SEARCH_DELEGATE_SUCCESS:
      return {
        ...state,
        isGlobalMobilitySearchDelegate: true,
        getGlobalMobilitySearchDelegateData: gmAction.payload,
      };
    case types.GET_GLOBAL_MOBILITY_SEARCH_DELEGATE_FAILED:
      return {
        ...state,
        isGlobalMobilitySearchDelegate: false,
        getGlobalMobilitySearchDelegateData: gmAction.payload,
      };
    case types.GET_GLOBAL_MOBILITY_VIEW_DELEGATE_DETAILS:
      return {
        ...state,
        isGlobalMobilityViewDelegateData: false,
        getGlobalMobilityViewDelegateData: null,
      };
    case types.GET_GLOBAL_MOBILITY_VIEW_DELEGATE_DETAILS_SUCCESS:
      return {
        ...state,
        isGlobalMobilityViewDelegateData: true,
        getGlobalMobilityViewDelegateData: gmAction.payload,
      };
    case types.GET_GLOBAL_MOBILITY_VIEW_DELEGATE_DETAILS_FAILED:
      return {
        ...state,
        isGlobalMobilityViewDelegateData: false,
        getGlobalMobilityViewDelegateData: gmAction.payload,
      };
    case types.POST_GLOBAL_MOBILITY_ADD_DELEGATE:
      return {
        ...state,
        isPostGlobalMobilityAddDelegate: false,
        postGlobalMobilityAddDelegate: null,
      };
    case types.POST_GLOBAL_MOBILITY_ADD_DELEGATE_SUCCESS:
      return {
        ...state,
        isPostGlobalMobilityAddDelegate: true,
        postGlobalMobilityAddDelegate: gmAction.payload,
      };
    case types.POST_GLOBAL_MOBILITY_ADD_DELEGATE_FAILED:
      return {
        ...state,
        isPostGlobalMobilityAddDelegate: false,
        postGlobalMobilityAddDelegate: gmAction.payload,
      };
    case types.POST_GLOBAL_MOBILITY_EDIT_DELEGATE:
      return {
        ...state,
        isPostGlobalMobilityEditDelegate: false,
        postGlobalMobilityEditDelegate: null,
      };
    case types.POST_GLOBAL_MOBILITY_EDIT_DELEGATE_SUCCESS:
      return {
        ...state,
        isPostGlobalMobilityEditDelegate: true,
        postGlobalMobilityEditDelegate: gmAction.payload,
      };
    case types.POST_GLOBAL_MOBILITY_EDIT_DELEGATE_FAILED:
      return {
        ...state,
        isPostGlobalMobilityEditDelegate: false,
        postGlobalMobilityEditDelegate: gmAction.payload,
      };
    case types.GET_GLOBAL_MOBILITY_EMP_DETAILS_AS_PER_DELEGATEE:
      return {
        ...state,
        isGlobalMobilitytDelegateeData: false,
        getGlobalMobilityDelegateeData: null,
      };
    case types.GET_GLOBAL_MOBILITY_EMP_DETAILS_AS_PER_DELEGATEE_SUCCESS:
      return {
        ...state,
        isGlobalMobilitytDelegateeData: true,
        getGlobalMobilityDelegateeData: gmAction.payload,
      };
    case types.GET_GLOBAL_MOBILITY_EMP_DETAILS_AS_PER_DELEGATEE_FAILED:
      return {
        ...state,
        isGlobalMobilitytDelegateeData: false,
        getGlobalMobilityDelegateeData: gmAction.payload,
      };
    case types.RESET_GLOBAL_MOBILITY_DELIGATION:
      return {
        ...state,
        isPostGlobalMobilityAddDelegate: false,
        isPostGlobalMobilityEditDelegate: false,
      };
    case types.UPDATE_GLOBAL_MOBILITY_DELEGATEE_EMP_ID:
      return {
        ...state,
        GMDelEmpId: gmAction.payload,
      };

    case types.GET_VIEW_BANDWISE_SALARY_RANGE:
      return {
        ...state,
        isGetViewBandwiseSalaryRangeData: false,
        getViewBandwiseSalaryRangeData: null,
      };
    case types.GET_VIEW_BANDWISE_SALARY_RANGE_SUCCESS:
      return {
        ...state,
        isGetViewBandwiseSalaryRangeData: true,
        getViewBandwiseSalaryRangeData: gmAction.payload,
      };
    case types.GET_VIEW_BANDWISE_SALARY_RANGE_FAILED:
      return {
        ...state,
        isGetViewBandwiseSalaryRangeData: false,
        getViewBandwiseSalaryRangeData: gmAction.payload,
      };
    case types.GET_EMPLOYEE_SPECIFIC_SALARY_RANGE:
      return {
        ...state,

        getEmployeeSpecificSalaryRange: null,
        isGetEmployeeSpecificSalaryRange: false,
      };
    case types.GET_EMPLOYEE_SPECIFIC_SALARY_RANGE_SUCCESS:
      return {
        ...state,
        getEmployeeSpecificSalaryRange: gmAction.payload,
        isGetEmployeeSpecificSalaryRange: true,
      };
    case types.GET_EMPLOYEE_SPECIFIC_SALARY_RANGE_FAILED:
      return {
        ...state,
        getEmployeeSpecificSalaryRange: gmAction.payload,
        isGetEmployeeSpecificSalaryRange: false,
      };

    case types.GET_GM_SALARY_VALID_COMBINATION:
      return {
        ...state,
        isGetGMSalaryValidCombinationList: false,
        getGMSalaryValidCombinationList: null,
      };
    case types.GET_GM_SALARY_VALID_COMBINATION_SUCCESS:
      return {
        ...state,
        getGMSalaryValidCombinationList: gmAction.payload,
        isGetGMSalaryValidCombinationList: true,
      };
    case types.GET_GM_SALARY_VALID_COMBINATION_FAILED:
      return {
        ...state,
        getGMSalaryValidCombinationList: gmAction.payload,
        isGetGMSalaryValidCombinationList: false,
      };
    case types.GET_GM_SIGNED_URL:
      return {
        ...state,
        isGetSignedUrL: false,
        getSignedUrlData: null,
      };
    case types.GET_GM_SIGNED_URL_SUCCESS:
      return {
        ...state,
        isGetSignedUrl: true,
        getSignedUrlData: gmAction.payload,
      };
    case types.GET_GM_SIGNED_URL_FAILED:
      return {
        ...state,
        isGetSignedUrl: false,
        getSignedUrlData: gmAction.payload,
      };
    case types.DELETE_GM_FILE_UPLOAD:
      return {
        ...state,
        isDeleteUploadedFile: false,
        deleteUploadedFile: null,
      };
    case types.DELETE_GM_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        isDeleteUploadedFile: false,
        deleteUploadedFile: null,
      };
    case types.DELETE_GM_FILE_UPLOAD_FAILED:
      return {
        ...state,
        isDeleteUploadedFile: false,
        deleteUploadedFile: null,
      };
    default:
      return {
        ...state,
      };
  }
}

export default GMCalForm;
