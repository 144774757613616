import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import cardImage from "../images/Expense.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { fontSize } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles({
  root: {
    width: window.screen.availWidth == 1098 ? 230 : (window.screen.availWidth == 1920 ? 400 : 275),
    height: window.screen.availWidth == 1098 ? 270 : (window.screen.availWidth == 1920 ? 475 : 325),
  },
  rootMobile: {
    minWidth: 186,
    // height: 50,
    marginBottom: "-2px",
  },
  componentBox1: {
    width: window.screen.availWidth == 1098 ? 230 : (window.screen.availWidth == 1920 ? 400 : 275),
    height: window.screen.availWidth == 1098 ? 230 : (window.screen.availWidth == 1920 ? 400 : 275),
  },
  componentBox1Mobile: {
    minWidth: "100px",
    // minHeight: 242.69,
  },
  componentBox2: {
    width: window.screen.availWidth == 1098 ? 230 :(window.screen.availWidth == 1920 ? 400 : 275),
    height: window.screen.availWidth == 1098 ? 30 : (window.screen.availWidth == 1920 ? 60 : 35),
    backgroundColor: "#F0F5FD",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  componentBox3: {
    width: window.screen.availWidth == 1098 ? 230 : (window.screen.availWidth == 1920 ? 400 : 275),
    //minHeight: 45,
    backgroundColor: "#F0F5FD",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  componentBox2Mobile: {
    minWidth: 100,
    // minHeight: 43,
    backgroundColor: "#F0F5FD",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  componentNameText: {
    ["@media (max-width:600px)"]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#0B1941",
      fontSize: "14px",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#0B1941",
  },
  cardmedia: {
    ["@media (max-width:600px)"]: { height: "36vw" },
    height: window.screen.availWidth == 1280 ? "20vw" : ( window.screen.availWidth == 1536 ? "18vw" : (window.screen.availWidth == 1098 || window.screen.availWidth == 1920 ? "21vw" : "14vw")),
    objectFit: "fill",
  },
  cardmedia1: {
    height: "25vw",
    objectFit: "none",
  },
});

export default function HomePageCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const routeName = useLocation().pathname;
  const matches = useMediaQuery("(max-width:600px)");

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );  
  

  const handleClick = (value) => {
    if (value === "Expenses") {
      history.push("/expense-Management");
    } 
    // else if (value === "Global Mobility Nomination" && ssoEmpIdDetails?.gmNomineeFlag == 1) {
    //   history.push("/empMobilityView");
    // }
    else if (value === "Global Mobility Calculator") {
      history.push("/gmdashboard");
    } else if (value === "Timesheet") {
      history.push("/timesheet-management");
      //history.push("/");
    }
  };
  return (
    <Card
      className={matches ? classes.rootMobile : classes.root}
      elevation={3}
      onClick={() => handleClick(props.componentName.name)}
    >
      <CardActionArea className={matches ? classes.rootMobile : classes.root}>
        <Box
          className={
            matches ? classes.componentBox1Mobile : classes.componentBox1
          }
        >
          <Box
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          >
            <CardMedia
              component="img"
              alt="Contemplative Reptile"
              height={"140"}
              image={props.componentName.image}
              className={
                window.screen.availWidth == 1792
                  ? classes.cardmedia1
                  : classes.cardmedia
              }
              title={props.componentName.name}
            />
          </Box>
        </Box>
        <Box
          className={
            matches ? classes.componentBox2Mobile : classes.componentBox2
          }
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{ 
              fontWeight: matches ? "575" : "600", 
              fontSize: !matches && window.screen.availWidth == 1098 ? "1rem" : (!matches && window.screen.availWidth == 1920 ? "1.5rem" : !matches ? "1.1rem" : ""), 
              marginBottom: "-0.5em" }}
            className={classes.componentNameText}
          >
            {props.componentName.name}
          </Typography>
          {props.componentName.name == "Timesheet " ? (
            <Typography
              gutterBottom
              style={{
                color: "red",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></Typography>
          ) : (
            ""
          )}
        </Box>
        <Box
          className={
            matches ? classes.componentBox2Mobile : classes.componentBox3
          }
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{ fontWeight: !matches ? "600" : "575" }}
            className={
              props.componentName.name == "GMCal " && classes.componentNameText
            }
          >
            {props.componentName.name == "GMCal " && props.componentName.name}
          </Typography>
          {props.componentName.name == "GMCal " ? (
            <Typography
              gutterBottom
              style={{
                color: "red",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></Typography>
          ) : (
            ""
          )}
        </Box>
      </CardActionArea>
    </Card>
  );
}