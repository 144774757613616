// Employee ActionType
export const GET_EMPLOYEE_LIST = "GET_EMPLOYEE_LIST";
export const GET_EMPLOYEE_LIST_SUCCESS = "GET_EMPLOYEE_LIST_SUCCESS";
export const GET_EMPLOYEE_LIST_FAILED = "GET_EMPLOYEE_LIST_FAILED";

// Employee Skill Expertise ActionType
export const GET_EMPLOYEE_SKILL_EXPERTISE_LIST =
  "GET_EMPLOYEE_SKILL_EXPERTISE_LIST";
export const GET_EMPLOYEE_SKILL_EXPERTISE_LIST_SUCCESS =
  "GET_EMPLOYEE_SKILL_EXPERTISE_LIST_SUCCESS";
export const GET_EMPLOYEE_SKILL_EXPERTISE_LIST_FAILED =
  "GET_EMPLOYEE_SKILL_EXPERTISE_LIST_SUCCESS";

// Skill ActionType
export const GET_SKILL_LIST = "GET_SKILL_LIST";
export const GET_SKILL_LIST_SUCCESS = "GET_SKILL_LIST_SUCCESS";
export const GET_SKILL_LIST_FAILED = "GET_SKILL_LIST_FAILED";

// Skill Range ActionType
export const GET_SKILL_RANGE_LIST = "GET_SKILL_RANGE_LIST";
export const GET_SKILL_RANGE_LIST_SUCCESS = "GET_SKILL_RANGE_LIST_SUCCESS";
export const GET_SKILL_RANGE_LIST_FAILED = "GET_SKILL_RANGE_LIST_FAILED";

// Business Unit ActionType
export const GET_BUSINESS_UNIT_LIST = "GET_BUSINESS_UNIT_LIST";
export const GET_BUSINESS_UNIT_LIST_SUCCESS = "GET_BUSINESS_UNIT_LIST_SUCCESS";
export const GET_BUSINESS_UNIT_LIST_FAILED = "GET_BUSINESS_UNIT_LIST_FAILED";

// Client ActionType
export const GET_CLIENT_LIST = "GET_CLIENT_LIST";
export const GET_CLIENT_LIST_SUCCESS = "GET_CLIENT_LIST_SUCCESS";
export const GET_CLIENT_LIST_FAILED = "GET_CLIENTT_LIST_FAILED";

// Visa Type ActionType
export const GET_VISA_TYPE_LIST = "GET_VISA_TYPE_LIST";
export const GET_VISA_TYPE_LIST_SUCCESS = "GET_VISA_TYPE_LIST_SUCCESS";
export const GET_VISA_TYPE_LIST_FAILED = "GET_VISA_TYPE_LIST_FAILED";

// BGV Type ActionType
export const GET_BGV_TYPE_LIST = "GET_BGV_TYPE_LIST";
export const GET_BGV_TYPE_LIST_SUCCESS = "GET_BGV_TYPE_LIST_SUCCESS";
export const GET_BGV_TYPE_LIST_FAILED = "GET_BGV_TYPE_LIST_FAILED";

// State ActionType
export const GET_STATE_LIST = "GET_STATE_LIST";
export const GET_STATE_LIST_SUCCESS = "GET_STATE_LIST_SUCCESS";
export const GET_STATE_LIST_FAILED = "GET_STATE_LIST_FAILED";

// State ActionType
export const GET_CITY_LIST = "GET_CITY_LIST";
export const GET_CITY_LIST_SUCCESS = "GET_CITY_LIST_SUCCESS";
export const GET_CITY_LIST_FAILED = "GET_CITY_LIST_FAILED";

// Work Location ActionType
export const GET_WORK_LOCATION_LIST = "GET_WORK_LOCATION_LIST";
export const GET_WORK_LOCATION_LIST_SUCCESS = "GET_WORK_LOCATION_LIST_SUCCESS";
export const GET_WORK_LOCATION_LIST_FAILED = "GET_WORK_LOCATION_LIST_FAILED";

// Other Charges ActionType
export const GET_OTHER_CHARGES = "GET_OTHER_CHARGES";
export const GET_OTHER_CHARGES_SUCCESS = "GET_OTHER_CHARGES_SUCCESS";
export const GET_OTHER_CHARGES_FAILED = "GET_OTHER_CHARGES_FAILED";

// Mobility Form ActionType
export const POST_MOBILITY_FORM = "POST_MOBILITY_FORM";
export const POST_MOBILITY_FORM_SUCCESS = "POST_MOBILITY_FORM_SUCCESS";
export const POST_MOBILITY_FORM_FAILED = "POST_MOBILITY_FORM_FAILED";

//GM STATUS L1L2
export const POST_GM_STATUS_L1_L2 = "POST_GM_STATUS_L1_L2";
export const POST_GM_STATUS_L1_L2_SUCCESS = "POST_GM_STATUS_L1_L2_SUCCESS";
export const POST_GM_STATUS_L1_L2_FAILED = "POST_GM_STATUS_L1_L2_FAILED";
// Get Visa Details ActionType
export const GET_VIEW_DETAILS_DATA = "GET_VIEW_DETAILS_DATA";
export const GET_VIEW_DETAILS_DATA_SUCCESS = "GET_VIEW_DETAILS_DATA_SUCCESS";
export const GET_VIEW_DETAILS_DATA_FAILED = "GET_VIEW_DETAILS_DATA_FAILED";

// Get Status ActionType
export const GET_STATUS_DATA = "GET_STATUS_DATA";
export const GET_STATUS_DATA_SUCCESS = "GET_STATUS_DATA_SUCCESS";
export const GET_STATUS_DATA_FAILED = "GET_STATUS_DATA_FAILED";

// Get Stepper ActionType
export const GET_STEPPER_DATA = "GET_STEPPER_DATA";
export const GET_STEPPER_DATA_SUCCESS = "GET_STEPPER_DATA_SUCCESS";
export const GET_STEPPER_DATA_FAILED = "GET_STEPPER_DATA_FAILED";

// Post BUHead ActionType
export const POST_BUHEAD_FORM = "POST_BUHEAD_FORM";
export const POST_BUHEAD_FORM_SUCCESS = "POST_BUHEAD_FORM_SUCCESS";
export const POST_BUHEAD_FORM_FAILED = "POST_BUHEAD_FORM_FAILED";

// Post gmIdWiseEmployeeDetails ActionType
export const GET_GMIDWISEEMPOYEE_DETAILS = "GET_GMIDWISEEMPOYEE_DETAILS";
export const GET_GMIDWISEEMPOYEE_DETAILS_SUCCESS =
  "GET_GMIDWISEEMPOYEE_DETAILS_SUCCESS";
export const GET_GMIDWISEEMPOYEE_DETAILS_FAILED =
  "GET_GMIDWISEEMPOYEE_DETAILS_FAILED";

// Get Dependents Cost ActionType
export const GET_DEPENDENTS_COST = "GET_DEPENDENTS_COST";
export const GET_DEPENDENTS_COST_SUCCESS = "GET_DEPENDENTS_COST_SUCCESS";
export const GET_DEPENDENTS_COST_FAILED = "GET_DEPENDENTS_COST_FAILED";

// Get GetRecommandedSalaryRange Cost ActionType
export const GET_RECOMMENDED_SALARY_RANGE = "GET_RECOMMENDED_SALARY_RANGE";
export const GET_RECOMMENDED_SALARY_RANGE_SUCCESS =
  "GET_RECOMMENDED_SALARY_RANGE_SUCCESS";
export const GET_RECOMMENDED_SALARY_RANGE_FAILED =
  "GET_RECOMMENDED_SALARY_RANGE_FAILED";

// Get GetRecommandedSalaryRange Cost ActionType
export const GET_VARIABLE = "GET_VARIABLE";
export const GET_VARIABLE_SUCCESS = "GET_VARIABLE_SUCCESS";
export const GET_VARIABLE_FAILED = "GET_VARIABLE_FAILED";

// Dashboard ActionType
export const GET_DASH_DATA_LIST = "GET_DASH_DATA_LIST";
export const GET_DASH_DATA_LIST_SUCCESS = "GET_DASH_DATA_LIST_SUCCESS";
export const GET_DASH_DATA_LIST_FAILED = "GET_DASH_DATA_LIST_FAILED";

// Dashboard Status ActionType
export const GET_DASH_STATUS_DATA_LIST = "GET_DASH_STATUS_DATA_LIST";
export const GET_DASH_STATUS_DATA_LIST_SUCCESS =
  "GET_DASH_STATUS_DATA_LIST_SUCCESS";
export const GET_DASH_STATUS_DATA_LIST_FAILED =
  "GET_DASH_STATUS_DATA_LIST_FAILED";

//EMPLOYEE ROLE WISE
export const GET_EMPLOYEEWISE_DATA = "GET_EMPLOYEEWISE_DATA";
export const GET_EMPLOYEEWISE_DATA_SUCCESS = "GET_EMPLOYEEWISE_DATA_SUCCESS";
export const GET_EMPLOYEEWISE_DATA_FAILED = "GET_EMPLOYEEWISE_DATA_FAILED";

//Employee Wise Skill ActionType
export const POST_EMPLOYEEWISESKILL_DATA = "POST_EMPLOYEEWISESKILL_DATA";
export const POST_EMPLOYEEWISESKILL_DATA_SUCCESS =
  "POST_EMPLOYEEWISESKILL_DATA_SUCCESS";
export const POST_EMPLOYEEWISESKILL_DATA_FAILED =
  "POST_EMPLOYEEWISESKILL_DATA_FAILED";

// Dashboard BU ActionType
export const GET_DASH_BU_DATA_LIST = "GET_DASH_BU_DATA_LIST";
export const GET_DASH_BU_DATA_LIST_SUCCESS = "GET_DASH_BU_DATA_LIST_SUCCESS";
export const GET_DASH_BU_DATA_LIST_FAILED = "GET_DASH_BU_DATA_LIST_FAILED";

// Employee Roles ActionType
export const GET_EMP_ROLES_DATA_LIST = "GET_EMP_ROLES_DATA_LIST";
export const GET_EMP_ROLES_DATA_LIST_SUCCESS =
  "GET_EMP_ROLES_DATA_LIST_SUCCESS";
export const GET_EMP_ROLES_DATA_LIST_FAILED = "GET_EMP_ROLES_DATA_LIST_FAILED";

// Delete Row
export const GET_DELETE_ROW = "GET_DELETE_ROW";
export const GET_DELETE_ROW_SUCCESS = "GET_DELETE_ROW_SUCCESS";
export const GET_DELETE_ROW_FAILED = "GET_DELETE_ROW_FAILED";

// Rest GM Response
export const RESET_GM_RESPONSE = "RESET_GM_RESPONSE";

//RESET DeligationType
export const RESET_GLOBAL_MOBILITY_DELIGATION =
  "RESET_GLOBAL_MOBILITY_DELIGATION";

//GLOBAL_MOBILITY SSO EMPlOYEE ID
export const UPDATED_GLOBAL_MOBILITY_SSO_EMP_ID =
  "UPDATED_GLOBAL_MOBILITY_SSO_EMP_ID";

//UPDATE GLOBAL_MOBILITY DELEGATEE EMPLOYEE ID
export const UPDATE_GLOBAL_MOBILITY_DELEGATEE_EMP_ID =
  "UPDATE_GLOBAL_MOBILITY_DELEGATEE_EMP_ID";

//GET GlobalMobility Access Flag action type:
export const GET_GLOBAL_MOBILITY_ACCESS_FLAG =
  "GET_GLOBAL_MOBILITY_ACCESS_FLAG";
export const GET_GLOBAL_MOBILITY_ACCESS_FLAG_SUCCESS =
  "GET_GLOBAL_MOBILITY_ACCESS_FLAG_SUCCESS";
export const GET_GLOBAL_MOBILITY_ACCESS_FLAG_FAILED =
  "GET_GLOBAL_MOBILITY_ACCESS_FLAG_FAILED";

//GET GlobalMobilityGetDelAccess action type:
export const GET_GLOBAL_MOBILITY_DELEGATION_ACCESS =
  "GET_GLOBAL_MOBILITY_DELEGATION_ACCESS";
export const GET_GLOBAL_MOBILITY_DELEGATION_ACCESS_SUCCESS =
  "GET_GLOBAL_MOBILITY_DELEGATION_ACCESS_SUCCESS";
export const GET_GLOBAL_MOBILITY_DELEGATION_ACCESS_FAILED =
  "GET_GLOBAL_MOBILITY_DELEGATION_ACCESS_FAILED";

//GET GlobalMobilitySearchEmpAndDel action type:
export const GET_GLOBAL_MOBILITY_SEARCH_EMP_AND_DEL =
  "GET_GLOBAL_MOBILITY_SEARCH_EMP_AND_DEL";
export const GET_GLOBAL_MOBILITY_SEARCH_EMP_AND_DEL_SUCCESS =
  "GET_GLOBAL_MOBILITY_SEARCH_EMP_AND_DEL_SUCCESS";
export const GET_GLOBAL_MOBILITY_SEARCH_EMP_AND_DEL_FAILED =
  "GET_GLOBAL_MOBILITY_SEARCH_EMP_AND_DEL_FAILED";

//GET GlobalMobilityGetEmpDetailsAsPerDelegatee action type:
export const GET_GLOBAL_MOBILITY_EMP_DETAILS_AS_PER_DELEGATEE =
  "GET_GLOBAL_MOBILITY_EMP_DETAILS_AS_PER_DELEGATEE";
export const GET_GLOBAL_MOBILITY_EMP_DETAILS_AS_PER_DELEGATEE_SUCCESS =
  "GET_GLOBAL_MOBILITY_EMP_DETAILS_AS_PER_DELEGATEE_SUCCESS";
export const GET_GLOBAL_MOBILITY_EMP_DETAILS_AS_PER_DELEGATEE_FAILED =
  "GET_GLOBAL_MOBILITY_EMP_DETAILS_AS_PER_DELEGATEE_FAILED";

//POST GlobalMobilityAddDelegate action type: GLOBAL_MOBILITY_ADD_DELEGATE
export const POST_GLOBAL_MOBILITY_ADD_DELEGATE =
  "POST_GLOBAL_MOBILITY_ADD_DELEGATE";
export const POST_GLOBAL_MOBILITY_ADD_DELEGATE_SUCCESS =
  "POST_GLOBAL_MOBILITY_ADD_DELEGATE_SUCCESS";
export const POST_GLOBAL_MOBILITY_ADD_DELEGATE_FAILED =
  "POST_GLOBAL_MOBILITY_ADD_DELEGATE_FAILED";

//POST GlobalMobilityEditDelegate action type: GLOBAL_MOBILITY_EDIT_DELEGATE
export const POST_GLOBAL_MOBILITY_EDIT_DELEGATE =
  "POST_GLOBAL_MOBILITY_EDIT_DELEGATE";
export const POST_GLOBAL_MOBILITY_EDIT_DELEGATE_SUCCESS =
  "POST_GLOBAL_MOBILITY_EDIT_DELEGATE_SUCCESS";
export const POST_GLOBAL_MOBILITY_EDIT_DELEGATE_FAILED =
  "POST_GLOBAL_MOBILITY_EDIT_DELEGATE_FAILED";

//GET GlobalMobilitySearchEmployee action type:
export const GET_GLOBAL_MOBILITY_SEARCH_EMPLOYEE =
  "GET_GLOBAL_MOBILITY_SEARCH_EMPLOYEE";
export const GET_GLOBAL_MOBILITY_SEARCH_EMPLOYEE_SUCCESS =
  "GET_GLOBAL_MOBILITY_SEARCH_EMPLOYEE_SUCCESS";
export const GET_GLOBAL_MOBILITY_SEARCH_EMPLOYEE_FAILED =
  "GET_GLOBAL_MOBILITY_SEARCH_EMPLOYEE_FAILED";

//GET GlobalMobilitySearchDelegate action type:
export const GET_GLOBAL_MOBILITY_SEARCH_DELEGATE =
  "GET_GLOBAL_MOBILITY_SEARCH_DELEGATE";
export const GET_GLOBAL_MOBILITY_SEARCH_DELEGATE_SUCCESS =
  "GET_GLOBAL_MOBILITY_SEARCH_DELEGATE_SUCCESS";
export const GET_GLOBAL_MOBILITY_SEARCH_DELEGATE_FAILED =
  "GET_GLOBAL_MOBILITY_SEARCH_DELEGATE_FAILED";

//GET ViewDelegateDetails action type:
export const GET_GLOBAL_MOBILITY_VIEW_DELEGATE_DETAILS =
  "GET_GLOBAL_MOBILITY_VIEW_DELEGATE_DETAILS";
export const GET_GLOBAL_MOBILITY_VIEW_DELEGATE_DETAILS_SUCCESS =
  "GET_GLOBAL_MOBILITY_VIEW_DELEGATE_DETAILS_SUCCESS";
export const GET_GLOBAL_MOBILITY_VIEW_DELEGATE_DETAILS_FAILED =
  "GET_GLOBAL_MOBILITY_VIEW_DELEGATE_DETAILS_FAILED";




//GET View Bandwise Salary Range action type
export const GET_VIEW_BANDWISE_SALARY_RANGE = "GET_VIEW_BANDWISE_SALARY_RANGE";
export const GET_VIEW_BANDWISE_SALARY_RANGE_SUCCESS =
  "GET_VIEW_BANDWISE_SALARY_RANGE_SUCCESS";
export const GET_VIEW_BANDWISE_SALARY_RANGE_FAILED =
  "GET_VIEW_BANDWISE_SALARY_RANGE_FAILED";

export const GET_EMPLOYEE_SPECIFIC_SALARY_RANGE =
  "GET_EMPLOYEE_SPECIFIC_SALARY_RANGE";
export const GET_EMPLOYEE_SPECIFIC_SALARY_RANGE_SUCCESS =
  "GET_EMPLOYEE_SPECIFIC_SALARY_RANGE_SUCCESS";
export const GET_EMPLOYEE_SPECIFIC_SALARY_RANGE_FAILED =
  "GET_EMPLOYEE_SPECIFIC_SALARY_RANGE_FAILED";

export const GET_GM_SALARY_VALID_COMBINATION =
  "GET_GM_SALARY_VALID_COMBINATION";
export const GET_GM_SALARY_VALID_COMBINATION_SUCCESS =
  "GET_GM_SALARY_VALID_COMBINATION_SUCCESS";
export const GET_GM_SALARY_VALID_COMBINATION_FAILED =
  "GET_GM_SALARY_VALID_COMBINATION_FAILED";

//GET GM SIGNED URL
export const GET_GM_SIGNED_URL = "GET_GM_SIGNED_URL";
export const GET_GM_SIGNED_URL_SUCCESS = "GET_GM_SIGNED_URL_SUCCESS";
export const GET_GM_SIGNED_URL_FAILED = "GET_GM_SIGNED_URL_FAILED";

//Delete Uploaded File
export const DELETE_GM_FILE_UPLOAD = "DELETE_GM_FILE_UPLOAD";
export const DELETE_GM_FILE_UPLOAD_SUCCESS = "DELETE_GM_FILE_UPLOAD_SUCCESS";
export const DELETE_GM_FILE_UPLOAD_FAILED = "DELETE_GM_FILE_UPLOAD_FAILED";
