import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GMNavBar from "./gmNavBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Slider,
  Slide,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Chip,
  Box,
  FormHelperText,
  Snackbar,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import ClearIcon from "@mui/icons-material/Clear";
import Alert from "@material-ui/lab/Alert";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Decrypt from "../Utils/decryption";
import Encrypt from "../Utils/encryption";
import DOMPurify from "dompurify";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import "../css/gmBUPage.css";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DialogActions from "@material-ui/core/DialogActions";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { isValid, parse } from "date-fns";
import Header from "../components/header";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import {
  getBusinessUnitData,
  getClientData,
  getEmployeeData,
  getSkillData,
  getVisaTypeData,
  postMobilityFormData,
  postEmployeeWiseSkillData,
  getGMIdWiseEmployeeData,
  getGetRecommendedSalaryRangeData,
  getEmployeeRolesData,
  getEmployeeSkillExpertiseData,
  getEmployeeSkillExpertiseList,
} from "../redux/GMCal/gmAction";
import {
  getRatingDescriptionData,
  postRatingReviewData,
} from "../redux/TimeSheet/actionTs";
import { getEmpDetail } from "../redux/Expense/action.js";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import Rating from "@mui/material/Rating";

var key = process.env.REACT_APP_ENCRYPTION_KEY;

const useStyles = makeStyles((theme) => ({
  // hideInputArrows:{
  //   '& input[type=number]::-webkit-outer-spin-button':{
  //     // '-webkit-appearance'
  //     display :'none',
  //     margin:0
  //   }
  //   // input::-webkit-outer-spin-button,
  //   // input::-webkit-inner-spin-button {
  //   //   -webkit-appearance: none;
  //   //   margin:0;
  //   // }
  // },
  dialogBody: {
    minHeight: "400px",
  },
  dialog: {
    backgroundColor: "#54B435",
    minHeight: "25px",
    padding: "12px",
    position: "fixed",
    top: "16px",
    right: "16px",
    borderRadius: "2px",
  },
  dialogAlert: {
    backgroundColor: "red",
    minHeight: "25px",
    padding: "12px",
    position: "fixed",
    top: "16px",
    right: "16px",
    borderRadius: "2px",
  },
  contentText: {
    color: "white",
    fontWeight: 200,
    lineHeight: "25px",
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: 40,
    minWidth: "100%",
  },
  thankYouText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    minWidth: "50px",
  },
  messageText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    minWidth: "50px",
  },
  stepper: {
    marginBottom: theme.spacing(2),
    minWidth: "50px",
  },
  disabledInput: {
    backgroundColor:
      "#EEEEEE" /* Set the desired background color for disabled input */,
  },
  errorText: {
    color: "#CB444A",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: 0,

    paddingRight: "99px",
  },
  errorRoot: {
    color: "#CB444A",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: 0,
  },
  expenseHeading: {
    display: "inline-flex",
    margin: "30px 0px 30px 0px",
    marginLeft: "25px",
    height: "24px",
    maxWidth: "2000px",
    overflowWrap: "anywhere",
  },
  formControl: {
    margin: "16px 0 8px 0",
    minWidth: "100%",
    borderRadius: 4,
    notchedOutline: {
      borderColor: "red",
      borderRadius: 4,
    },
    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
      },
    },
  },

  confirmationText: {
    // textAlign: 'left',
    textAlign: "start",
    marginTop: theme.spacing(2),
    // fontWeight: "bold",
    minWidth: "300px",
    minHeight: "50px",
  },

  noButton: {
    backgroundColor: "#fff",
    width: "200px",
    color: "#000",
    border: "1px solid #07073e",
  },

  yesButton: {
    backgroundColor: "#07073e",
    width: "200px",
    border: "1px solid #07073e",
  },

  formControl12: {
    margin: "16px 0 8px 0",
    minWidth: "100%",
    borderRadius: 4,
    notchedOutline: {
      borderColor: "red",
      borderRadius: 4,
    },
    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
      },
    },
  },
}));

const performanceRatingOptions = [
  { value: "ME", label: "ME" },
  { value: "EE", label: "EE" },
  { value: "OP", label: "OP" },
];

var formatter = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

function GMMobility() {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const [mobilityEmpId, setMobilityEmpId] = useState(""); //to be changed
  const [employeeId, setEmployeeId] = useState("");
  const [skillEmpId, setSkillEmployeeId] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [employeeGrade, setEmployeeGrade] = useState("");
  const [designation, setDesignation] = useState("");
  const [l3SkillFamily, setl3SkillFamily] = useState("");
  const [l3SkillId, setL3SkillId] = useState("");
  const [employeeRole, setEmployeeRole] = useState("");
  const [employeeSkillExpertiseId, setEmployeeSkillExpertiseId] = useState("");
  const [indianSalary, setIndianSalary] = useState("");
  const [incedoJoiningDate, setIncedoJoiningDate] = useState(null);
  const [yearOfExperience, setYearOfExperience] = useState("");
  const [latestPerformanceRating, setLatestPerformanceRating] = useState("");
  const [visaType, setVisaType] = useState("");
  const [visaId, setVisaTypeId] = useState("");
  const [numberofDependents, setNumberOfDependents] = useState("");
  const [businessUnit, setBusinessUnit] = useState("");
  const [buId, setBUId] = useState("");
  const [buName, setBUName] = useState("");
  const [isInitiated, setIsInitiated] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);
  const [activeStep, setActiveStep] = useState(-1);
  const steps = [
    "Delivery Head Submission",
    "BU Head Approval",
    "Business Finance Approval",
    "CHRO Approval",
  ];
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const [validationMsg, setvalidationMsg] = useState("");
  const [employeeIdMsg, setemployeeIdMsg] = useState("");
  const [employeeNameMsg, setemployeeNameMsg] = useState("");
  const [employeeGradeMsg, setemployeeGradeMsg] = useState("");
  const [designationMsg, setdesignationMsg] = useState("");
  const [employeeSkillsMsg, setemployeeSkillsMsg] = useState("");
  const [salaryMsg, setsalaryMsg] = useState("");
  const [businessUnitMsg, setbusinessUnitMsg] = useState("");
  const [joiningDateMsg, setjoiningDateMsg] = useState("");
  const [employeeRoleMsg, setEmpoyeeRoleMsg] = useState("");
  const [yearOfExperienceMsg, setyearOfExperienceMsg] = useState("");
  const [latestPerformanceRatingMsg, setlatestPerformanceRatingMsg] =
    useState("");
  const [visaTypeMsg, setvisaTypeMsg] = useState("");
  const [noOfDependentsMsg, setnoOfDependentsMsg] = useState("");
  const [clientMsg, setclientMsg] = useState("");
  const [projectNameMsg, setprojectNameMsg] = useState("");
  const [backgroundVerificationMsg, setbackgroundVerificationMsg] =
    useState("");
  const [workingAtMsg, setworkingAtMsg] = useState("");
  const [workingLocationMsg, setworkingLocationMsg] = useState("");
  const [billingStartDateMsg, setbillingStartDateMsg] = useState("");
  const [travelDateMsg, settravelDateMsg] = useState("");
  const [salaryRangeMsg, setsalaryRangeMsg] = useState("");
  const [minimumWageMsg, setminimumWageMsg] = useState("");
  const [offeredCompensationMsg, setofferedCompensationMsg] = useState("");
  const [billRateMsg, setbillRateMsg] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [disableYesNo, setDisableYesNo] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [existSnackbarOpen, setExistSnackbarOpen] = useState(false);
  const [openRating, setOpenRating] = useState(false);
  const [thankYouRatingOpen, setThankYouRatingOpen] = React.useState(false);
  const [pageModule, setPageModule] = useState("");
  const [ratingHeading, setRatingHeading] = useState("");
  const [value, setValue] = React.useState(-1);
  const [hover, setHover] = React.useState(-1);
  const [review, setReview] = React.useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const preventNegativeAndDecimal = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidInput = /^[0-9]*$/.test(keyValue);

    if (!isValidInput) {
      event?.preventDefault();
    }
  };

  const preventInvalidInput = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const currentValue = event.target.value;
    const decimalSeparatorIndex = currentValue.indexOf(".");

    if (decimalSeparatorIndex !== -1 && keyValue !== ".") {
      const decimalPart =
        currentValue.substring(decimalSeparatorIndex + 1) + keyValue;
      if (decimalPart.length > 2 || parseInt(decimalPart) > 11) {
        event?.preventDefault();
        return;
      }
    } else if (
      currentValue.length >= 2 &&
      keyValue !== "." &&
      !currentValue.includes(".")
    ) {
      event?.preventDefault();
      return;
    }

    const isValidInput = /^(\d{0,9}(\.\d{0,2})?)?$/.test(
      currentValue + keyValue
    );

    if (!isValidInput) {
      event?.preventDefault();
    }
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const employeeRolesDetails = useSelector(
    (state) => state.gmCalData.getEmpRolesList
  );

  const getEmployeeDataList = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );

  const sessionEmployee =
    useSelector((state) => state.createExpenseData.getEmpDetailsData) || [];

  const GMDelEmpIdRedux = useSelector((state) => state?.gmCalData?.GMDelEmpId);

  const ratingLabels = useSelector(
    (state) => state?.timesheetData?.getRatingDescriptionData
  );

  var path = window.location.href;
  var directories = path.split("/");
  var lastDirecotry = directories[directories.length - 1];
  var firstDirectory = directories[directories.length - 2];

  useEffect(() => {
    if (directories && directories?.length === 4) {
      if (
        lastDirecotry === "timesheet-management" ||
        lastDirecotry === "create-Timesheet-Management"
      ) {
        setPageModule("Timesheet");
        setRatingHeading("Timesheet Application");
      } else if (
        lastDirecotry === "expense-Management" ||
        lastDirecotry === "create-Expense-Management" ||
        lastDirecotry === "allExpenseReports"
      ) {
        setPageModule("Expense");
        setRatingHeading("Expense Application");
      } else if (
        lastDirecotry === "gmdashboard" ||
        lastDirecotry === "gmmobility"
      ) {
        setPageModule("GM");
        setRatingHeading("Global Mobility Application");
      }
    } else if (directories && directories?.length === 5) {
      if (firstDirectory === "timesheetviewpage") {
        setPageModule("Timesheet");
        setRatingHeading("Timesheet Application");
      } else if (
        firstDirectory === "viewExpenseDetails" ||
        firstDirectory === "editExpenseDetails" ||
        firstDirectory === "editTravelExpenseDetails" ||
        firstDirectory === "deligation"
      ) {
        setPageModule("Expense");
        setRatingHeading("Expense Application");
      } else if (
        firstDirectory === "gmbupage" ||
        firstDirectory === "gmmobility"
      ) {
        setPageModule("GM");
        setRatingHeading("Global Mobility Application");
      }
    }
  }, [directories]);

  useEffect(() => {
    if (value && value === -1) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [value]);

  function getLabelText(value) {
    return (
      ratingLabels &&
      `${value} Star${value !== 1 ? "s" : ""}, ${
        ratingLabels[value - 1]?.ratingDescription
      }`
    );
  }

  const handleRatingClose = async () => {
    setOpenRating(false);

    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append("ApplicationDiscription", "");
    ratingFormData.append("Review", "");
    ratingFormData.append("Skippedflag", 1);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));

      if (response.status === 200) {
        history.push("/gmdashboard"); // Route to the gmdashboard location after 3 seconds
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  const handleRatingSkipClose = async () => {
    setOpenRating(false);

    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append("ApplicationDiscription", "");
    ratingFormData.append("Review", "");
    ratingFormData.append("Skippedflag", 1);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));

      if (response.status === 200) {
        history.push("/gmdashboard"); // Route to the gmdashboard location after 3 seconds
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  const handleRatingSubmit = async () => {
    setOpenRating(false);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append(
      "ApplicationDiscription",
      ratingLabels && value && ratingLabels[value - 1]?.ratingDescription
    );
    ratingFormData.append("Review", review ? review : "");
    ratingFormData.append("Skippedflag", 0);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));
      if (response.status === 200) {
        setTimeout(() => {
          setThankYouRatingOpen(true);
        }, 500);
        setTimeout(() => {
          setThankYouRatingOpen(false);
          history.push("/gmdashboard");
        }, 5000);
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  useEffect(() => {
    dispatch(getEmpDetail({}));
    // dispatch(getEmployeeRolesData({}));
  }, []);

  useEffect(() => {
    if (ssoEmpIdDetails) {
      dispatch(getRatingDescriptionData());
    }
  }, [ssoEmpIdDetails]);

  useEffect(() => {
    if (
      sessionEmployee &&
      GMDelEmpIdRedux &&
      sessionEmployee?.empId != GMDelEmpIdRedux
    ) {
      setMobilityEmpId(GMDelEmpIdRedux);
    } else if (
      sessionEmployee &&
      GMDelEmpIdRedux &&
      sessionEmployee?.empId == GMDelEmpIdRedux
    ) {
      setMobilityEmpId(sessionEmployee?.empId);
    } else {
      history.push("/gmdashboard");
    }
  }, [sessionEmployee, GMDelEmpIdRedux]);

  //UseEffect for Employee Lists

  useEffect(() => {
    dispatch(getEmployeeData({}));
  }, []);

  const employeeLists =
    useSelector((state) => state?.gmCalData?.getEmployeeList) || [];

  // useEffect(() => {
  //   if(employeeLists){
  //     console.log("Skill", employeeLists)
  //   }
  // }, [employeeLists]);

  // UseEffect for Employee Skill Expertise Lists
  useEffect(() => {
    dispatch(getEmployeeSkillExpertiseData({}));
  }, []);

  const employeeSkillExpertiseLists =
    useSelector((state) => state?.gmCalData?.getEmployeeSkillExpertiseList) ||
    [];

  const employeeSkillExpertiseHandler = (event) => {
    //msgFunction(event.target.vaue);
    if (event.target.value) {
      setEmpoyeeRoleMsg("");
    } else {
      setEmpoyeeRoleMsg("*Please select Employee Role");
    }
    let name = event.target.value;
    let id = employeeSkillExpertiseLists.find(
      (item) => name === item.expertiseSkillName
    )?.skillExpertiseId;
    setEmployeeRole(name);
    setEmployeeSkillExpertiseId(id);
  };

  //UseEffect for Skills
  useEffect(() => {
    dispatch(getSkillData());
  }, []);

  const skillLists =
    useSelector((state) => state?.gmCalData?.getSkillList) || [];

  useEffect(() => {
    if (skillLists) {
      let obj = skillLists.find((item) => item.selectedFl === 1) || {};
      let selectedSkills = obj?.skillName || "";
      setl3SkillFamily(selectedSkills);
      setL3SkillId(obj?.skillId);
    }
  }, [skillLists]);

  useEffect(() => {
    if (employeeId) {
      dispatch(getSkillData(employeeId.empId));
    }
  }, [employeeId]);

  const handleSkillDelete = () => {
    setL3SkillId("");
    setl3SkillFamily("");
  };

  //UseEffect for Business Units
  useEffect(() => {
    dispatch(getBusinessUnitData({}));
  }, []);

  const businessUnitList =
    useSelector((state) => state?.gmCalData?.getBusinessUnitList) || [];

  const businessUnitChangeHandler = (event) => {
    //msgFunction(event.target.vaue);
    if (event.target.value) {
      setbusinessUnitMsg("");
    } else {
      setbusinessUnitMsg("*Please select Destination Business Unit");
    }
    let name = event.target.value;
    let id = businessUnitList.find((item) => name === item.buName)?.buId;
    setBusinessUnit(name);
    setBUId(id);
    setBUName(name);
  };
  //UseEffect for Visa Type
  useEffect(() => {
    dispatch(getVisaTypeData({}));
  }, []);

  const visaTypeLists =
    useSelector((state) => state?.gmCalData?.getVisaTypeList) || [];

  const skillFamilyHandler = (event) => {
    const skill = event.target.value;
    //msgFunction(event.target.value);
    if (skill == null || skill == "") {
      setemployeeSkillsMsg("*Please select Employee Skill");
    } else {
      setemployeeSkillsMsg("");
    }
    let skill_Id = skillLists.find((item) => skill === item.skillName)?.skillId;
    setL3SkillId(skill_Id);
    setl3SkillFamily(skill);
  };

  const handleJoiningDate = (date) => {
    //msgFunction(date);
    if (date) {
      setjoiningDateMsg("");
    } else {
      setjoiningDateMsg("*Please select Incedo Joining Date");
    }
    setIncedoJoiningDate(date);
  };

  const handleVisaType = (event) => {
    const { name, value } = event.target;
    //msgFunction(event.target.value);
    if (event.target.value) {
      setvisaTypeMsg("");
    } else {
      setvisaTypeMsg("*Please select Visa Type");
    }
    const input = event.target.value;
    let visaIdd = visaTypeLists.find(
      (item) => input === item.visaType
    )?.vsTypeId;
    setVisaTypeId(visaIdd);
    setVisaType(input);
  };

  const handleInitiate = async (event) => {
    event?.preventDefault();

    // console.log("EmployeeId", skillEmpId, Decrypt(key, "m8EExfomOue1+WQKZmMb6g=="),  parseInt(l3SkillId, 10))

    let hasError = false;
    if (!employeeId) {
      setemployeeIdMsg("*Please enter Employee Id");
      hasError = true;
    } else {
      setemployeeIdMsg("");
    }
    if (!l3SkillFamily) {
      setemployeeSkillsMsg("*Please select Employee L3 Skills");
      hasError = true;
    } else {
      setemployeeSkillsMsg("");
    }

    if (!incedoJoiningDate) {
      setjoiningDateMsg("*Please select Incedo Joining Date");
      hasError = true;
    } else {
      const parsedDate = Date.parse(incedoJoiningDate);
      if (isNaN(parsedDate)) {
        setjoiningDateMsg(
          "*Invalid date. Please enter in 'MM/DD/YYYY' format."
        );
        hasError = true;
      } else {
        setjoiningDateMsg("");
      }
    }

    // if (!incedoJoiningDate) {
    //   setjoiningDateMsg("*Please enter Incedo Joining Date");
    //   hasError = true;
    // }
    // else {
    //     setjoiningDateMsg("");
    //   }

    if (!employeeRole) {
      setEmpoyeeRoleMsg("*Please select Employee Role");
      hasError = true;
    } else {
      setEmpoyeeRoleMsg("");
    }

    if (!indianSalary) {
      setsalaryMsg("*Please enter Salary in India");
      hasError = true;
    } else {
      setsalaryMsg("");
    }
    if (!businessUnit) {
      setbusinessUnitMsg("*Please select Business Unit");
      hasError = true;
    } else {
      setbusinessUnitMsg("");
    }
    if (!yearOfExperience) {
      setyearOfExperienceMsg("*Please enter Year of Experience");
      hasError = true;
    } else {
      setyearOfExperienceMsg("");
    }
    if (!visaType) {
      setvisaTypeMsg("*Please select Visa Type");
      hasError = true;
    } else {
      setvisaTypeMsg("");
    }
    if (!latestPerformanceRating) {
      setlatestPerformanceRatingMsg("*Please select Latest Performance Rating");
      hasError = true;
    } else {
      setlatestPerformanceRatingMsg("");
    }
    if (!numberofDependents) {
      setnoOfDependentsMsg("*Please enter No. of Dependents");
      hasError = true;
    } else if (numberofDependents && Number(numberofDependents) > 3) {
      setnoOfDependentsMsg("*No. of Dependents should not be greater than 3.");
      hasError = true;
    } else {
      setnoOfDependentsMsg("");
    }

    if (hasError) {
      // Exit the function and prevent form submission
      return;
    }

    setShowConfirmation(true);
    setIsButtonDisabled(true);
  };

  const handleYesButton = async () => {
    setDisableYesNo(true);
    let formData = new FormData();
    // let skillFormData = new FormData();

    formData.append("EmpId", Encrypt(key, JSON.stringify(employeeId.empId)));
    formData.append("MobilityId", Encrypt(key, JSON.stringify(mobilityEmpId)));
    formData.append(
      "DelegateId",
      sessionEmployee?.empId != GMDelEmpIdRedux
        ? Encrypt(key, JSON.stringify(sessionEmployee?.empId))
        : ""
    );
    formData.append("EmpName", Encrypt(key, employeeName));
    formData.append("EmpBand", Encrypt(key, employeeGrade));
    formData.append("EmpDesignation", Encrypt(key, designation));
    formData.append("EmpSkillId", parseInt(l3SkillId, 10));
    formData.append("EmpSkillName", l3SkillFamily);
    formData.append(
      "ExpertiseSkillId",
      Encrypt(key, JSON.stringify(employeeSkillExpertiseId))
    );
    formData.append("ExpertiseSkillName", employeeRole);
    formData.append("BUId", parseInt(buId, 10));
    formData.append("BUName", buName);

    const salaryNum = indianSalary.replace(/,/g, "");
    formData.append("IndianSalary", Encrypt(key, salaryNum));
    formData.append(
      "EmpIncedoJoiningDate",
      moment(incedoJoiningDate).format("MM/DD/yyyy")
    );
    formData.append("EmpYearOfExperience", yearOfExperience);
    formData.append("LatestPerformanceRating", latestPerformanceRating || "");
    formData.append("VisaTypeId", parseInt(visaId, 10));
    formData.append("VisaType", visaType);

    const dependantsCount = numberofDependents
      ? parseInt(numberofDependents, 10)
      : 0;
    formData.append("DependantsCount", dependantsCount);

    try {
      const response = await dispatch(postMobilityFormData(formData));
      const data = Decrypt(key, await response.text());
      if (response.status === 200 && !data?.gmId) {
        setExistSnackbarOpen(true);
      } else if (response.status === 200) {
        setIsInitiated(true);
      } else {
        setSnackbarOpen(true);
        setIsValidationError(true);
        setShowConfirmation(false);
        setIsInitiated(false);
      }
    } catch (error) {
      // console.log("Brijesh", error);{
      setIsValidationError(true);
      setIsInitiated(false);
    }
  };

  const handleClose = () => {
    // Reset the state and close the pop-up

    setIsInitiated(false);
    setIsValidationError(false);
    // console.log("India", isInitiated);
    if (
      pageModule &&
      ((ssoEmpIdDetails?.expenseRatingFlag === 1 && pageModule === "Expense") ||
        (ssoEmpIdDetails?.timesheetRatingFlag === 1 &&
          pageModule === "Timesheet") ||
        (ssoEmpIdDetails?.gmRatingFlag === 1 && pageModule === "GM"))
    ) {
      setOpenRating(true);
      setShowConfirmation(false);
    } else {
      setIsValidationError(false);
      setShowConfirmation(false);
      history.push("/gmdashboard"); // Route to the gmdashboard location after 3 seconds
    }
  };

  useEffect(() => {
    if (isInitiated) {
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [isInitiated]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleSnackClose = () => {
    setIsInitiated(false);
    setIsValidationError(false);
    setSnackbarOpen(false);
    setIsButtonDisabled(true);
    history.push("/gmdashboard");
  };

  const handleExistSnackClose = () => {
    setIsInitiated(false);
    setExistSnackbarOpen(false);
    setIsButtonDisabled(true);
    history.push("/gmdashboard");
  };

  const handleConfirmClose = () => {
    // Reset the state and close the pop-up
    setShowConfirmation(false);
    setIsButtonDisabled(false);
  };

  const handleBackdropMouseDown = (event) => {
    // Check if the event target is the dialog's backdrop
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  // Rest of the code logic for form submission or data processing
  // };
  // PropTypes definition
  GMMobility.propTypes = {
    classes: PropTypes.object.isRequired,
  };

  return (
    <div className="body">
      <Header
        heading={""}
        ssoEmpId={ssoEmpIdDetails?.empId}
        disableAccOptions={1}
      />
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Box
          style={{
            paddingLeft: "70px",
            paddingTop: "5px",
          }}
          onClick={() => {
            history.push({
              pathname: "/gmdashboard",
              state: {
                compName: "GMDashboard",
              },
            });
          }}
        >
          <ArrowBackIosIcon />
        </Box>
        <Box className={classes.expenseHeading}>
          <Typography
            style={{
              fontWeight: "600",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontSize: "24px",
              lineHeight: "24px",
              letterSpacing: "0px",
              textAlign: "left",
              color: "#2B2D30",
              paddingBottom: "10px",
            }}
          >
            Initiate Mobility
          </Typography>
        </Box>
      </Box>
      <div className="form-container">
        <form onSubmit={handleInitiate}>
          <Grid
            variant="outlined"
            style={{
              height: "50px",
              color: "#1C3663",
              border: "1.5px solid #B9C3E0",
              borderRadius: "5px",
              backgroundColor: "#EDF2FF",
              marginBottom: "20px",
            }}
          >
            <Typography
              variant="h6"
              style={{
                marginLeft: "10px",
                lineHeight: "50px",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Employee Details
            </Typography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} className="custom-grid-outlined">
              <FormControl
                className={
                  employeeIdMsg != ""
                    ? classes.formControl12
                    : classes.formControl
                }
                variant="outlined"
                fullWidth
                margin="normal"
              >
                <Autocomplete
                  //required
                  options={employeeLists}
                  getOptionLabel={(option) =>
                    option?.empCode ? option?.empCode.toString() : ""
                  }
                  value={employeeId}
                  onChange={(event, newValue) => {
                    setEmployeeId(newValue);
                    //msgFunction(newValue);

                    if (newValue) {
                      setemployeeIdMsg("");
                      setemployeeGradeMsg("");
                      setemployeeNameMsg("");
                      setdesignationMsg("");
                      setjoiningDateMsg("");
                      setEmployeeName(newValue?.empName);
                      setDesignation(newValue?.empDesignation);
                      setEmployeeGrade(newValue?.empBand);
                      setIncedoJoiningDate(newValue?.empJoiningDate);
                      setSkillEmployeeId(newValue?.empId);
                    } else {
                      // Clear the fields if no option is selected
                      setemployeeIdMsg("*Please enter Employee Id");
                      setEmployeeName("");
                      setDesignation("");
                      setEmployeeGrade("");
                      setIncedoJoiningDate(null);
                    }
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Employee Id*"
                        variant="outlined"
                        fullWidth
                        type="text"
                        // onKeyPress={preventMinus}
                        onKeyPress={preventNegativeAndDecimal}
                        //required
                      />
                    </>
                  )}
                  renderOption={(option) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          minWidth: "100%",
                        }}
                      >
                        <div style={{ fontWeight: "bold" }}>
                          {option?.empCode}
                        </div>
                        <div style={{ display: "flex", fontSize: "14px" }}>
                          <div style={{ marginRight: "8px" }}>
                            {option?.empName} |{" "}
                          </div>
                          <div style={{ marginRight: "8px" }}>
                            {option?.empDesignation} |{" "}
                          </div>
                          <div>{option?.empBand}</div>
                        </div>
                      </div>
                    </>
                  )}
                />
                {employeeId == null || employeeId == "" ? (
                  <FormHelperText className={classes.errorRoot}>
                    {employeeIdMsg}
                  </FormHelperText>
                ) : (
                  <FormHelperText></FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                label={<span style={{ color: "#000" }}>Employee Name*</span>}
                name="employeeName"
                value={employeeName}
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.disabledInput}
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                    style: {
                      color: "black",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                label={<span style={{ color: "#000" }}>Employee Grade*</span>}
                name="employeeGrade"
                disabled
                value={employeeGrade}
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.disabledInput}
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                    style: {
                      color: "black",
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label={<span style={{ color: "#000" }}>Designation*</span>}
                name="designation"
                value={designation}
                disabled
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.disabledInput}
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                    style: {
                      color: "black",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <FormControl
                className={
                  employeeSkillsMsg !== "" && l3SkillFamily === "" // Add condition to check if l3SkillFamily is empty
                    ? classes.formControl12
                    : classes.formControl
                }
                variant="outlined"
                fullWidth
                margin="normal"
              >
                <InputLabel>Employee L3 Skills*</InputLabel>
                <Select
                  label="Employee L3 Skills"
                  name="l3SkillFamily"
                  value={l3SkillFamily || []}
                  onChange={(event, item) => skillFamilyHandler(event, item)}
                  MenuProps={{
                    MenuListProps: {
                      disableOutline: true,
                    },
                  }}
                  //required
                  renderValue={() => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {l3SkillFamily && (
                        <Chip
                          key={l3SkillFamily}
                          label={l3SkillFamily}
                          style={{
                            height: "20px",
                            width: "220px",
                            border: "1px solid #9E9E9E",
                            backgroundColor: "#fff",
                            justifyContent: "space-between",
                          }}
                          onDelete={() => handleSkillDelete(l3SkillFamily)}
                          deleteIcon={
                            <ClearIcon
                              style={{
                                color: "#9E9E9E",
                                height: "18px",
                                width: "18px",
                              }}
                            />
                          }
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                        />
                      )}
                    </Box>
                  )}
                >
                  {skillLists?.map((item) => (
                    <MenuItem key={item?.skillId} value={item?.skillName}>
                      {item?.skillName}
                    </MenuItem>
                  ))}
                </Select>
                {l3SkillFamily === null || l3SkillFamily === "" ? (
                  <FormHelperText className={classes.errorRoot}>
                    {employeeSkillsMsg}
                  </FormHelperText>
                ) : (
                  <FormHelperText></FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl
                className={
                  employeeRoleMsg != ""
                    ? classes.formControl12
                    : classes.formControl
                }
                variant="outlined"
                fullWidth
                margin="normal"
              >
                <InputLabel>Employee Role*</InputLabel>
                <Select
                  label="Employee Role"
                  name="employeeRole"
                  value={employeeRole}
                  onChange={employeeSkillExpertiseHandler}
                  MenuProps={{
                    MenuListProps: {
                      disableOutline: true,
                    },
                  }}
                  //required
                >
                  {employeeSkillExpertiseLists.map((item) => (
                    <MenuItem
                      key={item.skillExpertiseId}
                      value={item.expertiseSkillName}
                    >
                      {item.expertiseSkillName}
                    </MenuItem>
                  ))}
                </Select>
                {employeeRole == null || employeeRole == "" ? (
                  <FormHelperText className={classes.errorRoot}>
                    {employeeRoleMsg}
                  </FormHelperText>
                ) : (
                  <FormHelperText></FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <NumericFormat
                className={
                  salaryMsg != "" ? classes.formControl12 : classes.formControl
                }
                label="Salary in India (INR)*"
                name="indianSalary"
                value={indianSalary}
                thousandSeparator={true}
                onChange={(e) => {
                  const input = e.target.value;
                  setIndianSalary(input);
                  //msgFunction(e.target.value);
                  if (input) {
                    setsalaryMsg("");
                  } else {
                    setsalaryMsg("*Please enter Salary in India");
                  }
                }}
                variant="outlined"
                fullWidth
                min="0"
                // inputProps={{
                //   onKeyPress: preventNegativeAndDecimal,
                // }}
                onKeyPress={preventMinus}
                customInput={TextField}
                margin="normal"
                InputProps={{
                  inputProps: {
                    maxLength: 12,
                    onKeyPress: preventNegativeAndDecimal,
                  },
                }}
                helperText={
                  indianSalary == null || indianSalary == "" ? salaryMsg : ""
                }
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
                //required
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl
                className={
                  businessUnitMsg != ""
                    ? classes.formControl12
                    : classes.formControl
                }
                variant="outlined"
                fullWidth
                margin="normal"
              >
                <InputLabel>Destination Business Unit*</InputLabel>
                <Select
                  label="Destination Business Unit"
                  name="businessUnit"
                  value={businessUnit}
                  onChange={businessUnitChangeHandler}
                  MenuProps={{
                    MenuListProps: {
                      disableOutline: true,
                    },
                  }}
                  //required
                >
                  {businessUnitList.map((item) => (
                    <MenuItem key={item.buId} value={item.buName}>
                      {item.buName}
                    </MenuItem>
                  ))}
                </Select>
                {businessUnit == null || businessUnit == "" ? (
                  <FormHelperText className={classes.errorRoot}>
                    {businessUnitMsg}
                  </FormHelperText>
                ) : (
                  <FormHelperText></FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={
                    joiningDateMsg != ""
                      ? classes.formControl12
                      : classes.formControl
                  }
                  disableToolbar
                  inputVariant="outlined"
                  fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                  }}
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  autoOk={true}
                  id="date-picker-inline"
                  label="Incedo Joining Date*"
                  disableFuture={true}
                  value={incedoJoiningDate}
                  onChange={(e) => {
                    handleJoiningDate(e);
                    if (e) {
                      const formattedDate = e.toLocaleDateString("en-US");
                      const parsedDate = parse(
                        formattedDate,
                        "MM/dd/yyyy",
                        new Date()
                      );
                      if (
                        !isValid(parsedDate) ||
                        parsedDate.getFullYear() < 1900
                      ) {
                        setjoiningDateMsg(
                          "*Invalid date! Please enter in 'MM/DD/YYYY' format."
                        );
                      } else {
                        setjoiningDateMsg("");
                      }
                    } else {
                      setjoiningDateMsg("*Please select Incedo Joining Date");
                    }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  helperText={
                    incedoJoiningDate == null ||
                    incedoJoiningDate == "" ||
                    joiningDateMsg != "" ||
                    joiningDateMsg != null
                      ? joiningDateMsg
                      : ""
                  }
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorRoot,
                    },
                  }}
                  //required
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <NumericFormat
                className={
                  yearOfExperienceMsg != ""
                    ? classes.formControl12
                    : classes.formControl
                }
                label="Year of Experience*"
                name="yearOfExperience"
                value={yearOfExperience}
                onChange={(e) => {
                  setYearOfExperience(e.target.value);
                  //msgFunction(e.target.value);
                  if (e.target.value) {
                    setyearOfExperienceMsg("");
                  } else {
                    setyearOfExperienceMsg("*Please enter Years of Experience");
                  }
                }}
                variant="outlined"
                fullWidth
                min="0"
                onKeyPress={preventMinus}
                customInput={TextField}
                margin="normal"
                InputProps={{
                  inputProps: {
                    maxLength: 5,
                    min: 0,
                    step: 0.01,
                    onKeyPress: preventInvalidInput,
                  },
                }}
                helperText={
                  yearOfExperience == null || yearOfExperience == ""
                    ? yearOfExperienceMsg
                    : ""
                }
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
                //required
              />
              <span style={{ fontWeight: 600, fontSize: "12px" }}>
                Note: Format is YY.MM. Example: 4.10 is equals to 4 years and 10
                months.
              </span>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                className={
                  latestPerformanceRatingMsg != ""
                    ? classes.formControl12
                    : classes.formControl
                }
                variant="outlined"
                fullWidth
                margin="normal"
              >
                <InputLabel>Latest Performance Rating*</InputLabel>
                <Select
                  label="Latest Performance Rating*"
                  name="latestPerformanceRating"
                  value={latestPerformanceRating}
                  onChange={(e) => {
                    setLatestPerformanceRating(e.target.value);
                    //msgFunction(e.target.value)
                    if (e.target.value) {
                      setlatestPerformanceRatingMsg("");
                    } else {
                      setlatestPerformanceRatingMsg(
                        "*Please select Latest Performance Rating"
                      );
                    }
                  }}
                  MenuProps={{
                    MenuListProps: {
                      disableOutline: true,
                    },
                  }}
                  // required
                >
                  {performanceRatingOptions?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {latestPerformanceRating == null ||
                latestPerformanceRating == "" ? (
                  <FormHelperText className={classes.errorRoot}>
                    {latestPerformanceRatingMsg}
                  </FormHelperText>
                ) : (
                  <FormHelperText></FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            variant="outlined"
            style={{
              height: "50px",
              color: "#1C3663",
              border: "1.5px solid #B9C3E0",
              borderRadius: "5px",
              backgroundColor: "#EDF2FF",
              marginBottom: "20px",
              marginTop: "30px",
            }}
          >
            <Typography
              variant="h6"
              style={{
                marginLeft: "10px",
                lineHeight: "50px",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Visa Information
            </Typography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl
                className={
                  visaTypeMsg != ""
                    ? classes.formControl12
                    : classes.formControl
                }
                variant="outlined"
                fullWidth
                margin="normal"
              >
                <InputLabel>Visa Type*</InputLabel>
                <Select
                  label="Visa Type*"
                  name="visaType"
                  value={visaType}
                  onChange={handleVisaType}
                  SelectDisplayProps={{
                    style: { color: "black" },
                  }}
                  MenuProps={{
                    getContentAnchorEl: null,
                  }}
                  //required
                >
                  {visaTypeLists.map((item) => (
                    <MenuItem key={item.vsTypeId} value={item.visaType}>
                      {item.visaType}
                    </MenuItem>
                  ))}
                </Select>
                {visaType == null || visaType == "" ? (
                  <FormHelperText className={classes.errorRoot}>
                    {visaTypeMsg}
                  </FormHelperText>
                ) : (
                  <FormHelperText></FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <NumericFormat
                className={
                  noOfDependentsMsg != ""
                    ? classes.formControl12
                    : classes.formControl
                }
                label="No. of Dependents*"
                name="numberofDependents"
                value={numberofDependents}
                onChange={(e) => {
                  const input = e.target.value;
                  const wholeNumber = input.replace(/[^0-9]/g, "");
                  setNumberOfDependents(wholeNumber);
                  //msgFunction(e.target.value);
                  if (wholeNumber && wholeNumber < 4) {
                    setnoOfDependentsMsg("");
                  } else if (wholeNumber && wholeNumber > 3) {
                    setnoOfDependentsMsg(
                      "*No. of Dependents should not be greater than 3."
                    );
                  } else {
                    setnoOfDependentsMsg("*Please enter No. of Dependents");
                  }
                }}
                variant="outlined"
                fullWidth
                onKeyPress={preventMinus}
                min="0"
                customInput={TextField}
                margin="normal"
                InputProps={{
                  inputProps: {
                    maxLength: 2,
                    onKeyPress: preventNegativeAndDecimal,
                  },
                }}
                helperText={
                  numberofDependents == null ||
                  numberofDependents == "" ||
                  numberofDependents > 3
                    ? noOfDependentsMsg
                    : ""
                }
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
              />
            </Grid>
          </Grid>
          {showConfirmation ? (
            <Dialog
              open={showConfirmation}
              onClose={handleConfirmClose}
              className={classes.confirmDialogBody}
            >
              {/* Dialog content */}
              <DialogContent>
                <Typography
                  variant="body1"
                  className={classes.confirmationText}
                >
                  Do you want to initiate this form?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.yesButton}
                  style={{
                    textTransform: "none",
                    fontSize: "16px",
                  }}
                  disabled={disableYesNo ? true : false}
                  onClick={() => {
                    handleYesButton();
                  }} // Handle user confirmation
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  // color="secondary"
                  className={classes.noButton}
                  style={{
                    textTransform: "none",
                    fontSize: "16px",
                  }}
                  disabled={disableYesNo ? true : false}
                  onClick={handleConfirmClose} // Handle user cancellation
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <div className="button-container">
              <Button
                variant="contained"
                color="primary"
                className="button"
                type="submit"
                style={{
                  textTransform: "none",
                  fontSize: "16px",
                  backgroundColor: isButtonDisabled ? "#9E9E9E" : "#0B1941",
                }}
                onClick={() => {
                  handleInitiate();
                }}
                disabled={isButtonDisabled}
              >
                Initiate Process
                {/* {isButtonDisabled && (<span>Initiate Process</span>)} */}
              </Button>
            </div>
          )}

          <Snackbar open={snackbarOpen} onClose={() => handleSnackClose(false)}>
            <Alert
              onClose={() => handleSnackClose(false)}
              severity="error"
              sx={{ width: "100%", backgroundColor: "red", color: "white" }}
            >
              There is some problem with the application. Please connect with
              System Admin.
            </Alert>
          </Snackbar>

          <Snackbar
            open={existSnackbarOpen}
            onClose={() => handleExistSnackClose(false)}
          >
            <Alert
              onClose={() => handleExistSnackClose(false)}
              severity="error"
              sx={{ width: "100%", backgroundColor: "red", color: "white" }}
            >
              Mobility for this employee is already initiated.
            </Alert>
          </Snackbar>

          <Dialog
            open={isInitiated}
            onClose={handleClose}
            className={classes.dialogBody}
          >
            <DialogContent>
              <CheckCircleIcon className={classes.successIcon} />
              <Typography variant="h6" className={classes.thankYouText}>
                Thank You!
              </Typography>
              <Typography variant="body1" className={classes.messageText}>
                Your request has been forwarded to the BU <br /> head for
                providing further details.
              </Typography>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                className={classes.stepper}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </DialogContent>
          </Dialog>

          {!matches ? (
            <Box>
              <Dialog
                open={openRating}
                // TransitionComponent={Transition}
                keepMounted
                onClose={handleRatingClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <Box style={{ width: "400px", height: "650px" }}>
                  <DialogTitle>
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        marginLeft: "10px",
                        textAlignLast: "center",
                      }}
                    >
                      Rate and Review <br></br> {ratingHeading}
                    </Typography>
                    <Divider
                      style={{
                        backgroundColor: "#E1E1E1",
                        width: "100%",
                        height: "0.5px",
                        marginLeft: "2.5%",
                        marginTop: "10px",
                      }}
                      orientation="horizontal"
                    />
                  </DialogTitle>
                  <DialogContent style={{ paddingTop: "12px" }}>
                    <DialogContentText id="alert-dialog-slide-description">
                      <Box>
                        <Box style={{ width: "300px", marginLeft: "20px" }}>
                          <Typography
                            style={{ textAlign: "center", color: "#000" }}
                          >
                            <span
                              style={{ color: "#2CB0FE", fontWeight: "400" }}
                            >
                              {ssoEmpIdDetails ? ssoEmpIdDetails?.empName : ""}
                            </span>
                            , we'd like to know your reviews on {ratingHeading}.
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: 300,
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "40px",
                            textAlignLast: "center",
                          }}
                        >
                          <Box sx={{ width: 100 }}></Box>
                          <Box sx={{ width: 200 }}>
                            <Rating
                              name="hover-feedback"
                              value={value}
                              getLabelText={getLabelText}
                              size="large"
                              onChange={(event, newValue) => {
                                newValue === null
                                  ? setValue(-1)
                                  : setValue(newValue);
                              }}
                              onChangeActive={(event, newHover) => {
                                setHover(newHover);
                              }}
                              emptyIcon={
                                <StarOutlineIcon
                                  style={{ opacity: 0.8, color: "#2CB0FE" }}
                                  fontSize="inherit"
                                />
                              }
                              style={{ textAlignLast: "center" }}
                            />
                          </Box>
                          <Box sx={{ width: 50 }}></Box>
                        </Box>

                        {value !== null && ratingLabels && (
                          <Box
                            style={{
                              textAlignLast: "center",
                              paddingTop: "5px",
                              height: "20px",
                            }}
                          >
                            <Typography
                              style={{ fontSize: "14px", color: "#000" }}
                            >
                              {
                                ratingLabels[
                                  hover !== -1 ? hover - 1 : value - 1
                                ]?.ratingDescription
                              }
                            </Typography>
                          </Box>
                        )}
                      </Box>

                      <Box>
                        <Box
                          style={{
                            textAlignLast: "center",
                            paddingTop: "25px",
                          }}
                        >
                          <Typography
                            style={{ fontWeight: "550", color: "#2CB0FE" }}
                          >
                            Write a Review
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            paddingTop: "10px",
                            border: "0.5px solid #000",
                            borderRadius: "5px",
                          }}
                        >
                          <TextField
                            id="review"
                            value={review}
                            placeholder="What did you like or dislike? Write here your feedback about this application."
                            multiline
                            rows={3}
                            onChange={(e) => setReview(e.target.value)}
                            style={{
                              width: "100%",
                            }}
                            InputProps={{
                              inputProps: {
                                maxLength: 200,
                              },
                            }}
                          ></TextField>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: "6px",
                          }}
                        >
                          <Box style={{ width: "50px" }}></Box>
                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                width: "120px",
                                textTransform: "none",
                                backgroundColor: isSubmitDisabled
                                  ? "#9E9E9E"
                                  : "#043365",
                              }}
                              onClick={handleRatingSubmit}
                              disabled={isSubmitDisabled ? true : false}
                            >
                              Submit
                            </Button>
                          </Box>
                          <Box>
                            <Link underline="none" target="_blank">
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  height: "30px",
                                }}
                              >
                                <Typography
                                  onClick={handleRatingSkipClose}
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                  }}
                                >
                                  Skip {`>`}
                                </Typography>
                              </Box>
                            </Link>
                          </Box>
                        </Box>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                </Box>
              </Dialog>
            </Box>
          ) : (
            <Box>
              <Dialog
                open={openRating}
                // TransitionComponent={Transition}
                keepMounted
                onClose={handleRatingClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <Box style={{ width: "300px", height: "500px" }}>
                  <DialogTitle>
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        marginLeft: "10px",
                        textAlignLast: "center",
                      }}
                    >
                      Rate and Review <br></br> {ratingHeading}
                    </Typography>
                    <Divider
                      style={{
                        backgroundColor: "#E1E1E1",
                        width: "100%",
                        height: "0.5px",
                        marginLeft: "2.5%",
                        marginTop: "10px",
                      }}
                      orientation="horizontal"
                    />
                  </DialogTitle>
                  <DialogContent style={{ paddingTop: "12px" }}>
                    <DialogContentText id="alert-dialog-slide-description">
                      <Box style={{ marginLeft: "-44px" }}>
                        <Box style={{ width: "300px", marginLeft: "20px" }}>
                          <Typography
                            style={{
                              textAlign: "center",
                              color: "#000",
                              width: "18rem",
                            }}
                          >
                            <span
                              style={{ color: "#2CB0FE", fontWeight: "400" }}
                            >
                              {ssoEmpIdDetails ? ssoEmpIdDetails?.empName : ""}
                            </span>
                            , we'd like to know your reviews on {ratingHeading}.
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: 300,
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "40px",
                            textAlignLast: "center",
                          }}
                        >
                          <Box sx={{ width: 100 }}></Box>
                          <Box sx={{ width: 200 }}>
                            <Rating
                              name="hover-feedback"
                              value={value}
                              getLabelText={getLabelText}
                              size="large"
                              onChange={(event, newValue) => {
                                newValue === null
                                  ? setValue(-1)
                                  : setValue(newValue);
                              }}
                              onChangeActive={(event, newHover) => {
                                setHover(newHover);
                              }}
                              emptyIcon={
                                <StarOutlineIcon
                                  style={{ opacity: 0.8, color: "#2CB0FE" }}
                                  fontSize="inherit"
                                />
                              }
                              style={{ textAlignLast: "center" }}
                            />
                          </Box>
                          <Box sx={{ width: 50 }}></Box>
                        </Box>

                        {value !== null && ratingLabels && (
                          <Box
                            style={{
                              textAlignLast: "center",
                              paddingTop: "5px",
                              marginLeft: "3rem",
                              height: "1rem",
                            }}
                          >
                            <Typography
                              style={{ fontSize: "14px", color: "#000" }}
                            >
                              {
                                ratingLabels[
                                  hover !== -1 ? hover - 1 : value - 1
                                ]?.ratingDescription
                              }
                            </Typography>
                          </Box>
                        )}
                      </Box>

                      <Box>
                        <Box
                          style={{
                            textAlignLast: "center",
                            paddingTop: "25px",
                          }}
                        >
                          <Typography
                            style={{ fontWeight: "550", color: "#2CB0FE" }}
                          >
                            Write a Review
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            paddingTop: "10px",
                            border: "0.2px solid #000",
                            borderRadius: "5px",
                            marginLeft: "10px",
                          }}
                        >
                          <TextField
                            id="review"
                            value={review}
                            placeholder="What did you like or dislike? Write here your feedback about this application."
                            multiline
                            rows={3}
                            onChange={(e) => setReview(e.target.value)}
                            style={{
                              width: "100%",
                            }}
                            InputProps={{
                              inputProps: {
                                maxLength: 200,
                              },
                            }}
                          ></TextField>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: "6px",
                          }}
                        >
                          <Box style={{ width: "50px" }}></Box>
                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                width: "120px",
                                textTransform: "none",
                                backgroundColor: isSubmitDisabled
                                  ? "#9E9E9E"
                                  : "#043365",
                              }}
                              onClick={handleRatingSubmit}
                              disabled={isSubmitDisabled ? true : false}
                            >
                              Submit
                            </Button>
                          </Box>
                          <Box>
                            <Link underline="none" target="_blank">
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  height: "30px",
                                }}
                              >
                                <Typography
                                  onClick={handleRatingSkipClose}
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                  }}
                                >
                                  Skip {`>`}
                                </Typography>
                              </Box>
                            </Link>
                          </Box>
                        </Box>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                </Box>
              </Dialog>
            </Box>
          )}
          {!matches ? (
            <Box>
              <Dialog
                open={thankYouRatingOpen}
                // TransitionComponent={Transition}
                keepMounted
                // onClose={handleThankyou}
                aria-describedby="alert-dialog-slide-description"
              >
                <Box style={{ width: "400px", height: "350px" }}>
                  <DialogTitle>
                    <CheckCircleIcon className={classes.successIcon} />
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        marginLeft: "10px",
                        textAlignLast: "center",
                      }}
                    >
                      Thanks for <br></br>Rating and Review.
                    </Typography>
                  </DialogTitle>
                  <DialogContent style={{ paddingTop: "12px" }}>
                    <DialogContentText id="alert-dialog-slide-description">
                      <Box>
                        <Box
                          sx={{
                            width: 300,
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "40px",
                            textAlignLast: "center",
                          }}
                        >
                          <Box sx={{ width: 100 }}></Box>
                          <Box sx={{ width: 200 }}>
                            <Rating
                              name="hover-feedback"
                              value={value}
                              getLabelText={getLabelText}
                              size="large"
                              emptyIcon={
                                <StarOutlineIcon
                                  style={{ opacity: 0.8, color: "#2CB0FE" }}
                                  fontSize="inherit"
                                />
                              }
                              style={{ textAlignLast: "center" }}
                            />
                          </Box>
                          <Box sx={{ width: 50 }}></Box>
                        </Box>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                </Box>
              </Dialog>
            </Box>
          ) : (
            <Box>
              <Dialog
                open={thankYouRatingOpen}
                // TransitionComponent={Transition}
                keepMounted
                // onClose={handleThankyou}
                aria-describedby="alert-dialog-slide-description"
              >
                <Box style={{ width: "300px", height: "250px" }}>
                  <DialogTitle>
                    <CheckCircleIcon className={classes.successIcon} />
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        marginLeft: "10px",
                        textAlignLast: "center",
                      }}
                    >
                      Thanks for <br></br>Rating and Review.
                    </Typography>
                  </DialogTitle>
                  <DialogContent style={{ paddingTop: "12px" }}>
                    <DialogContentText id="alert-dialog-slide-description">
                      <Box style={{ marginLeft: "-44px" }}>
                        <Box
                          sx={{
                            width: 300,
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "40px",
                            textAlignLast: "center",
                          }}
                        >
                          <Box sx={{ width: 100 }}></Box>
                          <Box sx={{ width: 200 }}>
                            <Rating
                              name="hover-feedback"
                              value={value}
                              getLabelText={getLabelText}
                              size="large"
                              emptyIcon={
                                <StarOutlineIcon
                                  style={{ opacity: 0.8, color: "#2CB0FE" }}
                                  fontSize="inherit"
                                />
                              }
                              style={{ textAlignLast: "center" }}
                            />
                          </Box>
                          <Box sx={{ width: 50 }}></Box>
                        </Box>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                </Box>
              </Dialog>
            </Box>
          )}
        </form>
      </div>
    </div>
  );
}

export default GMMobility;
