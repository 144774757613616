import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import CloseIcon from "@material-ui/icons/Close";
import "../css/dragDrope.css";
// import ImageConfig from "../../../config/imageConfig";
import { Box, Button, Typography, Grid, Chip } from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { Edit } from "@material-ui/icons";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Paper from "@material-ui/core/Paper";
const useStyles = makeStyles((theme) => ({
  btnBox: {
    padding: "10px",
  },
  btnBoxMobile: {
    //margin:"0px",
    padding: "0px,16px",
    width: "270px",
    height: "42px",
    borderRadius: "4px",
    backgroundColor: "#0B1941",
    justifyContent: "center",
  },
  btnBoxMobile1: {},
  rootMobile: {
    display: "flex",

    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  },
  rootMobile1: {
    display: "flex",
    justifyContent: "center",
    margin: "0px",
    paddingTop: "10px",
  },
  cameraBox: {
    padding: "10px",
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #9E9E9E",
    borderRadius: "4px",
  },
  chip: {
    display: "flex",
    justifyContent: "space-between",
  },
  chip1: {
    display: "flex",
    justifyContent: "space-between",
    minWidth: "137px",
  },

  existingFile: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "24.38px",
    letterSpacing: "0.3px",
    marginTop: "20px",
    marginLeft: " 12px",
    // textAlign: "left",
    color: "#252733",
  },
}));

const DropFileInput = (props) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState([]);
  const [prePopulateList, setPrePopulatedList] = useState([]);
  const [deleteFileList, setDeleteFileList] = useState([]);
  
  

  
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const { files } = e.target;
    const newFile = [...files];
    if (props.Edit && newFile.length > 0) {
      const updatedList = [...fileList, ...newFile];
      props.handleFileList(newFile, props.index);
      
    } else if (newFile.length > 0) {
      if(props.fileDisplayForDD[props.index]){
        const updatedList = [...props.fileDisplayForDD[props.index], ...newFile];
        //const updatedList = [...newFile]
        //setFileList(updatedList);
        props.handleFileList(updatedList, props.index);
      }
      else{
        const updatedList = [ ...newFile];
        //const updatedList = [...newFile]
        //setFileList(updatedList);
        props.handleFileList(updatedList, props.index);
      }
      
    }
  };



  const fileRemove = (file) => {
   // console.log("i am in file remove" , props.fileDisplayForDD, fileList);
    // const updatedList = [...fileList];
    // updatedList.splice(fileList.indexOf(file), 1);
    // setFileList(updatedList);
    

    if (!props.Edit) {
      let list = [...props.fileDisplayForDD[props.index]];
    
      const index = list.findIndex((item) => item.name === file.name);
     

      // If the file is found, remove it from the list
      if (index !== -1) {
        list.splice(index, 1);


        // Update the state of fileDisplayForDD with the modified list
          const updatedDisplayForDD = [...props.fileDisplayForDD];
          updatedDisplayForDD[props.index] = list;
      props.handleFileList(updatedDisplayForDD[props.index], props.index);

      }
    } else {
      if (props.selectedCategory) {
        const selectedCategoryUpdatedList = [...props.selectedCategory];
        selectedCategoryUpdatedList[props.index].expenseattachements.splice(
          props.selectedCategory[props.index].expenseattachements.indexOf(file),
          1
        );
        let list = [...props.fileDisplayForDD[props.index]];
        // Find the index of the file in the list array
        const index = list.findIndex((item) => item.name === file.name);
        // If the file is found, remove it from the list
        if (index !== -1) {
          list.splice(index, 1);
          // Update the state of fileDisplayForDD with the modified list
          props.setFileDisplayForDD((prevState) => {
            const updatedDisplayForDD = [...prevState];
            updatedDisplayForDD[props.index] = list;
            return updatedDisplayForDD;
          });
        }
      }
    }

    //console.log("dragdroppre" , selectedCategoryUpdatedList)
  };

  const prefileRemove = (file, i) => {
    let list = [...props.fileDisplayForDD[i]];
    const index = list.findIndex((item) => item.name === file.name);
    if (index !== -1) {
      list.splice(index, 1);
      props.setFileDisplayForDD((prevState) => {
        const updatedDisplayForDD = [...prevState];
        updatedDisplayForDD[i] = list;
        return updatedDisplayForDD;
      });
    props.handleEditDeleteValidateFile(list, props.index);
    }
    props.handleDeleteFileList(file, props.index);
  };
  //   console.log("deleted file", deleteFileList ,selectedCategoryUpdatedList);

  //console.log("deleted file", deleteFileList , prePopulateList , fileList , props.selectedCategory);
  const handleClick = () => {
    // console.info("You clicked the Chip.");
  };
  //console.log("fileList", fileList);
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <>
      <div
        className="drop-file-container"
        style={{
          justifyContent: "center",
          padding: matches ? "2px 16px" : "2px 25px",
        }}
      >
        <Paper
          style={{
            boxShadow: "none",
            justifyContent: "center",
            // marginLeft:"-20px",
            // marginRight: matches ? "20px" : "",
            padding: "0px",
          }}
          className={classes.paper}
        >
          <div
            ref={wrapperRef}
            className="drop-file-input"
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            style={{
              justifyContent: matches ? "center" : "",
              padding: "0px",
              height: "130px",
            }}
          >
            <div
              className="drop-file-input__label"
              style={{
                justifyContent: matches ? "center" : "",
                padding: "0px",
              }}
            >
              {matches ? (
                // <Grid container spacing={1}>
                // <Grid item xs={11} sm={11} md={11} >
                <Box>
                  <div className={classes.rootMobile1}>
                    <input
                      accept=".jpg, .jpeg, .png, .pdf"
                      capture="environment"
                      multiple
                    ></input>
                    <Paper
                      variant="outlined"
                      style={{
                        border: "0px",
                        // width: "240px",
                        justifyItems: "center",
                      }}
                    >
                      <CameraAltOutlinedIcon
                        style={{ fontSize: 40 }}
                        color="red"
                      />
                      <Typography
                        style={{ fontSize: "10px", color: "#0B1941" }}
                      >
                        Take Photo / Upload the Reciepts/Invoice
                      </Typography>
                    </Paper>
                  </div>
                </Box>
              ) : (
                <>
                  <BackupOutlinedIcon
                    style={{ fontSize: 30 }}
                    color="disabled"
                  />
                  <Typography
                    style={{
                      fontSize: "16px",
                      color: "#0B1941",
                      fontWeight: 500,
                    }}
                  >
                    Upload the Reciepts/Invoice
                  </Typography>
                </>
              )}
              <p
                className="drop-file-input-label-validationText"
                style={{ margin: matches ? "11px" : "4px 0px 4px 0px" }}
              >
                <Typography style={{ fontSize: matches ? "10px" : "12px" }}>
                  <strong>
                    Only JPEG, JPG, XLS, XLSX, PNG and PDF allowed with maximum
                    size of less than 10 MB.
                  </strong>
                </Typography>
              </p>
            </div>
            <input
              type="file"
              id="drag-drop-files"
              value=""
              onChange={(e) => onFileDrop(e)}
              multiple
            />
          </div>
        </Paper>
      </div>

      <div
        className="drop-file-preview-container"
        style={{ padding: matches ? "0px 8px" : "0px 17px" }}
      >
        <Box>
          <Grid
            container
            // spacing={3}
            // spacing={matches ? 2 : 4}
            // style={{ paddingTop: "10px" }}
          >
            {/* {console.log("dispoattt", props.fileDisplayForDD)} */}
            {!props.Edit && props.fileDisplayForDD[props.index]?.length > 0
              ? props.fileDisplayForDD[props.index]?.map((item, index) => (
                  <Grid
                    item
                    xs={matches ? 6 : 1}
                    sm={3}
                    style={{
                      padding:
                        // matches
                        // index % 2 ? "10px 0px 0px 8px" : "10px 8px 0px 0px",
                        //   :
                        "5px 8px 5px",
                    }}
                  >
                    <Chip
                      key={index}
                      //   icon={<ImageConfig />}
                      label={item.name}
                      className={classes.chip}
                      clickable
                      onDelete={() => {
                        fileRemove(item);
                      }}
                      onClick={(e) => handleClick()}
                      deleteIcon={<CloseIcon />}
                    />
                  </Grid>
                ))
              : null}
            {props?.Edit &&
              props.fileDisplayForDD[props.index]?.map((item, index) => (
                <Grid
                  item
                  xs={matches ? 6 : 1}
                  sm={3}
                  style={{
                    padding:
                      //  matches
                      // index % 2 ? "10px 0px 0px 8px" : "10px 8px 0px 0px",
                      //   :
                      "5px 8px 5px",
                  }}
                >
                  <Chip
                    key={index}
                    //   icon={<ImageConfig />}
                    label={item.name}
                    className={matches ? classes.chip1 : classes.chip}
                    clickable
                    onDelete={() => {
                      if (item.AttachmentId) {
                        prefileRemove(item, props.index);
                      } else {
                        fileRemove(item);
                      }
                    }}
                    onClick={(e) => handleClick()}
                    deleteIcon={<CloseIcon />}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      </div>
    </>
  );
};

DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};

export default DropFileInput;
